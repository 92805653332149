import React from "react";
import Icon from "@ant-design/icons";

const PlusIconSvg = ({
  className = "",
  height = 20,
  width = 20,
  style = {},
}) => (
  <span role="img" className={`anticon ${className}`} style={style}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M4 10h12M10 8V4M10 16v-4"
      ></path>
    </svg>
  </span>
);

const PlusIconSecondarySvg = ({ className = "", style = {} }) => (
  <span role="img" className={`anticon ${className}`} style={style}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M10 4.167v11.666M4.167 10h11.666"
      ></path>
    </svg>
  </span>
);
const PlusIconBoldSvg = ({ className = "", style = {} }) => (
  <span role="img" className={`anticon ${className}`} style={style}>
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.0013 4.16602V15.8327M4.16797 9.99935H15.8346"
        stroke="#165ABF"
        strokeWidth="1.67"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </span>
);

const MinusIcon = ({
  className = "",
  height = "24",
  width = "24",
  style = {},
}) => (
  <span role="img" className={`anticon ${className}`} style={style}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M4.167 10h11.666"
      ></path>
    </svg>
  </span>
);

const PlusIcon = (props) => {
  return <Icon component={PlusIconSvg} {...props} />;
};
const PlusIconSecondary = (props) => {
  return <Icon component={PlusIconSecondarySvg} {...props} />;
};
const PlusIconBold = (props) => {
  return <Icon component={PlusIconBoldSvg} {...props} />;
};
export { PlusIcon, PlusIconSecondary, MinusIcon, PlusIconBold };
