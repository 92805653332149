import React from "react";
import { Pagination } from "antd";
import { useState } from "react";
import { ChevronIcon } from "../svgV2/Chevron";

const CustomPagination = ({
  pageSize = 2,
  total = 10,
  containerClassName = "flex items-center justify-end",
  handleOnChange = null,
}) => {
  const [current, setCurrent] = useState(1);

  const onChange = (page, pageSize) => {
    setCurrent(page);
    handleOnChange(page, pageSize);
  };

  return (
    <div className={`custom-pagination relative ${containerClassName}`}>
      <Pagination
        total={total}
        current={current}
        showQuickJumper={false}
        onChange={onChange}
        pageSize={pageSize}
        // hideOnSinglePage
        showSizeChanger={false}
        showTotal={(total, range) => {
          return (
            <div className="text-gray-500 out-500-14">
              <span>
                {range[0]}-{range[1]}{" "}
                <span className="text-grey-50">of {total}</span>
              </span>
            </div>
          );
        }}
        itemRender={(current, type) => {
          if (type === "prev" && current === 0) {
            return (
              <button className="absolute rotate-90 top-[5px]">
                <ChevronIcon
                  className={` ${
                    current === 0 ? "text-gray-300" : "text-gray-500"
                  }`}
                />
              </button>
            );
          }
          if (type === "prev" && current > 0) {
            return (
              <button className="absolute rotate-90 top-[5px]">
                <ChevronIcon
                  className={` ${
                    current === 0 ? "text-gray-300" : "text-gray-500"
                  }`}
                />
              </button>
            );
          }
          if (type === "next") {
            return (
              <button className="absolute -rotate-90 top-[5px] right-2">
                <ChevronIcon
                  className={` ${
                    current * pageSize <= total
                      ? "text-gray-500"
                      : "text-gray-300"
                  }`}
                />
              </button>
            );
          }
          if (type === "page") {
            return null;
          }

          return null;
        }}
      />
    </div>
  );
};

export default CustomPagination;
