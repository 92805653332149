import React, { useEffect } from "react";

import SprintMenu from "./SprintMenu";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";
import EditSprintModal from "../Modals/EditSprintModal";
import {
  resetSchedule,
  updateSchedule,
} from "../../../Core/redux/slices/schedule.slice";
import DeleteSprintModal from "../Modals/DeleteSprintModal";
import CompleteSprintModal from "../Modals/CompleteSprintModal";
import ScheduleLayout from "../Components/ScheduleLayout";
import KanbanBoard from "./KanbanBoard";
import SprintListView from "./SprintListView";
import {
  getGoalsBySprint,
  getLabels,
  getProjectPhases,
  getSwimlanes,
} from "../../../Core/redux/api/scheduleAPI";

import MoveSwimlaneModal from "../Modals/MoveSwimlaneModal";
import DeleteSwimlaneModal from "../Modals/DeleteSwimlaneModal";
import NewLabelModal from "../Modals/NewLabelModal";
import CustomButton from "../../../Core/CommonV2/CustomButton";
// import ImportCsvToJson from "../Modals/ImportCsvToJson";
import ConfirmSprintDates from "../Modals/ConfirmSprintDates";
import { useGetGoalsBySprint } from "../../../Core/redux/ReactQueryHooksV3/useScheduleAPI";
import { DEFAULT_LIMIT } from "../../../Core/constants";
import DeleteTaskModal from "../Modals/DeleteTaskModal";
import ExportModal from "../Modals/ExportModal";

const EmptyState = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { projectId, sprintId } = useParams();
  const { goals } = useAppSelector((state) => state.schedule);
  const onClick = () => {
    localStorage.removeItem("taskRedirectUrl");
    localStorage.setItem("taskRedirectUrl", location.pathname);
    navigate(`/schedule/${projectId}/task/new?sprintId=${sprintId}`);
    return;
  };
  useEffect(() => {
    if (sprintId && sprintId !== "undefined") {
      dispatch(
        getGoalsBySprint({
          projectId,
          sprintId,
        })
      );
    }
  }, [sprintId]);
  return (
    <div className=" w-full flex  items-center justify-center flex-col rounded-lg gap-y-6 h-[calc(100vh-300px)] px-4 py-6">
      <div className="out-500-14 text-center text-black">No task created</div>
      <img src="/images/v2/settings/frosty-search.svg" className="w-[146px]" />
      <p className="out-300-14 text-gray-500">
        Create a new task, bug or new scope.
      </p>
      {sprintId && +sprintId > 0 && (
        <CustomButton text="Create" height="30px" onClick={onClick} />
      )}
    </div>
  );
};

const SprintsScreen = () => {
  const dispatch = useAppDispatch();

  const { projectId, sprintId } = useParams();
  const {
    editSprintModal,
    confirmDatesModal,
    deleteSprintModal,
    completeSprintModal,
    sprintKanbanView,
    moveSwimlaneModal,
    goals,
    deleteStatusModal,
    newLabelModal,
    filterGoalParameter,
    exportModal,
    deleteTaskModal,
    selectedTasks,
    sprints,
    sprintsLoading,
  } = useAppSelector((state) => state.schedule);

  // const { data: Data, isLoading: goalsLoading } = useGetGoalsBySprint({
  //   projectId,
  //   sprintId: +sprintId,
  //   filters: {},
  // });

  // const goals = Data?.result;
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getProjectPhases({ projectId }));
    dispatch(getSwimlanes({ projectId }));
    dispatch(getLabels({ projectId, isMyBoard: false }));
  }, [projectId]);

  useEffect(() => {
    if (
      filterGoalParameter?.sprintIds?.length > 1 ||
      filterGoalParameter?.sprintIds?.length === 0
    ) {
      navigate(`/schedule/${projectId}/sprints`);
    } else if (filterGoalParameter?.sprintIds?.length !== 0) {
      navigate(
        `/schedule/${projectId}/sprints/${filterGoalParameter?.sprintIds[0]}`
      );
    }
  }, [filterGoalParameter]);

  return (
    <ScheduleLayout>
      <div id="schedule-tour-sprints" className="bg-white">
        <SprintMenu />
        {/* {!goalsLoading && ( */}
        <div className="min-h-[calc(100vh-174px)] max-h-[calc(100vh-174px)] bg-gray-50">
          {goals?.length === 0 ? (
            <EmptyState />
          ) : sprintKanbanView ? (
            <KanbanBoard />
          ) : (
            <SprintListView />
          )}
        </div>
        {/* )} */}
      </div>
      <EditSprintModal
        visible={editSprintModal.visible}
        onClose={() => dispatch(resetSchedule(["editSprintModal"]))}
      />
      <ConfirmSprintDates
        visible={confirmDatesModal?.visible}
        onClose={() => dispatch(resetSchedule(["confirmDatesModal"]))}
      />
      <DeleteSprintModal
        visible={deleteSprintModal.visible}
        onClose={() => dispatch(resetSchedule(["deleteSprintModal"]))}
      />
      <CompleteSprintModal
        visible={completeSprintModal.visible}
        onClose={() => dispatch(resetSchedule(["completeSprintModal"]))}
      />

      {moveSwimlaneModal && (
        <MoveSwimlaneModal
          visible={moveSwimlaneModal}
          onCancel={() => dispatch(resetSchedule(["moveSwimlaneModal"]))}
        />
      )}
      {/* {<ImportCsvToJson />}  */}

      {deleteStatusModal && (
        <DeleteSwimlaneModal
          visible={deleteStatusModal}
          onClose={() =>
            dispatch(resetSchedule(["deleteStatusModal", "swimlaneDetails"]))
          }
        />
      )}

      <NewLabelModal
        visible={newLabelModal}
        onClose={() => dispatch(resetSchedule(["newLabelModal"]))}
      />
      <ExportModal
        visible={exportModal}
        onClose={() => {
          dispatch(
            updateSchedule({
              key: "exportModal",
              value: false,
            })
          );
        }}
      />
      <DeleteTaskModal
        selectedTasks={selectedTasks}
        visible={deleteTaskModal.visible}
        onClose={() => {
          dispatch(
            updateSchedule({
              key: "selectedTasks",
              value: [],
            })
          );
          dispatch(
            updateSchedule({
              key: "deleteTaskModal",
              value: { ...deleteTaskModal, visible: false },
            })
          );
        }}
      />
    </ScheduleLayout>
  );
};

export default SprintsScreen;
