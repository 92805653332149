import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";
import NewLabelModal from "../Modals/NewLabelModal";
import { resetSchedule } from "../../../Core/redux/slices/schedule.slice";
import MyBoardMenu from "./MyBoardMenu";
import {
  getAllMyBoardTasks,
  getCollaboratorsForCurrentUser,
  getMyBoardTasks,
} from "../../../Core/redux/api/myBoardAPI";
import TaskItem from "./TaskItem";
import CustomPagination from "../../../Core/CommonV3/CustomPagination";
import {
  resetToInitialMyBoard,
  updateMyBoard,
} from "../../../Core/redux/slices/myBoard.slice";
import useDebounce from "../../../Core/hooks/useDebounceValue";
import {
  CreateTaskHeader,
  PrimaryTaskHeader,
  SelectedTaskHeader,
} from "./MyBoardTaskHeaders";
import { DEFAULT_LIMIT } from "../../../Core/constants";
import DeleteTaskModal from "../Modals/DeleteTaskModal";
import MyBoardLayout from "./MyBoardLayout";
import TaskCard from "./TaskCard";
import KanbanBoard from "./KanbanBoard";
import { getLabels } from "../../../Core/redux/api/scheduleAPI";
import ManageLabelsModal from "../Modals/ManageLabelsModal";
import ExportModal from "./Modals/ExportModal";
import MoveToProjectModal from "./Modals/MoveToProjectModal";
import MyBoardLabel from "../../../Core/CommonV3/MyBoardLabel";
import AddCollaboratorsModal from "./Modals/AddCollaboratorsModal";
import { updateDashboard } from "../../../Core/redux/slices/dashboard.slice";
import { useNavigate, useParams } from "react-router-dom";

const MyBoardScreen = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { user } = useAppSelector((state) => state.userDetails);
  const { tenantDetails } = useAppSelector((state) => state.tenant);
  const { newLabelModal, manageLabelModal, labels } = useAppSelector(
    (state) => state.schedule
  );
  const { taskId, subtaskId } = useParams();
  const {
    myBoardTasks,
    selectedTasks,
    deleteTaskModal,
    createTaskHeader,
    exportModal,
    projectListModal,
    addCollaboratorsModal,
    myBoardKanbanView,
    filters,
  } = useAppSelector((state) => state.myBoard);

  const [offset, setOffset] = useState(0);
  const [search, setSearch] = useState(null);
  const debouncedValue = useDebounce(search, 500);

  const labelOptions = labels
    ?.filter((item) => item.isMyBoard)
    ?.map((label) => {
      return {
        id: label.id,
        value: label.id,
        label: label.name,
        render: () => (
          <div className="flex items-center justify-between">
            <MyBoardLabel labelColorId={label.labelColorId} text={label.name} />
            <div className="h-[14px] w-[14px] rounded-full" />
          </div>
        ),
      };
    });

  const getHeader = () => {
    if (selectedTasks.length > 0) return <SelectedTaskHeader />;
    if (createTaskHeader)
      return <CreateTaskHeader labelOptions={labelOptions} />;
    return <PrimaryTaskHeader />;
  };

  const handlePagination = async (page: number) => {
    setOffset((page - 1) * DEFAULT_LIMIT);
  };

  const handleMultipleSelect = (e, taskId) => {
    e.preventDefault();
    const isCtrlPressed = e.ctrlKey || e.metaKey;
    const currentTask = myBoardTasks?.result?.find((item) => item.id == taskId);
    const isCreatedByTheUser = currentTask?.createdById == user.id;

    if (isCreatedByTheUser && isCtrlPressed) {
      dispatch(
        updateMyBoard({
          key: "selectedTasks",
          value: selectedTasks?.includes(taskId)
            ? selectedTasks.filter((item) => item !== taskId)
            : [...selectedTasks, taskId],
        })
      );
    }

    if (!isCreatedByTheUser && isCtrlPressed) {
      dispatch(
        updateDashboard({
          key: "alertPopupModal",
          value: {
            visible: true,
            data: {
              title: "Action not possible",
              subtitle: "",
              description:
                "The task cannot be moved or deleted which are not created by you.",
            },
          },
        })
      );
    }

    if (selectedTasks?.length > 0 && !isCtrlPressed) {
      if (selectedTasks?.length > 0) {
        dispatch(
          updateMyBoard({
            key: "selectedTasks",
            value: [],
          })
        );
      }
    }
  };

  useEffect(() => {
    const payload: { limit: number; offset: number; searchQuery?: string } = {
      limit: myBoardKanbanView ? null : DEFAULT_LIMIT,
      offset: myBoardKanbanView ? null : offset,
    };

    if (debouncedValue) payload.searchQuery = debouncedValue;

    dispatch(getMyBoardTasks(payload));
    dispatch(getCollaboratorsForCurrentUser({}));
  }, [offset, debouncedValue, myBoardKanbanView]);

  useEffect(() => {
    dispatch(getLabels({ projectId: null, isMyBoard: true }));
    dispatch(getAllMyBoardTasks({}));
  }, []);
  useEffect(() => {
    dispatch(
      updateMyBoard({
        key: "selectedTasks",
        value: [],
      })
    );
  }, [tenantDetails.tenantId]);
  const handleFilter = (goals) => {
    let filteredGoals = [...goals];

    // filter by assignee

    if (filters?.collaborator?.length > 0) {
      filteredGoals = filteredGoals?.filter((goal) =>
        goal?.collaborators?.some((collaboratorId) =>
          filters?.collaborator?.includes(collaboratorId)
        )
      );
    }

    return filteredGoals;
  };
  const filterGoals = (goals) => {
    const filteredGoals = handleFilter([...goals]);
    return filteredGoals;
  };

  return (
    <MyBoardLayout>
      <MyBoardMenu search={search} setSearch={setSearch} />

      {myBoardKanbanView ? (
        <KanbanBoard />
      ) : (
        <div className="px-6 py-3 bg-gray-50 h-[calc(100vh-162px)] max-h-[calc(100vh-162px)] overflow-y-scroll">
          {getHeader()}

          {myBoardTasks?.result?.length === 0 ? (
            <div className="px-3 py-[7px] bg-slate-50 h-10.5 rounded-bl-xl rounded-br-xl border border-dashed border-slate-200">
              <p className="text-center out-300-12 text-gray-500">
                Your personal task list is empty, create a new task
              </p>
            </div>
          ) : (
            <>
              {filterGoals(myBoardTasks?.result)?.map((task, index) => (
                <TaskItem
                  firstItem={index === 0}
                  selected={selectedTasks.includes(task.id)}
                  key={task.id}
                  task={task}
                  labelOptions={labelOptions}
                  handleMultipleSelect={handleMultipleSelect}
                />
              ))}

              {!filters.collaborator.length &&
                !filters.priority.length &&
                !filters.status.length &&
                !filters.label.length && (
                  <div className="flex items-center justify-between w-full py-3 bg-white border border-x rounded-b-xl">
                    <div className="flex-1" />
                    <CustomPagination
                      handleOnChange={handlePagination}
                      total={myBoardTasks?.totalRecords}
                      pageSize={DEFAULT_LIMIT}
                    />
                  </div>
                )}
            </>
          )}
        </div>
      )}

      <NewLabelModal
        visible={newLabelModal}
        onClose={() => dispatch(resetSchedule(["newLabelModal"]))}
        isMyBoard
      />

      <ManageLabelsModal
        visible={manageLabelModal}
        onClose={() => dispatch(resetSchedule(["manageLabelModal"]))}
      />

      <DeleteTaskModal
        visible={deleteTaskModal.visible}
        selectedTasks={selectedTasks}
        onClose={() => {
          const payload: {
            limit: number;
            offset: number;
            searchQuery?: string;
          } = {
            limit: myBoardKanbanView ? null : DEFAULT_LIMIT,
            offset: myBoardKanbanView ? null : offset,
          };
          dispatch(getMyBoardTasks(payload));

          dispatch(resetToInitialMyBoard(["deleteTaskModal"]));
          dispatch(
            updateMyBoard({
              key: "selectedTasks",
              value: [],
            })
          );
          if (taskId || subtaskId) {
            navigate(-1);
          }
        }}
      />

      <ExportModal
        visible={exportModal}
        onClose={() => {
          dispatch(
            updateMyBoard({
              key: "exportModal",
              value: false,
            })
          );
        }}
      />

      <AddCollaboratorsModal
        visible={addCollaboratorsModal}
        onClose={() => {
          dispatch(
            updateMyBoard({
              key: "addCollaboratorsModal",
              value: false,
            })
          );
        }}
      />

      <MoveToProjectModal
        visible={projectListModal}
        onClose={() => {
          dispatch(
            updateMyBoard({
              key: "projectListModal",
              value: false,
            })
          );
        }}
      />
    </MyBoardLayout>
  );
};

export default MyBoardScreen;
