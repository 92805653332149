/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react";

import { Checkbox, Popover, Tooltip } from "antd";
import CustomBadge from "../../../Core/CommonV2/CustomBadge";
import PriorityFlag from "../../../Core/svgV2/PriorityFlag";
import CustomAvatar from "../../../Core/CommonV2/CustomAvatar";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";
import getUsersName from "../../../Core/utils/getUserName";
import { useNavigate, useParams } from "react-router-dom";
import CustomSelect, { MultiSelect } from "../../../Core/CommonV2/CustomSelect";
import { updateSchedule } from "../../../Core/redux/slices/schedule.slice";
import { PlusIcon } from "../../../Core/svgV2/PlusIcon";

import TimeInput from "../Task/TimeInput";
import { TASK_ACTIVITY_LOG_ACTIONS } from "../../../Core/constants";
import { getPriorityDetails } from "../../../Core/utils/scheduleUtils";
import { TaskInput } from "../Task/TaskReusable";
import { updateGlobalKey } from "../../../Core/redux/slices/global.slice";
import SubTaskIcon from "../../../Core/svgV3/SubtaskIcon";
import TaskIcon from "../../../Core/svgV3/TaskIcon";
import dayjs from "dayjs";
import getStatus from "./getStatus";
import { ChevronIcon } from "../../../Core/svgV2/Chevron";
import SubTaskItem, { NewSubTaskItem } from "./SubTaskItem";
import {
  LabelSelect,
  PrioritySelect,
  StatusSelect,
  SubtaskButton,
  TimeSelect,
  UserSelect,
} from "./TaskReusable";

import {
  getMyBoardTaskById,
  getMyBoardTasks,
  updateMyBoardTask,
} from "../../../Core/redux/api/myBoardAPI";
import LabelIcon from "../../../Core/svgV3/LabelIcon";
import getCurrentUser from "./getCurrentUser";
import { updateMyBoard } from "../../../Core/redux/slices/myBoard.slice";
import CustomButton from "../../../Core/CommonV2/CustomButton";
import CustomInputBox from "../../../Core/CommonV2/CustomInputBox";
import { DotsVerticalIcon } from "../../../Core/svgV2/DotsVertical";
import { DeleteScheduleIcon } from "../../../Core/svgV3/DeleteIcon";
import MyBoardLabel from "../../../Core/CommonV3/MyBoardLabel";
import copyTextToClipboard from "../../../Core/utils/clipboard";
import { client } from "../../../Core/utils/axiosClient";
import { PencilEditIcon } from "../../../Core/svgV3/PencilEditIcon";
import { CustomDatePicker } from "../Modals/ManageLabelsModal";

export const ThreeDots = ({ task }) => {
  const dispatch = useAppDispatch();
  const { taskId, subtaskId } = useParams();
  const { deleteTaskModal } = useAppSelector((state) => state.myBoard);

  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const handleDuplicate = async () => {
    try {
      const { data } = await client.post("/my-board/duplicate-goal", {
        id: subtaskId ? subtaskId : task.id,
      });
      await dispatch(
        getMyBoardTaskById({ taskId: subtaskId ? subtaskId : task.id })
      );

      if (subtaskId) {
        dispatch(
          updateMyBoard({
            key: "currentSelectedSubTask",
            value: { ...data.result },
          })
        );
      } else {
        dispatch(
          updateMyBoard({
            key: "currentSelectedTask",
            value: { ...data.result },
          })
        );
      }

      const url = subtaskId
        ? `/my-board/task/${+task.id}/subtask/${data.result.id}`
        : `/my-board/task/${+data.result.id}`;
      navigate(url);
    } catch (e) {
      console.log(e);
    }
  };
  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };

  const options = [
    {
      id: 1,
      value: "Copy link",
      onClick: () => {
        copyTextToClipboard(window.location.href + `/task/${task.id}`);
        setOpen(false);
      },
    },
    {
      id: 2,
      value: "Duplicate",
      onClick: () => {
        handleDuplicate();
        setOpen(false);
      },
    },
    {
      id: 3,
      value: "Delete",
      icon: <DeleteScheduleIcon />,
      onClick: () => {
        dispatch(
          updateMyBoard({
            key: "selectedTasks",
            value: subtaskId ? [subtaskId] : [task.id],
          })
        );
        dispatch(
          updateMyBoard({
            key: "deleteTaskModal",
            value: { ...deleteTaskModal, visible: true },
          })
        );

        setOpen(false);
      },
    },
  ];

  return (
    <Popover
      getPopupContainer={(parent) => parent?.parentElement}
      content={
        <div className="min-w-[200px] overflow-hidden">
          {options.map((option) => (
            <div
              key={option.id}
              role="button"
              className={`flex items-center gap-x-3 px-3 py-1.5 hover:bg-gray-50 ${
                option.id === 3 && "border-t border-gray-200"
              }`}
              onClick={option.onClick}
            >
              {option.icon}
              <p
                className={`out-300-14 ${
                  option.id === 3 ? "text-error-600" : "text-gray-600 "
                }`}
              >
                {option.value}
              </p>
            </div>
          ))}
        </div>
      }
      arrow={false}
      open={open}
      onOpenChange={handleOpenChange}
      placement="bottomLeft"
      trigger={"click"}
      destroyTooltipOnHide
    >
      <button
        className={taskId || subtaskId ? "" : ` invisible group-hover:visible`}
      >
        <DotsVerticalIcon className="text-gray-500" />
      </button>
    </Popover>
  );
};

const TaskItem = ({
  firstItem,
  selected,
  task,
  labelOptions,
  handleMultipleSelect,
  showSubtaskKanbanBoard,
  setShowSubtaskKanbanBoard,
  className = "",
  id = "",
}: {
  firstItem?: boolean;
  selected?: boolean;
  task: any;
  labelOptions: any;
  handleMultipleSelect?: any;
  showSubtaskKanbanBoard?: boolean;
  setShowSubtaskKanbanBoard?: any;
  className?: any;
  id?: string;
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { projectId } = useParams();

  const { myBoardKanbanView } = useAppSelector((state) => state.myBoard);
  const [showSubtasks, setShowSubTasks] = useState(false);
  const [newSubtask, setNewSubtask] = useState(false);

  const [taskDetails, setTaskDetails] = useState(task);
  const [titleEdit, setTitleEdit] = useState(false);

  const [dateRange, setDateRange] = useState(
    taskDetails?.startDate && taskDetails?.endDate
      ? [dayjs(taskDetails.startDate), dayjs(taskDetails.endDate)]
      : null
  );
  const [showRangePicker, setShowRangePicker] = useState(false);

  useEffect(() => {
    setTaskDetails(task);
  }, [task]);

  const formattedPlaceholder =
    taskDetails?.startDate && taskDetails?.endDate
      ? `${dayjs(taskDetails.startDate).format("DD MMM")} - ${dayjs(
          taskDetails.endDate
        ).format("DD MMM")}`
      : "Not Set";

  const handleDateChange = async (dates) => {
    if (dates[0] && dates[1]) {
      const formattedStartDate = dayjs(dates[0]).format(
        "YYYY-MM-DD HH:mm:ss.SSS"
      );
      const formattedEndDate = dayjs(dates[1]).format(
        "YYYY-MM-DD HH:mm:ss.SSS"
      );

      await setTaskDetails({
        ...taskDetails,
        startDate: formattedStartDate,
        endDate: formattedEndDate,
      });
      await dispatch(
        updateMyBoardTask({
          startDate: formattedStartDate,
          endDate: formattedEndDate,
          goalId: task.id,
          action: {
            oldValue: task.timeLogged,
            newValue: dates,
            actionName: TASK_ACTIVITY_LOG_ACTIONS.TIMELOG_CHANGE,
          },
        })
      );
    }
  };

  return (
    <>
      <div
        id={id}
        data-taskItem={id}
        role="button"
        className={`overflow-hidden group ${className}  ${
          myBoardKanbanView
            ? " mb-2  rounded-[8px]"
            : "border-t border-x last:border-b last:rounded-b-xl"
        } ${selected ? "border border-sky-200" : ""}
        ${showSubtasks && !myBoardKanbanView ? "bg-primary-100" : ""} ${
          firstItem ? "rounded-t-xl" : ""
        }`}
        onDoubleClick={async () => {
          // dispatch(updateGlobalKey({ key: "loading", value: true }));

          // dispatch(
          //   updateMyBoard({
          //     key: "currentSelectedTask",
          //     value: { ...taskDetails },
          //   })
          // );
          await dispatch(getMyBoardTaskById({ taskId: task.id }));

          navigate(`/my-board/task/${task.id}`);
        }}
        onClick={(e) =>
          myBoardKanbanView
            ? console.log("")
            : handleMultipleSelect(e, taskDetails?.id)
        }
      >
        <div
          className={`flex items-center px-3 py-2 ${
            myBoardKanbanView ? "" : "border-gray-200"
          }  gap-x-3 ${
            selected || myBoardKanbanView || showSubtasks
              ? "bg-primary-100"
              : "bg-white"
          }`}
        >
          {taskDetails?.subtask?.length > 0 ? (
            <button
              className={` ${
                myBoardKanbanView
                  ? showSubtaskKanbanBoard
                    ? ""
                    : "-rotate-90"
                  : showSubtasks
                  ? "rotate-180"
                  : "-rotate-90"
              } `}
              onClick={() => {
                myBoardKanbanView
                  ? setShowSubtaskKanbanBoard(!showSubtaskKanbanBoard)
                  : setShowSubTasks(!showSubtasks);
              }}
            >
              <ChevronIcon
                className={` ${
                  showSubtasks ? "text-primary-600" : "text-gray-500"
                }`}
              />
            </button>
          ) : (
            <div className="w-5 h-5" />
          )}
          <div>
            <TaskIcon />
          </div>
          <p
            className="text-gray-500 cursor-pointer out-400-10 hover:underline text-[10px]"
            role="button"
            onClick={async () => {
              dispatch(updateGlobalKey({ key: "loading", value: true }));
              // dispatch(
              //   updateMyBoard({
              //     key: "currentSelectedTask",
              //     value: { ...taskDetails },
              //   })
              // );
              await dispatch(getMyBoardTaskById({ taskId: task.id }));
              navigate(`/my-board/task/${task.id}`);
            }}
          >
            {`MB-${task.identifier ? task.identifier : task.id}`}{" "}
          </p>
          {titleEdit ? (
            <div className="flex-1 ">
              {" "}
              <TaskInput
                autoSize
                value={taskDetails.name}
                placeholder="Enter a title"
                plannerScreen
                autoFocus={true}
                type="planner"
                onSave={async (e, save) => {
                  if (save) {
                    e.preventDefault();
                    setTaskDetails({ ...taskDetails, name: e.target.value });
                    await dispatch(
                      updateMyBoardTask({
                        name: e.target.value,
                        goalId: taskDetails.id,
                      })
                    );
                    setTitleEdit(false);
                  }
                }}
                onChange={async (e) => {
                  setTaskDetails({ ...taskDetails, name: e.target.value });
                }}
              />
            </div>
          ) : (
            <p
              role="button"
              onDoubleClick={async (e) => {
                e.stopPropagation();
                dispatch(updateGlobalKey({ key: "loading", value: true }));

                // dispatch(
                //   updateMyBoard({
                //     key: "currentSelectedTask",
                //     value: { ...taskDetails },
                //   })
                // );
                await dispatch(getMyBoardTaskById({ taskId: task.id }));
                navigate(`/my-board/task/${task.id}`);

                // handleSelect(taskId);
              }}
              className="flex-1 w-full text-slate-700 out-300-14 truncate"
              id="currentInputBoxMaxWidth"
            >
              <span
                onClick={() => {
                  setTitleEdit(true);
                }}
                className="inline-block rounded-[4px] p-1"
              >
                <div className="flex gap-0.5 items-center ">
                  <Tooltip title={taskDetails.name} placement="topLeft">
                    <p className="text-slate-700 overflow-hidden whitespace-nowrap overflow-ellipsis">
                      {taskDetails.name}
                    </p>
                  </Tooltip>
                  <span className="opacity-0 group-hover:opacity-100">
                    <PencilEditIcon />
                  </span>
                </div>
              </span>
            </p>
          )}

          <TimeSelect
            task={taskDetails}
            setTask={setTaskDetails}
            visible={true}
          />

          <LabelSelect
            visible={true}
            task={taskDetails}
            labelOptions={labelOptions}
            setTask={async (e) => {
              setTaskDetails({ ...taskDetails, labelId: e });
              await dispatch(
                updateMyBoardTask({ labelId: e, goalId: taskDetails.id })
              );
            }}
          />

          <StatusSelect
            task={taskDetails}
            setTask={async (e) => {
              setTaskDetails({ ...taskDetails, statusId: e });
              await dispatch(
                updateMyBoardTask({ statusId: e, goalId: taskDetails.id })
              );
            }}
          />
          <div className="text-black out-300-12">
            <Tooltip
              className="cursor-default"
              title="Duration"
              placement="bottom"
            >
              <div>
                <div
                  className="text-black out-300-12"
                  onClick={() => {
                    setShowRangePicker(!showRangePicker);
                  }}
                >
                  <CustomDatePicker
                    value={dateRange}
                    onChange={handleDateChange}
                    className=""
                    placeholder={formattedPlaceholder}
                  />
                </div>
              </div>
            </Tooltip>
          </div>

          <PrioritySelect
            task={taskDetails}
            setTask={async (e) => {
              setTaskDetails({ ...taskDetails, priorityId: e });

              await dispatch(
                updateMyBoardTask({ priorityId: e, goalId: taskDetails.id })
              );
            }}
          />
          <SubtaskButton
            subTaskLength={taskDetails.subtask.length}
            handleClick={
              () => {
                setNewSubtask(!newSubtask);
              }
              // navigate(`/my-board/task/${task.id}/subtask/new`)
            }
          />

          <UserSelect task={taskDetails} />

          <ThreeDots task={taskDetails} />
        </div>
      </div>

      {(showSubtasks && !myBoardKanbanView) || newSubtask ? (
        <div>
          <NewSubTaskItem
            parentGoalId={task.id}
            setNewSubtask={(val) => setNewSubtask(val)}
          />
        </div>
      ) : null}
      {showSubtasks && !myBoardKanbanView ? (
        <div>
          {task?.subtask?.map((subtask) => (
            <SubTaskItem key={subtask.id} subtask={subtask} />
          ))}
        </div>
      ) : null}
    </>
  );
};

export default TaskItem;
