import React, { useCallback, useEffect } from "react";
import SettingsLayout from "./SettingsLayout";
import General from "./Tabs/General";
import { useSearchParams } from "react-router-dom";
import Phases from "./Tabs/Phases";
import ProjectRolesAndPermissions from "./Tabs/ProjectRolesAndPermissions";
import ProjectMembers from "./Tabs/ProjectMembers";
import Preferences from "./Tabs/Preferences";
import Notifications from "./Tabs/Notifications";
import Labels from "./Tabs/Labels";
import StartGuide from "./Tabs/StartGuide";
import WorkspaceRoles from "./Tabs/WorkspaceRoles";
import WorkspaceMembers from "./Tabs/WorkspaceMembers";
import Integrations from "./Tabs/Integrations";
import { useAppDispatch, useAppSelector } from "../../Core/redux/hooks";
import {
  getProjectDetatis,
  getProjectList,
  getProjectPermission,
} from "../../Core/redux/api/projectAPI";
import { getTenantMembers } from "../../Core/redux/api/tenantAPI";
import RemoveMemberModal from "./Modals/RemoveMemberModal";
import { resetSettings } from "../../Core/redux/slices/settings.slice";
import DeleteLabelModal from "../Schedule/Modals/DeleteLabelModal";
import { resetSchedule } from "../../Core/redux/slices/schedule.slice";

const TAB_COMPONENTS = {
  General,
  Phases,
  ProjectRoles: ProjectRolesAndPermissions,
  ProjectMembers,
  Preferences,
  Notifications,
  Labels,
  "Start-Guide": StartGuide,
  WorkspaceRoles,
  WorkspaceMembers,
  Integrations,
};

function SettingsScreen() {
  const [searchParams] = useSearchParams();

  const dispatch = useAppDispatch();
  const { currentTenantId } = useAppSelector((state) => state.tenant);
  const { user } = useAppSelector((state) => state.userDetails);
  const { removeMemberModal } = useAppSelector((state) => state.settings);
  const { deleteLabelModal } = useAppSelector((state) => state.schedule);

  useEffect(() => {
    if (searchParams.has("projectId")) {
      dispatch(getProjectDetatis({ projectId: searchParams.get("projectId") }));
      dispatch(
        getProjectPermission({
          projectId: searchParams.get("projectId"),
          userId: user.id,
        })
      );
    }
  }, [searchParams]);

  useEffect(() => {
    dispatch(getTenantMembers({}));
    dispatch(getProjectList(user.id));
  }, [currentTenantId]);

  const render = useCallback(() => {
    const selectedTab = searchParams.get("tab");
    console.log(selectedTab, "sel tab");
    const Component = TAB_COMPONENTS[selectedTab];
    return Component ? <Component /> : null;
  }, [searchParams]);

  return (
    <SettingsLayout>
      {render()}
      <RemoveMemberModal
        visible={removeMemberModal.visible}
        onClose={() => dispatch(resetSettings(["removeMemberModal"]))}
      />
      <DeleteLabelModal
        visible={deleteLabelModal.visible}
        onClose={() => dispatch(resetSchedule(["deleteLabelModal"]))}
      />
    </SettingsLayout>
  );
}

export default SettingsScreen;
