import React, { useEffect } from "react";

import { useAppDispatch, useAppSelector } from "../../../../Core/redux/hooks";
import {
  resetSchedule,
  updateSchedule,
} from "../../../../Core/redux/slices/schedule.slice";
import { PlusIconSecondary } from "../../../../Core/svgV2/PlusIcon";

import usePermission from "../../../../Core/hooks/usePermission";
import { useParams } from "react-router-dom";
import {
  getLabels,
  updateProgress,
} from "../../../../Core/redux/api/scheduleAPI";

import {
  useGetLabels,
  useGetProjectPhases,
} from "../../../../Core/redux/ReactQueryHooksV3/useScheduleAPI";
import CompletePhaseModal from "../../../../ModulesV2/Dashboard/Modals/CompletePhaseModal";
import ErrorMessageModal from "../../../../ModulesV2/Dashboard/Modals/ErrorMessageModal";
import PhaseModal from "../../../../ModulesV2/Schedule/Modals/PhaseModal";
import ConfirmSprintDates from "../../../../ModulesV2/Schedule/Modals/ConfirmSprintDates";
import EditSprintModal from "../../../../ModulesV2/Schedule/Modals/EditSprintModal";
import GanttChart from "./GanttChart";
import PhaseCard from "../../../../ModulesV2/Schedule/Roadmap/PhaseCard";
import TimelineMenu from "../TimelineMenu";
import ScheduleLayout from "../../../../ModulesV2/Schedule/Components/ScheduleLayout";

import LabelCard from "./LabelCard";
import GanttChartNavigator from "./GanttChartNavigator";

const GanttChartScreen = () => {
  const { editSprintModal, confirmDatesModal, phaseModal } = useAppSelector(
    (state) => state.schedule
  );
  const { completePhaseModal, errorMessageModal } = useAppSelector(
    (state) => state.dashboard
  );
  const dispatch = useAppDispatch();
  const { projectId } = useParams();
  const { labels, timelineSelected, goalsByLabelId } = useAppSelector(
    (state) => state.schedule
  );

  const { hasPermission: PHASE_AND_SPRINT_ADD } = usePermission("5");

  useEffect(() => {
    if (projectId) {
      // feth project phases
      dispatch(
        updateProgress({
          projectId,
        })
      );
    }
    dispatch(resetSchedule(["goalsByLabelId"]));
    dispatch(getLabels({ projectId, isMyBoard: false }));
  }, [projectId]);

  useEffect(() => {
    if (
      labels &&
      labels.filter((label) => label.projectId === projectId).length === 0
    ) {
      dispatch(updateSchedule({ key: "timelineSelected", value: 3 }));
    }
  }, [labels, projectId]);
  return (
    <>
      {
        <div className="h-[calc(100vh-160px)] bg-white">
          <div className="flex">
            {timelineSelected !== 3 && (
              <div className=" flex flex-col min-w-[265px] max-w-[22%] overflow-x-hidden  max-h-[calc(100vh-159px)] min-h-[calc(100vh-159px)] overflow-y-auto border-r border-gray-200">
                <div className="px-6 py-3 mt-3 ">
                  <p className="out-500-14 text-gray-700">Gantt Chart</p>
                </div>
                <div className="flex flex-col flex-1">
                  <div
                    className={` flex flex-col gap-[1px] ${
                      timelineSelected === 2 ? "mt-[67px]" : "mt-[47px]"
                    }`}
                  >
                    {labels
                      ?.filter((label) => label.projectId === projectId)
                      .map((label, index) => (
                        <LabelCard key={label.id} label={label} index={index} />
                      ))}
                  </div>
                </div>
              </div>
            )}
            <div className="w-full  max-h-[calc(100vh-159px)] min-h-[calc(100vh-159px)] overflow-x-auto">
              <GanttChart />
            </div>
          </div>
          {labels &&
            labels.filter((label) => label.projectId === projectId).length >
              0 && (
              <div className="absolute bottom-3 right-6 z-50">
                <GanttChartNavigator />
              </div>
            )}
        </div>
      }

      <EditSprintModal
        visible={editSprintModal.visible}
        onClose={() => dispatch(resetSchedule(["editSprintModal"]))}
      />
      <ConfirmSprintDates
        visible={confirmDatesModal?.visible}
        onClose={() => dispatch(resetSchedule(["confirmDatesModal"]))}
      />
      {phaseModal.visible ? (
        <PhaseModal
          visible={phaseModal.visible}
          onClose={() => dispatch(resetSchedule(["phaseModal"]))}
        />
      ) : null}

      {errorMessageModal.visible && <ErrorMessageModal status={1} />}
      {completePhaseModal.visible && <CompletePhaseModal />}
    </>
  );
};

export default GanttChartScreen;
