import { Radio, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import CustomButton from "../../../Core/CommonV2/CustomButton";
import type { RadioChangeEvent } from "antd";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";
import {
  changeStep,
  compeleteResetProjectOnboarding,
  updateProjectOnboarding,
} from "../../../Core/redux/slices/projectOnboarding.slice";
import CurrentStep from "./CurrentStep";
import StepHeading from "./StepHeading";
import StepLayout from "./StepLayout";
import { divide } from "lodash";
import { ChevronIcon } from "../../../Core/svgV2/Chevron";
import { subset } from "semver";
import { EllipseIcon } from "../../../Core/svgV2/Ellipse";
import { InfoCircleOutlined } from "@ant-design/icons";
import { PhaseIcon } from "../../../Core/svgV2/PhaseIcon";
import { CrossIconLarge } from "../../../Core/svgV2/CrossIcon";
import { getPhaseColor } from "../../../Core/utils/getPhaseName";
import { useNavigate } from "react-router";
import { updateDashboard } from "../../../Core/redux/slices/dashboard.slice";
import { client } from "../../../Core/utils/axiosClient";
import { useQueryClient } from "react-query";

const ProjectTypeCard = ({ img, title, select, type, isSelected }) => {
  const [open, setOpen] = useState(isSelected);
  const dispatch = useAppDispatch();
  const { isOpen } = useAppSelector((state) => state.projectOnboarding);

  return (
    <Tooltip
      placement="bottomLeft"
      overlayClassName="min-w-[371px]"
      open={isOpen === type}
      title={
        <div className="flex">
          <div>
            Awesome, curating a list of components based on your selection!
          </div>
          <div
            onClick={() => {
              setOpen(false);
              dispatch(
                updateProjectOnboarding({
                  key: "isOpen",
                  value: 0,
                })
              );
            }}
            className="cursor-pointer"
          >
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12 4L4 12M4 4L12 12"
                stroke="#ffffff"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </div>
      }
    >
      <div
        className={`${
          isSelected
            ? "bg-primary-600"
            : "bg-white hover:bg-[#F0F5FA] border border-gray-200"
        } rounded-2xl flex justify-between flex-col h-32 w-32 cursor-pointer`}
        onClick={() => {
          select(type);
          setOpen(true);
        }}
      >
        <div className="flex justify-center items-center h-full">
          <img src={img} alt="" />
        </div>
        <div
          className={`out-500-12 ${
            isSelected ? "text-white" : "text-gray-500"
          } w-full text-center pb-4`}
        >
          {title}
        </div>
      </div>
    </Tooltip>
  );
};
const PhaseCTA = ({ isSelected, text, phaseId, color, select }) => {
  const [hover, setHover] = useState(false);
  return (
    <div
      className={`flex gap-x-3 rounded items-center  cursor-pointer px-3 py-2 ${
        isSelected ? "border border-transparent" : "border border-gray-200"
      }`}
      onClick={() => select(phaseId)}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      style={{
        backgroundColor: isSelected ? getPhaseColor(phaseId) : "white",
        transition: "all  0.5s ease-in-out ",
      }}
    >
      <PhaseIcon height="20" width="20" phaseId={+phaseId} />
      <div
        className={`out-500-14 ${isSelected ? "text-white" : "text-gray-500"}`}
      >
        {text}
      </div>
      {isSelected ? <div className="out-300-20 text-white">|</div> : ""}
      {isSelected ? (
        <button className="h-5 w-5">
          <CrossIconLarge className="text-white " />
        </button>
      ) : (
        ""
      )}
    </div>
  );
};
const Step4 = () => {
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();
  const [error, setError] = useState(false);
  const navigate = useNavigate();
  const {
    projectName,
    projectImage,
    projectColor,

    clientName,
    clientImage,
    internalProject,
    clientColor,

    projectDuration,
    clientLocation,
    // step 4
    softwareType,
    projectType,
    hasPhases,
    // step 5
    selectedPhase,
    phaseDetails,
    components,
  } = useAppSelector((state) => state.projectOnboarding);
  const handleSelectPhase = (id) => {
    setError(false);
    if (selectedPhase.includes(id)) {
      dispatch(
        updateProjectOnboarding({
          key: "selectedPhase",
          value: selectedPhase.filter((i) => i !== id),
        })
      );
    } else {
      dispatch(
        updateProjectOnboarding({
          key: "selectedPhase",
          value: [...selectedPhase, id],
        })
      );
    }
  };
  const [projectTypeValue, setProjectTypeValue] = useState(projectType);
  const [showPhases, setShowPhases] = useState(false);
  const handelCreateProject = async () => {
    const phases = phaseDetails.filter((p) => selectedPhase.includes(p.id));

    const project = {
      title: projectName,
      //components,
      startDate: projectDuration[0],
      endDate: projectDuration[1],
      projectColor,
      projectImage,
      clientName,
      clientImage,
      clientLocation,
      projectType,
      phases,
      internalProject,
      clientColor,
      hasPhases: false,
    };
    const { data } = await client.post(`/project/create`, project);

    queryClient.invalidateQueries("projectList");

    if (data.projectId) {
      dispatch(
        updateProjectOnboarding({
          key: "projectCreationModal",
          value: { visible: true, data: data },
        })
      );
    }
  };

  useEffect(
    () => console.log(projectTypeValue, "projectTypeValue"),
    [projectTypeValue]
  );
  const handleRadioButtonChange = (e: RadioChangeEvent) => {
    setProjectTypeValue(e.target.value);

    // setSelectedSize({ current: e.target.value });
  };

  const [subStep, setSubStep] = useState(1);
  return (
    <StepLayout>
      <CurrentStep className={"mt-20"} currentStep={4} totalStep={6} />
      <StepHeading
        title={
          <div className="flex justify-between items-center w-full gap-x-2.5">
            <p
              className={`"text-gray-900 ${
                subStep === 1 ? "out-500-20" : "text-gray-500 out-300-20"
              }`}
              id="stepHead"
            >
              1. Describe your project to us. How would you categorise it?
            </p>
            {subStep !== 1 && <ChevronIcon className="text-gray-500" />}
          </div>
        }
        error={error}
        hideSubtitle={subStep !== 1}
        subTitle={
          error ? (
            <p className="">Please select project type to continue</p>
          ) : (
            <p className="">
              Select the kind of project you’re going to work on
            </p>
          )
        }
      />
      {subStep === 1 && (
        <Radio.Group
          value={projectTypeValue}
          onChange={handleRadioButtonChange}
          className="flex flex-col  mt-5 px-1.5 "
        >
          <Radio
            className={`text-gray-700  py-2 ${
              projectTypeValue === 1 ? "out-500-14" : "out-300-14"
            }`}
            value={1}
          >
            <p className="ml-1">Software development</p>
          </Radio>
          <Radio
            className={`text-gray-700 py-2 ${
              projectTypeValue === 2 ? "out-500-14" : "out-300-14"
            }`}
            value={2}
          >
            <p className="ml-1">Design & Creativity</p>
          </Radio>
          <Radio
            className={`text-gray-700 py-2 ${
              projectTypeValue === 3 ? "out-500-14" : "out-300-14"
            }`}
            value={3}
          >
            <p className="ml-1">Business Operations</p>
          </Radio>
          <Radio
            className={`text-gray-700 py-2 ${
              projectTypeValue === 4 ? "out-500-14" : "out-300-14"
            }`}
            value={4}
          >
            <p className="ml-1">Research & Development</p>
          </Radio>
          <Radio
            className={`text-gray-700 py-2 ${
              projectTypeValue === 5 ? "out-500-14" : "out-300-14"
            }`}
            value={5}
          >
            <p className="ml-1">Other</p>
          </Radio>
        </Radio.Group>
      )}
      {subStep > 1 && (
        <StepHeading
          title={
            <div className="flex justify-between items-center w-full gap-x-2.5">
              <p
                className={`"text-gray-900 ${
                  subStep === 2 ? "out-500-20" : "text-gray-500 out-300-20"
                }`}
              >
                2. Would you like to structure your project with phases?
              </p>
              {subStep !== 2 && <ChevronIcon className="text-gray-500" />}
            </div>
          }
          hideSubtitle={subStep !== 2}
          error={error}
          subTitle={
            subStep !== 2 ? (
              <></>
            ) : error ? (
              <p className="">Please select your preference type to continue</p>
            ) : (
              <p className="">Phases help you efficiently plan projects</p>
            )
          }
        />
      )}
      {subStep == 2 && (
        <>
          <Radio.Group
            value={showPhases ? true : false}
            onChange={(e: RadioChangeEvent) => setShowPhases(e.target.value)}
            className="flex flex-col  mt-5 px-1.5 "
          >
            <Radio
              className={`text-gray-700  ${
                showPhases ? "out-500-14" : "out-300-14"
              }`}
              value={true}
            >
              <p className="ml-1">Yes</p>
            </Radio>
            <Radio
              className={`text-gray-700  ${
                !showPhases ? "out-500-14" : "out-300-14"
              }`}
              value={false}
            >
              <p className="ml-1">No</p>
            </Radio>
          </Radio.Group>
          <div
            className="flex mt-4 gap-x-1 cursor-pointer"
            onClick={() =>
              dispatch(
                updateProjectOnboarding({
                  key: "phasesDescriptionModal",
                  value: true,
                })
              )
            }
          >
            <InfoCircleOutlined className="text-primary-600" />
            <p className="out-500-14 text-primary-600">What are phases?</p>
          </div>
        </>
      )}
      {subStep > 2 && (
        <StepHeading
          title={
            <div className="flex justify-between items-center w-full gap-x-2.5">
              <p
                className={`"text-gray-900 ${
                  subStep === 3 ? "out-500-20" : "text-gray-500 out-300-20"
                }`}
              >
                Please choose the phases you want to use in your project
              </p>
              {subStep !== 3 && <ChevronIcon className="text-gray-500" />}
            </div>
          }
          hideSubtitle={subStep !== 3}
          error={error}
          subTitle={
            subStep !== 3 ? (
              <></>
            ) : error ? (
              <p className="">Please select phases to continue</p>
            ) : (
              <p className="">
                For an end-to-end development of your project, I have
                pre-selected the important phases for you.
              </p>
            )
          }
        />
      )}
      {subStep == 3 && (
        <>
          <div className=" mt-5 flex gap-x-6">
            <PhaseCTA
              isSelected={selectedPhase.includes(1)}
              text="Ideation"
              phaseId={1}
              color={"#FFDB01"}
              select={handleSelectPhase}
            />
            <PhaseCTA
              isSelected={selectedPhase.includes(2)}
              text="Design"
              phaseId={2}
              color={"#F92B63"}
              select={handleSelectPhase}
            />
            <PhaseCTA
              isSelected={selectedPhase.includes(3)}
              text="Development"
              phaseId={3}
              color={"#6938EF"}
              select={handleSelectPhase}
            />
          </div>
          <div className=" mt-6 flex gap-x-6">
            <PhaseCTA
              isSelected={selectedPhase.includes(4)}
              text="Deployment"
              phaseId={4}
              color={"#33B7FF"}
              select={handleSelectPhase}
            />
            <PhaseCTA
              isSelected={selectedPhase.includes(5)}
              text="Maintenance"
              phaseId={5}
              color={"#46C66C"}
              select={handleSelectPhase}
            />
          </div>
        </>
      )}

      <div className="mt-6 flex justify-between items-center">
        <CustomButton
          text="Cancel"
          width="75px"
          height="30px"
          className={`bg-gray-100 out-500-14 text-gray-700 `}
          onClick={() => {
            dispatch(compeleteResetProjectOnboarding({}));
            navigate("/dashboard");
          }}
        />
        <div className="flex gap-x-2">
          <CustomButton
            text="Back"
            onClick={() => {
              switch (subStep) {
                case 1:
                  dispatch(changeStep(3));
                  break;
                case 2:
                  setSubStep(1);

                  break;
                case 3:
                  setSubStep(2);
              }
            }}
            className=" text-gray-700 bg-gray-100 out-500-14"
            height="30px"
            width="63px"
          />
          <CustomButton
            text="Continue"
            width="88px"
            height="30px"
            className={`bg-primary-600 out-500-14 text-white 
             
            `}
            onClick={async () => {
              switch (subStep) {
                case 1:
                  dispatch(
                    updateProjectOnboarding({
                      key: "projectType",
                      value: projectTypeValue,
                    })
                  );
                  dispatch(
                    updateProjectOnboarding({
                      key: "hasPhases",
                      value: showPhases,
                    })
                  );
                  if (projectTypeValue === 1) {
                    setSubStep(2);
                  } else {
                    dispatch(changeStep(5));
                  }
                  break;
                case 2:
                  dispatch(
                    updateProjectOnboarding({
                      key: "hasPhases",
                      value: showPhases,
                    })
                  );
                  if (showPhases) {
                    setSubStep(3);
                  } else {
                    dispatch(changeStep(5));
                  }
                  break;
                case 3:
                  dispatch(changeStep(5));
              }
            }}
          />
        </div>
      </div>
    </StepLayout>
  );
};

export default Step4;
