import { Progress } from "antd";
import dayjs from "dayjs";
import React from "react";

const LabelCard = ({ label }) => {
  // todo integrate with label data
  const formattedCreatedAt = label.startDate
    ? dayjs(label.startDate).format("MMM DD")
    : "Not set";
  const formattedDueDate = label.endDate
    ? dayjs(label.endDate).format("MMM DD")
    : "Not set";

  // Progress calculation now uses label's startDate and endDate
  const getProjectTimelineProgress = (startDate, endDate) => {
    const completedDuration = dayjs().diff(dayjs(startDate), "day");
    const totalDuration = dayjs(endDate).diff(dayjs(startDate), "day");

    const projectTimelineProgress = (completedDuration / totalDuration) * 100;

    return Math.min(Math.max(projectTimelineProgress, 0), 100);
  };
  return (
    <div className="flex flex-col w-full h-16 px-2 pb-2 ">
      <div className="flex w-full justify-between">
        <div className="flex mt-[9px] out-500-14">{label.name}</div>
        <div className="flex px-2 py-0.5 mt-2">
          <p className="out-300-12">
            {formattedCreatedAt} - {formattedDueDate}
          </p>
        </div>
      </div>
      <div className="w-full flex items-center mt-1.5 ">
        <Progress
          showInfo={false}
          strokeWidth={4}
          className="w-[95%]"
          strokeColor={label.color}
          percent={
            Math.round(
              getProjectTimelineProgress(label.startDate, label.endDate) * 100
            ) / 100
          }
          trailColor="#EFF8FF"
          style={{ marginBottom: "4px" }}
        />
        <span className="out-500-12 text-gray-700 ">
          {Math.round(
            getProjectTimelineProgress(label.startDate, label.endDate) * 100
          ) / 100}
          %
        </span>
      </div>
    </div>
  );
};

export default LabelCard;
