const getPriorityDetails = (type) => {
  let color = "";
  let priorityName = "";
  switch (+type) {
    case 1:
      color = "#101828";
      priorityName = "Low";
      break;
    case 2:
      color = "#165ABF";
      priorityName = "Medium";
      break;
    case 3:
      color = "#DC6803";
      priorityName = "High";
      break;
    case 4:
      color = "#D92D20";
      priorityName = "Urgent";
      break;
    default:
      color = "#101828";
      priorityName = "Not Found";
      break;
  }
  return { color, priorityName };
};

const getStatusDetails = (status) => {
  switch (+status) {
    case 1:
      return {
        text: "To Do",
        color: "#667085",
        className: "border border-[#667085]",
      };
    case 2:
      return {
        text: "In Progress",
        color: "#165ABF",
        className: "border border-[#165ABF]",
      };
    case 3:
      // return {
      //   text: "To Be Reviewed",
      //   color: "#F79009",
      //   className: "border border-[#F79009]",
      // };
      return {
        text: "Done",
        color: "#039855",
        className: "border border-[#039855]",
      };
    case 4:
      return {
        text: "Done",
        color: "#039855",
        className: "border border-[#039855]",
      };
    default:
      return {
        text: "To Do",
        color: "#667085",
        className: "border border-[#667085]",
      };
  }
};

const getScreenName = (id) => {
  if (!id) return "roadmap";
  let screenName = "";
  switch (+id) {
    case 1:
      screenName = "roadmap";
      break;
    case 2:
      screenName = "backlog";
      break;
    case 3:
      screenName = "sprints";
      break;
    case 4:
      screenName = "reports";
      break;
    default:
      screenName = "roadmap";
  }

  return screenName;
};

const updateTask = ({ updatedTask, state }) => {
  const parentGoalId = updatedTask?.parentGoalId;

  let newTasksArr = [];
  if (parentGoalId) {
    newTasksArr = [...state.myBoardTasks.result].map((task) => {
      if (task.id === parentGoalId) {
        const updatedSubtasks = task.subtask.map((sb) => {
          if (sb.id === updatedTask.id) {
            return updatedTask;
          }
          return sb;
        });
        return { ...task, subtask: updatedSubtasks };
      }
      return task;
    });
  } else {
    newTasksArr = [...state.myBoardTasks.result].map((sb) => {
      if (sb.id === updatedTask.id) {
        return { ...updatedTask, subtask: sb.subtask };
      }
      return sb;
    });
  }

  return newTasksArr;
};

export { getPriorityDetails, getStatusDetails, getScreenName, updateTask };
