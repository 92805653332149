import React, { useEffect, useState } from "react";
import { ChevronIcon } from "../../../Core/svgV2/Chevron";
import CustomBadge from "../../../Core/CommonV2/CustomBadge";
import { CheckIcon } from "../../../Core/svgV2/Check";
import { Checkbox, DatePicker, Input, Popover, Tooltip } from "antd";
import CustomSelect, { MultiSelect } from "../../../Core/CommonV2/CustomSelect";
import CustomAvatar from "../../../Core/CommonV2/CustomAvatar";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";
import getUsersName from "../../../Core/utils/getUserName";
import { PhaseIcon } from "../../../Core/svgV2/PhaseIcon";
import PriorityFlag from "../../../Core/svgV2/PriorityFlag";
import {
  getPriorityDetails,
  getStatusDetails,
} from "../../../Core/utils/scheduleUtils";
import { PlusIcon } from "../../../Core/svgV2/PlusIcon";
import { CrossIcon } from "../../../Core/svgV2/CrossIcon";
import { CalendarIcon } from "../../../Core/svgV2/CalendarIcon";
import dayjs from "dayjs";
import { updateSchedule } from "../../../Core/redux/slices/schedule.slice";
import { useParams } from "react-router-dom";
import CustomButton from "../../../Core/CommonV2/CustomButton";
import { client } from "../../../Core/utils/axiosClient";
import {
  getGoalDetails,
  getGoalsBySprint,
  getSubtaskDetails,
  updateGoal,
} from "../../../Core/redux/api/scheduleAPI";
import WaddledItModal from "../Modals/WaddledItModal";
import { useGetSwimlanes } from "../../../Core/redux/ReactQueryHooksV3/useScheduleAPI";
import { Taskicon } from "../../../Core/svgV2/TaskIcon";
import CustomSearchBox from "../../../Core/CommonV2/CustomSearchBox";
import { updateGlobal } from "../../../Core/redux/slices/global.slice";
import CustomInputBox from "../../../Core/CommonV2/CustomInputBox";
import getCurrentUser from "./getCurrentUser";
import useDebounce from "../../../Core/hooks/useDebounceValue";
import { labelColors } from "../../../Core/constants";
import { updateDashboard } from "../../../Core/redux/slices/dashboard.slice";
import MyBoardLabel from "../../../Core/CommonV3/MyBoardLabel";

export const RowLayout = ({ customLeft, children, className, style = {} }) => {
  return (
    <div className={`${className} flex items-center`} style={style}>
      <div className="min-w-[136px] max-w-[156px] out-300-14 text-gray-500">
        {customLeft}
      </div>
      <div className="flex-1">{children}</div>
    </div>
  );
};

export const TaskInput = ({
  disabled = false,
  showError = false,
  placeholder = "",
  inputClassName = "task-input",
  autoSize,
  autoFocus = false,
  type = "textbox",
  value = "",
  onChange,
  onSave = async (e, save = false) => console.log(""),
  name = "",
  plannerScreen = false,
  isSubtask = false,
  formState = {},
}) => {
  const [focused, setFocused] = useState(false);
  const getWidth = () => {
    const maxWidth = document.getElementById(
      "currentInputBoxMaxWidth"
    )?.clientWidth;
    // Calculate the width dynamically based on the length of the entered text
    const textWidth = value?.length * 6; // You may need to adjust the multiplier based on your font size and styling

    return textWidth < 75
      ? 75
      : textWidth >= maxWidth - 300
      ? maxWidth - 300
      : textWidth; // Ensure a minimum width
  };
  const DynamicInput = type === "textbox" ? Input.TextArea : Input;
  return (
    <div
      className={`transition-shadow duration-300  ${
        showError && !plannerScreen
          ? "border border-error-600 shadow-input-error bg-white"
          : focused && !plannerScreen
          ? "border border-primary-500 shadow-input-focus bg-white"
          : disabled && !plannerScreen
          ? "border border-grey-200 bg-white"
          : plannerScreen || isSubtask
          ? "bg-gray-50"
          : "border border-transparent hover:border-gray-200 bg-white"
      }   rounded-[4px]  flex items-center w-full`}
    >
      <div
        className={
          plannerScreen
            ? ` ${
                showError
                  ? "border border-error-600 shadow-input-error"
                  : focused
                  ? "border border-primary-500 shadow-input-focus"
                  : disabled
                  ? "border border-grey-200 "
                  : plannerScreen
                  ? ""
                  : "border border-transparent hover:border-gray-200"
              }   rounded-[4px] `
            : "w-full"
        }
      >
        <DynamicInput
          name={name}
          value={value}
          bordered={false}
          placeholder={placeholder}
          className={
            plannerScreen ? "out-300-12 text-black flex-1 " : inputClassName
          }
          onFocus={() => {
            setFocused(true);
          }}
          autoFocus={autoFocus}
          autoSize={autoSize}
          style={{
            width: plannerScreen ? getWidth() : " 100% ",
            maxWidth: "100%",
          }}
          onBlur={(e) => {
            setFocused(false);

            plannerScreen ? onSave(e, false) : console.log("");
          }}
          onPressEnter={(e) => {
            plannerScreen ? onSave(e, true) : setFocused(true);
          }}
          onChange={(e) => onChange(e, plannerScreen)}
        />
      </div>
    </div>
  );
};

export const ScheduleCollaboratorSelect = ({ task }) => {
  const dispatch = useAppDispatch();
  const { projectId } = useParams();

  const { userList } = useAppSelector((state) => state.overview);
  const { collaboratorsForTask } = useAppSelector((state) => state.schedule);

  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState("");

  const handleMultiSelect = (current) => {
    dispatch(
      updateSchedule({
        key: "collaboratorsForTask",
        value: collaboratorsForTask.includes(current.userId)
          ? collaboratorsForTask.filter((item) => item != current.userId)
          : [...collaboratorsForTask, current.userId],
      })
    );
  };

  const onClose = () => {
    setOpen(false);
    setSearch("");
    dispatch(
      updateSchedule({
        key: "collaboratorsForTask",
        value: [],
      })
    );
  };
  const onRequest = async () => {
    setOpen(false);
    const collaboratorIds = collaboratorsForTask.filter((newCollab) => {
      return !task?.collaborators?.some(
        (existingCollab) => existingCollab.id === newCollab
      );
    });
    const uniqueCollaboratorIds = [];
    collaboratorIds.forEach((element) => {
      if (!uniqueCollaboratorIds.includes(element)) {
        uniqueCollaboratorIds.push(element);
      }
    });

    if (task.id) {
      await dispatch(
        updateGoal({
          goalId: task.id,
          projectId: projectId,
          collaborators: uniqueCollaboratorIds,
        })
      );
      dispatch(getGoalDetails({ goalId: task.id }));
      dispatch(
        updateSchedule({
          key: "collaboratorsForTask",
          value: [],
        })
      );
      dispatch(
        updateDashboard({
          key: "alertPopupModal",
          value: {
            visible: true,
            data: {
              title: `Added New Collaborators`,
              subtitle: `New collaborators were added for task`,
              description: `${task.name}`,
            },
          },
        })
      );
    }
  };

  return (
    <Popover
      getPopupContainer={(parent) =>
        parent?.parentElement?.parentElement?.parentElement?.parentElement
      }
      content={
        <div className="min-w-[200px] bg-white rounded-lg">
          <CustomInputBox
            className="px-3 pt-2"
            placeholder="Search for a name"
            value={search}
            onChange={(event) => setSearch(event.target.value)}
            autoFocus
          />

          <div className="overflow-y-scroll h-[200px]">
            <p className="px-3 pt-1.5 text-gray-500 out-300-14">
              Select 1 or multiple members
            </p>
            {userList
              .filter((current) =>
                collaboratorsForTask?.includes(current.userId)
              )
              .filter(
                (u) =>
                  (u.firstname.toLowerCase().includes(search.toLowerCase()) ||
                    u.lastname.toLowerCase().includes(search.toLowerCase())) &&
                  u.userId !== task?.assigneeId
              )
              .map((current, index) => (
                <div
                  role="button"
                  key={`${current.userId}${index}`}
                  className="flex items-center gap-x-3 mt-1.5 px-3"
                  onClick={() => handleMultiSelect(current)}
                >
                  <Checkbox
                    checked={collaboratorsForTask?.includes(current.userId)}
                  />
                  <CustomAvatar
                    src={current?.profilePicture}
                    size={24}
                    title={getUsersName(current)}
                    whiteText
                    color={current?.profilePictureColorCode}
                  />

                  <p className="text-gray-900 capitalize out-300-12">
                    {getUsersName(current)}
                  </p>
                </div>
              ))}
            {userList
              .filter(
                (current) => !collaboratorsForTask?.includes(current.userId)
              )
              .filter(
                (u) =>
                  (u.firstname.toLowerCase().includes(search.toLowerCase()) ||
                    u.lastname.toLowerCase().includes(search.toLowerCase())) &&
                  u.userId !== task?.assigneeId
              )
              .map((current, index) => (
                <div
                  role="button"
                  key={`${current.userId}${index}`}
                  className="flex items-center gap-x-3 mt-1.5 px-3"
                  onClick={() => handleMultiSelect(current)}
                >
                  <Checkbox
                    checked={collaboratorsForTask?.includes(current.userId)}
                  />
                  <CustomAvatar
                    src={current.profilePicture}
                    size={24}
                    title={getUsersName(current)}
                    whiteText
                    color={current.profilePictureColorCode}
                  />

                  <p className="text-gray-900 capitalize out-300-12">
                    {getUsersName(current)}
                  </p>
                </div>
              ))}
          </div>

          <div className="z-10 flex items-center justify-end w-full p-3 bg-white rounded-b-lg">
            <CustomButton
              text="Cancel"
              className="text-gray-500 bg-transparent"
              height="30px"
              width="63px"
              onClick={onClose}
            />
            <CustomButton
              text="Save"
              height="30px"
              width="71px"
              className="bg-transparent text-primary-600"
              onClick={onRequest}
            />
          </div>
        </div>
      }
      arrow={false}
      open={open}
      onOpenChange={(open) => setOpen(open)}
      placement="bottomLeft"
      trigger={"click"}
    >
      {task && getCurrentUser({ task })}
    </Popover>
  );
};
export const UserSelect = ({
  placeholder,
  task,
  setTask,
  onEdit,
  type,
  disabled,
}) => {
  const { userList } = useAppSelector((state) => state.overview);
  const [showSelect, setShowSelect] = useState(false);
  const [currentSelectedUser, setCurrentSelectedUser] = useState(null);

  useEffect(() => {
    let userId = "";
    if (type === "assignee") {
      userId = task.assigneeId;
    } else {
      userId = task.reporterId;
    }
    const user = userList.find((user) => user.userId == userId);
    if (user) {
      setCurrentSelectedUser(user);
    } else {
      setCurrentSelectedUser(null);
    }
  }, [task]);

  const handleMenuItemClick = (user) => {
    setCurrentSelectedUser(user);
    setShowSelect(false);
    if (type === "assignee") {
      setTask({
        ...task,
        assigneeId: user.userId,
      });
    } else {
      setTask({
        ...task,
        reporterId: user.userId,
      });
    }
    onEdit(user.userId);
  };

  if (!showSelect) {
    return (
      <button
        className="flex border border-transparent hover:border-gray-200 w-full rounded pl-2 h-[30px] cursor-pointer items-center "
        onClick={() => setShowSelect(true)}
        disabled={disabled}
      >
        {currentSelectedUser ? (
          <>
            <CustomAvatar
              src={currentSelectedUser?.profilePicture}
              size={24}
              title={getUsersName(currentSelectedUser)}
              whiteText
              color={currentSelectedUser?.profilePictureColorCode}
            />

            <p className="pl-2 text-gray-900 capitalize out-500-14 ">
              {getUsersName(currentSelectedUser)}
            </p>
          </>
        ) : (
          <p className="pl-2 leading-5 text-gray-500 capitalize out-300-14">
            Select {type}
          </p>
        )}
      </button>
    );
  }

  return (
    <CustomSelect
      placeholder={placeholder}
      onDropdownVisibleChange={(open) => !open && setShowSelect(false)}
      defaultOpen
      showSearch
      disabled={disabled}
      options={userList.map((user) => {
        return {
          id: user.userId,
          value: user.userId,
          label: getUsersName(user),

          render: () => (
            <div
              className="flex items-center "
              onClick={() => {
                handleMenuItemClick(user);
              }}
            >
              <CustomAvatar
                src={user.profilePicture}
                size={24}
                title={getUsersName(user)}
                whiteText
                color={user.profilePictureColorCode}
              />

              <p className="pl-2 text-gray-900 capitalize out-300-12 ">
                {getUsersName(user)}
              </p>
            </div>
          ),
        };
      })}
    />
  );
};
export const CollaboratorSelect = ({
  placeholder,
  task,
  setTask,
  onEdit,
  type,
  disabled,
}) => {
  const { userList } = useAppSelector((state) => state.overview);
  const [showSelect, setShowSelect] = useState(false);
  const [currentSelectedUser, setCurrentSelectedUser] = useState(null);

  useEffect(() => {
    let userId = "";
    if (type === "assignee") {
      userId = task.assigneeId;
    } else {
      userId = task.reporterId;
    }
    const user = userList.find((user) => user.userId == userId);
    if (user) {
      setCurrentSelectedUser(user);
    } else {
      setCurrentSelectedUser(null);
    }
  }, [task]);

  const handleMenuItemClick = (user) => {
    setCurrentSelectedUser(user);
    setShowSelect(false);
    if (type === "assignee") {
      setTask({
        ...task,
        assigneeId: user.userId,
      });
    } else {
      setTask({
        ...task,
        reporterId: user.userId,
      });
    }
    onEdit(user.userId);
  };

  if (!showSelect) {
    return (
      <button
        className="flex border border-transparent hover:border-gray-200 w-full rounded pl-2 h-[30px] cursor-pointer items-center "
        onClick={() => setShowSelect(true)}
        disabled={disabled}
      >
        {currentSelectedUser ? (
          <>
            <CustomAvatar
              src={currentSelectedUser?.profilePicture}
              size={24}
              title={getUsersName(currentSelectedUser)}
              whiteText
              color={currentSelectedUser?.profilePictureColorCode}
            />

            <p className="out-500-14 pl-2 text-gray-900 capitalize ">
              {getUsersName(currentSelectedUser)}
            </p>
          </>
        ) : (
          // <p className="pl-2 out-300-14 leading-5 text-gray-500 capitalize">
          //   Select {type}
          // </p>
          <>{placeholder}</>
        )}
      </button>
    );
  }

  return (
    <CustomSelect
      placeholder={placeholder}
      onDropdownVisibleChange={(open) => !open && setShowSelect(false)}
      defaultOpen
      showSearch
      disabled={disabled}
      options={userList.map((user) => {
        return {
          id: user.userId,
          value: user.userId,
          label: getUsersName(user),

          render: () => (
            <div
              className="flex items-center "
              onClick={() => {
                handleMenuItemClick(user);
              }}
            >
              <CustomAvatar
                src={user.profilePicture}
                size={24}
                title={getUsersName(user)}
                whiteText
                color={user.profilePictureColorCode}
              />

              <p className="out-300-12 pl-2 text-gray-900 capitalize ">
                {getUsersName(user)}
              </p>
            </div>
          ),
        };
      })}
    />
  );
};

export const PhaseSelect = ({ task, setTask, onEdit, disabled }) => {
  const [phaseDropdown, setPhaseDropdown] = useState(false);
  const { phases } = useAppSelector((state) => state.schedule);
  const phaseDetails = phases.find((p) => p.projectPhaseId == task.phaseId);

  const option = phases.map((p) => {
    return {
      id: p.projectPhaseId,
      value: p.projectPhaseId,
      label: `${p.phases} ${p.phaseIteration}`,
      render: () => (
        <div className="flex items-center">
          <PhaseIcon phaseId={p.id} />
          <p className="pl-2 leading-5 text-black capitalize out-300-14">
            {p.phases} {p.phaseIteration}
          </p>
        </div>
      ),
    };
  });

  if (!task.phaseId && !phaseDropdown) {
    return (
      <button
        className="flex items-center h-[30px] cursor-pointer border border-transparent hover:border-gray-200 w-full rounded pl-2  "
        onClick={() => setPhaseDropdown(true)}
        disabled={disabled}
      >
        <p className="pl-2 leading-5 text-gray-500 capitalize out-300-14">
          Select phase
        </p>
      </button>
    );
  }

  if (!phaseDropdown) {
    return (
      <button
        className="flex items-center h-[30px] cursor-pointer border border-transparent hover:border-gray-200 w-full rounded pl-2  "
        onClick={() => setPhaseDropdown(true)}
        disabled={disabled}
      >
        <PhaseIcon phaseId={phaseDetails?.id} />
        <p className="pl-2 leading-5 text-black capitalize out-500-14">
          {phaseDetails?.phases} {phaseDetails?.phaseIteration}
        </p>
      </button>
    );
  }

  return (
    <CustomSelect
      placeholder="Select phase"
      options={option}
      onDropdownVisibleChange={(open) => !open && setPhaseDropdown(false)}
      defaultOpen
      showSearch
      onChange={(e) => {
        setTask({ ...task, phaseId: e });
        onEdit(e);
        setPhaseDropdown(false);
      }}
    />
  );
};

export const SprintSelect = ({ task, setTask, disabled }) => {
  const [sprintDropDown, setSprintDropDown] = useState(false);
  const { sprints } = useAppSelector((state) => state.schedule);
  // const phase = task.phaseId;

  const currentSprint = sprints.find((sprint) => sprint.id == task.sprintId);

  const option = [
    {
      id: "Remove-sprint",
      value: null,
      label: "Remove Sprint",
      render: () => (
        <div className="flex items-center">
          <p className="pl-2 leading-5 capitalize out-300-14 text-error-500">
            Remove Sprint
          </p>
        </div>
      ),
    },
    ...sprints.map((sprint) => {
      return {
        id: sprint?.id,
        value: sprint?.id,
        label: sprint?.name,
        render: () => (
          <div className="flex items-center">
            <p className="pl-2 leading-5 text-black capitalize out-300-14">
              {sprint?.name} {sprint?.status == 2 && "(Active)"}
            </p>
          </div>
        ),
      };
    }),
  ];

  if (!task.sprintId && !sprintDropDown) {
    return (
      <button
        className="flex items-center h-[30px] cursor-pointer border border-transparent hover:border-gray-200 w-full rounded pl-2  "
        onClick={() => setSprintDropDown(true)}
        disabled={disabled}
      >
        <p className="pl-2 leading-5 text-gray-500 capitalize out-300-14">
          Select Sprint
        </p>
      </button>
    );
  }

  if (!sprintDropDown) {
    return (
      <button
        className="flex items-center h-[30px] cursor-pointer border border-transparent hover:border-gray-200 w-full rounded pl-2  "
        onClick={() => setSprintDropDown(true)}
        disabled={disabled}
      >
        {currentSprint?.name} {currentSprint?.status == 2 && "(Active)"}
      </button>
    );
  }

  return (
    <CustomSelect
      placeholder="Select Sprint"
      options={option}
      onDropdownVisibleChange={(open) => !open && setSprintDropDown(false)}
      defaultOpen
      disabled={disabled}
      showSearch
      onChange={(e) => {
        setTask(e);
        setSprintDropDown(false);
      }}
    />
  );
};

export const PrioritySelect = ({ task, setTask, disabled }) => {
  const [priorityDropdown, setPriorityDropdown] = useState(false);
  const priority = task.priorityId;

  const getLable = (priority, bold = false) => {
    const { color, priorityName } = getPriorityDetails(priority);
    return (
      <div className="flex items-center ">
        <PriorityFlag
          style={{
            color,
          }}
        />
        <p
          className="ml-2 out-300-14"
          style={{ color, fontWeight: bold ? 500 : 300 }}
        >
          {priorityName}
        </p>
      </div>
    );
  };

  const option = [
    {
      id: 1,
      value: 1,
      label: "Low",
      render: () => getLable(1),
    },
    {
      id: 2,
      value: 2,
      label: "Medium",
      render: () => getLable(2),
    },
    {
      id: 3,
      value: 3,
      label: "High",
      render: () => getLable(3),
    },
    {
      id: 4,
      value: 4,
      label: "Urgent",
      render: () => getLable(4),
    },
  ];
  if (!priorityDropdown) {
    return (
      <button
        className="flex items-center h-[30px] cursor-pointer border border-transparent hover:border-gray-200 w-full rounded pl-2 "
        onClick={() => setPriorityDropdown(true)}
        disabled={disabled}
      >
        {getLable(priority, true)}
      </button>
    );
  }

  return (
    <CustomSelect
      placeholder="Select Priority"
      options={option}
      onDropdownVisibleChange={(open) => !open && setPriorityDropdown(false)}
      defaultOpen
      showSearch
      onChange={(e) => {
        setPriorityDropdown(false);
        setTask(e);
      }}
    />
  );
};

export const LabelSelect = ({ task, setTask, disabled }) => {
  const { projectId } = useParams();
  const { labels } = useAppSelector((state) => state.schedule);

  const filteredLabels = labels?.filter(
    (label) => label.projectId === projectId
  );

  const labelId = task.labelId;
  const label = labels.find((l) => l.id == task.labelId);
  const [showLabelSelect, setShowLabelSelect] = useState(false);
  const dispatch = useAppDispatch();

  const option = filteredLabels.map((label) => {
    const labelColor = labelColors.find(
      (color) => color.id === +label.labelColorId
    );
    const baseColor = labelColor ? labelColor.textColor : "#7DC7FF";

    return {
      id: label.id,
      value: label.id,
      label: label.name,
      render: () => (
        <div className="flex items-center justify-between">
          <MyBoardLabel labelColorId={label.labelColorId} text={label.name} />
          <div className="h-[14px] w-[14px] rounded-full" />
        </div>
      ),
    };
  });

  if (!label && !showLabelSelect) {
    return (
      <button
        className="flex items-center h-[30px] cursor-pointer border border-transparent hover:border-gray-200 w-full rounded pl-2 "
        onClick={() => setShowLabelSelect(true)}
        disabled={disabled}
      >
        <PlusIcon className="text-gray-500" />
        <p className="pl-2 leading-5 text-gray-500 capitalize out-300-14">
          Add a label
        </p>
      </button>
    );
  }

  if (labelId && !showLabelSelect) {
    return (
      <button
        className="flex items-center h-[30px] cursor-pointer border border-transparent hover:border-gray-200 w-full rounded pl-2 "
        onClick={() => setShowLabelSelect(true)}
        disabled={disabled}
      >
        <MyBoardLabel labelColorId={label.labelColorId} text={label.name} />
      </button>
    );
  }

  return (
    <CustomSelect
      placeholder="Select label"
      options={option}
      onDropdownVisibleChange={(open) => !open && setShowLabelSelect(false)}
      defaultOpen
      showSearch
      disabled={disabled}
      customDropDownRender={(menu) => {
        return (
          <div>
            <div className="scrollbar-hidden">{menu}</div>
            <button
              onClick={() => {
                dispatch(updateSchedule({ key: "newLabelModal", value: true }));
              }}
              className="flex items-center py-3 px-3.5 hover:bg-gray-50 w-full cursor-pointer "
            >
              <PlusIcon className="text-gray-500" />
              <p className="pl-2 leading-5 text-gray-500 capitalize out-300-12">
                Create new label
              </p>
            </button>
          </div>
        );
      }}
      onChange={(e) => {
        setTask(e);
        setShowLabelSelect(false);
      }}
    />
  );
};

export const VersionSelect = ({ task, setTask, disabled }) => {
  const { releaseVersions } = useAppSelector((state) => state.schedule);

  const versionId = task.releaseVersionId;
  const version = releaseVersions.find((l) => l.id == versionId);
  const [showVersionSelect, setShowVersionSelect] = useState(false);
  const dispatch = useAppDispatch();

  const option = releaseVersions.map((r) => {
    return {
      id: r.id,
      value: r.id,
      label: r.versionName,
      render: () => (
        <div className="flex items-center justify-between">
          <CustomBadge
            text={r.versionName}
            color="#344054"
            className="border border-gray-200"
          />
        </div>
      ),
    };
  });

  if (!version && !showVersionSelect) {
    return (
      <button
        className="flex items-center h-[30px] cursor-pointer border border-transparent hover:border-gray-200 w-full rounded pl-2 "
        onClick={() => setShowVersionSelect(true)}
        disabled={disabled}
      >
        <p className="pl-2 leading-5 text-gray-500 capitalize out-300-14">
          Select version
        </p>
      </button>
    );
  }

  if (version && !showVersionSelect) {
    return (
      <button
        className="flex items-center h-[30px] cursor-pointer border border-transparent hover:border-gray-200 w-full rounded pl-2 "
        onClick={() => setShowVersionSelect(true)}
        disabled={disabled}
      >
        <CustomBadge
          text={version.versionName}
          color="#344054"
          className="border border-gray-200"
        />
      </button>
    );
  }

  return (
    <CustomSelect
      placeholder="Select version"
      options={option}
      onDropdownVisibleChange={(open) => !open && setShowVersionSelect(false)}
      defaultOpen
      showSearch
      disabled={disabled}
      customDropDownRender={(menu) => {
        return (
          <div>
            <div className="scrollbar-hidden">{menu}</div>
            <button
              onClick={() =>
                dispatch(
                  updateSchedule({ key: "newReleaseVersionModal", value: true })
                )
              }
              className="flex items-center hover:bg-gray-50 w-full py-3 px-3.5 cursor-pointer "
            >
              <PlusIcon className="text-gray-500" />
              <p className="pl-2 leading-5 text-gray-500 capitalize out-300-12">
                Create new version
              </p>
            </button>
          </div>
        );
      }}
      onChange={(e) => {
        setTask(e);
        setShowVersionSelect(false);
      }}
    />
  );
};

const AddApproversUnMemo = ({
  task,
  selectedUsers,
  setSelectedUsers,
  waddleItData,
  onRemoveUser,
  onAddUser,
}) => {
  const { taskId } = useParams();
  const [showApprover, setShowApprover] = useState(false);
  const dispatch = useAppDispatch();
  const [showWaddledItModal, setShowWaddledItModal] = useState(false);

  const { userList } = useAppSelector((state) => state.overview);
  const { user } = useAppSelector((state) => state.userDetails);

  let header = <></>;

  if (!showApprover) {
    header = (
      <button
        className="flex items-center h-[32px] cursor-pointer border border-transparent hover:border-gray-200 w-full rounded pl-2 "
        onClick={() => setShowApprover(true)}
      >
        <img src="/images/v2/schedule/add-round.svg" />
        <p className="ml-2 text-black out-500-14">Add Approvers</p>
      </button>
    );
  } else {
    header = (
      <div>
        <MultiSelect
          placeholder="Add Approvers"
          onOpenChange={(open) => {
            setShowApprover(open);
          }}
          autoFocus
          options={userList
            .filter(
              (u) =>
                selectedUsers?.indexOf(u.userId) === -1 &&
                +u.userId !== +user.id
            )
            .map((user) => {
              return {
                id: user.userId,
                value: user.userId,
                label: getUsersName(user),
                render: () => (
                  <div
                    onClick={() => {
                      setSelectedUsers((oldList) => [...oldList, user.userId]);
                      onAddUser(user.userId);
                    }}
                    className="flex items-center "
                  >
                    <CustomAvatar
                      src={user.profilePicture}
                      size={24}
                      title={getUsersName(user)}
                      whiteText
                      color={user.profilePictureColorCode}
                    />

                    <p className="pl-2 text-gray-900 capitalize out-300-12 ">
                      {getUsersName(user)}
                    </p>
                  </div>
                ),
              };
            })}
        />
      </div>
    );
  }

  const shouldShowWaddleIt = task.assigneeId !== user.id && taskId !== "new";

  if (shouldShowWaddleIt) {
    const selfStatus = waddleItData?.find((waddle) => waddle.userId == user.id);
    const handleClick = async (value) => {
      try {
        await client.put("/schedule/waddle-it", {
          userId: user.id,
          goalId: taskId,
          isWaddled: value,
        });
        dispatch(getGoalDetails({ goalId: taskId }));
      } catch (e) {
        console.log(e);
      } finally {
        if (value === 1) {
          setShowWaddledItModal(true);
        }
      }
    };
    if (selfStatus?.isWaddled) {
      header = (
        <div className="flex items-center gap-x-3">
          <Tooltip
            title={
              <div className="">
                <p className="text-white out-500-14">Approved</p>
                {selfStatus?.waddledOn && (
                  <p className="mt-1 text-white out-300-12">
                    {dayjs(selfStatus?.waddledOn).format(
                      "MMMM DD YYYY, hh:mm a"
                    )}
                  </p>
                )}
              </div>
            }
          >
            <div>
              <CustomButton
                text="Waddled by you"
                className="bg-[#AADAFF] out-500-14 text-white"
                height="30px"
              />
            </div>
          </Tooltip>
          <CustomButton
            text="Undo"
            height="30px"
            onClick={() => handleClick(0)}
            className="text-gray-500 bg-transparent"
          />
        </div>
      );
    } else {
      header = (
        <CustomButton
          text="Waddle it !"
          width="100%"
          height="30px"
          disabled={selfStatus ? false : true}
          onClick={() => handleClick(1)}
        />
      );
    }
  }

  const getStatusDetails = (currentUser) => {
    const selfStatus = waddleItData?.find(
      (waddle) => waddle.userId == currentUser.userId
    );
    if (selfStatus) {
      return selfStatus;
    }
    return {};
  };

  return (
    <>
      {header}
      <div>
        {userList.map((user, i) => {
          if (selectedUsers?.indexOf(user.userId) !== -1) {
            const selfStatus = getStatusDetails(user);

            const waddled = selfStatus?.isWaddled == 1;
            return (
              <div
                className="flex py-[2px] mt-2 group hover:border-gray-200 border border-transparent rounded justify-between px-3"
                key={user.userId + i}
              >
                <div className="flex items-center ">
                  <Tooltip
                    title={
                      waddled ? (
                        <div className="">
                          <p className="text-white out-500-14">Approved</p>
                          {selfStatus?.waddledOn && (
                            <p className="mt-1 text-white out-300-12">
                              {dayjs(selfStatus?.waddledOn).format(
                                "MMMM DD YYYY, hh:mm a"
                              )}
                            </p>
                          )}
                        </div>
                      ) : (
                        "Approval pending"
                      )
                    }
                  >
                    <img
                      src={
                        waddled
                          ? "/images/v2/schedule/waddled.svg"
                          : "/images/v2/schedule/remove-round.svg"
                      }
                      className="mr-2"
                    />
                  </Tooltip>
                  <CustomAvatar
                    size={24}
                    src={user.profilePicture}
                    title={getUsersName(user)}
                    whiteText
                    color={user.profilePictureColorCode}
                  />

                  <p className="pl-2 text-gray-900 capitalize out-300-14 ">
                    {getUsersName(user)}
                  </p>
                </div>
                <button
                  onClick={() => {
                    setSelectedUsers((oldList) => [
                      ...oldList.filter((old) => +old !== +user.userId),
                    ]);

                    onRemoveUser(user.userId);
                  }}
                  className={`hidden ${
                    shouldShowWaddleIt ? "hidden" : "group-hover:block"
                  } `}
                >
                  <CrossIcon className="text-gray-500" />
                </button>
              </div>
            );
          }
        })}
      </div>
      <WaddledItModal
        visible={showWaddledItModal}
        onClose={() => setShowWaddledItModal(false)}
      />
    </>
  );
};
const AddApprovers = React.memo(AddApproversUnMemo);
export { AddApprovers };

export const DueDate = ({ setTask, task }) => {
  const [date, setDate] = useState(task.dueDate);
  const [showSelect, setShowSelect] = useState(false);

  if (!showSelect && !date) {
    return (
      <button
        className="flex items-center border border-transparent hover:border-gray-200 w-full rounded pl-2 h-[30px] cursor-pointer "
        onClick={() => setShowSelect(true)}
      >
        <CalendarIcon className="text-gray-500" />
        <span className="ml-2 text-gray-500 out-300-14">Select Date</span>
      </button>
    );
  }

  if (date && !showSelect) {
    return (
      <button
        className="flex items-center border border-transparent hover:border-gray-200 w-full rounded pl-2 h-[30px] cursor-pointer "
        onClick={() => setShowSelect(true)}
      >
        <CalendarIcon className="text-gray-500" />
        <span className="ml-2 text-black out-500-14">
          {dayjs(date).format("MMMM DD YYYY")}
        </span>
      </button>
    );
  }

  return (
    <div>
      <DatePicker
        className="w-full  bg-transparent rounded-[4px] border-gray-200"
        format="DD MMM YYYY"
        placeholder="Select Date"
        autoFocus
        open
        suffixIcon={<CalendarIcon className="text-gray-500" />}
        onChange={(date) => {
          setDate(date);
          setTask(date);
          setShowSelect(false);
        }}
      />
    </div>
  );
};
export const TaskDueDate = ({ setTask, task, type }) => {
  const [date, setDate] = useState(
    type == "Start" ? task.startDate : task.endDate
  );
  const [showSelect, setShowSelect] = useState(false);

  useEffect(
    () => setDate(type == "Start" ? task.startDate : task.endDate),
    [type, task]
  );
  if (!showSelect && !date) {
    return (
      <button
        className="flex items-center border border-transparent hover:border-gray-200 w-full rounded pl-2 h-[30px] cursor-pointer "
        onClick={() => setShowSelect(true)}
      >
        <span className="out-300-14 text-gray-500 ml-2">Add {type} Date</span>
      </button>
    );
  }

  if (date && !showSelect) {
    return (
      <button
        className="flex items-center border border-transparent hover:border-gray-200 w-full rounded pl-2 h-[30px] cursor-pointer "
        onClick={() => setShowSelect(true)}
      >
        <span className="out-500-14 text-black ml-2">
          {dayjs(date).format("DD/MM/YYYY")}
        </span>
      </button>
    );
  }

  return (
    <div>
      <DatePicker
        className="w-full  bg-transparent rounded-[4px] border-gray-200"
        format="DD MMM YYYY"
        placeholder="Select Date"
        autoFocus
        open
        suffixIcon={<CalendarIcon className="text-gray-500" />}
        onChange={(date) => {
          setDate(date);
          setTask(date);
          setShowSelect(false);
        }}
      />
    </div>
  );
};
export const ParentTaskSelect = ({ subTask, setSubTask, disabled }) => {
  //   // const { myBoardGoals } = useAppSelector((state) => state.myBoard);
  const { goals } = useAppSelector((state) => state.schedule);
  const { projectId, taskId, subtaskId } = useParams();

  const originalParentGoal = goals?.find((l) => +l.id == +taskId);
  const [parentGoal, setParentGoal] = useState(originalParentGoal);
  const [parentTaskList, setParentTaskList] = useState(null);
  const [showParentGoal, setShowParentGoal] = useState(false);
  const dispatch = useAppDispatch();
  const [search, setSearch] = useState(null);
  const debouncedValue = useDebounce(search, 500);
  useEffect(() => {
    const parentTasks = goals.filter((goal) => +goal.goalType === 1);

    setParentTaskList(parentTasks);
    const originalParentGoal = goals?.find((l) => +l.id == +taskId);

    setParentGoal(originalParentGoal);
  }, [goals, taskId]);

  useEffect(() => {
    const payload: {
      limit: number;
      offset: number;
      searchQuery?: string;
      projectId: string;
    } = {
      limit: null,
      offset: null,
      projectId,
    };

    if (debouncedValue) {
      payload.searchQuery = debouncedValue;

      dispatch(getGoalsBySprint(payload));
    }
  }, [debouncedValue]);
  if (!parentGoal && !showParentGoal) {
    return (
      <button
        className="flex  items-center h-[30px] cursor-pointer border border-transparent hover:border-gray-200 w-full rounded pl-2 "
        onClick={() => setShowParentGoal(true)}
        disabled={disabled}
      >
        <PlusIcon className="text-gray-500" />
        <p className="pl-2 out-300-14 leading-5 text-gray-500 capitalize">
          Add a Parent Goal
        </p>
      </button>
    );
  }

  if (parentGoal && !showParentGoal) {
    return (
      <div
        className="flex justify-start items-center px-[14px] py-[5px] gap-x-3 max-w-[196px] cursor-pointer"
        onClick={() => setShowParentGoal(true)}
      >
        <Taskicon className="text-primary-600" />
        <div className="flex gap-x-1  items-center justify-start max-w-[140px]">
          <p className="out-300-12 text-gray-900 flex w-full">
            {parentGoal?.identifier}
          </p>
          <p className="out-500-14 text-gray-700 min-w-[50px] truncate">
            {parentGoal?.name}
          </p>
        </div>
      </div>
    );
  }

  return (
    <Popover
      arrow={false}
      placement="bottomLeft"
      open={showParentGoal}
      onOpenChange={() => setShowParentGoal(false)}
      content={
        <div className="flex flex-col p-3 max-h-[308px] max-w-[300px] ">
          <CustomSearchBox
            width="296px"
            placeholder="Search for a task"
            search={search}
            setSearch={(e) => setSearch(e)}
          />
          <div className="flex flex-col w-full h-[260px] overflow-y-scroll">
            {parentTaskList?.slice(0, 9).map((parentGoal) => (
              <div
                className="flex  items-center px-3 py-4 gap-x-3 cursor-pointer"
                key={parentGoal.id}
                onClick={() => {
                  setParentGoal(parentGoal);
                  setSubTask(parentGoal?.id);

                  setShowParentGoal(false);
                }}
              >
                <Taskicon className="text-primary-600" />
                <div className="flex gap-x-1  items-center justify-center max-w-[280px]">
                  <p className="out-300-12 text-gray-900">
                    {parentGoal?.identifier}
                  </p>
                  <p className="out-500-14 text-gray-700 max-w-[200px] truncate">
                    {parentGoal?.name}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      }
      trigger={["click"]}
    >
      <div
        className={`flex rounded-[4px] justify-start items-center px-[14px] py-[5px] gap-x-3 max-w-[196px] cursor-pointer ${
          showParentGoal ? "border border-primary-300" : ""
        }`}
        onClick={() => setShowParentGoal(true)}
      >
        <Taskicon className="text-primary-600" />
        <div className="flex gap-x-1  items-center justify-start max-w-[140px]">
          <p className="out-300-12 text-gray-900 flex ">
            {parentGoal?.identifier}
          </p>
          <p className="out-500-14 text-gray-700 min-w-[50px] truncate">
            {parentGoal?.name}
          </p>
        </div>
      </div>
    </Popover>
  );
};
const SelectStatus = ({ task, setStatusId, statusId }) => {
  const { projectId } = useParams();
  const { data: swimLanesData, isLoading: swimLoading } = useGetSwimlanes({
    projectId,
  });
  const swimLanes = swimLanesData?.result;
  const [swimlaneIdState, setSwimlaneIdState] = useState(task.swimlaneId);
  const [open, setOpen] = useState(false);
  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };

  const handleStatusChange = async (newStatusId, swimlaneId) => {
    setSwimlaneIdState(swimlaneId);
    setOpen(false);
    setStatusId({ statusId: newStatusId, swimlaneId });
    //todo implement status change for frontend
    // await client.post("/notifications/create", {
    //   notificationType: "33",
    //   goalId: task.id,

    //   allUsers: [{ userId: task.assigneeId }, { userId: task.reporterId }],
    //   projectId: task.projectId,
    //   meta: {
    //     oldStatusId: task.statusId,
    //     newStatusId: newStatusId,
    //     goalId: task.id,
    //   },
    // });

    // dispatch(getTaskActivityLog({ goalId: taskId }));
  };

  const StatusItem = ({ status, text, swimlaneId }) => {
    const { color, className } = getStatusDetails(status);

    return (
      <div
        onClick={() => handleStatusChange(status, swimlaneId)}
        role="button"
        className="flex cursor-pointer hover:bg-gray-50 justify-between  px-[14px] py-2.5 "
      >
        <CustomBadge text={text} color={color} className={className} dot />
        {swimlaneId == swimlaneIdState && (
          <CheckIcon className="text-primary-600" />
        )}
      </div>
    );
  };

  const currentSwimlane = swimLanes?.find((s) => s.id == task.swimlaneId);
  const { color, className } = getStatusDetails(currentSwimlane?.statusId);

  return (
    <>
      {task.id ? (
        <Popover
          arrow={false}
          placement="bottomLeft"
          open={open}
          onOpenChange={handleOpenChange}
          content={
            <div className="w-[200px] bg-white shadow-lg rounded-lg ">
              {swimLanes?.map((s) => (
                <StatusItem
                  key={s.id}
                  status={s.statusId}
                  text={s.title}
                  swimlaneId={s.id}
                />
              ))}
            </div>
          }
          trigger={["click"]}
        >
          <button
            className="flex items-center gap-x-2.5 cursor-pointer"
            onClick={() => setOpen(true)}
          >
            <CustomBadge
              text={currentSwimlane?.title || "To Do"}
              color={color}
              className={className}
              icon={<ChevronIcon />}
              dot
            />
          </button>
        </Popover>
      ) : (
        <CustomBadge
          text={currentSwimlane?.title || "To Do"}
          color={color}
          className={className}
          dot
        />
      )}
    </>
  );
};

export { SelectStatus };
