import React from "react";
import Icon from "@ant-design/icons";

const PhaseIcon = ({
  className = "",
  height = "20",
  width = "20",
  style = {},
  phaseId = 0,
  icon = false,
}) => {
  const getPhaseColor = (phaseId) => {
    switch (+phaseId) {
      case 1:
        return "#FFDB01";
      case 2:
        return "#F92B63";
      case 3:
        return "#6938EF";
      case 4:
        return "#33B7FF";
      case 5:
        return "#46C66C";
      default:
        return "#98A2B3"; //when phase is not added returning gray color
    }
  };

  if (icon) {
    const getPhaseSrc = (phaseId) => {
      switch (+phaseId) {
        case 1:
          return "/images/v2/schedule/ideation.svg";
        case 2:
          return "/images/v2/schedule/design.svg";
        case 3:
          return "/images/v2/schedule/development.svg";
        case 4:
          return "/images/v2/schedule/deployment.svg";
        case 5:
          return "/images/v2/schedule/maintenance.svg";
        default:
          return "/images/v2/schedule/code-circle-01.svg";
      }
    };
    const src = getPhaseSrc(phaseId);
    return <img src={src} width={width} height={height} />;
  }
  return (
    <span
      role="img"
      className={`anticon ${className}`}
      style={{ color: getPhaseColor(phaseId) }}
    >
      <svg
        width={width}
        height={height}
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_272_10859)">
          <circle cx="10" cy="10" r="9.5" fill="#F9FBFE" stroke="#F0F5FA" />
          <circle cx="10" cy="10" r="4" fill="currentColor" />
        </g>
        <defs>
          <clipPath id="clip0_272_10859">
            <rect width="20" height="20" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </span>
  );
};

export { PhaseIcon };
