import React from "react";
import Icon from "@ant-design/icons";

const UserGroupDcfSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="none"
    viewBox="0 0 16 16"
  >
    <path
      stroke="#165ABF"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M11.333 14v-1.333A2.667 2.667 0 008.667 10H3.333a2.667 2.667 0 00-2.666 2.667V14m14.666 0v-1.333a2.667 2.667 0 00-2-2.58m-2.666-8a2.667 2.667 0 010 5.166m-2-2.586a2.667 2.667 0 11-5.334 0 2.667 2.667 0 015.334 0z"
    ></path>
  </svg>
);

const UserGroupIcon = (props) => {
  return <Icon component={UserGroupDcfSvg} {...props} />;
};

export { UserGroupIcon };
