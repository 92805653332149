/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";
import { PlusIcon, PlusIconSecondary } from "../../../Core/svgV2/PlusIcon";
import { Popover, Tooltip } from "antd";
import { ChevronIcon } from "../../../Core/svgV2/Chevron";
import { updateMyBoard } from "../../../Core/redux/slices/myBoard.slice";
import { DeleteScheduleIcon } from "../../../Core/svgV3/DeleteIcon";
import MoveIcon from "../../../Core/svgV3/MoveIcon";
import ExportIcon from "../../../Core/svgV3/ExportIcon";
import { useNavigate, useParams } from "react-router-dom";
import TaskIcon from "../../../Core/svgV3/TaskIcon";
import ExpandIcon from "../../../Core/svgV3/ExpandIcon";
import {
  createMyBoardTask,
  getMyBoardTasks,
} from "../../../Core/redux/api/myBoardAPI";
import { DEFAULT_LIMIT, DEFAULT_OFFSET } from "../../../Core/constants";
import dayjs from "dayjs";
import getUsersName from "../../../Core/utils/getUserName";
import { convertArrayToCsv } from "../../../Core/utils/exportToCsv";
import CustomAvatar from "../../../Core/CommonV2/CustomAvatar";
import {
  LabelSelect,
  PrioritySelect,
  StatusSelect,
} from "./../MyBoard/TaskReusable";
import { updateSchedule } from "../../../Core/redux/slices/schedule.slice";
import { client } from "../../../Core/utils/axiosClient";
import { CollaboratorSelect, TaskTypeItem } from "./TaskItem";
import {
  createGoal,
  getGoalsBySprint,
  getTodoList,
} from "../../../Core/redux/api/scheduleAPI";
import CustomBadge from "../../../Core/CommonV2/CustomBadge";
import { Calendar } from "react-multi-date-picker";

const TaskDates = ({ task, setTask }) => {
  const [showRangePicker, setShowRangePicker] = useState(false);

  const [dates, setDates] = useState([null, null]);

  const handleDateRange = (e) => {
    console.log(e, "theft");
    setDates(e);
    setShowRangePicker(false);
    setTask(e);
  };

  return (
    <div>
      <button
        className="pl-2 out-300-12"
        onClick={() => setShowRangePicker(!showRangePicker)}
      >
        <Popover
          trigger="click"
          placement="bottomLeft"
          content={
            <Calendar
              value={dates}
              format=""
              onChange={handleDateRange}
              range
              numberOfMonths={2}
              showOtherDays
            />
          }
          arrow={false}
        >
          {dates[0] && dates[1] ? (
            <p className="text-black">
              {dayjs(dates[0]).format("DD MMM")} -{" "}
              {dayjs(dates[1]).format("DD MMM")}
            </p>
          ) : (
            <p className="text-gray-500">Start/End dates</p>
          )}
        </Popover>
      </button>
    </div>
  );
};
import { useQueryClient } from "react-query";
import { useGetGoalsBySprint } from "../../../Core/redux/ReactQueryHooksV3/useScheduleAPI";

// when none of the task is selected

const PrimaryTaskHeader = () => {
  const dispatch = useAppDispatch();
  const { sprintId } = useParams();
  //   const queryClient: any = useQueryClient();

  const { todoList, phases, labels, filterGoalParameter, todoListCount } =
    useAppSelector((state) => state.schedule);
  return (
    <div className="flex flex-col px-3 gap-y-2">
      {window.location.pathname.includes("backlog") ||
        (sprintId && (
          <div className="flex items-center gap-x-1.5">
            <p className="text-black out-500-14">Tasks</p>
            <p className="text-gray-500 out-300-14">{todoList.length} items</p>
          </div>
        ))}

      <button
        className="flex items-center gap-x-1.5 py-[11px] max-w-max"
        onClick={() =>
          dispatch(updateSchedule({ key: "createTaskHeader", value: true }))
        }
      >
        <PlusIconSecondary className="text-gray-500" />
        <p className="text-gray-500 out-500-14">Create task</p>
      </button>
    </div>
  );
};

// when the task is selected
const SelectedTaskHeader = ({
  isBacklog = false,
  goalCount = 0,
  sprintId = "",
  offset = 0,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { projectId } = useParams();

  const {
    selectedTasks,
    selectedTasksSprintIds,
    todoList,
    goals,
    deleteTaskModal,
    sprints,
    filterGoalParameter,
    selectedTasksSprintId,
  } = useAppSelector((state) => state.schedule);
  const { tenantMembers } = useAppSelector((state) => state.tenant);

  const queryClient: any = useQueryClient();
  const { data: Data, isLoading: goalsLoading } = useGetGoalsBySprint({
    projectId,
    sprintId: selectedTasksSprintId ? selectedTasksSprintId : null,
    limit: DEFAULT_LIMIT,
    offset: offset,
    filters: {},
    enabled: true,
  });

  const sprintGoals = Data?.result;

  const [open, setOpen] = useState({
    selectionOptions: false,
    projectOptions: false,
  });

  const selectionOptions = [
    {
      id: 1,
      value: "Select All",
      onClick: () => {
        dispatch(
          updateSchedule({
            key: "selectedTasks",
            value: isBacklog
              ? [...(todoList?.map((item) => item.id) || [])].flat()
              : [...(sprintGoals?.map((item) => item.id) || [])].flat(),
          })
        );
        if (!isBacklog) {
          dispatch(
            updateSchedule({
              key: "selectedTasksSprintId",
              value: isBacklog ? "" : sprintId,
            })
          );
        }
        setOpen({ ...open, selectionOptions: false });
      },
    },
    {
      id: 2,
      value: "Clear Selection",
      onClick: () => {
        dispatch(
          updateSchedule({
            key: "selectedTasks",
            value: [],
          })
        );
        setOpen({ ...open, selectionOptions: false });
      },
    },
  ];

  const projectOptions = [
    {
      id: 1,
      value: "New Project",
      onClick: () => {
        navigate("/project-onboardingV2");
        setOpen({ ...open, projectOptions: false });
      },
    },
    {
      id: 2,
      value: "Existing Project",
      onClick: () => {
        dispatch(
          updateSchedule({
            key: "projectListModal",
            value: true,
          })
        );
        setOpen({ ...open, projectOptions: false });
      },
    },
  ];

  return (
    <div className="bg-white flex items-center gap-x-3 p-1.5 rounded-lg my-1.5">
      <Popover
        trigger="click"
        placement="bottomLeft"
        content={
          <div className="w-[136px] bg-white border border-gray-200 rounded-lg overflow-hidden">
            {selectionOptions.map((option) => (
              <div
                key={option.id}
                className="px-3 py-1.5 cursor-pointer hover:bg-gray-50"
                onClick={option.onClick}
              >
                <p className="text-gray-600 out-300-14">{option.value}</p>
              </div>
            ))}
          </div>
        }
        open={open.selectionOptions}
        arrow={false}
      >
        <button
          className="py-[5px] pl-[10px] pr-[5px] flex items-center justify-center gap-x-1 border border-gray-200 rounded"
          onClick={() =>
            setOpen({
              ...open,
              selectionOptions: !open.selectionOptions,
              projectOptions: false,
            })
          }
        >
          <p className="text-gray-600 out-300-14">
            {selectedTasks?.length} of {goalCount}
            selected
          </p>
          <ChevronIcon className="text-gray-700" />
        </button>
      </Popover>

      {isBacklog ? (
        <Popover
          trigger="click"
          placement="bottomLeft"
          content={
            <div className="min-w-[136px] bg-white border border-gray-200 rounded-lg overflow-x-hidden max-h-[300px] overflow-y-scroll">
              {sprints.map((sprint) => (
                <div
                  key={sprint.id}
                  className=" flex justify-between min-w-[169px] w-full px-3 py-1.5 cursor-pointer hover:bg-gray-50"
                  onClick={async () => {
                    const payload = {
                      taskIds: selectedTasks,
                      projectId: projectId,
                      sprintId: sprint.id,
                    };
                    await client.post("/schedule/move-to-project", payload);
                    await dispatch(
                      updateSchedule({
                        key: "filterGoalParameter",
                        value: {
                          ...filterGoalParameter,
                          sprintIds: [sprint.id],
                        },
                      })
                    );
                    navigate(`/schedule/${projectId}/sprints/${sprint.id}`);
                    await dispatch(
                      updateSchedule({ key: "selectedTasks", value: [] })
                    );
                  }}
                >
                  <p className="text-gray-600 out-300-14">{sprint.name}</p>
                  <CustomBadge
                    text={
                      sprint.status === 2
                        ? "Active"
                        : sprint.status === 3
                        ? "Completed"
                        : "Upcoming"
                    }
                    color={
                      sprint.status === 2
                        ? "#165ABF"
                        : sprint.status === 3
                        ? "#027A48"
                        : "#667085"
                    }
                    className={`out-500-12 ${
                      sprint.status === 2
                        ? "bg-primary-100"
                        : sprint.status === 3
                        ? "bg-green-100"
                        : "bg-gray-200"
                    }  `}
                  />
                </div>
              ))}
            </div>
          }
          open={open.projectOptions}
          arrow={false}
        >
          <button
            className="p-1 rounded cursor-pointer hover:bg-gray-50"
            onClick={() =>
              setOpen({
                ...open,
                projectOptions: !open.projectOptions,
                selectionOptions: false,
              })
            }
          >
            <MoveIcon />
          </button>
        </Popover>
      ) : (
        <Tooltip title="Move to Backlog">
          <button
            className="p-1 rounded cursor-pointer hover:bg-gray-50"
            onClick={async () => {
              const payload = {
                taskIds: selectedTasks,
                projectId: projectId,
                sprintId: null,
              };
              await client.post("/schedule/move-to-project", payload);
              navigate(`/schedule/${projectId}/backlog`);

              const uniqueSprintIdsSet = new Set();
              selectedTasksSprintIds.forEach((pair) => {
                uniqueSprintIdsSet.add(pair.sprintId);
              });

              const uniqueSprintIds = Array.from(uniqueSprintIdsSet);

              uniqueSprintIds.forEach(async (sprintId) => {
                queryClient.invalidateQueries([
                  "goalsBySprint",
                  projectId,
                  +sprintId,
                ]);
              });

              await dispatch(
                updateSchedule({ key: "selectedTasks", value: [] })
              );
              await dispatch(
                updateSchedule({ key: "selectedTasksSprintIds", value: [] })
              );
            }}
          >
            <MoveIcon />
          </button>
        </Tooltip>
      )}

      <button
        className="p-1 rounded cursor-pointer hover:bg-gray-50"
        onClick={() => {
          dispatch(
            updateSchedule({
              key: "exportModal",
              value: true,
            })
          );
        }}
      >
        <ExportIcon />
      </button>

      <button
        className="p-1 rounded cursor-pointer hover:bg-error-50"
        onClick={() => {
          dispatch(
            updateSchedule({
              key: "deleteTaskModal",
              value: { ...deleteTaskModal, visible: true },
            })
          );
        }}
      >
        <DeleteScheduleIcon />
      </button>
    </div>
  );
};

export const TaskType = ({
  onTaskTypeChange,
  taskType = 1,
  isNewGoal = false,
}) => {
  const [selectedType, setSelectedType] = useState(isNewGoal ? 4 : taskType);
  const [open, setOpen] = useState(false);
  const getTaskDetails = (taskType) => {
    switch (+taskType) {
      case 1:
        return { src: "/images/v2/schedule/Task.svg", taskName: "Task" };
      case 2:
        return { src: "/images/v2/schedule/meet.svg", taskName: "Event" };
      case 3:
        return { src: "/images/v2/schedule/Bug.svg", taskName: "Bug" };
      case 4:
        return { src: "/images/v2/schedule/Subtask.svg", taskName: "Subtask" };
      default:
        return {
          src: "/images/v2/schedule/Task.svg",
          taskName: "Task",
        };
    }
  };
  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };
  const { taskName, src } = getTaskDetails(selectedType);

  return (
    <Popover
      arrow={false}
      open={open}
      placement="bottomLeft"
      content={
        <div className="w-[200px] bg-white shadow-lg rounded-lg ">
          <TaskTypeItem
            taskDetails={getTaskDetails(1)}
            checked={selectedType === 1}
            onClick={async () => {
              await onTaskTypeChange(1);
              setSelectedType(1);
              setOpen(false);
            }}
          />
          <TaskTypeItem
            taskDetails={getTaskDetails(3)}
            checked={selectedType === 3}
            onClick={async () => {
              await onTaskTypeChange(3);
              setSelectedType(3);
              setOpen(false);
            }}
          />

          {/* <TaskTypeItem
            taskDetails={getTaskDetails(4)}
            checked={selectedType === 4}
            onClick={async () => {
              await onTaskTypeChange(4);
              setSelectedType(4);
              setOpen(false);
            }}
          /> */}
        </div>
      }
      onOpenChange={handleOpenChange}
      trigger={["click"]}
    >
      <button
        className="flex items-center cursor-pointer"
        onClick={() => setOpen(true)}
      >
        <Tooltip title="Select Type">
          <img src={src} className="h-[20px] w-[20px]" />
        </Tooltip>
      </button>
    </Popover>
  );
};
const CreateTaskHeader = ({
  labelOptions,
  sprintId = null,
  callBack,
  offset,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { projectId } = useParams();
  const [addTask, setAddTask] = useState(false);

  const { todoList, phases, labels, filterGoalParameter } = useAppSelector(
    (state) => state.schedule
  );

  const { user } = useAppSelector((state) => state.userDetails);

  const [showRangePicker, setShowRangePicker] = useState(false);

  const initialState = {
    name: "",
    labelId: null,
    statusId: 1,
    priorityId: 1,
    startDate: null,
    endDate: null,
    isNewGoal: true,
    goalType: 1,
    collaborators: [],
  };

  const [task, setTask] = useState(initialState);

  const enableCreateButton = task.name.trim() !== "";
  useEffect(
    () => console.log(task.startDate, task.endDate, "dates top 44"),
    [task]
  );

  const handleDateRange = (e) => {
    console.log(e[0], e[1], "dates top");
    // if (e[0] && e[1]) {
    console.log(e, "dates top 1");
    setTask({ ...task, startDate: e[0], endDate: e[1] });
    console.log(task, "dates top 2");

    setShowRangePicker(false);
    // }
    // if (task.startDate === null) {
    //   setTask({ ...task, startDate: e[0] });
    // } else {
    //   setTask({ ...task, endDate: e[1] });
    // }
  };

  const createTask = async () => {
    setAddTask(false);

    const payload = {
      sprintId,
      projectId: projectId,
      name: task.name,
      isCompleted: 0,
      isNewGoal: task.goalType === 4 ? 4 : 0,
      statusId: task.statusId,
      priorityId: task.priorityId,
      goalType: task.goalType === 4 ? 1 : task.goalType,
      assigneeId: user.id,
      labelId: task.labelId,
      startDate: task.startDate,
      endDate: task.endDate,
      collaborators: task.collaborators,
    };

    await dispatch(createGoal(payload));
    setTask(initialState);

    dispatch(updateSchedule({ key: "createTaskHeader", value: false }));

    if (sprintId) {
      dispatch(
        getGoalsBySprint({ projectId, sprintId, offset, limit: DEFAULT_LIMIT })
      );
    } else {
      dispatch(getTodoList({ projectId, limit: DEFAULT_LIMIT, offset: 0 }));
    }
    if (callBack && typeof callBack === "function") {
      callBack();
    }
  };
  const handleExpand = () => {
    dispatch(
      updateSchedule({
        key: "newTask",
        value: {
          goalType: 1,
          name: task.name,
          taskDetails: task,
          startDate: dayjs(task.startDate).format(),
          endDate: dayjs(task.endDate).format(),
          priorityId: task.priorityId,
          sprintId: sprintId ? sprintId : null,
        },
      })
    );
    localStorage.removeItem("taskRedirectUrl");
    localStorage.setItem("taskRedirectUrl", location.pathname);

    let url = `/schedule/${projectId}/task/new`;
    if (sprintId) {
      url = `/schedule/${projectId}/task/new?sprintId=${sprintId}`;
    }
    navigate(url);
  };
  return (
    <div>
      <div className="flex items-center gap-x-1.5 pl-3">
        <p className="text-black out-500-14">Tasks</p>
        <p className="text-gray-500 out-300-14">{todoList?.length} items</p>
      </div>

      <div className="bg-white border-2 border-primary-400 rounded-t-xl py-[5px] px-3 flex items-center gap-x-3 relative mt-2">
        <button data-no-dnd="true" className="flex gap-x-1.5 items-center">
          <TaskType
            isNewGoal={false}
            taskType={+task.goalType}
            onTaskTypeChange={async (type) =>
              setTask({ ...task, goalType: +type })
            }
          />
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="8"
            height="6"
            viewBox="0 0 8 6"
            fill="none"
          >
            <path d="M1 1.5L4 4.5L7 1.5" fill="#667085" />
            <path
              d="M1 1.5L4 4.5L7 1.5H1Z"
              stroke="#667085"
              strokeWidth="1.67"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
        <div className="flex-1">
          <input
            value={task?.name}
            placeholder="What needs to be done?"
            className="w-full text-black bg-white border-none outline-none out-300-14"
            type="text"
            onChange={(e) => setTask({ ...task, name: e.target.value })}
          />
        </div>

        <LabelSelect
          visible={true}
          task={task}
          labelOptions={labelOptions}
          setTask={async (e) => {
            setTask({ ...task, labelId: e });
          }}
        />

        <StatusSelect
          task={task}
          setTask={async (e) => {
            setTask({ ...task, statusId: e });
          }}
        />

        {+task.goalType !== 3 && (
          <TaskDates
            task={task}
            setTask={async (e) => {
              setTask({
                ...task,
                startDate: dayjs(e[0]),
                endDate: dayjs(e[1]),
              });
            }}
          />
        )}

        <PrioritySelect
          task={task}
          setTask={async (e) => {
            setTask({ ...task, priorityId: e });
          }}
        />

        <CollaboratorSelect
          task={task}
          setTask={(e) => setTask({ ...task, collaborators: e })}
        />

        <button onClick={handleExpand}>
          <ExpandIcon />
        </button>

        <button onClick={createTask} disabled={!enableCreateButton}>
          <p
            className={`out-500-14 ${
              enableCreateButton ? "text-primary-600" : "text-gray-300"
            }`}
          >
            Create
          </p>
        </button>

        <button
          onClick={() =>
            dispatch(updateSchedule({ key: "createTaskHeader", value: false }))
          }
        >
          <p className="text-gray-500 out-500-14">Cancel</p>
        </button>
      </div>
    </div>
  );
};

export { PrimaryTaskHeader, SelectedTaskHeader, CreateTaskHeader };
