import { Input, Tooltip } from "antd";
import React, { useState } from "react";
import {
  getSwimlanes,
  updateSwimlane,
} from "../../../Core/redux/api/scheduleAPI";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";

import { PlusIcon } from "../../../Core/svgV2/PlusIcon";

import EditStatusModal from "../Modals/EditStatusModal";
import { updateSchedule } from "../../../Core/redux/slices/schedule.slice";
import { updateMyBoard } from "../../../Core/redux/slices/myBoard.slice";
import { useParams } from "react-router-dom";

const KanbanColumnHeader = ({
  isTask,
  title,
  count,
  channel,
  addSwimalne,
}: {
  isTask: boolean;
  title: string;
  count: string | number;
  channel: any;
  addSwimalne?: () => void;
}) => {
  const [renameStatus, setRenameStatus] = useState(false);
  const dispatch = useAppDispatch();
  const [editStatusModal, setEditStatusModal] = useState(false);
  const { showSubtaskView } = useAppSelector((state) => state.myBoard);

  const [name, setName] = useState(title);

  const { swimlanes } = useAppSelector((state) => state.schedule);

  const handleRename = () => {
    let modifiedSwimlanes = [...swimlanes];
    modifiedSwimlanes = modifiedSwimlanes.map((s) => {
      if (s.id === channel.id) {
        return { ...s, title: name };
      }
      return s;
    });
    dispatch(updateSchedule({ key: "swimlanes", value: modifiedSwimlanes }));
    setRenameStatus(false);
    dispatch(updateSwimlane({ title: name, id: channel.id }));
  };

  return renameStatus ? (
    <Input
      autoFocus
      className="rounded-lg bg-white out-500-14 text-gray-700"
      value={name}
      onPressEnter={handleRename}
      onChange={(e) => setName(e.target.value)}
      onBlur={() => setRenameStatus(false)}
    />
  ) : (
    <div className="bg-white group  py-[11px] px-4 pr-2 rounded-lg shadow-xs  hover:shadow-s flex items-center justify-between  max-h-[40px] min-h-[40px]">
      <div className="out-500-12 truncate whitespace-nowrap text-gray-700 flex items-center gap-x-2">
        <p className="uppercase truncate whitespace-nowrap">{title}</p>
        <div className=" rounded-full py-[2px] px-2 bg-primary-50">
          <p className="out-500-12 text-gray-700">{count}</p>
        </div>
      </div>
      <div className="group-hover:flex hidden justfiy-center gap-x-0.5">
        {!showSubtaskView && (
          <Tooltip title="Add new task">
            <button
              onClick={() =>
                dispatch(
                  updateMyBoard({
                    key: "showNewTaskCard",
                    value: { visible: true, channel },
                  })
                )
              }
            >
              <PlusIcon className="text-gray-700" />
            </button>
          </Tooltip>
        )}
      </div>

      <EditStatusModal
        visible={editStatusModal}
        onClose={() => setEditStatusModal(false)}
      />
    </div>
  );
};

export default KanbanColumnHeader;
