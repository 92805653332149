import dayjs from "dayjs";
import React, { useEffect, useRef, useState } from "react";
import { ChevronIcon } from "../../Core/svgV2/Chevron";

import { PlusIcon } from "../../Core/svgV2/PlusIcon";
import { useAppDispatch, useAppSelector } from "../../Core/redux/hooks";
import { updateDashboard } from "../../Core/redux/slices/dashboard.slice";
import { client } from "../../Core/utils/axiosClient";
import { gapi } from "gapi-script";
import { Dropdown, Spin } from "antd";
import Timeline from "./MyCalendarTimelines";
import CalendarintegrationModal from "./Modals/CalendarintegrationModal";
import { SearchIcon } from "../../Core/svgV2/SearchIcon";
import MyCalendarSidebar from "./MyCalendarSidebar";
import MyCalendarNavigator from "./MyCalendarNavigator";
import CustomSearchBox from "../../Core/CommonV2/CustomSearchBox";
import useDebounce from "../../Core/hooks/useDebounceValue";
import { searchEvents } from "../../Core/redux/api/scheduleAPI";
import { CircleIcon } from "../../Core/svgV3/CalendarCircleIcon";
import { CrossIconSecondary } from "../../Core/svgV2/CrossIcon";
import CalendarSyncedModal from "./Modals/CalendarSyncedModal";

const Events = () => {
  const CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;
  const SCOPE = "openid email profile https://www.googleapis.com/auth/calendar";

  const { user } = useAppSelector((state) => state.userDetails);
  const { myCalendarTimelineSelected, integrationModalSuccess } =
    useAppSelector((state) => state.dashboard);

  const [loading, setLoading] = useState(false);
  const [showCalendarEvents, setShowCalendarEvents] = useState(false);
  const [currentDate, setCurrentDate] = useState(dayjs());
  const [showSearchBox, setShowSearchBox] = useState(false);
  const [search, setSearch] = useState("");
  const debouncedSearch = useDebounce(search, 500);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [dropdownItems, setDropdownItems] = useState([]);

  const dropdownRef = useRef(null);

  // useEffect(() => {
  //   const handleClickOutside = (event) => {
  //     if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
  //       setDropdownVisible(false);
  //     }
  //   };

  //   document.addEventListener("mousedown", handleClickOutside);

  //   return () => {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   };
  // }, [dropdownRef]);

  useEffect(() => {
    fetchData();
  }, [debouncedSearch]);

  const fetchData = async () => {
    if (debouncedSearch) {
      try {
        const response = await dispatch(
          searchEvents({
            userId: +user.id,
            searchQuery: debouncedSearch,
          })
        );

        if (response && response.payload.events.length) {
          const formattedData = formatDataForDropdown(response.payload.events);
          setDropdownItems(formattedData);
          setDropdownVisible(true);
        } else {
          console.error("Error: Unexpected response format");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    } else {
      setDropdownVisible(false);
      setDropdownItems([]);
    }
  };

  const formatDataForDropdown = (events) => {
    const formatTimeRange = (startTime, endTime) => {
      const formatTime = (timeString) => {
        const time = dayjs(timeString);
        const hour = time.hour();
        const minute = time.minute();
        const ampm = hour >= 12 ? "pm" : "am";
        const formattedHour = hour % 12 || 12;
        const formattedMinute = minute === 0 ? "" : `:${minute}`;
        return `${formattedHour}${formattedMinute}${ampm}`;
      };

      const formatStartTime = formatTime(startTime);
      const formatEndTime = formatTime(endTime);

      if (formatStartTime.endsWith("am") && formatEndTime.endsWith("am")) {
        return `${formatStartTime.replace("am", "")}-${formatEndTime}`;
      } else if (
        formatStartTime.endsWith("pm") &&
        formatEndTime.endsWith("pm")
      ) {
        return `${formatStartTime.replace("pm", "")}-${formatEndTime}`;
      } else {
        return `${formatStartTime}-${formatEndTime}`;
      }
    };

    events.sort((a, b) => {
      try {
        // Set selectedColor for each event
        a.selectedColor = a.extendedProperties?.shared?.selectedColor || "-1";
        b.selectedColor = b.extendedProperties?.shared?.selectedColor || "-1";

        // Get the start times
        const startTimeA = a.start.dateTime
          ? dayjs(a.start.dateTime)
          : dayjs(a.start.date);
        const startTimeB = b.start.dateTime
          ? dayjs(b.start.dateTime)
          : dayjs(b.start.date);
        const currentTime = dayjs();

        if (
          startTimeA.isAfter(currentTime) &&
          startTimeB.isAfter(currentTime)
        ) {
          return startTimeA.diff(startTimeB);
        } else if (
          startTimeA.isBefore(currentTime) &&
          startTimeB.isBefore(currentTime)
        ) {
          return startTimeB.diff(startTimeA);
        } else if (startTimeA.isAfter(currentTime)) {
          return -1;
        } else {
          return 1;
        }
      } catch (error) {
        console.error("Error processing events:", a, b);
        throw error;
      }
    });

    console.log("search events", events);

    return events.map((event) => {
      const formatDate = (dateString) => {
        const date = dayjs(dateString);
        const currentDate = dayjs();

        // If the date is today, display it as "Today"
        if (date.isSame(currentDate, "day")) {
          return "Today";
        }

        // If the date is yesterday, display it as "Yesterday"
        if (date.isSame(currentDate.subtract(1, "day"), "day")) {
          return "Yesterday";
        }

        // If the date is tomorrow, display it as "Tomorrow"
        if (date.isSame(currentDate.add(1, "day"), "day")) {
          return "Tomorrow";
        }

        return date.format("ddd DD MMM");
      };

      const colorNumber = event?.extendedProperties?.shared?.selectedColor || 6;

      return {
        key: event.id,
        label: (
          <div className="flex gap-2 px-3.5 py-2.5 items-center">
            <div>
              <CircleIcon colorNumber={+colorNumber} />
            </div>
            <div
              className={`flex-grow truncate out-300-14 ${
                dayjs(event.start.dateTime).isBefore(dayjs())
                  ? "text-gray-500"
                  : "text-gray-900"
              }`}
            >
              {event.summary}
            </div>
            <div>
              <div
                className={`flex justify-end out-300-12 ${
                  dayjs(event.start.dateTime).isBefore(dayjs())
                    ? "text-gray-500"
                    : "text-gray-900"
                }`}
              >
                {formatDate(event.start.dateTime)}
              </div>
              <div
                className={`out-300-12 ${
                  dayjs(event.start.dateTime).isBefore(dayjs())
                    ? "text-gray-500"
                    : "text-gray-900"
                }`}
              >
                {formatTimeRange(event.start.dateTime, event.end.dateTime)}
              </div>
            </div>
          </div>
        ),
        onClick: () => {
          dispatch(updateDashboard({ key: "eventInfoModal", value: true }));
          dispatch(updateDashboard({ key: "selectedEvent", value: event }));
          setDropdownVisible(false);
          setSearch("");
        },
      };
    });
  };

  const handleSearchChange = (event, value) => {
    setSearch(value);
    console.log(value, "value for search change");
  };

  const responseGoogle = async (response) => {
    console.log(response, "This is the consolelog");
    try {
      const { data } = await client.post(
        "/integration/calender/create-tokens",
        {
          code: response.code,
        }
      );
      await checkIfgoogleCalenderIsIntegrated();
      dispatch(
        updateDashboard({ key: "integrationModalSuccess", value: true })
      );
    } catch (e) {
      console.log(e);
    }
  };

  const responseError = (error) => {
    console.log(error);
  };

  useEffect(() => {
    settingCurrentDate(currentDate);
  }, [currentDate]);

  function settingCurrentDate(currentDate) {
    dispatch(
      updateDashboard({
        key: "currentDate",
        value: currentDate,
      })
    );
  }

  useEffect(() => {
    function start() {
      gapi.client?.init({
        clientId: CLIENT_ID,
        scope: SCOPE,
      });
    }

    gapi.load("client:auth2", start);
  }, []);

  const getEvents = async () => {
    try {
      let minTime, maxTime;

      const timeZoneOffsetMinutes = dayjs().utcOffset();

      // For day view
      if (myCalendarTimelineSelected === 0) {
        minTime = dayjs(currentDate)
          .startOf("day")
          .utcOffset(timeZoneOffsetMinutes)
          .toISOString();
        maxTime = dayjs(currentDate)
          .endOf("day")
          .utcOffset(timeZoneOffsetMinutes)
          .toISOString();
      }
      // For week view
      else if (myCalendarTimelineSelected === 1) {
        minTime = dayjs(currentDate)
          .startOf("week")
          .startOf("day")
          .utcOffset(timeZoneOffsetMinutes)
          .toISOString();
        maxTime = dayjs(currentDate)
          .endOf("week")
          .endOf("day")
          .utcOffset(timeZoneOffsetMinutes)
          .toISOString();
      }
      // For month view
      else if (myCalendarTimelineSelected === 2) {
        const firstDayOfMonth = currentDate
          .clone()
          .startOf("month")
          .startOf("week");
        const lastDayOfMonth = currentDate.clone().endOf("month").endOf("week");

        minTime = firstDayOfMonth
          .utcOffset(timeZoneOffsetMinutes)
          .toISOString();
        maxTime = lastDayOfMonth.utcOffset(timeZoneOffsetMinutes).toISOString();
      }
      // Add more conditions for other views as needed

      if (myCalendarTimelineSelected !== 3) {
        const { data: events } = await client.get(
          "/integration/calender/get-events",
          {
            params: {
              userId: user.id,
              date: currentDate.format("YYYY-MM-DD"),
              timeMin: minTime,
              timeMax: maxTime,
            },
          }
        );

        console.log(events, "these are the events ");

        dispatch(
          updateDashboard({
            key: "events",
            value: events?.events || [],
          })
        );
      }
    } catch (e) {
      console.log(e);
    }
  };

  const checkIfgoogleCalenderIsIntegrated = async () => {
    setLoading(true);
    try {
      const { data } = await client.get(
        "/integration/calender/refresh-token-status",
        {
          params: { userId: user.id },
        }
      );
      if (data.value) {
        setShowCalendarEvents(true);
      }
    } catch (e) {
      setShowCalendarEvents(false);
    } finally {
      setLoading(false);
    }
  };

  const handleTimelineChange = (increment) => {
    let newDate;
    switch (myCalendarTimelineSelected) {
      case 0:
        newDate = currentDate.add(increment, "days");
        break;
      case 1:
        newDate = currentDate.add(increment * 7, "days");
        break;
      case 2:
        newDate = currentDate.add(increment, "month");
        break;
      case 3:
        newDate = currentDate.add(increment, "years");
        break;
      default:
        newDate = currentDate;
    }
    setCurrentDate(newDate);
  };

  useEffect(() => {
    getEvents();
  }, [currentDate, myCalendarTimelineSelected]);

  useEffect(() => {
    checkIfgoogleCalenderIsIntegrated();
  }, []);

  const dispatch = useAppDispatch();

  const handleTimelineChangeYear = (newDate) => {
    console.log(currentDate, "before");
    console.log(newDate);
    setCurrentDate(newDate);
    console.log(currentDate, "after");
    dispatch(updateDashboard({ key: "myCalendarTimelineSelected", value: 0 }));
  };

  const handleSearchIconClick = () => {
    setShowSearchBox(true);
  };

  const handleClearSearch = () => {
    setSearch("");
    setShowSearchBox(false);
    setDropdownVisible(false);
  };

  // In the Timeline component

  if (loading) {
    return (
      <div className="pt-60 w-full flex items-center justify-center">
        <Spin spinning={loading} />
      </div>
    );
  }
  return (
    <div className="overflow-hidden relative">
      <div
        ref={dropdownRef}
        className="flex justify-between px-6 py-4 items-center bg-slate-50 border-b border-slate-200"
      >
        <div className="flex gap-3 items-center">
          <div className="flex gap-2 items-center">
            <button
              className="w-[30px] h-[30px] border border-slate-200 rounded"
              onClick={() => handleTimelineChange(-1)}
            >
              <ChevronIcon className="mt-2 transform rotate-90" />
            </button>
            <button
              className="w-[30px] h-[30px] border border-slate-200 rounded"
              onClick={() => handleTimelineChange(1)}
            >
              <ChevronIcon className="-mt-2 transform -rotate-90" />
            </button>
          </div>
          <div className="flex gap-1 items-center">
            {myCalendarTimelineSelected === 0 && (
              <p className="text-gray-900 out-500-16">
                {currentDate.format("DD")}
              </p>
            )}
            {myCalendarTimelineSelected !== 3 && (
              <p className="text-gray-900 out-500-16">
                {currentDate.format(" MMMM")}
              </p>
            )}
            <p className="text-gray-500 out-300-16">
              {currentDate.format("YYYY")}
            </p>
          </div>
        </div>
        <div className="flex gap-3 items-center">
          <div className="flex gap-3 items-center">
            {showSearchBox && (
              <Dropdown
                dropdownRender={(origin) => (
                  <div className="min-w-[287px] max-h-[50vh] overflow-auto">
                    {origin}
                  </div>
                )}
                menu={{ items: dropdownItems }}
                trigger={[]}
                placement="bottom"
                open={dropdownVisible}
              >
                <CustomSearchBox
                  width="w-[480px]"
                  placeholder="Search events"
                  search={search}
                  onChange={handleSearchChange}
                  suffix={
                    <div role="button" onClick={handleClearSearch}>
                      <CrossIconSecondary className="grey-500" />
                    </div>
                  }
                />
              </Dropdown>
            )}
            {!showSearchBox && (
              <SearchIcon
                className="text-[#667085]"
                onClick={handleSearchIconClick}
              />
            )}
          </div>

          <button className="bg-primary-600 text-white out-500-14 h-[30px] py-[5px] px-[10px] flex gap-2 items-center rounded-[4px]">
            <PlusIcon className="flex text-[#F9FBFE] " />
            <div
              className="flex cursor-pointer"
              role="button"
              onClick={() => {
                const currentSelectedDate = dayjs();
                const currentHour = dayjs().hour();
                const startTime = dayjs(`${currentHour}:00`, "HH:mm");
                const endTime = dayjs(`${currentHour + 1}:00`, "HH:mm");
                dispatch(
                  updateDashboard({
                    key: "createEventModal",
                    value: {
                      visible: true,
                      date: currentSelectedDate,
                      startTime,
                      endTime,
                    },
                  })
                );
              }}
            >
              Create Event
            </div>
          </button>
        </div>
      </div>
      <div className="overflow-hidden flex w-full bg-slate-100">
        <div className="flex-1">
          <div className=" max-h-[calc(100vh-140px)] overflow-y-scroll">
            <Timeline
              currentDate={currentDate}
              view={myCalendarTimelineSelected}
              onClick={handleTimelineChangeYear}
            />
          </div>
        </div>
        <MyCalendarSidebar onDateClick={handleTimelineChangeYear} />
      </div>
      <div className="absolute bottom-3 right-6 z-50">
        <MyCalendarNavigator onTodayClick={handleTimelineChangeYear} />
      </div>
      <CalendarintegrationModal
        visible={!showCalendarEvents && !loading}
        CLIENT_ID={CLIENT_ID}
        responseGoogle={responseGoogle}
        responseError={responseError}
        SCOPE={SCOPE}
      />

      <CalendarSyncedModal
        visible={integrationModalSuccess}
        onClose={() => {
          dispatch(
            updateDashboard({ key: "integrationModalSuccess", value: false })
          );
        }}
      />
    </div>
  );
};

export default Events;
