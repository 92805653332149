import React, { useEffect, useState } from "react";
import ScheduleLayout from "../../Components/ScheduleLayout";
import ReleaseNotesMenu from "./ReleaseNotesMenu";
import {
  getPriorityDetails,
  getStatusDetails,
} from "../../../../Core/utils/scheduleUtils";
import CustomBadge from "../../../../Core/CommonV2/CustomBadge";
import { useAppDispatch, useAppSelector } from "../../../../Core/redux/hooks";
import { Dropdown, Tooltip } from "antd";
import { getPhaseName } from "../../../../Core/utils/getPhaseName";
import TaskTypeIcon from "../../../../Core/svgV2/TaskTypeIcon";
import CustomAvatar from "../../../../Core/CommonV2/CustomAvatar";
import getUsersName from "../../../../Core/utils/getUserName";

import PriorityFlag from "../../../../Core/svgV2/PriorityFlag";
import CustomTable from "../../../../Core/CommonV2/CustomTable";
import dayjs from "dayjs";
import { EditIcon } from "../../../../Core/svgV2/EditIcon";
import { DeleteIcon } from "../../../../Core/svgV2/DeleteIcon";
import { DotsVerticalIcon } from "../../../../Core/svgV2/DotsVertical";
import NewReleaseVersionModal from "../../Modals/NewReleaseVersionModal";
import DeleteReleaseVersionModal from "../../Modals/DeleteReleaseVersionModal";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import RemoveFromVersionModal from "../../Modals/RemoveFromVersionModal";
import MoveToVersionModal from "../../Modals/MoveToVersionModal";
import {
  getGoalsBySprint,
  getLabels,
  getReleaseVersions,
} from "../../../../Core/redux/api/scheduleAPI";
import { ReleaseNotesIcon } from "../../../../Core/svgV2/ReleaseNotesIcon";
import ReleaseModal from "../../Modals/ReleaseModal";
import ReleaseErrorModal from "../../../Dashboard/Modals/ReleaseErrorModal";
import { updateDashboard } from "../../../../Core/redux/slices/dashboard.slice";

const ReleaseNoteTable = () => {
  const { projectId, parentNoteId } = useParams();
  const dispatch = useAppDispatch();
  const [removeFromVersion, setRemoveFromVersion] = useState(false);
  const [limit, setLimit] = useState(1000);
  const [currentPageNo, setCurrentPageNo] = useState(1);
  const [relaseNoteModal, setReleaseNoteModal] = useState(false);
  const [releaseNoteDetails, setReleaseNoteDetails] = useState(null);
  const [deleteVersionModal, setDeleteVersionModal] = useState(false);
  const [releaseModal, setReleaseModal] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const { releaseErrorModal } = useAppSelector((state) => state.dashboard);
  const { releaseVersions } = useAppSelector((state) => state.schedule);
  const noteId =
    parentNoteId && !searchParams.has("noteId")
      ? parentNoteId
      : searchParams.get("noteId");
  useEffect(() => {
    dispatch(getReleaseVersions({ projectId }));
  }, [projectId]);

  const filterReleaseVersion = () => {
    if (!parentNoteId) return releaseVersions;
    const currentReleaseVerion = releaseVersions.find(
      (version) => version.id == parentNoteId
    );
    if (!currentReleaseVerion) return [];

    if (
      currentReleaseVerion &&
      currentReleaseVerion.hasAssociatedVersions == 1
    ) {
      const associatedReleaseVersions =
        currentReleaseVerion.associatedVersions || [];
      console.log(associatedReleaseVersions, "release 2");
      if (associatedReleaseVersions && associatedReleaseVersions.length === 0)
        return [];
      return releaseVersions.filter((version) =>
        associatedReleaseVersions.includes(version.id)
      );
    }
  };

  console.log(releaseVersions, "releaseVersions");
  const columns = [
    {
      title: () => (
        <div className="out-500-12 leading-[18px] text-gray-500 ">
          Version No.
        </div>
      ),

      dataIndex: "versionName",
      defaultSortOrder: "ascend",
      sorter: (a, b) => a.id - b.id,
      render: (text) => <p className="out-300-12 text-black">{text}</p>,
    },
    {
      title: () => (
        <div className="out-500-12 leading-[18px] text-gray-500 ">Type</div>
      ),

      dataIndex: "hasAssociatedVersions",
      defaultSortOrder: "ascend",
      sorter: (a, b) => a.id - b.id,
      render: (text) => (
        <p className="out-300-12 text-black">{text == 1 ? "PROD" : "QA"}</p>
      ),
    },
    {
      title: () => (
        <div className="out-500-12 leading-[18px] text-gray-500 ">
          Start date
        </div>
      ),

      dataIndex: "startDate",
      defaultSortOrder: "ascend",
      sorter: (a, b) => a.id - b.id,
      render: (text) => (
        <p className="out-300-12 text-gray-500">
          {dayjs(text).format("DD MMM YYYY")}
        </p>
      ),
    },
    {
      title: () => (
        <div className="out-500-12 leading-[18px] text-gray-500 ">
          Release date
        </div>
      ),

      dataIndex: "releaseDate",
      defaultSortOrder: "ascend",
      sorter: (a, b) => a.id - b.id,
      render: (text) => (
        <p className="out-300-12 text-gray-500">
          {dayjs(text).format("DD MMM YYYY")}
        </p>
      ),
    },
    {
      title: () => (
        <div className="out-500-12 leading-[18px] text-gray-500 ">Status</div>
      ),

      dataIndex: "versionStatus",
      defaultSortOrder: "ascend",
      sorter: (a, b) => a.id - b.id,
      render: (text) => {
        if (text === 0) {
          return (
            <CustomBadge
              text={"Unreleased"}
              color="#667085"
              className="border border-gray-200"
            />
          );
        } else {
          return (
            <CustomBadge
              text={"Released"}
              color="#039855"
              className="border border-gray-200"
            />
          );
        }
      },
    },
    {
      title: () => (
        <div className="out-500-12 leading-[18px] text-gray-500 ">
          Description
        </div>
      ),

      dataIndex: "description",
      defaultSortOrder: "ascend",
      sorter: (a, b) => a.id - b.id,
      render: (text) => (
        <p className="out-300-12 text-black">{text || "N/A"}</p>
      ),
    },
    {
      width: "5%",
      render: (text, record) => (
        <Dropdown
          menu={{
            items: parentNoteId
              ? [
                  {
                    key: "4",
                    label: (
                      <div className="out-300-14 leading-5 text-gray-700">
                        Remove
                      </div>
                    ),
                    icon: <DeleteIcon className="text-gray-700 " />,
                  },
                ]
              : [
                  {
                    key: "1",
                    label: (
                      <div className="out-300-14 leading-5 text-gray-700">
                        Edit
                      </div>
                    ),
                    icon: <EditIcon className="text-gray-700" />,
                  },

                  !record.versionStatus && {
                    key: "2",
                    label: (
                      <div className="out-300-14 text-gray-700">Release</div>
                    ),
                    icon: <ReleaseNotesIcon className="text-gray-700" />,
                  },
                  {
                    key: "3",
                    label: (
                      <div className="out-300-14 text-gray-700">Delete</div>
                    ),
                    icon: <DeleteIcon className="text-gray-700 " />,
                  },
                ],
            onClick: async ({ key, domEvent }) => {
              domEvent.stopPropagation();
              setReleaseNoteDetails(record);

              switch (key) {
                case "1":
                  setReleaseNoteModal(true);
                  break;
                case "2":
                  {
                    const isProd = record?.hasAssociatedVersions;
                    if (isProd) {
                      const childVersions = record?.associatedVersions;
                      if (childVersions.length > 0) {
                        childVersions.map(async (childVersion) => {
                          if (!childVersion.versionStatus) {
                            dispatch(
                              updateDashboard({
                                key: "releaseErrorModal",
                                value: {
                                  visible: true,
                                  type: releaseErrorModal.type,
                                },
                              })
                            );
                          } else {
                            await dispatch(
                              getGoalsBySprint({
                                projectId,
                                filters: {
                                  releaseVersionId: record?.id,
                                },
                              })
                            );
                            setReleaseModal(true);
                          }
                        });
                      }
                    } else {
                      await dispatch(
                        getGoalsBySprint({
                          projectId,
                          filters: { releaseVersionId: record?.id },
                        })
                      );
                      setReleaseModal(true);
                    }
                  }
                  break;
                case "3":
                  setDeleteVersionModal(true);

                  break;
                case "4":
                  setRemoveFromVersion(true);
                  break;

                default:
                  break;
              }
            },
          }}
          trigger={["click"]}
          dropdownRender={(origin) => {
            return <div className="w-[150px]">{origin}</div>;
          }}
        >
          <button onClick={(e) => e.stopPropagation()}>
            <DotsVerticalIcon className="text-black" />
          </button>
        </Dropdown>
      ),
    },
  ];

  return (
    <div className="bg-white">
      <CustomTable
        // customPadding="p-3"
        className="custom-header cursor-pointer"
        // rowClassName="border-none"
        //scroll={projectList ? "calc(100vh-325px)" : "0px"}
        columns={columns}
        data={filterReleaseVersion() || []}
        dataCount={filterReleaseVersion()?.length || 0}
        current={currentPageNo}
        pageSize={limit}
        scroll="calc(100vh - 215px)"
        setPageSize={setLimit}
        setCurrent={(e) => {
          setCurrentPageNo(e);
        }}
        onRow={(record) => {
          return {
            onClick: () => {
              if (record.hasAssociatedVersions == 1) {
                navigate(location.pathname + `/${record.id}`);
              } else {
                navigate(location.pathname + `?noteId=${record.id}`);
              }
            },
          };
        }}
      />
      <NewReleaseVersionModal
        visible={relaseNoteModal}
        isEdit
        releaseNoteDetails={releaseNoteDetails}
        onClose={() => setReleaseNoteModal(false)}
      />
      <DeleteReleaseVersionModal
        visible={deleteVersionModal}
        onClose={() => setDeleteVersionModal(false)}
        releaseNoteDetails={releaseNoteDetails}
      />
      <RemoveFromVersionModal
        visible={removeFromVersion}
        onClose={() => setRemoveFromVersion(false)}
        task={{
          parentNoteId,
          newChildNotes:
            (filterReleaseVersion() || [])
              .filter((v) => v?.id != releaseNoteDetails?.id)
              .map((v) => v.id) || [],
        }}
        isParent
      />
      {releaseModal && (
        <ReleaseModal
          visible={releaseModal}
          onClose={() => setReleaseModal(false)}
          version={releaseNoteDetails}
        />
      )}
    </div>
  );
};

const NoteDetailsTable = () => {
  const { projectId } = useParams();
  const { goals, releaseNotesView } = useAppSelector((state) => state.schedule);
  const dispatch = useAppDispatch();
  const { filters, labels } = useAppSelector((state) => state.schedule);

  useEffect(() => {
    dispatch(getLabels({ projectId }));
  }, [projectId]);

  const { userList } = useAppSelector((state) => state.overview);

  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedTask, setSelectedTask] = useState(null);

  useEffect(() => {
    if (searchParams.has("noteId")) {
      dispatch(
        getGoalsBySprint({
          projectId,
          filters: { releaseVersionId: searchParams.get("noteId") },
        })
      );
    }
  }, [searchParams]);

  const StatusItem = ({ status }) => {
    const { text, color, className } = getStatusDetails(status);
    return <CustomBadge text={text} color={color} className={className} dot />;
  };

  const taskColumns = [
    {
      title: () => (
        <div className="out-500-12 leading-[18px] text-gray-500 uppercase">
          Type
        </div>
      ),
      width: "100px",
      dataIndex: "goalType",
      defaultSortOrder: "ascend",
      sorter: (a, b) => a.id - b.id,
      render: (text, record) => (
        <Tooltip
          className="text-white cursor-pointer"
          title={getPhaseName(record.phaseId)}
        >
          <TaskTypeIcon
            type={record.isNewGoal ? "newscope" : +record.goalType}
            width="16"
            height="16"
          />
        </Tooltip>
      ),
    },
    {
      title: () => (
        <div className="out-500-12 leading-[18px]  text-gray-500 ">ID No.</div>
      ),
      width: "100px",
      dataIndex: "id",
      defaultSortOrder: "ascend",
      sorter: (a, b) => a.id - b.id,
      render: (text, record) => (
        <div className="out-300-12 leading-5 text-gray-500 ">
          {" "}
          PI - {`${record.linkedGoalId || record.id}`}
        </div>
      ),
    },
    {
      title: () => (
        <div className="out-500-12 leading-[18px]  text-gray-500 ">Title</div>
      ),
      width: "320px",
      dataIndex: "name",
      defaultSortOrder: "ascend",
      sorter: (a, b) => a.id - b.id,
      render: (text) => (
        <p className="out-300-12 leading-5 text-black ">{text}</p>
      ),
    },

    {
      title: () => (
        <div className="out-500-12 leading-[18px]  text-gray-500 ">
          Assignee
        </div>
      ),
      width: "100px",
      defaultSortOrder: "ascend",
      sorter: (a, b) => a.id - b.id,

      dataIndex: "assigneeId",
      render: (text, record) => {
        const assignee = userList.find(
          (user) => +user.userId === +record.assigneeId
        );
        //const ownerDetails = getOwnerDetails(text);
        return (
          <div className="flex items-center gap-x-3 ">
            <Tooltip title={`${getUsersName(assignee)}`}>
              <div>
                {" "}
                <CustomAvatar
                  title={`${getUsersName(assignee)}`}
                  src={assignee?.profilePicture}
                  size={20}
                  color={assignee?.profilePictureColorCode}
                  whiteText
                />
              </div>
            </Tooltip>
          </div>
        );
      },
    },
    {
      title: () => (
        <div className="out-500-12 leading-[18px]  text-gray-500 ">
          Priority
        </div>
      ),
      width: "68px",
      defaultSortOrder: "ascend",
      sorter: (a, b) => a.id - b.id,

      dataIndex: "priorityId",
      render: (text) => {
        const priorityData = getPriorityDetails(text);
        return (
          <Tooltip title={`Priority: ${priorityData.priorityName}`}>
            <div>
              <PriorityFlag
                style={{ color: priorityData.color }}
                height="20"
                width="20"
              />
            </div>
          </Tooltip>
        );
      },
    },
    {
      title: () => (
        <div className="out-500-12 leading-[18px]  text-gray-500 ">Label</div>
      ),
      width: "200px",
      defaultSortOrder: "ascend",
      sorter: (a, b) => a.id - b.id,

      dataIndex: "priorityId",
      render: (text, record) => {
        const currentLable = labels.find((l) => l.id == record.labelId);
        if (!currentLable) return "";
        return (
          <CustomBadge
            text={currentLable?.name}
            color={currentLable?.color}
            className="border border-gray-200"
          />
        );
      },
    },

    {
      title: () => (
        <div className="out-500-12 leading-[18px]  text-gray-500 ">Status</div>
      ),
      width: "200px",
      defaultSortOrder: "ascend",
      sorter: (a, b) => a.id - b.id,

      dataIndex: "statusId",
      render: (text) => {
        //const ownerDetails = getOwnerDetails(text);
        return <StatusItem status={text} />;
      },
    },
    {
      width: "5%",
      render: (text, record) => (
        <Dropdown
          menu={{
            items: [
              {
                key: "1",
                label: (
                  <div className="out-300-14 leading-5 text-gray-700">
                    Move to
                  </div>
                ),
                icon: <img className="" src="/images/v2/schedule/move.svg" />,
              },
              {
                key: "9",
                label: (
                  <div className="out-300-14 text-gray-700">
                    Remove from version
                  </div>
                ),
                icon: <DeleteIcon className="text-gray-700 " />,
              },
            ],
            onClick: ({ key }) => {
              if (key === "1") {
                setMoveToVersionModal(true);
              } else {
                setRemoveFromVersion(true);
              }
              setSelectedTask(record);
            },
          }}
          trigger={["click"]}
          dropdownRender={(origin) => {
            return <div className="w-[200px]">{origin}</div>;
          }}
        >
          <DotsVerticalIcon className="text-black" />
        </Dropdown>
      ),
    },
  ];
  const subtaskColumns = [
    {
      title: () => (
        <div className="out-500-12 leading-[18px] text-gray-500 uppercase">
          Type
        </div>
      ),
      width: "100px",
      dataIndex: "goalType",
      defaultSortOrder: "ascend",
      sorter: (a, b) => a.id - b.id,
      render: (text, record) => (
        <Tooltip
          className="text-white cursor-pointer"
          title={getPhaseName(record.phaseId)}
        >
          <TaskTypeIcon
            type={record.isNewGoal ? "newscope" : +record.goalType}
            width="16"
            height="16"
          />
        </Tooltip>
      ),
    },
    {
      title: () => (
        <div className="out-500-12 leading-[18px]  text-gray-500 ">ID No.</div>
      ),
      width: "100px",
      dataIndex: "id",
      defaultSortOrder: "ascend",
      sorter: (a, b) => a.id - b.id,
      render: (text, record) => (
        <div className="out-300-12 leading-5 text-gray-500 ">
          {" "}
          SPI - {`${record.linkedGoalId || record.id}`}
        </div>
      ),
    },
    {
      title: () => (
        <div className="out-500-12 leading-[18px]  text-gray-500 ">Title</div>
      ),
      width: "320px",
      dataIndex: "name",
      defaultSortOrder: "ascend",
      sorter: (a, b) => a.id - b.id,
      render: (text) => (
        <p className="out-300-12 leading-5 text-black ">{text}</p>
      ),
    },
    {
      title: () => (
        <div className="out-500-12 leading-[18px]  text-gray-500 ">
          Parent Goal Id No.
        </div>
      ),
      width: "100px",
      dataIndex: "parentGoalId",
      defaultSortOrder: "ascend",
      sorter: (a, b) => a.id - b.id,
      render: (text, record) => (
        <div className="out-300-12 leading-5 text-gray-500 ">
          {" "}
          PI - {`${record.parentGoalId || record.id}`}
        </div>
      ),
    },
    {
      title: () => (
        <div className="out-500-12 leading-[18px]  text-gray-500 ">
          Assignee
        </div>
      ),
      width: "100px",
      defaultSortOrder: "ascend",
      sorter: (a, b) => a.id - b.id,

      dataIndex: "assigneeId",
      render: (text, record) => {
        const assignee = userList.find(
          (user) => +user.userId === +record.assigneeId
        );
        //const ownerDetails = getOwnerDetails(text);
        return (
          <div className="flex items-center gap-x-3 ">
            <Tooltip title={`${getUsersName(assignee)}`}>
              <div>
                {" "}
                <CustomAvatar
                  title={`${getUsersName(assignee)}`}
                  src={assignee?.profilePicture}
                  size={20}
                  color={assignee?.profilePictureColorCode}
                  whiteText
                />
              </div>
            </Tooltip>
          </div>
        );
      },
    },
    {
      title: () => (
        <div className="out-500-12 leading-[18px]  text-gray-500 ">
          Priority
        </div>
      ),
      width: "68px",
      defaultSortOrder: "ascend",
      sorter: (a, b) => a.id - b.id,

      dataIndex: "priorityId",
      render: (text) => {
        const priorityData = getPriorityDetails(text);
        return (
          <Tooltip title={`Priority: ${priorityData.priorityName}`}>
            <div>
              <PriorityFlag
                style={{ color: priorityData.color }}
                height="20"
                width="20"
              />
            </div>
          </Tooltip>
        );
      },
    },
    {
      title: () => (
        <div className="out-500-12 leading-[18px]  text-gray-500 ">Label</div>
      ),
      width: "200px",
      defaultSortOrder: "ascend",
      sorter: (a, b) => a.id - b.id,

      dataIndex: "priorityId",
      render: (text, record) => {
        const currentLable = labels.find((l) => l.id == record.labelId);
        if (!currentLable) return "";
        return (
          <CustomBadge
            text={currentLable?.name}
            color={currentLable?.color}
            className="border border-gray-200"
          />
        );
      },
    },

    {
      title: () => (
        <div className="out-500-12 leading-[18px]  text-gray-500 ">Status</div>
      ),
      width: "200px",
      defaultSortOrder: "ascend",
      sorter: (a, b) => a.id - b.id,

      dataIndex: "statusId",
      render: (text) => {
        //const ownerDetails = getOwnerDetails(text);
        return <StatusItem status={text} />;
      },
    },
    {
      width: "5%",
      render: (text, record) => (
        <Dropdown
          menu={{
            items: [
              {
                key: "1",
                label: (
                  <div className="out-300-14 leading-5 text-gray-700">
                    Move to
                  </div>
                ),
                icon: <img className="" src="/images/v2/schedule/move.svg" />,
              },
              {
                key: "9",
                label: (
                  <div className="out-300-14 text-gray-700">
                    Remove from version
                  </div>
                ),
                icon: <DeleteIcon className="text-gray-700 " />,
              },
            ],
            onClick: ({ key }) => {
              if (key === "1") {
                setMoveToVersionModal(true);
              } else {
                setRemoveFromVersion(true);
              }
              setSelectedTask(record);
            },
          }}
          trigger={["click"]}
          dropdownRender={(origin) => {
            return <div className="w-[200px]">{origin}</div>;
          }}
        >
          <DotsVerticalIcon className="text-black" />
        </Dropdown>
      ),
    },
  ];

  const handleFilter = (goals) => {
    let filteredGoals = [];
    if (releaseNotesView === 1) {
      filteredGoals = [...goals];
    } else {
      const subtaskArray = goals.flatMap((goal) => goal.subtasks);

      console.log(subtaskArray, "subtaskArray");
      filteredGoals = [...subtaskArray];
    }

    // filter by assignee
    if (filters?.assignee.length > 0) {
      filteredGoals = filteredGoals.filter((goal) =>
        filters.assignee.includes(goal.assigneeId)
      );
    }

    if (filters?.status.length > 0) {
      filteredGoals = filteredGoals.filter((goal) =>
        filters.status.includes(goal.statusId)
      );
    }

    if (filters?.label.length > 0) {
      filteredGoals = filteredGoals.filter((goal) => {
        console.log(filters.label.includes(+goal.labelId), goal, "filters");
        return filters.label.includes(+goal.labelId);
      });
    }

    if (filters?.priority.length > 0) {
      filteredGoals = filteredGoals.filter((goal) =>
        filters.priority.includes(goal.priorityId)
      );
    }

    if (filters?.type.length > 0) {
      filteredGoals = filteredGoals.filter((goal) => {
        const type = +goal.goalType === 4 ? 1 : +goal.goalType;
        return filters.type.includes(type);
      });
    }

    return filteredGoals;
  };

  const [limit, setLimit] = useState(1000);
  const [currentPageNo, setCurrentPageNo] = useState(1);
  const [removeFromVersion, setRemoveFromVersion] = useState(false);
  const [moveToVersionModal, setMoveToVersionModal] = useState(false);

  return (
    <div className="bg-white">
      <CustomTable
        // customPadding="p-3"
        className="custom-header cursor-pointer"
        // rowClassName="border-none"
        //scroll={projectList ? "calc(100vh-325px)" : "0px"}
        columns={releaseNotesView === 1 ? taskColumns : subtaskColumns}
        data={handleFilter(goals)}
        dataCount={goals.length}
        current={currentPageNo}
        pageSize={limit}
        scroll="calc(100vh - 215px)"
        setPageSize={setLimit}
        setCurrent={(e) => {
          setCurrentPageNo(e);
        }}
      />
      <RemoveFromVersionModal
        visible={removeFromVersion}
        onClose={() => setRemoveFromVersion(false)}
        task={selectedTask}
      />
      <MoveToVersionModal
        visible={moveToVersionModal}
        onClose={() => setMoveToVersionModal(false)}
        task={selectedTask}
      />
    </div>
  );
};

const ReleaseNotesScreen = () => {
  const [searchParams] = useSearchParams();
  const { parentNoteId } = useParams();

  const { releaseErrorModal } = useAppSelector((state) => state.dashboard);

  const render = () => {
    if (parentNoteId && !searchParams.has("noteId")) {
      return (
        <div className="h-[calc(100vh-160px)] bg-white">
          <ReleaseNoteTable />
        </div>
      );
    } else if (searchParams.has("noteId")) {
      return (
        <div className="h-[calc(100vh-160px)] bg-white">
          <NoteDetailsTable />
        </div>
      );
    } else {
      return (
        <div className="h-[calc(100vh-113px)] bg-white">
          <ReleaseNoteTable />
        </div>
      );
    }
  };

  return (
    <ScheduleLayout>
      <div className="bg-white" id="schedule-tour-release-notes">
        <ReleaseNotesMenu />
        {releaseErrorModal?.visible && <ReleaseErrorModal />}
        {render()}
      </div>
    </ScheduleLayout>
  );
};

export default ReleaseNotesScreen;
