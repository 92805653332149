import React from "react";
import CustomBadge from "../../../Core/CommonV2/CustomBadge";
const StatusBadge = ({ statusType, strikethrough = false, done = false }) => {
  switch (+statusType) {
    case 1:
      return (
        <CustomBadge
          text="Discarded"
          color="#667085"
          className={`border-[#667085] border-[0.5px] items-center flex ${
            strikethrough ? "line-through" : ""
          } `}
          dot
        />
      );
    case 2:
      return (
        <CustomBadge
          text="Ongoing"
          color="#165ABF"
          className={`border-[0.5px] border-[#165ABF] items-center flex ${
            strikethrough ? "line-through" : ""
          }`}
          dot
        />
      );

    case 3:
      return (
        <CustomBadge
          text={`${done ? "Done" : "Completed"}`}
          color="#039855"
          className={`border-[0.5px] border-[#039855] items-center flex ${
            strikethrough ? "line-through" : ""
          }`}
          dot
        />
      );
    default:
      <CustomBadge
        text="Ongoing"
        color="#667085"
        className={`border-[0.5px] border-[#667085] items-center flex ${
          strikethrough ? "line-through" : ""
        }`}
        dot
      />;
  }

  return (
    <CustomBadge
      text="Ongoing"
      color="#667085"
      className={`border-[0.5px] border-[#667085] items-center flex ${
        strikethrough ? "line-through" : ""
      }`}
      dot
    />
  );
};

export default StatusBadge;
