import React, { useEffect, useState } from "react";
import CustomModal from "../../../Core/CommonV2/CustomModal";
import InfoByFrosty from "../../../Core/CommonV2/InfoByFrosty";
import { CrossIcon } from "../../../Core/svgV2/CrossIcon";
import { EditIcon } from "../../../Core/svgV2/EditIcon";
import { DatePicker, Tooltip, Popover } from "antd";
import { DeleteIcon } from "../../../Core/svgV2/DeleteIcon";
import CustomInputBox from "../../../Core/CommonV2/CustomInputBox";
import CustomColorPicker from "../../../Core/CommonV2/CustomColorPicker";
import CustomButton from "../../../Core/CommonV2/CustomButton";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";
import { updateSchedule } from "../../../Core/redux/slices/schedule.slice";
import { getLabels } from "../../../Core/redux/api/scheduleAPI";
import { useParams } from "react-router-dom";
import { client } from "../../../Core/utils/axiosClient";
import MyBoardLabel from "../../../Core/CommonV3/MyBoardLabel";
import dayjs from "dayjs";
import type { Dayjs } from "dayjs";
import { Calendar } from "react-multi-date-picker";
import LabelColorPicker from "../MyBoard/LabelColorPicker";

export const CustomDatePicker = ({
  value,
  onChange,
  className,
  placeholder,
}) => {
  const [showCalendar, setShowCalendar] = useState(false);
  const [selectedDates, setSelectedDates] = useState(value || []);

  useEffect(() => {
    setSelectedDates(value || []);
  }, [value]);

  const handleDateChange = (dates) => {
    setSelectedDates(dates);
    setShowCalendar(false);
    onChange(dates);
  };

  return (
    <div className={className}>
      <button
        className="pl-2 out-300-12"
        onClick={() => setShowCalendar(!showCalendar)}
      >
        <Popover
          trigger="click"
          placement="bottomLeft"
          content={
            <Calendar
              value={selectedDates}
              onChange={handleDateChange}
              format=""
              range
              numberOfMonths={2}
              showOtherDays
            />
          }
          arrow={false}
        >
          <div className="w-full">
            {selectedDates.length === 2 ? (
              <p className="text-black">
                {dayjs(selectedDates[0]).format("DD MMM")} -{" "}
                {dayjs(selectedDates[1]).format("DD MMM")}
              </p>
            ) : (
              <p className="text-gray-500">{placeholder}</p>
            )}
          </div>
        </Popover>
      </button>
    </div>
  );
};

export const LabelItem = ({ label, projectId }) => {
  const [edit, setEdit] = useState(false);
  const dispatch = useAppDispatch();
  const [color, setColor] = useState(label.color);
  const [labelName, setLabelName] = useState(label.name);
  const [dateRange, setDateRange] = useState<[Dayjs, Dayjs] | null>(null);

  useEffect(() => {
    setLabelName(label.name);
    setColor(label.color);
    setDateRange(
      label.startDate && label.endDate
        ? [dayjs(label.startDate), dayjs(label.endDate)]
        : null
    );
  }, [label]);

  const handelUpdateLabel = async () => {
    try {
      await client.put("/schedule/label", {
        labelId: label.id,
        labelName,
        color,
        startDate: dateRange ? dayjs(dateRange[0]).toISOString() : null,
        endDate: dateRange ? dayjs(dateRange[1]).toISOString() : null,
      });
      dispatch(getLabels({ projectId, isMyBoard: projectId ? false : true }));
    } catch (e) {
      console.log("Some error happened while updating the label", e);
    } finally {
      setEdit(false);
    }
  };

  if (edit) {
    return (
      <div className="py-2 gap-x-2 flex justify-between">
        <CustomInputBox
          className="w-full"
          autoFocus
          placeholder="Enter a name"
          value={labelName}
          onChange={(e) => setLabelName(e.target.value)}
        />
        <CustomDatePicker
          value={dateRange}
          onChange={(dates) => setDateRange(dates)}
          className="min-w-[100px]"
          placeholder="Start/End dates"
        />
        <LabelColorPicker selectedLabel={label} setSelectedLabel={setColor} />
        {/* <CustomColorPicker
          currentColor={color}
          onColorChange={(color) => setColor(color)}
        /> */}
        <CustomButton
          text="Save"
          className="bg-transparent text-primary-700 out-500-14"
          height="30px"
          onClick={handelUpdateLabel}
        />
        <CustomButton
          text="Cancel"
          className="bg-transparent text-gray-500 out-500-14"
          height="30px"
          onClick={() => setEdit(false)}
        />
      </div>
    );
  }

  return (
    <div className="py-2.5 group flex justify-between">
      <MyBoardLabel labelColorId={label.labelColorId} text={label.name} />
      <div className="group-hover:flex hidden gap-x-4 items-center">
        <Tooltip title="Edit">
          <button onClick={() => setEdit(true)}>
            <EditIcon />
          </button>
        </Tooltip>
        <Tooltip title="Delete">
          <button
            onClick={() =>
              dispatch(
                updateSchedule({
                  key: "deleteLabelModal",
                  value: { visible: true, labelId: label.id },
                })
              )
            }
          >
            <DeleteIcon />
          </button>
        </Tooltip>
      </div>
    </div>
  );
};

const ManageLabelsModal = ({ visible, onClose }) => {
  const { projectId } = useParams();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (projectId) {
      dispatch(getLabels({ projectId }));
    } else {
      dispatch(getLabels({ projectId, isMyBoard: true }));
    }
  }, [projectId, dispatch]);

  const { labels } = useAppSelector((state) => state.schedule);

  return (
    <CustomModal
      visible={visible}
      width="543px"
      onCancel={() => onClose()}
      body={
        <div className="p-4">
          <div className="flex justify-between">
            <p className="text-black out-500-14">Manage Labels</p>
            <div className="flex items-center gap-x-2.5">
              <InfoByFrosty
                title="Label"
                content="Add labels to your tasks, bugs or events to help organize your boards. All the labels will be visible in every project of this workspace."
              />
              <button onClick={() => onClose()}>
                <CrossIcon className="text-gray-700" />
              </button>
            </div>
          </div>
          <div className="mt-5">
            {labels.map((label) => (
              <LabelItem label={label} key={label.id} projectId={projectId} />
            ))}
          </div>
        </div>
      }
    />
  );
};

export default ManageLabelsModal;
