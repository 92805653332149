import React from "react";
import ScheduleLayout from "../../../ModulesV2/Schedule/Components/ScheduleLayout";
import TimelineMenu from "./TimelineMenu";
import PhasesTable from "./TableView/PhasesTable";
import { useAppSelector } from "../../../Core/redux/hooks";
import AccordionComponent from "./TableView/LabelAccordion";
import { useGetLabels } from "../../../Core/redux/ReactQueryHooksV3/useScheduleAPI";
import { useParams } from "react-router-dom";
import GanttChartScreen from "./GanttChart/GantttChartScreen";
import CalendarSyncedModal from "../../Calendar/Modals/CalendarSyncedModal";
import CustomRecurrenceModal from "../../Calendar/Modals/CustomRecurrenceModal";
import EventInfoModal from "../../Calendar/Modals/EventInfoModal";
import NewTimeModal from "../../Calendar/Modals/NewTimeModal";

const TimelineScreen = () => {
  const { labels, timelineView } = useAppSelector((state) => state.schedule);
  const { projectId } = useParams();
  const filteredLabels = labels?.filter(
    (label) => label.projectId === projectId
  );
  return (
    <ScheduleLayout>
      <div className="bg-white" id="schedule-tour-roadmap">
        <TimelineMenu />
        <div className="bg-slate-100 max-h-[(calc(100vh-164px)] ">
          {timelineView === "LabelView" ? (
            <div className="w-full p-3 flex flex-col gap-5 ">
              {filteredLabels?.map((label) => (
                <AccordionComponent key={label.id} label={label} />
              ))}
            </div>
          ) : timelineView === "GanttView" ? (
            <GanttChartScreen />
          ) : (
            <PhasesTable />
          )}
        </div>
      </div>
    </ScheduleLayout>
  );
};

export default TimelineScreen;
