import React, { useEffect, useRef, useState } from "react";
import CustomModal from "../../../Core/CommonV2/CustomModal";
import { CrossIcon, CrossIconSecondary } from "../../../Core/svgV2/CrossIcon";
import { Checkbox, DatePicker, Divider, Dropdown, TimePicker } from "antd";
import CustomInputBox from "../../../Core/CommonV2/CustomInputBox";
import { ClockCircleOutlined } from "@ant-design/icons";
import { CalendarRightIcon } from "../../../Core/svgV3/CalendarRightIcon";
import { ChevronIcon } from "../../../Core/svgV2/Chevron";
import { MarkerPinIcon } from "../../../Core/svgV3/MarkerPinIcon";
import CustomTextEditor from "../../../Core/CommonV3/CustomTextEditor";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";
import CustomSearchBox from "../../../Core/CommonV2/CustomSearchBox";
import useSearch from "../../../Core/hooks/useSearch";
import { CalendarAvatarIcon } from "../../../Core/svgV3/CalendarAvatarIcon";
import CustomAvatar from "../../../Core/CommonV2/CustomAvatar";
import MarkOptionalIcon from "../../../Core/svgV3/MarkOptionalIcon";
import RemoveFromEventIcon from "../../../Core/svgV3/RemoveFromEventIcon";
import { CircleIconPrimary } from "../../../Core/svgV3/CalendarCircleIcon";
import { CalendarNotificationIcon } from "../../../Core/svgV3/NotificationCalendarIcon";
import CustomRecurrenceModal from "./CustomRecurrenceModal";
import { updateDashboard } from "../../../Core/redux/slices/dashboard.slice";
import dayjs from "dayjs";
import CustomButton from "../../../Core/CommonV2/CustomButton";
import { newCreateEvent } from "../../../Core/redux/api/scheduleAPI";
import { client } from "../../../Core/utils/axiosClient";
import Quill from "quill";
import "quill/dist/quill.snow.css";

const MoreInformationAccordion = ({
  onLocationChange,
  onRecurrenceChange,
  isChecked,
  handleCheckboxChange,
}) => {
  const [accordionOpen, setAccordionOpen] = useState(false);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [selectedRepitition, setSelectedRepitition] =
    useState("Doesn’t repeat");
  const { myCalendarRecurrence } = useAppSelector((state) => state.dashboard);
  const dispatch = useAppDispatch();

  const currentYear = new Date().getFullYear();
  const endDate = `${currentYear}1231T235959Z`;

  const options = [
    {
      key: "1",
      label: "Doesn’t repeat",
      onClick: () => {
        setSelectedRepitition("Doesn’t repeat");
        onRecurrenceChange("");
      },
    },
    {
      key: "2",
      label: "Daily",
      onClick: () => {
        setSelectedRepitition("Daily");
        onRecurrenceChange(`RRULE:FREQ=DAILY;UNTIL=${endDate}`);
      },
    },
    {
      key: "3",
      label: "Weekly on Monday",
      onClick: () => {
        setSelectedRepitition("Weekly on Monday");
        onRecurrenceChange(`RRULE:FREQ=WEEKLY;BYDAY=MO;UNTIL=${endDate}`);
      },
    },
    {
      key: "4",
      label: "Monthly on the second Monday",
      onClick: () => {
        setSelectedRepitition("Monthly on the second Monday");
        onRecurrenceChange(`RRULE:FREQ=MONTHLY;BYDAY=2MO;UNTIL=${endDate}`);
      },
    },
    {
      key: "5",
      label: "Custom",
      onClick: () => {
        setSelectedRepitition("Custom");
        dispatch(
          updateDashboard({
            key: "myCalendarRecurrence",
            value: true,
          })
        );
      },
    },
  ];

  const handleDropDownVisibleChange = (visible) => {
    setDropdownVisible(visible);
  };

  const toggleAccordion = () => {
    setAccordionOpen(!accordionOpen);
  };

  const handleLocationChange = (e) => {
    const location = e.target.value;
    onLocationChange(location);
  };

  const handleCancelRecurrence = () => {
    dispatch(
      updateDashboard({
        key: "myCalendarRecurrence",
        value: false,
      })
    );
    setSelectedRepitition("Doesn’t repeat");
  };

  const handleChange = (isChecked) => () => {
    handleCheckboxChange(!isChecked);
  };

  return (
    <div className="px-2.5 pt-2 pb-2.5 bg-slate-100 rounded flex-col gap-2 inline-flex">
      <div className="flex gap-2" role="button" onClick={toggleAccordion}>
        <div className="flex flex-grow">
          <div className="flex flex-col gap-1">
            <p className="text-slate-700 out-500-14">More Information</p>
            <p className="text-gray-500 out-300-12">
              Add location and set the frequency of repeating this event
            </p>
          </div>
        </div>
        <div className="w-5 h-5">
          <ChevronIcon
            className={`transform ${
              accordionOpen ? "rotate-180" : ""
            } text-gray-500 cursor-pointer`}
          />
        </div>
      </div>
      {accordionOpen && (
        <div className="flex-col gap-2 flex">
          {/* Checkbox for All Day */}
          <div className="items-center gap-2 flex">
            <Checkbox
              style={{
                borderColor: "initial",
                background: "initial",
              }}
              className="createEventV3"
              checked={isChecked}
              onChange={handleChange(isChecked)}
            />
            <p className="text-slate-700 out-300-14">All Day</p>
          </div>
          {/* Repeat Options */}
          <Dropdown
            menu={{
              items: options,
            }}
            trigger={["click"]}
            open={dropdownVisible}
            onOpenChange={handleDropDownVisibleChange}
          >
            <div className="flex justify-between bg-white border border-slate-200 items-center py-[5px] px-[10px] rounded">
              <p className="text-grey-900 out-300-14">{selectedRepitition}</p>
              <ChevronIcon
                style={{
                  transform: dropdownVisible ? "rotate(180deg)" : "none",
                }}
              />
            </div>
          </Dropdown>
          {/* Location Input */}
          <CustomInputBox
            placeholder="Enter location"
            prefix={<MarkerPinIcon />}
            onChange={handleLocationChange}
          />
        </div>
      )}
      {myCalendarRecurrence && (
        <CustomRecurrenceModal
          visible={myCalendarRecurrence}
          onCancel={handleCancelRecurrence}
          onSave={(customRecurrence) => {
            onRecurrenceChange(customRecurrence);
            handleCancelRecurrence;
            dispatch(
              updateDashboard({
                key: "myCalendarRecurrence",
                value: false,
              })
            );
          }}
        />
      )}
    </div>
  );
};

const NotesAccordion = ({ initialNotesContent, onSave }) => {
  const [accordionOpen, setAccordionOpen] = useState(false);
  const [quill, setQuill] = useState(null);
  const editorContainerRef = useRef(null);
  const toolbarRef = useRef(null);

  const initializeQuillEditor = () => {
    if (!quill && editorContainerRef.current) {
      const newQuill = new Quill(editorContainerRef.current, {
        theme: "snow",
        modules: modules,
      });
      newQuill.root.innerHTML = initialNotesContent || "";
      setQuill(newQuill);
    }
  };

  const destroyQuillEditor = () => {
    if (quill) {
      quill.off("text-change");
      quill.disable(); // Disable editor before destroying
      quill.container.remove(); // Remove the editor container
      setQuill(null);
    }
  };

  const toggleAccordion = () => {
    setAccordionOpen(!accordionOpen);
  };

  useEffect(() => {
    if (accordionOpen) {
      initializeQuillEditor();
    } else {
      destroyQuillEditor();
    }
  }, [accordionOpen, initialNotesContent]);

  useEffect(() => {
    if (quill) {
      const handleChange = () => {
        const notesContent = quill.root.innerHTML;
        onSave(notesContent);
      };
      quill.on("text-change", handleChange);

      return () => {
        quill.off("text-change", handleChange);
      };
    }
  }, [quill, onSave]);

  return (
    <div className="px-2.5 pt-2 pb-2.5 bg-slate-100 rounded flex-col inline-flex">
      <div className="flex gap-2" role="button" onClick={toggleAccordion}>
        <div className="flex flex-grow">
          <div className="flex flex-col gap-1">
            <p className="text-slate-700 out-500-14">Notes</p>
            <p className="text-gray-500 out-300-12">
              Add your notes or agenda prior to the meeting
            </p>
          </div>
        </div>
        <div className="w-5 h-5">
          <ChevronIcon
            className={`transform ${
              accordionOpen ? "rotate-180" : ""
            } text-gray-500 cursor-pointer`}
          />
        </div>
      </div>
      {accordionOpen && (
        <div className="mt-0.5 bg-white">
          <div ref={toolbarRef}></div>
          <div ref={editorContainerRef}></div>
        </div>
      )}
    </div>
  );
};

const AttendeeCard = ({ attendee, onRemoveAttendee, onMarkAsOptional }) => {
  const [dropdownVisible, setDropdownVisible] = useState(false);

  const isOptional = attendee.dbField.optional || false;

  const markOptionalText = isOptional
    ? "Unmark as optional"
    : "Mark as optional";

  const content = [
    {
      key: "1",
      label: markOptionalText,
      icon: <MarkOptionalIcon />,
      onClick: () => onMarkAsOptional(attendee),
    },
    {
      key: "2",
      label: "Remove",
      icon: <RemoveFromEventIcon />,
      danger: true,
      onClick: () => onRemoveAttendee(attendee),
    },
  ];

  const handleDropDownVisibleChange = (visible) => {
    setDropdownVisible(visible);
  };

  const optionalLabel = isOptional ? "Optional" : "";
  const organizerLabel = attendee.organizer ? "Organizer" : "";
  const label =
    optionalLabel && organizerLabel
      ? `${organizerLabel}, ${optionalLabel}`
      : optionalLabel || organizerLabel;

  return (
    <div className="px-3 py-2 justify-start items-center gap-3 inline-flex">
      <div className="">
        {attendee.external ? (
          <CalendarAvatarIcon />
        ) : (
          <CustomAvatar
            size={24}
            title={attendee.title}
            src={attendee.profilePicture}
          />
        )}
      </div>
      <div className="text-zinc-800 text-sm font-light font-['Outfit'] leading-tight">
        {attendee.title}
        {label && <span className="text-gray-400 ml-1">{`(${label})`}</span>}
      </div>

      <div className="">
        <Dropdown
          menu={{
            items: content,
          }}
          open={dropdownVisible}
          onOpenChange={handleDropDownVisibleChange}
          placement="top"
          trigger={["click"]}
        >
          <ChevronIcon
            style={{
              transform: dropdownVisible ? "rotate(180deg)" : "none",
            }}
          />
        </Dropdown>
      </div>
    </div>
  );
};

const modules = {
  toolbar: {
    container: [
      ["bold", "italic"],
      [{ header: 1 }, { header: 2 }],
      ["blockquote"],
      [{ list: "ordered" }, { list: "bullet" }],
    ],
  },
};

const CreateEventModal = ({ visible, onClose }) => {
  const { tenantMembers } = useAppSelector((state) => state.tenant);
  const { user } = useAppSelector((state) => state.userDetails);
  const { createEventModal, myCalendarTimelineSelected, currentDate } =
    useAppSelector((state) => state.dashboard);
  const filterKeys = ["firstname", "lastname", "email"];
  const { search, setSearch, filteredData } = useSearch(
    tenantMembers,
    filterKeys
  );
  const [selectedColor, setSelectedColor] = useState(0);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [selectedNotification, setSelectedNotification] = useState(10);
  const [location, setLocation] = useState("");
  const [recurrence, setRecurrence] = useState("");
  const [eventTitle, setEventTitle] = useState("");
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedStartTime, setSelectedStartTime] = useState(null);
  const [selectedEndTime, setSelectedEndTime] = useState(null);
  const [isGoogleMeet, setIsGoogleMeet] = useState(false);
  const [notes, setNotes] = useState("");
  const [attendees, setAttendees] = useState([]);
  const [isAllDay, setIsAllDay] = useState(false);

  const dispatch = useAppDispatch();
  const quillRef = useRef(null);
  const editor = quillRef.current;

  useEffect(() => {
    if (visible) {
      setAttendees((prevAttendees) => [
        ...prevAttendees,
        {
          dbField: {
            email: user.email,
            userId: user.id,
          },
          profilePicture: user.profilePicture,
          title: `${user.firstName} ${user.lastName}`,
          external: false,
          organizer: true, // Assuming the user is the organizer by default
        },
      ]);
      const editor = quillRef.current;
      if (editor) {
        editor.setText("");
      }
      setSelectedDate(dayjs(createEventModal.date));
      setSelectedStartTime(dayjs(createEventModal.startTime));
      setSelectedEndTime(dayjs(createEventModal.endTime));
    }
  }, [visible]);

  const handleLocationChange = (location) => {
    setLocation(location);
  };

  const handleRecurrenceChange = (recurrence) => {
    setRecurrence(recurrence);
  };

  const handleColorSelect = (colorNumber) => {
    setSelectedColor(colorNumber);
  };

  const handleDropDownVisibleChange = (visible) => {
    setDropdownVisible(visible);
  };

  const handleRemoveAttendee = (attendeeToRemove) => {
    setAttendees((prevAttendees) =>
      prevAttendees.filter(
        (attendee) => attendee.dbField.email !== attendeeToRemove.dbField.email
      )
    );
  };

  const notifyContent = [
    {
      key: "5",
      label: "5 minutes before the meeting",
      onClick: () => setSelectedNotification(5),
    },
    {
      key: "10",
      label: "10 minutes before the meeting",
      onClick: () => setSelectedNotification(10),
    },
    {
      key: "15",
      label: "15 minutes before the meeting",
      onClick: () => setSelectedNotification(15),
    },
    {
      key: "30",
      label: "30 minutes before the meeting",
      onClick: () => setSelectedNotification(30),
    },
    {
      key: "60",
      label: "1 hour before the meeting",
      onClick: () => setSelectedNotification(60),
    },
    {
      key: "1440",
      label: "1 day before the meeting",
      onClick: () => setSelectedNotification(1440),
    },
  ];

  const handleMarkAsOptional = (attendeeToMark) => {
    const updatedAttendees = attendees.map((attendee) => {
      if (attendee.dbField.email === attendeeToMark.dbField.email) {
        let optional = true;
        if (attendee.dbField.optional !== undefined) {
          optional = !attendee.dbField.optional;
        }
        return {
          ...attendee,
          dbField: {
            ...attendee.dbField,
            optional: optional,
          },
        };
      } else {
        return attendee;
      }
    });

    setAttendees(updatedAttendees);
  };

  const selectedAttendees = attendees.map((attendee) => (
    <AttendeeCard
      key={attendee.dbField.email}
      attendee={attendee}
      onRemoveAttendee={handleRemoveAttendee}
      onMarkAsOptional={handleMarkAsOptional}
    />
  ));

  // Create the users dropdown options
  const usersDropdown = filteredData.length
    ? filteredData.map((user, index) => ({
        key: index.toString(),
        label: `${user.firstname} ${user.lastname}`,
        profilePicture: user.profilePicture,
        firstname: user.firstname,
        lastname: user.lastname,
        external: false,
        onClick: () => {
          // Check if the user is already present in the attendees list
          const userExists = attendees.some(
            (attendee) => attendee.dbField.email === user.email
          );

          // If the user is not already present, add them to attendees with default responseStatus
          if (!userExists) {
            setAttendees((prevAttendees) => [
              ...prevAttendees,
              {
                dbField: {
                  email: user.email,
                  userId: user.userId,
                  optional: false,
                },
                profilePicture: user.profilePicture,
                title: `${user.firstname} ${user.lastname}`,
                external: false,
                organizer: false,
              },
            ]);
          }
          setSearch("");
        },
      }))
    : [
        {
          key: "search",
          label: `${search}`,
          firstname: search,
          lastname: "",
          profilePicture: "",
          external: true,
          onClick: () => {
            // Check if the search value is already present in the attendees list
            const searchExists = attendees.some(
              (attendee) => attendee.dbField.email === search
            );

            // If the search value is not already present, add it to attendees with default responseStatus
            if (!searchExists) {
              setAttendees((prevAttendees) => [
                ...prevAttendees,
                {
                  dbField: {
                    email: search,
                    userId: -1,
                  },
                  profilePicture: "",
                  title: search,
                  external: true,
                  organizer: false,
                },
              ]);
            }
            setSearch("");
          },
        },
      ];

  const formatLabel = (minutes) => {
    if (minutes === "60") {
      return "1 hour";
    } else if (minutes === "1440") {
      return "1 day";
    } else {
      return `${minutes} minutes`;
    }
  };

  const handleSave = async () => {
    // Early return if any required fields are missing
    if (
      !eventTitle ||
      !selectedDate ||
      !selectedStartTime ||
      !selectedEndTime ||
      attendees.length === 0
    ) {
      alert("Input fields empty");
      return;
    }

    // Set the date part to the selected date
    const startTimeWithDate = selectedStartTime.set(
      "date",
      selectedDate.date()
    );
    const endTimeWithDate = selectedEndTime.set("date", selectedDate.date());

    // Convert to UTC
    const startTimeUTC = dayjs.utc(startTimeWithDate).toISOString();
    const endTimeUTC = dayjs.utc(endTimeWithDate).toISOString();

    const payload = {
      date: selectedDate?.format("YYYY-MM-DD"),
      startTime: startTimeUTC,
      endTime: endTimeUTC,
      description: notes,
      eventTitle,
      attendees,
      location,
      recurrence,
      selectedColor,
      selectedNotification,
      isGoogleMeet,
      isAllDay,
    };
    console.log(payload);

    await dispatch(newCreateEvent(payload));

    const getEvents = async () => {
      try {
        let minTime, maxTime;

        const timeZoneOffsetMinutes = dayjs().utcOffset();

        // For day view
        if (myCalendarTimelineSelected === 0) {
          minTime = dayjs(currentDate)
            .startOf("day")
            .utcOffset(timeZoneOffsetMinutes)
            .toISOString();
          maxTime = dayjs(currentDate)
            .endOf("day")
            .utcOffset(timeZoneOffsetMinutes)
            .toISOString();
        }
        // For week view
        else if (myCalendarTimelineSelected === 1) {
          minTime = dayjs(currentDate)
            .startOf("week")
            .startOf("day")
            .utcOffset(timeZoneOffsetMinutes)
            .toISOString();
          maxTime = dayjs(currentDate)
            .endOf("week")
            .endOf("day")
            .utcOffset(timeZoneOffsetMinutes)
            .toISOString();
        } else if (myCalendarTimelineSelected === 2) {
          const firstDayOfMonth = dayjs(currentDate)
            .clone()
            .startOf("month")
            .startOf("week");
          const lastDayOfMonth = dayjs(currentDate)
            .clone()
            .endOf("month")
            .endOf("week");

          minTime = firstDayOfMonth
            .utcOffset(timeZoneOffsetMinutes)
            .toISOString();
          maxTime = lastDayOfMonth
            .utcOffset(timeZoneOffsetMinutes)
            .toISOString();
        }

        if (myCalendarTimelineSelected !== 3) {
          const { data: events } = await client.get(
            "/integration/calender/get-events",
            {
              params: {
                userId: user.id,
                date: dayjs(currentDate).format("YYYY-MM-DD"),
                timeMin: minTime,
                timeMax: maxTime,
              },
            }
          );

          console.log(events, "these are the events ");

          dispatch(
            updateDashboard({
              key: "events",
              value: events?.events || [],
            })
          );
        }
        dispatch(
          updateDashboard({
            key: "alertPopupModal",
            value: {
              visible: true,
              data: {
                title: `Event Created`,
                subtitle: `${eventTitle} on ${selectedDate
                  ?.format("DD MMMM")
                  .replace(/(^|\s)\S/g, (firstLetter) =>
                    firstLetter.toUpperCase()
                  )}, ${startTimeWithDate.format("h:mmA")}`,
                description: "",
              },
            },
          })
        );
      } catch (e) {
        console.log(e);
      }
    };

    if (selectedDate) {
      getEvents();
    }

    handleCancel();
  };

  const handleCancel = () => {
    // Reset all values back to default
    setEventTitle("");
    setNotes("");
    setSelectedDate(null);
    setSelectedStartTime(null);
    setSelectedEndTime(null);
    setAttendees([]);
    setLocation("");
    setRecurrence("");
    setSelectedColor(0);
    setSelectedNotification(10);
    setIsGoogleMeet(false);
    setIsAllDay(false);

    onClose();
  };

  return (
    <CustomModal
      visible={visible}
      width="480px"
      onCancel={handleCancel}
      body={
        <div className=" h-[546px]">
          <div className="p-4 flex justify-between">
            <p className="text-zinc-800 out-500-16">New Event</p>
            <button className="w-5 h-5" onClick={handleCancel}>
              <CrossIcon className={"text-gray-500 cursor-pointer"} />
            </button>
          </div>
          <Divider className="m-0" />
          <div className="flex flex-col gap-4 p-4 max-h-[428px] overflow-y-scroll">
            <CustomInputBox
              placeholder="New event title"
              value={eventTitle}
              onChange={(e) => setEventTitle(e.target.value)}
            />
            <DatePicker
              placeholder="Select Date"
              format="dddd, D MMMM"
              value={selectedDate}
              onChange={(date) => setSelectedDate(date)}
              suffixIcon={null}
            />
            {!isAllDay && (
              <div className="flex gap-3">
                <div className="flex flex-grow">
                  <TimePicker
                    value={selectedStartTime}
                    onChange={(time) => setSelectedStartTime(time)}
                    format="h:mm a"
                    className="border border-slate-200 rounded text-grey-900 out-300-14 w-full"
                    suffixIcon={
                      <ClockCircleOutlined className="text-gray-500 mr-1" />
                    }
                  />
                </div>
                <CalendarRightIcon />
                <div className="flex flex-grow">
                  <TimePicker
                    value={selectedEndTime}
                    onChange={(time) => setSelectedEndTime(time)}
                    format="h:mm a"
                    className="border border-slate-200 rounded text-grey-900 out-300-14 w-full"
                    suffixIcon={
                      <ClockCircleOutlined className="text-gray-500 mr-1" />
                    }
                  />
                </div>
              </div>
            )}
            <CustomButton
              text=" Add Google Meet video conferencing"
              width="278px"
              height="30px"
              icon={
                <img
                  src={
                    isGoogleMeet
                      ? "/images/icons/check-white.svg"
                      : "/images/icons/plus.svg"
                  }
                  className="ml-2"
                />
              }
              onClick={() => setIsGoogleMeet(!isGoogleMeet)}
            />
            <MoreInformationAccordion
              onLocationChange={handleLocationChange}
              onRecurrenceChange={handleRecurrenceChange}
              isChecked={isAllDay}
              handleCheckboxChange={setIsAllDay}
            />
            <NotesAccordion initialNotesContent={notes} onSave={setNotes} />
            <div className="flex flex-col gap-2">
              <p className="text-slate-700 out-500-14">Invite People</p>
              {/* Dropdown only when search is not empty */}
              <Dropdown
                menu={{
                  items: usersDropdown,
                }}
                trigger={[]}
                placement="bottom"
                open={search !== ""}
              >
                <CustomSearchBox
                  placeholder="Invite people to the event"
                  search={search}
                  setSearch={setSearch}
                />
              </Dropdown>
            </div>
            <div className="flex flex-col">{selectedAttendees}</div>
            <div className="flex gap-2">
              {[...Array(6)].map((_, index) => (
                <div
                  key={index}
                  onClick={() => handleColorSelect(index)}
                  className={`flex items-center justify-center w-5 h-5 rounded-full border-2 ${
                    selectedColor === index
                      ? "border-blue-500"
                      : "border-transparent"
                  } cursor-pointer`}
                >
                  <CircleIconPrimary colorNumber={index} />
                </div>
              ))}
            </div>

            <Dropdown
              menu={{
                items: notifyContent,
              }}
              open={dropdownVisible && search !== ""}
              onOpenChange={handleDropDownVisibleChange}
              placement="top"
              trigger={["click"]}
            >
              <div className="flex gap-3 px-2.5 py-2">
                <CalendarNotificationIcon />
                <p>{formatLabel(selectedNotification)} before the meeting</p>
                <ChevronIcon
                  style={{
                    transform: dropdownVisible ? "rotate(180deg)" : "none",
                  }}
                />
              </div>
            </Dropdown>
          </div>
          <Divider className="m-0" />
          <div className="flex gap-3 justify-end items-center p-4">
            <button
              className="text-grey-500 out-500-14 py-[5px] px-[10px] flex justify-between items-center rounded-[4px]"
              onClick={handleCancel}
            >
              Cancel
            </button>
            <button
              className="bg-primary-600 text-white out-500-14 py-[5px] px-[10px] flex justify-between items-center rounded-[4px]"
              onClick={handleSave}
            >
              Create
            </button>
          </div>
        </div>
      }
    />
  );
};

export default CreateEventModal;
