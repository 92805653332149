import React from "react";
import Icon from "@ant-design/icons";

const InfoSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="13"
    height="13"
    fill="none"
    viewBox="0 0 16 16"
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M8 11.2v-4"
    ></path>
    <circle cx="8" cy="8.002" r="6.4" stroke="currentColor"></circle>
    <path fill="currentColor" d="M8.4 5.2a.4.4 0 11-.8 0 .4.4 0 01.8 0z"></path>
  </svg>
);

const InfoIcon = (props) => {
  return <Icon component={InfoSvg} {...props} />;
};

export { InfoIcon };
