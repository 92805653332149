import React, { useEffect, useState } from "react";
import CustomLayout from "../../../Core/LayoutV2/CustomLayout";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import ScheduleAppTour from "../ScheduleAppTour";
import { getAllAutomation } from "../../../Core/redux/api/settingsAPI";
import { updateSchedule } from "../../../Core/redux/slices/schedule.slice";
import { ChevronIcon } from "../../../Core/svgV2/Chevron";
import { Popover } from "antd";
import CustomSearchBox from "../../../Core/CommonV2/CustomSearchBox";
import { CheckIcon } from "../../../Core/svgV2/Check";
import useSearch from "../../../Core/hooks/useSearch";
import { getScreenName } from "../../../Core/utils/scheduleUtils";
import { useGetProjectList } from "../../../Core/redux/ReactQueryHooksV3/useProjectAPI";

const ScheduleLayout = ({ children }) => {
  const { user } = useAppSelector((state) => state.userDetails);
  const [isActive, setIsActive] = useState(false);
  const { data: projectList, isLoading: isLoadingProjectList } =
    useGetProjectList(user.id);
  const { search, setSearch, filteredData } = useSearch(projectList, ["title"]);
  const { projectId } = useParams();
  const navigate = useNavigate();

  const { defaultNavigation } = useAppSelector((state) => state.settings);
  const dispatch = useAppDispatch();

  const { lastVisitedSchedulePage } = useAppSelector((state) => state.schedule);
  useEffect(() => {
    dispatch(
      updateSchedule({
        key: "lastVisitedSchedulePage",
        value: {
          projectId: projectId,
          previousProjectId: lastVisitedSchedulePage?.projectId,
          redirectUrl: window.location.pathname,
        },
      })
    );
  }, [projectId]);

  const getTitle = () => {
    let routeName = "";

    if (projectId) {
      const currentProject = projectList?.find((p) => p.id == projectId);
      if (currentProject?.title) {
        routeName = currentProject.title;
      }

      return routeName;
    } else {
      return "All Projects";
    }
  };

  useEffect(() => {
    if (projectId) {
      dispatch(getAllAutomation({ projectId }));
    }
  }, [projectId]);
  useEffect(() => {
    if (+user.roleId === 4 && user.isGuestDeactivated) {
      navigate("/");
    }
  }, []);
  return (
    <CustomLayout
      page="schedule"
      title={
        <div className="flex gap-x-1.5 ">
          <span className="out-300-14 text-gray-500">Schedule</span>{" "}
          <span className="out-300-14 text-gray-500">/</span>
          <span className="out-500-14 text-black">{getTitle()}</span>{" "}
          <Popover
            open={isActive}
            placement="bottomLeft"
            onOpenChange={(open) => {
              setSearch("");
              setIsActive(open);
            }}
            content={
              <div className="w-[320px] shadow-md rounded-md bg-white border border-gray-100">
                <div className="p-2 px-4">
                  <CustomSearchBox search={search} setSearch={setSearch} />
                </div>
                <div className="max-h-[200px] overflow-y-auto">
                  {filteredData.map((project) => (
                    <div
                      key={project.id}
                      onClick={() => {
                        setIsActive(false);
                        let screenName = "roadmap";
                        if (defaultNavigation.isEnabled) {
                          screenName = getScreenName(
                            defaultNavigation.screenId
                          );
                        }
                        const redirectTo =
                          +lastVisitedSchedulePage?.projectId === +project.id
                            ? lastVisitedSchedulePage?.redirectUrl
                            : project.currentactivesprint
                            ? `/schedule/${project.id}/${screenName}/${
                                screenName == "sprints"
                                  ? project.currentactivesprint
                                  : ""
                              }`
                            : `/schedule/${project.id}/${screenName}`;
                        navigate(redirectTo);
                      }}
                      className={`w-full cursor-pointer hover:bg-gray-50 flex justify-between items-center py-2 px-4 ${
                        project.id === projectId ? "bg-gray-50" : ""
                      }`}
                    >
                      <div className="out-300-14 leading-5 text-gray-700">
                        {project.title}
                      </div>
                      {project.id === projectId ? (
                        <CheckIcon className="text-primary-600" />
                      ) : (
                        ""
                      )}
                    </div>
                  ))}
                </div>
                <div className="w-full py-3 px-4 flex justify-between items-center">
                  <div className="out-300-14 leading-5 text-primary-600">
                    View all projects
                  </div>
                </div>
              </div>
            }
            arrow={false}
            trigger={["click"]}
          >
            <span onClick={() => setIsActive(!isActive)}>
              <ChevronIcon
                className="cursor-pointer text-black"
                style={{
                  transition: "transform 0.3s ease-in-out 0s",
                  transform: isActive ? "rotate(180deg)" : "rotate(0deg)",
                }}
              />
            </span>
          </Popover>
        </div>
      }
    >
      <ScheduleAppTour
        initialProjectId={projectList ? projectList[0]?.id : ""}
      />
      {children}
    </CustomLayout>
  );
};

export default ScheduleLayout;
