import React, { useEffect, useState } from "react";
import CustomModal from "../../../Core/CommonV2/CustomModal";
import { CrossIcon } from "../../../Core/svgV2/CrossIcon";
import { DatePicker, Divider, Dropdown } from "antd";
import { ChevronIcon } from "../../../Core/svgV2/Chevron";
import dayjs from "dayjs";
const Frequency = [
  {
    key: "1",
    label: "Daily",
  },
  {
    key: "2",
    label: "Weekly",
  },
  {
    key: "3",
    label: "Monthly",
  },
  {
    key: "4",
    label: "Yearly",
  },
];
const DaySelector = ({ selectedDays, onClick }) => {
  const days = ["S", "M", "T", "W", "T", "F", "S"];
  return (
    <div className="justify-start items-start gap-2 flex">
      {days.map((day, index) => (
        <div
          key={index}
          className={`w-10 h-10 cursor-pointer out-500-14 flex justify-center items-center ${
            selectedDays.includes(index)
              ? "text-slate-50 bg-blue-700"
              : "text-slate-700 bg-slate-100"
          } rounded-full`}
          onClick={() => onClick(index)}
        >
          {day}
        </div>
      ))}
    </div>
  );
};
const DateSelector = ({ selectedDates, onClick }) => {
  const days = Array.from({ length: 31 }, (_, index) => index + 1);
  const weeks = Array.from({ length: Math.ceil(days.length / 7) }, (_, index) =>
    days.slice(index * 7, index * 7 + 7)
  );
  const isSelected = (day) => selectedDates.includes(day);
  return (
    <div className="w-[191px] p-2 bg-slate-100 rounded-lg">
      {weeks.map((week, rowIndex) => (
        <div key={rowIndex} className="flex justify-between">
          {week.map((day) => (
            <div
              key={day}
              className={`w-[25px] h-[25px] px-2 py-[9px] rounded-[100px] flex items-center justify-center cursor-pointer ${
                isSelected(day)
                  ? "bg-blue-700 text-slate-50"
                  : "bg-slate-100 text-zinc-800 hover:bg-gray-300"
              }`}
              onClick={() => onClick(day)}
            >
              <div className="text-center text-[10px] font-normal font-['Outfit']">
                {day}
              </div>
            </div>
          ))}
          {[...Array(7 - week.length)].map((_, index) => (
            <div key={index} className="w-[25px]" />
          ))}
        </div>
      ))}
    </div>
  );
};
const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
const YearSelector = ({ selectedMonths, onClick }) => {
  return (
    <div className="w-[191px] h-[155.25px] p-2 bg-slate-100 rounded-lg">
      <div className="flex justify-start items-start gap-y-1 flex-wrap">
        {months.map((month, index) => (
          <div
            key={index}
            className={`w-[43.75px] h-[43.75px] cursor-pointer flex items-center justify-center rounded-[100px] ${
              selectedMonths.includes(index)
                ? "bg-blue-700 text-white"
                : "hover:bg-gray-300"
            }`}
            onClick={() => onClick(index)}
          >
            {month}
          </div>
        ))}
      </div>
    </div>
  );
};
const CustomRecurrenceModal = ({
  visible,
  onCancel,
  onSave,
  recurrence = "",
}) => {
  const [selectedFrequency, setSelectedFrequency] = useState(0);
  const [selectedDays, setSelectedDays] = useState([]);
  const [selectedDates, setSelectedDates] = useState([]);
  const [selectedMonths, setSelectedMonths] = useState([]);
  const [repeatValue, setRepeatValue] = useState<number>(1);
  const [endDate, setEndDate] = useState(dayjs());
  const [pickerOpen, setPickerOpen] = useState(false);
  useEffect(() => {
    if (recurrence) {
      decodeRecurrence(recurrence);
      console.log("recurrence here useEffect", recurrence);
    }
  }, [recurrence]);
  const handleMenuClick = (item) => {
    setSelectedFrequency(item.key - 1);
  };
  const handleDayClick = (index) => {
    setSelectedDays((prevSelectedDays) =>
      prevSelectedDays.includes(index)
        ? prevSelectedDays.filter((day) => day !== index)
        : [...prevSelectedDays, index]
    );
  };
  const handleDateClick = (day) => {
    setSelectedDates((prevSelectedDates) =>
      prevSelectedDates.includes(day)
        ? prevSelectedDates.filter((date) => date !== day)
        : [...prevSelectedDates, day]
    );
  };
  const handleMonthClick = (index) => {
    const isSelected = selectedMonths.includes(index);
    if (isSelected) {
      setSelectedMonths(
        selectedMonths.filter((selectedIndex) => selectedIndex !== index)
      );
    } else {
      setSelectedMonths([...selectedMonths, index]);
    }
  };
  const getRecurrence = () => {
    let recurrenceRule = "";
    console.log(endDate, "endDate");
    switch (selectedFrequency) {
      case 0: // Daily
        recurrenceRule = `RRULE:FREQ=DAILY;`;
        if (endDate) {
          const untilString = endDate
            .toISOString()
            .replace(/[-:]/g, "")
            .slice(0, -5); // Format the UNTIL part
          recurrenceRule += `UNTIL=${untilString}Z;`;
        }
        break;
      case 1: // Weekly
        recurrenceRule = `RRULE:FREQ=WEEKLY;`;
        if (repeatValue && repeatValue > 1) {
          recurrenceRule += `INTERVAL=${repeatValue};`;
        }
        if (selectedDays.length > 0) {
          const days = selectedDays.map(
            (index) => ["SU", "MO", "TU", "WE", "TH", "FR", "SA"][index]
          );
          recurrenceRule += `BYDAY=${days.join(",")};`;
        }
        if (endDate) {
          const untilString = endDate
            .toISOString()
            .replace(/[-:]/g, "")
            .slice(0, -5); // Format the UNTIL part
          recurrenceRule += `UNTIL=${untilString}Z;`;
        }
        break;
      case 2: // Monthly
        recurrenceRule = `RRULE:FREQ=MONTHLY;`;
        if (repeatValue && repeatValue > 1) {
          recurrenceRule += `INTERVAL=${repeatValue};`;
        }
        if (selectedDates.length > 0) {
          recurrenceRule += `BYMONTHDAY=${selectedDates.join(",")};`;
        }
        if (endDate) {
          const untilString = endDate
            .toISOString()
            .replace(/[-:]/g, "")
            .slice(0, -5); // Format the UNTIL part
          recurrenceRule += `UNTIL=${untilString}Z;`;
        }
        break;
      case 3: // Yearly
        recurrenceRule = `RRULE:FREQ=YEARLY;`;
        if (repeatValue && repeatValue > 1) {
          recurrenceRule += `INTERVAL=${repeatValue};`;
        }
        if (selectedMonths.length > 0) {
          const months = selectedMonths.map((index) => index + 1);
          recurrenceRule += `BYMONTH=${months.join(",")};`;
        }
        if (endDate) {
          const untilString = endDate
            .toISOString()
            .replace(/[-:]/g, "")
            .slice(0, -5); // Format the UNTIL part
          recurrenceRule += `UNTIL=${untilString}Z;`;
        }
        break;
      default:
        break;
    }
    console.log(recurrenceRule, "recurrenceRule");
    return recurrenceRule;
  };
  const decodeRecurrence = (rrule) => {
    const ruleObj: { [key: string]: string } = {};
    const ruleParts = rrule.replace("RRULE:", "").split(";");
    ruleParts.forEach((part) => {
      const [key, value] = part.split("=");
      ruleObj[key] = value;
    });
    if (ruleObj.FREQ) {
      switch (ruleObj.FREQ) {
        case "DAILY":
          setSelectedFrequency(0);
          break;
        case "WEEKLY":
          setSelectedFrequency(1);
          if (ruleObj.BYDAY) {
            const days = ruleObj.BYDAY.split(",").map((day) =>
              ["SU", "MO", "TU", "WE", "TH", "FR", "SA"].indexOf(day)
            );
            setSelectedDays(days);
          }
          break;
        case "MONTHLY":
          setSelectedFrequency(2);
          if (ruleObj.BYMONTHDAY) {
            const dates = ruleObj.BYMONTHDAY.split(",").map(Number);
            setSelectedDates(dates);
          }
          break;
        case "YEARLY":
          setSelectedFrequency(3);
          if (ruleObj.BYMONTH) {
            const months = ruleObj.BYMONTH.split(",").map((monthName: string) =>
              months.indexOf(monthName)
            );
            setSelectedMonths(months);
          }
          break;
        default:
          break;
      }
    }
    if (ruleObj.INTERVAL) {
      setRepeatValue(Number(ruleObj.INTERVAL));
    }
    if (ruleObj.UNTIL) {
      const untilDate = dayjs(ruleObj.UNTIL, "YYYYMMDDTHHmmssZ");
      const endDate = untilDate;
      setEndDate(endDate);
    }
  };
  const handleSave = () => {
    const recurrenceRule = getRecurrence();
    onSave(recurrenceRule);
  };
  const menuProps = {
    items: Frequency,
    onClick: handleMenuClick,
  };
  const handleCancel = () => {
    setSelectedFrequency(0);
    onCancel();
  };
  return (
    <CustomModal
      visible={visible}
      width="480px"
      onCancel={handleCancel}
      body={
        <div className="h-[384px] flex flex-col">
          <div className="flex justify-between items-center p-4">
            <div className="h-5 out-500-16 text-grey-900">
              Custom Recurrence
            </div>
            <div className="h-5 w-5">
              <CrossIcon className={"text-gray-500 cursor-pointer"} />
            </div>
          </div>
          <Divider className="m-0" />
          <div className="flex flex-col flex-grow gap-4 p-4">
            <div className="flex flex-col gap-2">
              <p className="text-slate-700 out-500-14">Frequency</p>
              <Dropdown menu={menuProps} trigger={["click"]}>
                <div className="h-[30px] flex justify-between border border-slate-200 px-3.5 rounded items-center">
                  <span>{Frequency[selectedFrequency].label}</span>
                  <ChevronIcon />
                </div>
              </Dropdown>
            </div>
            {selectedFrequency === 0 && (
              <div>
                <div className="flex flex-col gap-2">
                  <p className="text-slate-700 out-500-14">Ends on</p>
                  <DatePicker
                    value={endDate}
                    onChange={(date) => setEndDate(date)}
                    className="border border-slate-200 rounded px-2.5 py-[5px] w-full text-grey-900 out-300-14"
                    format="dddd, DD MMMM"
                    suffixIcon={null}
                  />
                </div>
              </div>
            )}
            {selectedFrequency === 1 && (
              <div className="flex flex-col gap-4">
                <div className="flex gap-2 items-center">
                  <p className="text-slate-700 out-500-14">Repeat every</p>
                  <input
                    type="number"
                    value={repeatValue}
                    onChange={(e) => setRepeatValue(parseInt(e.target.value))}
                    className="border border-slate-200 rounded px-2.5 py-[5px] w-[52px] h-[30px] text-grey-900 out-300-14 text-center"
                  />
                  <p className="text-slate-700 out-500-14">week(s) on:</p>
                </div>
                <DaySelector
                  selectedDays={selectedDays}
                  onClick={handleDayClick}
                />
                <div className="flex flex-col gap-2">
                  <p className="text-slate-700 out-500-14">Ends on</p>
                  <DatePicker
                    value={endDate}
                    onChange={(date) => setEndDate(date)}
                    className="border border-slate-200 rounded px-2.5 py-[5px] w-full text-grey-900 out-300-14"
                    format="dddd, DD MMMM"
                    suffixIcon={null}
                  />
                </div>
              </div>
            )}
            {selectedFrequency === 2 && (
              <div className="flex gap-4">
                <div className="flex flex-col gap-4">
                  <div className="flex gap-2 items-center">
                    <p className="text-slate-700 out-500-14">Repeat every</p>
                    <input
                      type="number"
                      value={repeatValue}
                      onChange={(e) => setRepeatValue(parseInt(e.target.value))}
                      className="border border-slate-200 rounded px-2.5 py-[5px] w-[52px] h-[30px] text-grey-900 out-300-14 text-center"
                    />
                    <p className="text-slate-700 out-500-14">month(s) on:</p>
                  </div>
                  <div className="flex flex-col gap-2">
                    <p className="text-slate-700 out-500-14">Ends on</p>
                    <DatePicker
                      value={endDate}
                      onChange={(date) => setEndDate(date)}
                      className="border border-slate-200 rounded px-2.5 py-[5px] w-[240px] text-grey-900 out-300-14"
                      format="dddd, DD MMMM"
                      suffixIcon={
                        <ChevronIcon
                          style={{
                            transform: pickerOpen ? "rotate(180deg)" : "",
                          }}
                        />
                      }
                      picker="month"
                      onOpenChange={(open) => setPickerOpen(open)}
                    />
                  </div>
                </div>
                <DateSelector
                  selectedDates={selectedDates}
                  onClick={handleDateClick}
                />
              </div>
            )}
            {selectedFrequency === 3 && (
              <div className="flex gap-4">
                <div className="flex flex-col gap-4">
                  <div className="flex gap-2 items-center">
                    <p className="text-slate-700 out-500-14">Repeat every</p>
                    <input
                      type="number"
                      value={repeatValue}
                      onChange={(e) => setRepeatValue(parseInt(e.target.value))}
                      className="border border-slate-200 rounded px-2.5 py-[5px] w-[52px] h-[30px] text-grey-900 out-300-14 text-center"
                    />
                    <p className="text-slate-700 out-500-14">year(s) in:</p>
                  </div>
                  <div className="flex flex-col gap-2">
                    <p className="text-slate-700 out-500-14">Ends on</p>
                    <DatePicker
                      value={endDate}
                      onChange={(date) => setEndDate(date)}
                      className="border border-slate-200 rounded px-2.5 py-[5px] w-[240px] text-grey-900 out-300-14"
                      format="dddd, DD MMMM"
                      suffixIcon={
                        <ChevronIcon
                          style={{
                            transform: pickerOpen ? "rotate(180deg)" : "",
                          }}
                        />
                      }
                      picker="year"
                      onOpenChange={(open) => setPickerOpen(open)}
                    />
                  </div>
                </div>
                <YearSelector
                  selectedMonths={selectedMonths}
                  onClick={handleMonthClick}
                />
              </div>
            )}
          </div>
          <Divider className="m-0" />
          <div className="flex items-center justify-end gap-3 cursor-pointer p-4">
            <div
              className="out-500-14 text-gray-500 flex py-[5px] px-[10px]"
              onClick={handleCancel}
            >
              Cancel
            </div>
            <button
              className="flex items-center justify-center out-500-14 text-white bg-primary-700  py-[5px] px-[10px] rounded "
              onClick={handleSave}
            >
              Save
            </button>
          </div>
        </div>
      }
    />
  );
};
export default CustomRecurrenceModal;
