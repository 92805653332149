import React, { useCallback, useEffect, useRef, useState } from "react";
//import { HTML5Backend } from "react-dnd-html5-backend";
import KanbanColumn from "./KanbanColumn";
// import update from "immutability-helper";
import KanbanItem from "./KanbanItem";

import { useParams, useSearchParams } from "react-router-dom";

import {
  DndContext,
  useSensors,
  PointerSensor,
  useSensor,
} from "@dnd-kit/core";
import { restrictToFirstScrollableAncestor } from "@dnd-kit/modifiers";

import KanbanColumnHeader from "./KanbanColumnHeader";

import { useAppDispatch, useAppSelector } from "../../../../Core/redux/hooks";
import {
  resetSchedule,
  updateSchedule,
} from "../../../../Core/redux/slices/schedule.slice";
import {
  createSwimlane,
  getGoalsBySprint,
  getSwimlanes,
  updateGoal,
} from "../../../../Core/redux/api/scheduleAPI";
import { setError } from "../../../../Core/redux/slices/global.slice";

import TaskCard, { NewtaskCard } from "../TaskCard";

import AddNewStatus from "./AddNewStatus";
import {
  useGetGoalsBySprint,
  useGetSwimlanes,
} from "../../../../Core/redux/ReactQueryHooksV3/useScheduleAPI";
import { useQueryClient } from "react-query";
import SubtaskKanbanBoard from "./SubTaskKanbanBoard";
import { DEFAULT_LIMIT } from "../../../../Core/constants";
import BugKanbanBoard from "./BugKanbanBoard";
import { ChevronIcon } from "../../../../Core/svgV2/Chevron";

const KanbanBoard = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [addNewStatus, setAddNewStatus] = useState(false);
  const { projectId, sprintId } = useParams();

  const {
    filterGoalParameter,
    filters,
    selectedTasksForDragAndDrop,
    showNewTaskCard,
    showSubtaskView,
    goals,
  } = useAppSelector((state) => state.schedule);

  const board = useRef(null);
  const subTaskBoard = useRef(null);
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    })
  );

  const dispatch = useAppDispatch();
  const queryClient: any = useQueryClient();
  const { data: swimLanes, isLoading: swimLoading } = useGetSwimlanes({
    projectId,
  });
  const channels = swimLanes?.result;

  console.log(channels, "channels check");

  const [statusArray, setStatusArray] = useState(channels);
  const [isOpen, setIsOpen] = useState(false);

  const { sprints } = useAppSelector((state) => state.schedule);
  const [maxHeight, setMaxHeight] = useState("0px");
  useEffect(() => {
    if (sprintId) {
      dispatch(
        getGoalsBySprint({
          projectId,
          sprintId,
        })
      );
    }
  }, [sprintId]);
  // using this temporarily

  // const goals = queryClient.getQueryData([
  //   "goalsBySprint",
  //   projectId,
  //   +sprintId,
  // ])
  //   ? queryClient.getQueryData(["goalsBySprint", projectId, +sprintId]).result
  //   : [];

  useEffect(() => {
    if (channels) {
      setStatusArray(channels);
    }
  }, [channels]);

  useEffect(() => {
    const updateHeight = () => {
      if (board && board.current) {
        const viewportOffset =
          board.current.getBoundingClientRect()?.top || 100;
        const height = `${window.innerHeight - viewportOffset - 20}px`;
        //  board.current.style.height = height;
        board.current.style.maxHeight = height;

        console.log(
          board.current,
          viewportOffset,
          window.innerHeight,
          "board.current.getBoundingClientRect().height"
        );
        setMaxHeight(board.current.getBoundingClientRect().height);
      }
    };
    updateHeight();
    window.addEventListener("resize", updateHeight);
    return () => window.removeEventListener("resize", updateHeight);
  }, [board]);
  useEffect(() => {
    const updateHeight = () => {
      if (subTaskBoard && subTaskBoard.current) {
        const viewportOffset =
          subTaskBoard.current.getBoundingClientRect()?.top || 100;
        const height = `${window.innerHeight - viewportOffset - 20}px`;
        //  subTaskBoard.current.style.height = height;
        subTaskBoard.current.style.maxHeight = height;

        console.log(
          subTaskBoard.current,
          viewportOffset,
          window.innerHeight,
          "subTaskBoard.current.getBoundingClientRect().height"
        );
        setMaxHeight(subTaskBoard.current.getBoundingClientRect().height);
      }
    };
    updateHeight();
    window.addEventListener("resize", updateHeight);
    return () => window.removeEventListener("resize", updateHeight);
  }, [subTaskBoard]);
  const changeTaskStatus = useCallback(
    async (data) => {
      const taskIds =
        selectedTasksForDragAndDrop.length > 0
          ? selectedTasksForDragAndDrop
          : [data?.active?.id];
      const status = showSubtaskView
        ? data?.over?.data?.current?.channel?.id
        : data?.over?.id;
      const statusId = channels.find((s) => s.id == status)?.statusId || 1;
      const currentSprint = sprints.find((s) => +s.id === +sprintId);
      if (currentSprint.status == 3) {
        dispatch(setError({ message: "Sprint already ended!" }));
        return;
      }
      if (currentSprint && currentSprint.status < 2) {
        dispatch(setError({ message: "Please Start the Sprint first!" }));
        return;
      }

      const updatedGoals = (currentSprint.goals || goals).map((goal) => {
        if (taskIds.includes(goal.id)) {
          return { ...goal, swimlaneId: status };
        }
        return goal;
      });

      const updatedSprints = sprints.map((s) => {
        if (s.id === currentSprint.id) {
          return { ...currentSprint, goals: updatedGoals };
        }
        return s;
      });

      // queryClient.setQueryData(
      //   ["goalsBySprint", projectId, +sprintId],
      //   (oldData) => {
      //     return {
      //       ...oldData,
      //       result: oldData.result.map((goal) => {
      //         if (taskIds.includes(goal.id)) {
      //           return { ...goal, swimlaneId: status };
      //         }
      //         return goal;
      //       }),
      //     };
      //   }
      // );

      await dispatch(
        updateGoal({ goalId: taskIds, swimlaneId: status, statusId })
      );
      dispatch(resetSchedule(["selectedTasksForDragAndDrop"]));
    },
    [sprints, sprintId, selectedTasksForDragAndDrop, goals]
  );

  const handelAddNewStatus = async (name, status) => {
    setAddNewStatus(false);
    setStatusArray((old) => [
      ...old,
      {
        id: old.length + 1,
        statusId: status,
        title: name,
      },
    ]);

    await dispatch(
      createSwimlane({
        projectId,
        statusId: status,
        title: name,
      })
    );
    dispatch(getSwimlanes({ projectId }));

    queryClient.invalidateQueries(["swimlanes", projectId]);
  };

  const count = (channel) => {
    const items = filterGoals(goals, channel);

    const isMatch = (item) => {
      return (
        (item.swimlaneId === 0 && item.swimlaneId + 1 === channel.id) ||
        item.swimlaneId === channel.id
      );
    };

    return items.filter(isMatch).length;
  };

  const handleFilter = (goals) => {
    let filteredGoals = [...goals];

    const filterParams = filterGoalParameter;

    // filter by assignee

    if (filterParams?.assignee.length > 0) {
      const currentProjectFilter = filterGoalParameter?.assignee?.find(
        (filter) => +filter.projectId === +projectId
      );

      if (
        currentProjectFilter !== undefined &&
        currentProjectFilter?.assignee.length > 0
      ) {
        filteredGoals = filteredGoals.filter((goal) => {
          if (goal?.collaborators?.length > 0) {
            return goal?.collaborators?.some((collaborator) =>
              currentProjectFilter?.assignee?.includes(collaborator)
            );
          } else {
            return currentProjectFilter?.assignee?.includes(goal.assigneeId);
          }
        });
      }
    }

    if (filters?.assignee.length > 0) {
      filteredGoals = filteredGoals.filter((goal) =>
        filters.assignee.includes(goal.assigneeId)
      );
    }

    if (filters?.reporter.length > 0) {
      filteredGoals = filteredGoals.filter((goal) =>
        filters.reporter.includes(goal.reporterId)
      );
    }

    if (filters?.status.length > 0) {
      filteredGoals = filteredGoals.filter((goal) =>
        filters.status.includes(goal.statusId)
      );
    }

    if (filters?.label.length > 0) {
      filteredGoals = filteredGoals.filter((goal) => {
        return filters.label.includes(goal.labelId);
      });
    }

    if (filters?.priority.length > 0) {
      filteredGoals = filteredGoals.filter((goal) =>
        filters.priority.includes(goal.priorityId)
      );
    }

    if (filters?.type.length > 0) {
      filteredGoals = filteredGoals.filter((goal) => {
        const type = +goal.goalType === 4 ? 1 : +goal.goalType;
        return filters.type.includes(type);
      });
    }

    if (filters?.phase.length > 0) {
      filteredGoals = filteredGoals.filter((goal) =>
        filters.phase.includes(goal.phaseId)
      );
    }
    // filter by search value
    const searchValue =
      filterGoalParameter?.searchValue?.projectId === projectId &&
      filterGoalParameter?.searchValue?.module === "sprints"
        ? filterParams?.searchValue?.searchValue?.toLowerCase()
        : "";
    filteredGoals = filteredGoals.filter(
      (goal) =>
        goal.name.toLowerCase().includes(searchValue) ||
        goal.id == searchValue ||
        goal.identifier?.includes(searchValue)
    );

    return filteredGoals;
  };

  const filterGoals = (goals, channel) => {
    const filteredGoals = handleFilter([...goals]);
    return [...filteredGoals].filter((item) => {
      return +item.swimlaneId === +channel.id;
    });
  };
  const newStatusDivWidth =
    document.getElementById("addNewStatus")?.offsetWidth;
  // console.log(statusArray, "statusArray here");

  const toggleAccordion = () => setIsOpen(!isOpen);

  return (
    <div className="bg-gray-50 flex max-h-[calc(100vh-174px)]   p-4  relative ">
      {!swimLoading && (
        <div className="flex-1">
          <div
            className={`overflow-y-scroll max-h-[calc(100vh-174px)]  ${
              searchParams.has("selectedSprint")
                ? "max-w-[calc(100vw-300px)]"
                : " max-w-[calc(100vw-250px)]"
            }`}
          >
            <DndContext
              modifiers={[restrictToFirstScrollableAncestor]}
              onDragEnd={changeTaskStatus}
              sensors={sensors}
            >
              <div className={`grid-container ml-[2px] `}>
                {statusArray &&
                  statusArray.map((channel) => (
                    <KanbanColumnHeader
                      title={channel.title}
                      key={channel.id}
                      count={count(channel)}
                      channel={channel}
                      isTask={searchParams.has("selectedSprint")}
                      addSwimalne={() => setAddNewStatus(true)}
                    />
                  ))}
                <AddNewStatus handelAddNewStatus={handelAddNewStatus} />
              </div>
              {showSubtaskView ? (
                <div
                  className={`overflow-y-scroll  overflow-x-hidden max-h-[calc(100vh-250px)]`}
                  style={{ width: `calc(100% - ${newStatusDivWidth}px)` }}
                >
                  {goals &&
                    [...goals]
                      .filter((goal) => +goal.goalType !== 3)
                      .map((task) => (
                        <SubtaskKanbanBoard
                          board={subTaskBoard}
                          handleFilter={(tasks, channel) =>
                            filterGoals(tasks, channel)
                          }
                          statusArray={statusArray}
                          task={task}
                          key={task.id}
                        />
                      ))}
                  {goals && goals.some((goal) => +goal.goalType === 3) && (
                    <>
                      <div
                        className="flex group items-center gap-x-3 px-3 py-2 bg-error-100 rounded-t-lg h-[44px] pl-[20px]"
                        data-bugs="true"
                      >
                        <ChevronIcon
                          style={{
                            transform: isOpen
                              ? "rotate(0deg)"
                              : "rotate(-90deg)",
                          }}
                          className="cursor-pointer"
                          onClick={toggleAccordion}
                        />

                        <img src="/images/v2/schedule/Bug.svg" />
                        <p className="text-error-700">Bugs</p>
                      </div>

                      {isOpen && (
                        <BugKanbanBoard
                          board={subTaskBoard}
                          handleFilter={(tasks, channel) =>
                            filterGoals(tasks, channel)
                          }
                          statusArray={statusArray}
                          task={goals.filter((goal) => +goal.goalType === 3)}
                        />
                      )}
                    </>
                  )}
                </div>
              ) : (
                <div
                  data-kanbanboard={true}
                  className="grid-container ml-[2px] overflow-y-scroll overflow-x-hidden max-h-[100vh-226px]"
                  ref={board}
                  // onScroll={() => console.log("")}
                >
                  {statusArray &&
                    [...statusArray, {}].map((channel) => (
                      <KanbanColumn
                        key={channel.id}
                        status={channel.id}
                        channel={channel}
                        className="kanban-col"
                      >
                        {showNewTaskCard.visible &&
                        showNewTaskCard.channel.id === channel.id ? (
                          <NewtaskCard
                            status={channel}
                            isHovered={true}
                            defaultOpen
                          />
                        ) : null}
                        {filterGoals(goals, channel).map((item) => (
                          <KanbanItem key={item.id} id={item.id}>
                            <TaskCard task={item} />
                          </KanbanItem>
                        ))}
                      </KanbanColumn>
                    ))}
                </div>
              )}
            </DndContext>
          </div>
        </div>
      )}
    </div>
  );
};

export default KanbanBoard;
