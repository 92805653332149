import { createAsyncThunk } from "@reduxjs/toolkit";
import { client, getError } from "../../utils/axiosClient";
import { updateSchedule } from "../slices/schedule.slice";
import { useAppDispatch } from "../hooks";

export const getProjectPhases = createAsyncThunk(
  "schedule/getPhases",
  async (
    {
      projectId,
    }: {
      projectId: string;
    },
    { rejectWithValue }
  ) => {
    try {
      if (!projectId) throw Error();
      const response = await client.get(
        `/project/project-phases?projectId=${projectId}`
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(getError(error));
      //return error;
    }
  }
);
export const getReportsBySprint = createAsyncThunk(
  "schedule/getReportsBySprint",
  async (
    {
      payload,
    }: {
      payload: any;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await client.get(`/reports/get-reports-by-sprints`, {
        params: payload,
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(getError(error));
      //return error;
    }
  }
);
export const getReportsByDateRange = createAsyncThunk(
  "schedule/getReportsByDateRange",
  async (
    {
      payload,
    }: {
      payload: any;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await client.get(`/reports/get-reports-by-date-range`, {
        params: payload,
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(getError(error));
      //return error;
    }
  }
);

export const hasUnfinishedGoals = createAsyncThunk(
  "schedule/hasUnfinishedTasks",
  async (
    {
      phaseId,
      projectId,
    }: {
      phaseId: string | number;
      projectId: string | number;
    },
    { rejectWithValue }
  ) => {
    try {
      console.log(phaseId, "showErrorMessagemOdal");
      if (!phaseId) throw Error();
      const response = await client.get(
        `/schedule/has-unfinished-goals?phaseId=${phaseId}&projectId=${projectId}`
      );
      console.log(response.data, "showErrorMessagemOdal");

      return response.data;
    } catch (error) {
      return rejectWithValue(getError(error));
      //return error;
    }
  }
);

export const addProjectPhase = createAsyncThunk(
  "schedule/addProjectPhase",
  async (payload: object, { rejectWithValue }) => {
    try {
      const response = await client.post(`/project/project-phases`, payload);

      return response.data;
    } catch (error) {
      return rejectWithValue(getError(error));
      //return error;
    }
  }
);

export const getProjectPhasesWithSprints = createAsyncThunk(
  "schedule/getPhasesWithSprints",
  async (
    {
      projectId,
    }: {
      projectId: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await client.get(
        `/project/project-phases-with-sprints?projectId=${projectId}`
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(getError(error));
      //return error;
    }
  }
);

export const updatePhase = createAsyncThunk(
  "schedule/updatePhase",
  async (payload: object, { rejectWithValue }) => {
    try {
      const response = await client.put(`project/project-phases`, payload);

      return response.data;
    } catch (error) {
      return rejectWithValue(getError(error));
      //return error;
    }
  }
);

export const getSprintsByPhase = createAsyncThunk(
  "schedule/getSprints",
  async (
    {
      projectId,
      phaseId,
    }: {
      projectId: string;
      phaseId?: number;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await client.get(`/schedule/sprints`, {
        params: { projectId, phaseId },
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(getError(error));
      //return error;
    }
  }
);

// TO BE REMOVED
export const getAllSprintsByPhase = createAsyncThunk(
  "schedule/getAllSprints",
  async (
    {
      projectId,
      phaseId,
    }: {
      projectId: string;
      phaseId: number;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await client.get(
        `/schedule/sprints?phaseId=${phaseId}&projectId=${projectId}`
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(getError(error));
      //return error;
    }
  }
);

export const getGoalsByLabelId = createAsyncThunk(
  "schedule/getGoalsByLabelId",
  async (
    {
      labelId,
      projectId,
      filters,
    }: {
      labelId: string;
      projectId?: string;
      filters?: object;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await client.get(`schedule/goals-by-label`, {
        params: { labelId, projectId, ...filters },
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(getError(error));
    }
  }
);

export const getGoalsBySprint = createAsyncThunk(
  "schedule/getGoals",
  async (
    {
      projectId,
      sprintId,
      filters,
      limit,
      offset,
      phase,
      searchQuery,
      assignee,
      reporter,
      priority,
      status,
      label,
      type,
      sprint,
    }: {
      projectId: string;
      phase?: any;
      sprintId?: string;
      filters?: object;
      limit?: number;
      offset?: number;
      searchQuery?: any;
      assignee?: any;
      reporter?: any;
      priority?: any;
      status?: any;
      label?: any;
      type?: any;
      sprint?: any;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await client.get(`schedule/goals`, {
        params: {
          sprintId,
          projectId,
          ...filters,
          limit,
          offset,
          searchQuery,
          phase,
          assignee,
          reporter,
          priority,
          status,
          label,
          type,
          sprint,
        },
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(getError(error));
      //return error;
    }
  }
);

export const getTodoList = createAsyncThunk(
  "schedule/getTodoList",
  async (
    // {
    //   projectId,
    //   limit,
    //   offset,
    //   searchQuery,
    //   assignee,
    // }: {
    //   projectId: string;
    //   limit?: number;
    //   offset?: number;
    //   searchQuery?: string;
    //   assignee?: any;
    // },
    payload: any,
    { rejectWithValue }
  ) => {
    try {
      const mutatedPayload = payload;
      mutatedPayload.sprintId = "null";

      const response = await client.get(`schedule/goals`, {
        params: mutatedPayload,
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(getError(error));
      //return error;
    }
  }
);

export const getGoalDetails = createAsyncThunk(
  "schedule/getGoalDetails",
  async (
    {
      goalId,
    }: {
      goalId;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await client.get(`schedule/goal`, {
        params: { goalId },
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(getError(error));
      //return error;
    }
  }
);
export const getSubtaskDetails = createAsyncThunk(
  "schedule/getSubtaskDetails",
  async (
    {
      goalId,
    }: {
      goalId;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await client.get(`schedule/subtask`, {
        params: { goalId },
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(getError(error));
      //return error;
    }
  }
);

export const getGoalsByPhase = createAsyncThunk(
  "schedule/getGoalsByPhase",
  async (
    {
      projectId,
      phaseId,
    }: //phaseId,
    {
      projectId: string;
      phaseId: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await client.get(
        `schedule/ongoing-goals?phaseId=${phaseId}&projectId=${projectId}`
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(getError(error));
      //return error;
    }
  }
);

export const getSwimlanes = createAsyncThunk(
  "schedule/getSwimlanes",
  async (
    {
      projectId,
    }: //phaseId,
    {
      projectId: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await client.get(
        `schedule/swimlane?projectId=${projectId}`
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(getError(error));
      //return error;
    }
  }
);

export const getLabels = createAsyncThunk(
  "schedule/getLabel",
  async (
    {
      projectId,
      isMyBoard = false,
    }: //phaseId,
    {
      projectId: string;
      isMyBoard?: boolean;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await client.get(`schedule/label`, {
        params: { projectId, isMyBoard },
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(getError(error));
      //return error;
    }
  }
);

export const getTaskActivityLog = createAsyncThunk(
  "schedule/getTaskActivityLog",
  async (
    {
      goalId,
    }: //phaseId,
    {
      goalId: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await client.get(`schedule/goal-activity-log`, {
        params: { goalId },
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(getError(error));
      //return error;
    }
  }
);

export const getReleaseVersions = createAsyncThunk(
  "schedule/getReleaseVersions",
  async (
    {
      projectId,
    }: //phaseId,
    {
      projectId?: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await client.get(`/schedule/release-version`, {
        params: { projectId },
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(getError(error));
      //return error;
    }
  }
);

export const createSwimlane = createAsyncThunk(
  "schedule/createSwimlane",
  async (payload: object, { rejectWithValue }) => {
    try {
      const response = await client.post(`schedule/swimlane`, payload);

      return response.data;
    } catch (error) {
      return rejectWithValue(getError(error));
      //return error;
    }
  }
);

export const updateSwimlane = createAsyncThunk(
  "schedule/updateSwimlane",
  async (payload: object, { rejectWithValue }) => {
    try {
      const response = await client.put(`schedule/swimlane`, payload);

      return response.data;
    } catch (error) {
      return rejectWithValue(getError(error));
      //return error;
    }
  }
);

export const removeSwimlane = createAsyncThunk(
  "schedule/removeSwimlane",
  async (payload: object, { rejectWithValue }) => {
    try {
      const response = await client.delete(`schedule/swimlane`, {
        params: payload,
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(getError(error));
      //return error;
    }
  }
);

export const createSprint = createAsyncThunk(
  "schedule/createSprint",
  async (payload: object, { rejectWithValue }) => {
    try {
      const response = await client.post(`schedule/create-sprint`, payload);

      return response.data;
    } catch (error) {
      return rejectWithValue(getError(error));
      //return error;
    }
  }
);

export const createEvent = createAsyncThunk(
  "schedule/createEvent",
  async (payload: object, { rejectWithValue }) => {
    try {
      const response = await client.post(
        `/integration/calender/create-events`,
        payload
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(getError(error));
      //return error;
    }
  }
);

export const newCreateEvent = createAsyncThunk(
  "schedule/createEvent",
  async (payload: object, { rejectWithValue }) => {
    try {
      console.log(payload, "payload For Create Event");
      const response = await client.post(
        `/integration/calender/new-create-events`,
        payload
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(getError(error));
      //return error;
    }
  }
);

export const updateEvent = createAsyncThunk(
  "schedule/updateEvent",
  async (payload: object, { rejectWithValue }) => {
    try {
      const response = await client.put(
        `/integration/calender/update-events`,
        payload
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(getError(error));
      //return error;
    }
  }
);

export const searchEvents = createAsyncThunk(
  "calender/searchEvents",
  async (
    { userId, searchQuery }: { userId: number; searchQuery: string },
    { rejectWithValue }
  ) => {
    try {
      const response = await client.get(`/integration/calender/search-events`, {
        params: {
          userId,
          searchQuery,
        },
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(getError(error));
    }
  }
);

export const getEventById = createAsyncThunk(
  "calender/getEventById",
  async (eventId: string, { rejectWithValue }) => {
    try {
      const response = await client.get(
        `/integration/calender/get-event-by-id?eventId=${eventId}`
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(getError(error));
    }
  }
);

export const updateEventV3 = createAsyncThunk(
  "schedule/updateEventv3",
  async (payload: object, { rejectWithValue }) => {
    try {
      const response = await client.put(
        `/integration/calender/update-eventsV3`,
        payload
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(getError(error));
      //return error;
    }
  }
);

export const deleteEvent = createAsyncThunk(
  "schedule/deleteEvent",
  async (
    payload: { eventId: string; sendUpdates: boolean },
    { rejectWithValue }
  ) => {
    try {
      const { eventId, sendUpdates } = payload;
      const url = `/integration/calender/delete-event?eventId=${eventId}&sendUpdates=${sendUpdates}`;
      const response = await client.delete(url);

      return response.data;
    } catch (error) {
      return rejectWithValue(getError(error));
    }
  }
);

export const proposeNewTime = createAsyncThunk(
  "schedule/proposeNewTime",
  async (newTimeData: object, { rejectWithValue }) => {
    try {
      const response = await client.post(
        "/integration/calender/propose-new-time",
        newTimeData
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const updateEventResponseV3 = createAsyncThunk(
  "schedule/updateEventResponsev3",
  async (payload: object, { rejectWithValue }) => {
    try {
      const response = await client.patch(
        "/integration/calender/update-event-responseV3",
        payload
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const rejectProposedTime = createAsyncThunk(
  "calendar/rejectProposedTime",
  async (payload: object, { rejectWithValue }) => {
    try {
      const response = await client.post(
        "/integration/calender/reject-proposed-time",
        payload
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateEventTime = createAsyncThunk(
  "calendar/updateEventTime",
  async (payload: object, { rejectWithValue }) => {
    try {
      const response = await client.patch(
        "/integration/calender/update-event-time",
        payload
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateSprint = createAsyncThunk(
  "schedule/updateSprint",
  async (payload: object, { rejectWithValue }) => {
    try {
      const response = await client.put(`schedule/update-sprint`, payload);

      return response.data;
    } catch (error) {
      return rejectWithValue(getError(error));
      //return error;
    }
  }
);

export const createGoal = createAsyncThunk(
  "schedule/createGoal",
  async (payload: object, { rejectWithValue }) => {
    try {
      const response = await client.post(`schedule/create-goal`, payload);

      return response.data;
    } catch (error) {
      return rejectWithValue(getError(error));
      //return error;
    }
  }
);

export const updateGoal = createAsyncThunk(
  "schedule/updateGoal",
  async (payload: object, { rejectWithValue }) => {
    try {
      const response = await client.put(`schedule/update-goal`, payload);
      return response.data;
    } catch (error) {
      return rejectWithValue(getError(error));
      //return error;
    }
  }
);

export const updateProgress = createAsyncThunk(
  "schedule/updateProgress",
  async (payload: object, { rejectWithValue }) => {
    try {
      const response = await client.put(`schedule/update-progress`, payload);
      return response.data;
    } catch (error) {
      return rejectWithValue(getError(error));
      //return error;
    }
  }
);
export const getGoalCount = createAsyncThunk(
  "schedule/getGoalCount",
  async (
    {
      projectId,
      sprintId,
    }: {
      projectId?: string;
      sprintId?: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await client.get(`/schedule/get-goal-count`, {
        params: { projectId, sprintId },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(getError(error));
      //return error;
    }
  }
);
export const deleteScheduleTasks = createAsyncThunk(
  "schedule/deleteScheduleTasks",
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await client.post(`/schedule/delete-task`, payload);

      return response.data;
    } catch (error) {
      return rejectWithValue(getError(error));
      //return error;
    }
  }
);
