import React, { useState, useEffect, useRef } from "react";
import { NewtaskCard } from "../TaskCard";
import { useDroppable } from "@dnd-kit/core";
import usePermission from "../../../../Core/hooks/usePermission";
import { useAppSelector } from "../../../../Core/redux/hooks";

const KanbanColumn = ({
  status,
  children,
  className,
  channel,
  parentTaskId = "",
  isBugs = false,
}) => {
  const { showNewTaskCard, showSubtaskView, collaboratorsForTask } =
    useAppSelector((state) => state.schedule);
  const { setNodeRef, active, isOver, over } = useDroppable({
    id: status,
    data: { channel },
  });
  const [isHovered, setIsHoverd] = useState(false);

  const { hasPermission: TASK_EVENT_SCOPE_ADD } = usePermission("8");

  return (
    <div
      ref={setNodeRef}
      className={`h-full  ${
        !showSubtaskView ? (!isHovered ? "pb-[40px]" : "") : ""
      }  ${className}`}
      onMouseEnter={() => {
        setIsHoverd(true);
      }}
      onMouseLeave={() => setIsHoverd(false)}
    >
      {children}
      {status && TASK_EVENT_SCOPE_ADD && !isBugs && (
        <div>
          <NewtaskCard
            status={channel}
            isHovered={
              !showSubtaskView
                ? isHovered
                : +channel?.statusId === 1
                ? true
                : false
            }
            parentTaskId={showSubtaskView ? parentTaskId : ""}
          />
        </div>
      )}
    </div>
  );
};

export default KanbanColumn;
