import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  InfoCircleOutlined,
  LeftOutlined,
  RightOutlined,
} from "@ant-design/icons";
import { Divider, Tooltip } from "antd";
import dayjs from "dayjs";
import { DCFGradientBarIcon } from "../../../Core/svgV3/DCFGradientBarIcon";
import { updateFootprint } from "../../../Core/redux/slices/footprint.slice";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";
import { getSelectedMonthActivity } from "../../../Core/redux/api/myFootprintAPI";

const DCFCalendar = () => {
  const [currentMonth, setCurrentMonth] = useState(dayjs().format());
  const { currentMonthUsageOverview, footprintMode, selectedDate } =
    useAppSelector((state) => state.footPrintDetails);
  const { user } = useAppSelector((state) => state.userDetails);
  const { tenantDetails } = useAppSelector((state) => state.tenant);
  const [dayColors, setDayColors] = useState({});
  const dispatch = useAppDispatch();

  useEffect(() => {
    let payload;
    if (footprintMode === 1) {
      payload = {
        userId: user.id,
        tenantId: tenantDetails.tenantId,
      };
    } else {
      payload = {
        tenantId: tenantDetails.tenantId,
      };
    }
    dispatch(
      getSelectedMonthActivity({
        dateFilter: [
          dayjs(currentMonth)
            .startOf("month")
            .add(1, "day")
            .format("DD MMM YYYY"),
          dayjs(currentMonth).endOf("month").format("DD MMM YYYY"),
        ],
        ...payload,
      })
    );
  }, [currentMonth, footprintMode]);

  useEffect(() => {
    const colors = [
      { id: 1, bg: "#D1E9FF", text: "#0D3271" },
      { id: 2, bg: "#AADAFF", text: "#0D3271" },
      { id: 3, bg: "#7DC7FF", text: "#0D3271" },
      { id: 4, bg: "#53B1FD", text: "#F9FBFE" },
      { id: 5, bg: "#165ABF", text: "#F9FBFE" },
      { id: 6, bg: "#144EA6", text: "#F9FBFE" },
      { id: 7, bg: "#194185", text: "#F9FBFE" },
      { id: 8, bg: "#B42318", text: "#F9FBFE" },
    ];
    //37.5
    const getRandomColor = ({ selectedDate }) => {
      const selectedDayUsage = currentMonthUsageOverview?.dayData?.find(
        (day) => {
          return dayjs(day?.createdAt).isSame(dayjs(selectedDate), "day");
        }
      );
      // currentMonthUsageOverview?.dayData?.map((day) => {
      //   console.log(
      //     dayjs(day?.createdAt).isSame(dayjs(selectedDate), "date"),
      //     dayjs(day?.createdAt),
      //     dayjs(selectedDate),
      //     day?.createdAt,
      //     day,
      //     "dates console log"
      //   );
      // });
      if (selectedDayUsage) {
        console.log(selectedDayUsage, "dum usage data");
        if (selectedDayUsage?.dcf >= 0 && selectedDayUsage?.dcf < 37.5) {
          return colors[0];
        } else if (
          selectedDayUsage?.dcf >= 37.5 &&
          selectedDayUsage?.dcf < 37.5 * 2
        ) {
          return colors[1];
        } else if (
          selectedDayUsage?.dcf >= 37.5 * 2 &&
          selectedDayUsage?.dcf < 37.5 * 3
        ) {
          return colors[2];
        } else if (
          selectedDayUsage?.dcf >= 37.5 * 3 &&
          selectedDayUsage?.dcf < 37.5 * 4
        ) {
          return colors[3];
        } else if (
          selectedDayUsage?.dcf >= 37.5 * 4 &&
          selectedDayUsage?.dcf < 37.5 * 5
        ) {
          return colors[4];
        } else if (
          selectedDayUsage?.dcf >= 37.5 * 5 &&
          selectedDayUsage?.dcf < 37.5 * 6
        ) {
          return colors[5];
        } else if (
          selectedDayUsage?.dcf >= 37.5 * 6 &&
          selectedDayUsage?.dcf < 37.5 * 7
        ) {
          return colors[6];
        } else if (
          selectedDayUsage?.dcf >= 37.5 * 7 &&
          selectedDayUsage?.dcf < 37.5 * 8
        ) {
          return colors[7];
        } else {
          return colors[0];
        }
      } else {
        console.log(selectedDayUsage, "dum usage data");
        return { bg: "#EDF3F9", text: "#98A2B3" };
      }
    };

    const startOfMonth = dayjs(currentMonth).startOf("month");
    const endOfMonth = dayjs(currentMonth).endOf("month").endOf("week");
    let day = dayjs(startOfMonth).startOf("week");
    const newDayColors = {};

    while (day.isBefore(endOfMonth, "day")) {
      const formattedDay = day.format("YYYY-MM-DD");
      if (!newDayColors[formattedDay]) {
        newDayColors[formattedDay] = getRandomColor({
          selectedDate: dayjs(day),
        });
      }
      day = day.add(1, "day");
    }
    setDayColors(newDayColors);
  }, [currentMonth, currentMonthUsageOverview]);

  const goToPreviousMonth = () => {
    const prevMonth = dayjs(currentMonth).subtract(1, "month").format();
    setCurrentMonth(prevMonth);
  };

  const goToNextMonth = () => {
    const nextMonth = dayjs(currentMonth).add(1, "month").format();
    setCurrentMonth(nextMonth);
  };

  const handleDateCellClick = (day) => {
    const isCurrentMonth = dayjs(day).isSame(dayjs(currentMonth), "month");
    if (isCurrentMonth) {
      const formattedDate = dayjs(day).format("DD MMM YYYY");
      dispatch(updateFootprint({ key: "selectedDate", value: formattedDate }));
      dispatch(updateFootprint({ key: "view", value: 1 }));
    }
  };

  const daysOfWeek = ["S", "M", "T", "W", "T", "F", "S"];
  const startOfMonth = dayjs(currentMonth).startOf("month");
  const endOfMonth = dayjs(currentMonth).endOf("month").endOf("week");

  const weeks = [];
  let day = dayjs(startOfMonth).startOf("week").format();

  while (dayjs(day).isBefore(endOfMonth, "day")) {
    const days = [];
    for (let i = 0; i < 7; i++) {
      days.push(dayjs(day).clone().format());
      day = dayjs(day).add(1, "day").format();
    }
    weeks.push(days);
  }

  return (
    <div className="p-4 w-full max-w-[328px] bg-white h-full max-h-[calc(100vh-133px)] overflow-y-auto rounded-xl">
      <div className="flex flex-col  h-full">
        <div className="flex justify-between items-center mb-4">
          <div className="flex justify-start items-center gap-1.5">
            <p className="text-grey-900 out-500-16">Monthly Summary</p>
            <Tooltip title="Monthly heat-map of your digital carbon footprint so you can identify trends, peak impact days and monitor your consumption over time.">
              <InfoCircleOutlined className="cursor-pointer text-[#888888]" />
            </Tooltip>
          </div>
        </div>
        <div className="flex flex-col gap-y-6 h-[45%]  items-stretch justify-between">
          <div className="flex flex-col gap-y-1 h-full min-h-[92px]">
            <div className="flex justify-between max-h-[78%] rounded items-center bg-slate-100 py-1 px-2">
              <LeftOutlined
                onClick={goToPreviousMonth}
                className="cursor-pointer text-[#667085]"
              />
              <p className="text-slate-700 out-500-14">
                {dayjs(currentMonth).format("MMMM YYYY")}
              </p>
              <RightOutlined
                onClick={goToNextMonth}
                className="cursor-pointer text-[#667085]"
              />
            </div>
            <div className="grid grid-cols-7 gap-1 ">
              {daysOfWeek.map((day) => (
                <div
                  key={day}
                  className="text-center text-gray-500 text-[10px] font-normal font-['Outfit']"
                >
                  {day}
                </div>
              ))}
              {weeks.map((week, weekIndex) =>
                week.map((day, dayIndex) => {
                  const isCurrentMonth = dayjs(day).isSame(
                    dayjs(currentMonth),
                    "month"
                  );
                  const isBeforeToday = dayjs(day).isBefore(dayjs(), "day");
                  const isToday = dayjs(day).isSame(dayjs(), "day");
                  const isSelected = selectedDate
                    ? dayjs(day).isSame(dayjs(selectedDate), "day")
                    : false;
                  const formattedDay = dayjs(day).format("YYYY-MM-DD");
                  const cellColor =
                    (isBeforeToday || isToday) && dayColors[formattedDay]
                      ? dayColors[formattedDay]
                      : null;

                  return (
                    <div
                      key={`${weekIndex}-${dayIndex}`}
                      className={`text-center w-[39px] h-[27px] p-2.5 flex items-center justify-center text-[10px] font-normal rounded font-['Outfit'] ${
                        isSelected ? " border-2 border-primary-400" : ""
                      } ${
                        isCurrentMonth ? "bg-slate-100 cursor-pointer " : ""
                      } ${day ? "text-gray-400" : "text-gray-500"}`}
                      style={
                        cellColor && isCurrentMonth
                          ? {
                              backgroundColor: cellColor.bg,
                              color: cellColor.text,
                            }
                          : {}
                      }
                      onClick={() => handleDateCellClick(day)}
                    >
                      {day && isCurrentMonth && dayjs(day).format("D")}
                    </div>
                  );
                })
              )}
            </div>
          </div>

          <div className="mt-4">
            <DCFGradientBarIcon />
          </div>
        </div>
        <Divider className="mx-2" />
        <div className="flex flex-col max-h-[55%] ">
          <p className="text-gray-900 out-500-16 flex h-[20%] ">
            Insights for {dayjs(currentMonth).format("MMMM YYYY")}
          </p>
          <div className="px-5 py-4 rounded-xl border border-slate-200 h-[40%] my-4 flex flex-col">
            {currentMonthUsageOverview?.totalDcf > 1000 ? (
              <p className="text-zinc-800 text-base font-semibold font-['Outfit']">
                {(currentMonthUsageOverview?.totalDcf / 1000).toFixed(2) || 0}{" "}
                <span className="text-slate-600 out-500-12">kgCO₂e</span>
              </p>
            ) : (
              <p className="text-zinc-800 text-base font-semibold font-['Outfit']">
                {currentMonthUsageOverview?.totalDcf
                  ? currentMonthUsageOverview.totalDcf.toFixed(2)
                  : 0}
                <span className="text-slate-600 out-500-12">gCO₂e</span>
              </p>
            )}
            <p className="text-gray-500 out-300-14">
              Total Digital Carbon Footprint (DCF)
            </p>
          </div>
          <div className="px-5 py-4 rounded-xl border border-slate-200 flex flex-col h-[40%]">
            {currentMonthUsageOverview?.averageDcf > 1000 ? (
              <p className="text-zinc-800 text-base font-semibold font-['Outfit']">
                {(currentMonthUsageOverview?.averageDcf / 1000).toFixed(2) || 0}{" "}
                <span className="text-slate-600 out-500-12">kgCO₂e</span>
              </p>
            ) : (
              <p className="text-zinc-800 text-base font-semibold font-['Outfit']">
                {currentMonthUsageOverview?.averageDcf
                  ? currentMonthUsageOverview.averageDcf.toFixed(2)
                  : 0}{" "}
                <span className="text-slate-600 out-500-12">gCO₂e</span>
              </p>
            )}

            <p className="text-gray-500 out-300-14">Daily Average DCF</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DCFCalendar;
