import React, { useCallback, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";
import { EditIcon } from "../../../Core/svgV2/EditIcon";
import { DeleteIcon } from "../../../Core/svgV2/DeleteIcon";
import { updateSchedule } from "../../../Core/redux/slices/schedule.slice";
import PlannerAccordian from "./PlannerAccordian";
import CustomBadge from "../../../Core/CommonV2/CustomBadge";
import TaskItem, { ThreeDots } from "./TaskItem";
import DropTarget from "./DNDKit/DropTarget";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import usePermission from "../../../Core/hooks/usePermission";
import {
  useGetGoalsBySprint,
  useGetSprintsByPhase,
} from "../../../Core/redux/ReactQueryHooksV3/useScheduleAPI";
import { useQueryClient } from "react-query";
import CustomPagination from "../../../Core/CommonV3/CustomPagination";
import { DEFAULT_LIMIT, labelColors } from "../../../Core/constants";
import {
  CreateTaskHeader,
  PrimaryTaskHeader,
  SelectedTaskHeader,
} from "./BacklogTaskHeader";
import MyBoardLabel from "../../../Core/CommonV3/MyBoardLabel";
import { getGoalsBySprint } from "../../../Core/redux/api/scheduleAPI";
import { DotsVerticalIcon } from "../../../Core/svgV2/DotsVertical";
import { Dropdown } from "antd";
import CustomButton from "../../../Core/CommonV2/CustomButton";

const SprintAccordian = ({ sprint }) => {
  const dispatch = useAppDispatch();
  const queryClient: any = useQueryClient();
  const {
    filterGoalParameter,
    goals,
    phases,
    labels,
    goalsCount,
    selectedTasks,
    selectedTasksSprintId,
    selectedTasksSprintIds,
    createTaskHeader,
    filters,
    sprints,
  } = useAppSelector((state) => state.schedule);
  const { user } = useAppSelector((state) => state.userDetails);
  const { projectId } = useParams();
  const [checkedTask, setCheckedTask] = useState([]);
  // const [sprintGoals, setSprintGoals] = useState([...goals]);
  // const [open, setOpen] = useState(sprint.status === 2);
  const [open, setOpen] = useState(false);

  const { hasPermission: PHASE_AND_SPRINT_EDIT } = usePermission("6");
  const { hasPermission: PHASE_AND_SPRINT_DELETE } = usePermission("7");
  const [offset, setOffset] = useState(0);
  const [existingFilter, setExistingFilter] = useState(false);

  // const { data: sprintsData, isLoading: sprintsLoading } = useGetSprintsByPhase(
  //   {
  //     projectId,
  //   }
  // );
  useEffect(() => {
    if (open && !existingFilter) {
      dispatch(
        getGoalsBySprint({
          projectId,
          sprintId: sprint.id,
          limit: DEFAULT_LIMIT,
          offset: offset,
        })
      );
    }
  }, [open]);

  const { data: Data, isLoading: goalsLoading } = useGetGoalsBySprint({
    projectId,
    sprintId: sprint.id,
    limit: DEFAULT_LIMIT,
    offset,
    filters: {},
    enabled: open || sprint.status === 2,
  });

  const sprintGoals = sprint.goals;
  const totalGoalCount = Data?.totalRecords;

  const handelTaskCheck = (id) => {
    if (checkedTask.includes(id)) {
      const newIds = checkedTask.filter((taskId) => taskId !== id);
      setCheckedTask(newIds);
    } else {
      setCheckedTask([...checkedTask, id]);
    }
  };

  // useEffect(() => {
  //   dispatch(getGoalsBySprint({ projectId, sprintId: sprint.id }));
  //   dispatch(getSprintsByPhase({ projectId }));
  // }, []);

  const sprintMoreOptions = [
    PHASE_AND_SPRINT_EDIT && {
      key: "1",
      label: (
        <div className="flex gap-x-2 items-center">
          <EditIcon className="text-gray-700" />
          <div className="out-300-14 leading-5 text-gray-700">Edit</div>
        </div>
      ),
    },
    PHASE_AND_SPRINT_DELETE && {
      key: "2",

      label: (
        <div className="flex group  gap-x-2 items-center">
          <DeleteIcon className="text-error-500 " />
          <div className="out-300-14 leading-5 text-error-500 ">Delete</div>
        </div>
      ),
    },
  ];

  const onClick = ({ key }) => {
    switch (key) {
      case "1":
        dispatch(
          updateSchedule({
            key: "editSprintModal",
            value: { visible: true, sprint, mode: "edit" },
          })
        );
        break;
      case "2":
        dispatch(
          updateSchedule({
            key: "deleteSprintModal",
            value: { visible: true, sprintId: sprint.id },
          })
        );
        break;
      default:
        break;
    }
  };

  const searchValue =
    filterGoalParameter?.searchValue?.projectId === projectId &&
    filterGoalParameter?.searchValue?.module === "planner"
      ? filterGoalParameter?.searchValue?.searchValue?.toLowerCase()
      : "";
  let searchedSprint = "";
  if (
    sprint.name.toLowerCase().includes(searchValue) ||
    sprint.id == searchValue ||
    sprint.identifier?.includes(searchValue)
  ) {
    searchedSprint = sprint;
  }

  const handleFilter = useCallback(
    (goals) => {
      if (!goals) return [];
      let filteredGoals = [...goals];

      const filterParams = filterGoalParameter;
      // filter by assignee
      if (filterParams?.assignee.length > 0) {
        const currentProjectFilter = filterGoalParameter?.assignee?.find(
          (filter) => +filter.projectId === +projectId
        );
        if (
          currentProjectFilter !== undefined &&
          currentProjectFilter?.assignee.length > 0
        ) {
          filteredGoals = filteredGoals.filter((goal) =>
            currentProjectFilter?.assignee?.includes(goal.assigneeId)
          );
        }
      }

      // filter by search value
      const searchValue =
        filterGoalParameter?.searchValue?.projectId === projectId &&
        filterGoalParameter?.searchValue?.module === "planner"
          ? filterParams?.searchValue?.searchValue?.toLowerCase()
          : "";
      filteredGoals = filteredGoals.filter(
        (goal) =>
          goal.name.toLowerCase().includes(searchValue) ||
          goal.id == searchValue ||
          goal.identifier?.includes(searchValue)
      );

      // If no goals are found based on the search parameters, revert to the original goals
      if (filteredGoals.length <= 0 && searchedSprint) {
        filteredGoals = [...goals];
      }
      return filteredGoals;
    },
    [filterGoalParameter, sprint]
  );
  const labelOptions = labels
    ?.filter((item) => item.isMyBoard)
    ?.map((label) => {
      return {
        id: label.id,
        value: label.id,
        label: label.name,
        render: () => (
          <div className="flex items-center justify-between">
            <MyBoardLabel labelColorId={label.labelColorId} text={label.name} />

            <div className="h-[14px] w-[14px] rounded-full" />
          </div>
        ),
      };
    });
  const getHeader = ({ sprintId = "" }) => {
    if (selectedTasks?.length > 0 && +selectedTasksSprintId === +sprint.id)
      return (
        <SelectedTaskHeader
          offset={offset}
          goalCount={sprintGoals?.length}
          sprintId={sprintId}
        />
      );
    if (createTaskHeader)
      return (
        <CreateTaskHeader
          offset={offset}
          labelOptions={labelOptions}
          callBack={() =>
            queryClient.invalidateQueries([
              "goalsBySprint",
              projectId,
              sprint.id,
            ])
          }
          sprintId={sprint.id}
          key={sprint.id}
        />
      );
    return <PrimaryTaskHeader />;
  };
  // const handleSort = (goals) => {
  //   goals.sort((a, b) => {
  //     const aGoalCreatedAt = a.createdAt ? new Date(a.createdAt).getTime() : 0;
  //     const bGoalCreatedAt = b.createdAt ? new Date(b.createdAt).getTime() : 0;

  //     if (aGoalCreatedAt > bGoalCreatedAt) {
  //       return 1;
  //     } else if (aGoalCreatedAt < bGoalCreatedAt) {
  //       return -1;
  //     } else {
  //       return 0;
  //     }
  //   });

  //   return goals;
  // };
  const hasActivePhase =
    phases.filter(({ isOngoing }) => isOngoing === 1).length > 0;

  const handlePagination = async (page: number) => {
    const newOffset = (page - 1) * DEFAULT_LIMIT;
    setOffset(newOffset);
    queryClient.invalidateQueries([
      "goalsBySprint",
      projectId,
      sprint.id,
      newOffset,
    ]);
  };

  const option = labels
    ?.filter((item) => !item.isMyBoard && +item.projectId === +projectId)
    ?.map((label) => {
      return {
        id: label.id,
        value: label.id,
        label: label.name,
        render: () => (
          <div className="flex items-center justify-between">
            <MyBoardLabel labelColorId={label.labelColorId} text={label.name} />
            <div className="h-[14px] w-[14px] rounded-full" />
          </div>
        ),
      };
    });

  const filteredGoals = handleFilter(sprintGoals);
  const handleMultipleSelect = (e, taskId) => {
    e.preventDefault();
    const isCtrlPressed = e.ctrlKey || e.metaKey;
    const currentTask = sprintGoals?.find((item) => +item.id == taskId);
    const isCreatedByTheUser = currentTask?.createdById == user.id;

    if (isCtrlPressed) {
      if (+selectedTasksSprintId !== +sprint.id) {
        dispatch(
          updateSchedule({
            key: "selectedTasks",
            value: [taskId],
          })
        );
      } else {
        dispatch(
          updateSchedule({
            key: "selectedTasks",
            value: selectedTasks?.includes(taskId)
              ? selectedTasks.filter((item) => item !== taskId)
              : [...selectedTasks, taskId],
          })
        );
      }

      dispatch(
        updateSchedule({
          key: "selectedTasksSprintId",
          value: sprint.id,
        })
      );

      const updatedTaskSprintPairs = selectedTasksSprintIds
        ? [...selectedTasksSprintIds]
        : [];

      const sprintId = sprint.id;
      const pairIndex = updatedTaskSprintPairs.findIndex(
        (pair) => pair.taskId === taskId && pair.sprintId === sprintId
      );
      if (pairIndex !== -1) {
        updatedTaskSprintPairs.splice(pairIndex, 1);
      } else {
        updatedTaskSprintPairs.push({ taskId, sprintId });
      }

      dispatch(
        updateSchedule({
          key: "selectedTasksSprintIds",
          value: updatedTaskSprintPairs,
        })
      );
    }
    if (selectedTasks?.length > 0 && !isCtrlPressed) {
      if (selectedTasks?.length > 0) {
        dispatch(
          updateSchedule({
            key: "selectedTasks",
            value: [],
          })
        );
        dispatch(
          updateSchedule({
            key: "selectedTasksSprintIds",
            value: [],
          })
        );
      }
    }
  };
  useEffect(() => {
    if (filteredGoals.length > 0) {
      setOpen(true);
    }
  }, [filterGoalParameter]);

  useEffect(() => {
    if (
      Object.values(filters).some(
        (value) => Array.isArray(value) && value.length !== 0
      )
    ) {
      setOpen(true);

      setExistingFilter(true);
    } else {
      setOpen(false);
      setExistingFilter(false);
    }
  }, [filters]);

  return (
    <DropTarget key={"1"} id={sprint.id}>
      {filterGoalParameter?.searchValue?.projectId === projectId &&
      filterGoalParameter?.searchValue?.module === "planner" ? (
        searchedSprint || filteredGoals.length > 0 ? (
          <PlannerAccordian
            title={sprint.name}
            itemsCount={sprint.goals?.length || sprint.goalCount || 0}
            open={open}
            setOpen={(open) => setOpen(open)}
            sprint={sprint}
            menu={
              <div className="flex gap-x-2 items-center">
                <div
                  className=" flex items-center gap-x-3 cursor-pointer "
                  onClick={() => setOpen(!open)}
                ></div>

                <>
                  {PHASE_AND_SPRINT_EDIT && (
                    <>
                      {!hasActivePhase ||
                      (sprints.length && sprints[0].status == 2) ? (
                        sprint.status === 2 ? (
                          <CustomButton
                            text="Complete Sprint"
                            className="bg-primary-50  out-500-14 text-primary-700 "
                            height="30px"
                            onClick={async () => {
                              await dispatch(
                                getGoalsBySprint({
                                  projectId,
                                  sprintId: sprint.id,
                                })
                              ).then(() =>
                                dispatch(
                                  updateSchedule({
                                    key: "completeSprintModal",
                                    value: {
                                      visible: true,
                                      sprintId: sprint.id,
                                      sprint: sprint,
                                    },
                                  })
                                )
                              );
                              queryClient.invalidateQueries([
                                "sprintsByPhase",
                                projectId,
                                sprint.id,
                              ]);
                            }}
                          />
                        ) : (
                          <CustomButton
                            text="Start Sprint"
                            className="bg-primary-50  out-500-14 text-primary-700 "
                            height="30px"
                            disabled={
                              !hasActivePhase ||
                              (sprints.length && sprints[0].status == 2)
                            }
                            onClick={() =>
                              dispatch(
                                updateSchedule({
                                  key: "editSprintModal",
                                  value: {
                                    visible: true,
                                    sprint,
                                    mode: "start",
                                  },
                                })
                              )
                            }
                          />
                        )
                      ) : (
                        <CustomButton
                          text="Start Sprint"
                          className="bg-primary-50  out-500-14 text-primary-700 "
                          height="30px"
                          onClick={() =>
                            dispatch(
                              updateSchedule({
                                key: "editSprintModal",
                                value: {
                                  visible: true,
                                  sprint,
                                  mode: "start",
                                },
                              })
                            )
                          }
                        />
                      )}{" "}
                    </>
                  )}
                </>

                <span className="out-300-12 border border-green-800 text-gray-500">
                  {sprint.startDate
                    ? dayjs(sprint.startDate).format("DD MMM")
                    : "N/A"}
                  {"-"}
                  {sprint.endDate
                    ? dayjs(sprint.endDate).format("DD MMM")
                    : "N/A"}
                </span>
                {(PHASE_AND_SPRINT_DELETE || PHASE_AND_SPRINT_EDIT) && (
                  <Dropdown
                    dropdownRender={(origin) => {
                      return <div className="w-[200px]">{origin}</div>;
                    }}
                    menu={{ items: sprintMoreOptions, onClick }}
                    trigger={["click"]}
                  >
                    <button>
                      <DotsVerticalIcon />
                    </button>
                  </Dropdown>
                )}
                <DotsVerticalIcon />
              </div>
            }
          >
            <div className="mt-3 first:rounded-t-xl">
              {getHeader({ sprintId: sprint?.id })}

              {!goalsLoading &&
                filteredGoals?.map((task) => (
                  <TaskItem
                    handleMultipleSelect={handleMultipleSelect}
                    selected={selectedTasks.includes(task.id)}
                    handleSelect={handelTaskCheck}
                    taskId={task.id}
                    key={task.id}
                    task={task}
                    selectedItems={checkedTask}
                    labelOptions={option}
                    offset={offset}
                  />
                ))}
            </div>
            <div className="flex items-center justify-end w-full py-3 bg-white border h-[60px] rounded-b-xl">
              {sprint.goalCount > 0 && (
                <CustomPagination
                  handleOnChange={handlePagination}
                  total={sprint.goalCount}
                  pageSize={DEFAULT_LIMIT}
                />
              )}
            </div>
          </PlannerAccordian>
        ) : (
          <></>
        )
      ) : (
        <PlannerAccordian
          sprint={sprint}
          title={sprint.name}
          itemsCount={sprint.goals?.length || sprint.goalCount || 0}
          open={open}
          setOpen={(open) => setOpen(open)}
          menu={
            <div className="flex items-center gap-x-2">
              <div className="flex gap-x-2 items-center">
                {open ? (
                  <></>
                ) : (
                  <div className="flex gap-x-3 items-center">
                    <div
                      className=" flex items-center gap-x-3 cursor-pointer "
                      onClick={() => setOpen(!open)}
                    ></div>

                    {sprint.status === 3 ? (
                      <></>
                    ) : (
                      <>
                        {PHASE_AND_SPRINT_EDIT && (
                          <>
                            {!hasActivePhase || sprints[0].status == 2 ? (
                              sprint.status === 2 ? (
                                <CustomButton
                                  text="Complete Sprint"
                                  className="bg-primary-50  out-500-14 text-primary-700 "
                                  height="30px"
                                  onClick={async () => {
                                    await dispatch(
                                      getGoalsBySprint({
                                        projectId,
                                        sprintId: sprint.id,
                                      })
                                    ).then(() =>
                                      dispatch(
                                        updateSchedule({
                                          key: "completeSprintModal",
                                          value: {
                                            visible: true,
                                            sprintId: sprint.id,
                                            sprint: sprint,
                                          },
                                        })
                                      )
                                    );
                                    queryClient.invalidateQueries([
                                      "sprintsByPhase",
                                      projectId,
                                      sprint.id,
                                    ]);
                                  }}
                                />
                              ) : (
                                <CustomButton
                                  text="Start Sprint"
                                  className="bg-primary-50  out-500-14 text-primary-700 "
                                  height="30px"
                                />
                              )
                            ) : (
                              <CustomButton
                                text="Start Sprint"
                                className="bg-primary-50  out-500-14 text-primary-700 "
                                height="30px"
                                disabled={
                                  !hasActivePhase || sprints[0].status == 2
                                }
                                onClick={() =>
                                  dispatch(
                                    updateSchedule({
                                      key: "editSprintModal",
                                      value: {
                                        visible: true,
                                        sprint,
                                        mode: "start",
                                      },
                                    })
                                  )
                                }
                              />
                            )}{" "}
                          </>
                        )}
                      </>
                    )}

                    <span className="out-500-12  text-gray-500">
                      {sprint.startDate
                        ? dayjs(sprint.startDate).format("DD MMM YYYY")
                        : "N/A"}
                      {"-"}
                      {sprint.endDate
                        ? dayjs(sprint.endDate).format("DD MMM YYYY")
                        : "N/A"}
                    </span>
                    {(PHASE_AND_SPRINT_DELETE || PHASE_AND_SPRINT_EDIT) && (
                      <Dropdown
                        dropdownRender={(origin) => {
                          return <div className="w-[200px]">{origin}</div>;
                        }}
                        menu={{ items: sprintMoreOptions, onClick }}
                        trigger={["click"]}
                      >
                        <button>
                          <DotsVerticalIcon />
                        </button>
                      </Dropdown>
                    )}
                  </div>
                )}
              </div>
            </div>
          }
        >
          <div className="mt-3 first:rounded-t-xl">
            {getHeader({ sprintId: sprint?.id })}

            {!goalsLoading &&
              filteredGoals?.map((task) => (
                <TaskItem
                  handleMultipleSelect={handleMultipleSelect}
                  selected={selectedTasks?.includes(task.id)}
                  taskId={task.id}
                  key={task.id}
                  task={task}
                  labelOptions={option}
                />
              ))}
          </div>
          {!existingFilter ? (
            <div className="flex items-center justify-end w-full py-3 bg-white border h-[60px] rounded-b-xl">
              {totalGoalCount > 0 && (
                <CustomPagination
                  handleOnChange={handlePagination}
                  total={totalGoalCount}
                  pageSize={DEFAULT_LIMIT}
                />
              )}
            </div>
          ) : (
            <div className="flex items-center justify-end w-full py-3 bg-white border text-gray-500 out-300-12 rounded-b-xl pr-2">
              {filteredGoals.length} issue
              {filteredGoals.length === 1 ? "" : "s"} found
            </div>
          )}
        </PlannerAccordian>
      )}
    </DropTarget>
  );
};

export default SprintAccordian;
