import React, { useEffect, useState } from "react";

import { Popover, Tooltip } from "antd";
import PriorityFlag from "../../../Core/svgV2/PriorityFlag";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";
import { useNavigate, useParams } from "react-router-dom";
import CustomSelect from "../../../Core/CommonV2/CustomSelect";
import { getGoalDetails } from "../../../Core/redux/api/scheduleAPI";
import { getPriorityDetails } from "../../../Core/utils/scheduleUtils";
import { TaskInput } from "../Task/TaskReusable";
import { updateGlobalKey } from "../../../Core/redux/slices/global.slice";
import SubTaskIcon from "../../../Core/svgV3/SubtaskIcon";
import getStatus from "./getStatus";
import {
  createMyBoardTask,
  getMyBoardTasks,
  updateMyBoardTask,
} from "../../../Core/redux/api/myBoardAPI";
import getCurrentUser from "./getCurrentUser";
import { ThreeDots } from "./TaskItem";
import { updateMyBoard } from "../../../Core/redux/slices/myBoard.slice";
import {
  PlusIcon,
  PlusIconBold,
  PlusIconSecondary,
} from "../../../Core/svgV2/PlusIcon";
import dayjs from "dayjs";
import { DEFAULT_LIMIT, DEFAULT_OFFSET } from "../../../Core/constants";
import CustomAvatar from "../../../Core/CommonV2/CustomAvatar";
import { updateDashboard } from "../../../Core/redux/slices/dashboard.slice";
import CustomButton from "../../../Core/CommonV2/CustomButton";

const statusOptions = [
  {
    id: 1,
    value: 1,
    label: "To Do",
    render: () => getStatus({ statusId: 1, showIcon: false }),
  },
  {
    id: 2,
    value: 2,
    label: "In Progress",
    render: () => getStatus({ statusId: 2, showIcon: false }),
  },
  {
    id: 3,
    value: 3,
    label: "Done",
    render: () => getStatus({ statusId: 3, showIcon: false }),
  },
];

export const StatusSelect = ({ task, setTask }) => {
  const [open, setOpen] = useState(false);
  const [status, setStatus] = useState(task.statusId);

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };

  useEffect(() => {
    setStatus(task.statusId);
  }, [task]);

  return (
    <Popover
      getPopupContainer={(parent) => parent?.parentElement}
      content={
        <div className="min-w-[200px]">
          <CustomSelect
            options={statusOptions}
            defaultOpen
            showSearch
            onChange={(e) => {
              setOpen(false);
              setTask(e);
              setStatus(e);
            }}
          />
        </div>
      }
      arrow={false}
      open={open}
      onOpenChange={handleOpenChange}
      placement="bottomLeft"
      trigger="click"
      destroyTooltipOnHide
    >
      <button>{status && getStatus({ statusId: status })}</button>
    </Popover>
  );
};

export const PrioritySelect = ({ visible = false, task, setTask }) => {
  // const priority = task.priorityId;
  const [priorityId, setPriorityId] = useState(task.priorityId);

  const [open, setOpen] = useState(false);

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };

  const getLabel = (priority, bold = false) => {
    const { color, priorityName } = getPriorityDetails(priority);
    return (
      <div className="flex items-center ">
        <PriorityFlag
          style={{
            color,
          }}
        />
        <p
          className="ml-2 out-300-14"
          style={{ color, fontWeight: bold ? 500 : 300 }}
        >
          {priorityName}
        </p>
      </div>
    );
  };

  const option = [
    {
      id: 1,
      value: 1,
      label: "Low",
      render: () => getLabel(1),
    },
    {
      id: 2,
      value: 2,
      label: "Medium",
      render: () => getLabel(2),
    },
    {
      id: 3,
      value: 3,
      label: "High",
      render: () => getLabel(3),
    },
    {
      id: 4,
      value: 4,
      label: "Urgent",
      render: () => getLabel(4),
    },
  ];

  useEffect(() => {
    setPriorityId(task.priorityId);
  }, [task]);

  return (
    <Popover
      getPopupContainer={(parent) => parent?.parentElement}
      content={
        <div className="min-w-[200px]">
          <CustomSelect
            placeholder="Select Priority"
            options={option}
            defaultOpen
            showSearch
            onChange={(e) => {
              setOpen(false);
              setTask(e);
              setPriorityId(e);
            }}
          />
        </div>
      }
      arrow={false}
      open={open}
      onOpenChange={handleOpenChange}
      placement="bottomLeft"
      trigger={"click"}
      destroyTooltipOnHide
    >
      <button>
        {(priorityId || visible) && (
          <PriorityFlag priorityId={+task.priorityId} />
        )}
      </button>
    </Popover>
  );
};

const SubTaskItem = ({ subtask }: { subtask: any }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { projectId } = useParams();

  const [taskDetails, setTaskDetails] = useState(subtask);

  const [titleEdit, setTitleEdit] = useState(false);

  return (
    <>
      <div className="overflow-hidden border-t border-x">
        <div className="flex items-center px-3 py-2 bg-gray-50 border-gray-200 gap-x-3 pl-[54px]">
          <SubTaskIcon />

          <p
            className="text-gray-500 cursor-pointer out-400-10 hover:underline"
            role="button"
            onClick={async () => {
              dispatch(
                updateMyBoard({
                  key: "currentSelectedSubTask",
                  value: { ...taskDetails },
                })
              );
              navigate(
                `/my-board/task/${subtask.parentGoalId}/subtask/${subtask.id}`
              );
            }}
          >
            {`SMB-${subtask.identifier ? subtask.identifier : subtask.id}`}{" "}
          </p>

          {titleEdit ? (
            <div className="flex-1 ">
              {" "}
              <TaskInput
                autoSize
                value={taskDetails.name}
                placeholder="Enter a title"
                plannerScreen
                autoFocus={true}
                type="planner"
                onSave={async (e, save) => {
                  if (save) {
                    e.preventDefault();
                    setTaskDetails({ ...taskDetails, name: e.target.value });
                    await dispatch(
                      updateMyBoardTask({
                        name: e.target.value,
                        goalId: taskDetails.id,
                      })
                    );
                    setTitleEdit(false);
                  }
                }}
                onChange={async (e) => {
                  setTaskDetails({ ...taskDetails, name: e.target.value });
                }}
              />
            </div>
          ) : (
            <p
              role="button"
              onDoubleClick={async (e) => {
                e.stopPropagation();
                console.log("clicked");

                dispatch(
                  updateMyBoard({
                    key: "currentSelectedSubTask",
                    value: { ...taskDetails },
                  })
                );

                navigate(
                  `/my-board/task/${subtask.parentGoalId}/subtask/${subtask.id}`
                );

                // handleSelect(taskId);
              }}
              className="flex-1 w-full text-black out-300-12"
              id="currentInputBoxMaxWidth"
            >
              <span
                onClick={() => {
                  setTitleEdit(true);
                }}
                className={
                  "rounded-[4px] p-1 hover:border  hover:border-primary-400"
                }
              >
                <Tooltip title={subtask.name} placement="topLeft">
                  {subtask.name}
                </Tooltip>
              </span>
            </p>
          )}

          <StatusSelect
            task={taskDetails}
            setTask={async (e) => {
              setTaskDetails({ ...taskDetails, statusId: e });
              await dispatch(
                updateMyBoardTask({ statusId: e, goalId: taskDetails.id })
              );
            }}
          />

          <PrioritySelect
            task={taskDetails}
            setTask={async (e) => {
              setTaskDetails({ ...taskDetails, priorityId: e });

              await dispatch(
                updateMyBoardTask({ priorityId: e, goalId: taskDetails.id })
              );
            }}
          />

          <>{getCurrentUser({ task: subtask, isSubTask: true })}</>
          <ThreeDots task={taskDetails} />
        </div>
      </div>
    </>
  );
};
export const NewSubTaskItem = ({
  parentGoalId,
  setNewSubtask,
}: {
  parentGoalId: any;
  setNewSubtask?: any;
}) => {
  const navigate = useNavigate();
  const { user } = useAppSelector((state) => state.userDetails);
  const dispatch = useAppDispatch();
  const { projectId } = useParams();
  const initialState = {
    name: "",
    isCompleted: 0,
    isNewGoal: 0,
    statusId: "1",
    priorityId: "1",
    goalType: 4,
    assigneeId: user?.id,
    parentGoalId: parentGoalId,
    isSubTask: true,
    description: "",
    labelId: "",
    releaseVersionId: "",
    createdAt: dayjs().format(),
    collaborators: [user.id],
  };
  const [taskDetails, setTaskDetails] = useState(initialState);
  const { myBoardKanbanView, showSubtaskView } = useAppSelector(
    (state) => state.myBoard
  );

  const [titleEdit, setTitleEdit] = useState(false);

  const createTask = async () => {
    const payload = {
      ...taskDetails,
    };
    try {
      await dispatch(createMyBoardTask(payload));
      await dispatch(
        getMyBoardTasks({ limit: DEFAULT_LIMIT, offset: DEFAULT_OFFSET })
      );
    } catch (error) {
      console.log("Error occured while creating the task", error);
    } finally {
      setTaskDetails(initialState);
      setTitleEdit(false);
      dispatch(
        updateDashboard({
          key: "alertPopupModal",
          value: {
            visible: true,
            data: {
              title: `New Subtask created`,
              subtitle: `${taskDetails?.name}`,
              description: "",
            },
          },
        })
      );
    }
  };
  const handleExpand = () => {
    dispatch(
      updateMyBoard({
        key: "newTask",
        value: {
          goalType: taskDetails.goalType,
          name: taskDetails.name,
          priorityid: taskDetails.priorityId,
          labelId: taskDetails.labelId,
        },
      })
    );
    if (showSubtaskView) {
      navigate(`/my-board/task/${taskDetails.parentGoalId}/subtask/new`);
    } else {
      navigate(`/my-board/task/new`);
    }
    dispatch(
      updateMyBoard({
        key: "showNewTaskCard",
        value: { visible: false, channel: "", goalId: "" },
      })
    );
  };
  return (
    <>
      <div
        className={`overflow-hidden border-t border-x ${
          showSubtaskView && myBoardKanbanView ? "border-b" : ""
        }`}
      >
        <div className="flex items-center px-3 py-2 bg-gray-50 border-gray-200 gap-x-3 pl-[54px]">
          <SubTaskIcon />

          {titleEdit ? (
            <div className="flex-1 bg-gray-50">
              {" "}
              <TaskInput
                autoSize
                isSubtask
                value={taskDetails.name}
                placeholder="Enter a title"
                plannerScreen
                autoFocus={true}
                type="planner"
                onSave={async (e, save) => {
                  if (save) {
                    e.preventDefault();
                    setTaskDetails({ ...taskDetails, name: e.target.value });
                  }
                }}
                onChange={async (e) => {
                  setTaskDetails({ ...taskDetails, name: e.target.value });
                }}
              />
            </div>
          ) : (
            <p
              role="button"
              onClick={() => {
                setTitleEdit(true);
              }}
              className="flex gap-x-3 w-full text-black out-300-12"
              id="currentInputBoxMaxWidth"
            >
              <PlusIconBold />
              <span
                onClick={() => {
                  setTitleEdit(true);
                }}
                className={
                  "rounded-[4px] p-1 hover:border out-500-14 text-primary-600  hover:border-primary-400"
                }
              >
                Create subtask
              </span>
            </p>
          )}

          <button
            onClick={() => handleExpand()}
            className="flex justify-center items-center"
          >
            <img src="/images/v2/schedule/expand-02.svg" />
          </button>

          <StatusSelect
            task={taskDetails}
            setTask={async (e) => {
              setTaskDetails({ ...taskDetails, statusId: e });
            }}
          />

          <PrioritySelect
            task={taskDetails}
            setTask={async (e) => {
              setTaskDetails({ ...taskDetails, priorityId: e });
            }}
          />

          <>
            <CustomAvatar
              title={`${user?.firstName} ${user?.lastName}`}
              src={user.profilePicture}
              size={24}
              whiteText
              fontSize={"10px"}
              color={user.profilePictureColorCode}
            />
          </>
          <div className="mr-5">
            <CustomButton
              text="Save"
              className="bg-primary-600 px-5 text-white"
              height="30px"
              onClick={() => {
                createTask();
                setNewSubtask(false);
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default SubTaskItem;
