import React from "react";
import CustomModal from "../../../Core/CommonV2/CustomModal";
import { CrossIcon } from "../../../Core/svgV2/CrossIcon";
import CustomButton from "../../../Core/CommonV2/CustomButton";

const ChangePhaseDateWarningModal = ({ visible, onClose, onConfirm }) => {
  return (
    <CustomModal
      visible={visible}
      width="360px"
      onCancel={() => onClose()}
      body={
        <div className="p-4">
          <div className="flex justify-between">
            <p className="text-primary-700 out-500-14">Save changes?</p>
            <button onClick={() => onClose()}>
              <CrossIcon className="text-gray-700" />
            </button>
          </div>
          <img src="/images/v2/common/delete-modal.svg" className="mt-4" />
          <p className="mt-4 out-300-14 text-gray-500 leading-5">
            Do you want to change the dates for this phase?
          </p>
          <div className="flex mt-[21px] items-center gap-x-3 justify-end">
            <CustomButton
              text="Cancel"
              className="bg-transparent text-gray-500"
              height="30px"
              onClick={() => onClose()}
            />
            <CustomButton text="Confirm" height="30px" onClick={onConfirm} />
          </div>
        </div>
      }
    />
  );
};

export default ChangePhaseDateWarningModal;
