import React, { useEffect, useState } from "react";
import { ChevronIcon } from "../../../Core/svgV2/Chevron";
import { Popover, Tooltip } from "antd";
import PriorityFlag from "../../../Core/svgV2/PriorityFlag";
import { TaskInput } from "./TaskReusable";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";
import CustomAvatar from "../../../Core/CommonV2/CustomAvatar";
import getUsersName from "../../../Core/utils/getUserName";
import { CheckIcon } from "../../../Core/svgV2/Check";
import { getPriorityDetails } from "../../../Core/utils/scheduleUtils";
import { PlusIcon } from "../../../Core/svgV2/PlusIcon";
import { useParams, useSearchParams } from "react-router-dom";
import {
  createGoal,
  getGoalDetails,
  updateGoal,
} from "../../../Core/redux/api/scheduleAPI";
import { updateSchedule } from "../../../Core/redux/slices/schedule.slice";

const UserSelect = ({ setCurrentSelectedUser, currentSelectedUser }) => {
  const { userList } = useAppSelector((state) => state.overview);

  const UserItem = ({ user, setCurrentSelectedUser, currentSelectedUser }) => {
    return (
      <div
        onClick={() => setCurrentSelectedUser(user)}
        key={user?.id}
        className="flex cursor-pointer hover:bg-gray-50 justify-between  px-[14px] py-2.5 "
      >
        <div className="flex items-center">
          <CustomAvatar
            src={user?.profilePicture}
            title={getUsersName(user)}
            size={24}
            whiteText
            color={user?.profilePictureColorCode}
            fontSize={"12px"}
          />
          <span className="ml-2 out-300-14 text-gray-900">
            {getUsersName(user)}
          </span>
        </div>
        {user?.userId === currentSelectedUser?.userId ? <CheckIcon /> : <></>}
      </div>
    );
  };

  return (
    <div className="w-[216px] border border-gray-200 shadow-lg bg-white overflow-hidden max-h-[184px] overflow-y-scroll  rounded-lg last:rounded-t-lg">
      <div
        onClick={() => setCurrentSelectedUser(null)}
        className="flex cursor-pointer hover:bg-gray-50 justify-between  px-[14px] py-2.5 "
      >
        <div className="flex items-center">
          <img src="/images/v2/schedule/Avatar.svg" />
          <span className="ml-2 out-300-14 text-gray-900">Unassigned</span>
        </div>
        {!currentSelectedUser ? <CheckIcon /> : <></>}
      </div>
      {userList.map((user) => (
        <UserItem
          user={user}
          key={user?.userId}
          setCurrentSelectedUser={setCurrentSelectedUser}
          currentSelectedUser={currentSelectedUser}
        />
      ))}
    </div>
  );
};

const PrioritySelect = ({ currentPriority, setCurrentPriority }) => {
  const getLable = (priority) => {
    const { color, priorityName } = getPriorityDetails(priority);
    return (
      <div className="flex items-center">
        <PriorityFlag
          style={{
            color,
          }}
        />
        <p className="out-300-14 ml-2" style={{ color }}>
          {priorityName}
        </p>
      </div>
    );
  };
  return (
    <div className="w-[216px] border border-gray-200 shadow-lg bg-white  rounded-lg">
      <div
        onClick={() => setCurrentPriority(1)}
        className="flex cursor-pointer hover:bg-gray-50 justify-between first:rounded-t-lg px-[14px] py-2.5 "
      >
        {getLable(1)}
        {currentPriority === 1 ? <CheckIcon /> : <></>}
      </div>
      <div
        onClick={() => setCurrentPriority(2)}
        className="flex cursor-pointer hover:bg-gray-50 justify-between  px-[14px] py-2.5 "
      >
        {getLable(2)}
        {currentPriority === 2 ? <CheckIcon /> : <></>}
      </div>
      <div
        onClick={() => setCurrentPriority(3)}
        className="flex cursor-pointer hover:bg-gray-50 justify-between  px-[14px] py-2.5 "
      >
        {getLable(3)}
        {currentPriority === 3 ? <CheckIcon /> : <></>}
      </div>
      <div
        onClick={() => setCurrentPriority(4)}
        className="flex cursor-pointer hover:bg-gray-50 justify-between last:rounded-b-lg px-[14px] py-2.5 "
      >
        {getLable(4)}
        {currentPriority === 4 ? <CheckIcon /> : <></>}
      </div>
    </div>
  );
};

const SubtaskItem = ({
  isNew = false,
  subtask,
  handleOnCancel,
  handleSaveClick,
  handleSubtaskOnChange,
}) => {
  const { userList } = useAppSelector((state) => state.overview);
  const [currentSelectedUser, setCurrentSelectedUser] = useState(null);
  const { color } = getPriorityDetails(subtask.priorityId);

  useEffect(() => {
    const currentUser = userList.find((u) => u.userId === subtask.assigneeId);
    setCurrentSelectedUser(currentUser || null);
  }, [subtask]);

  // value, key, id, isNew
  return (
    <div
      className={`w-full h-[36px] flex items-center px-3 py-2 bg-white  rounded-lg border border-gray-200 ${
        isNew ? "shadow-m" : ""
      }`}
    >
      <img src="/images/v2/schedule/Subtask.svg" />
      <div className="mx-3 w-full">
        <TaskInput
          placeholder="Enter a title"
          inputClassName="subtask-input"
          autoSize={true}
          value={subtask.name}
          type="input"
          onChange={(e) =>
            handleSubtaskOnChange(e.target.value, "name", subtask.id, isNew)
          }
        />
      </div>

      {!isNew && (
        <div className="out-300-12 text-gray-500  mr-3 uppercase">
          S{subtask.identifier}
        </div>
      )}

      <Popover
        content={
          <PrioritySelect
            currentPriority={subtask.priorityId}
            setCurrentPriority={(e) =>
              handleSubtaskOnChange(e, "priorityId", subtask.id, isNew)
            }
          />
        }
        arrow={false}
        placement="bottomLeft"
        trigger={["click"]}
      >
        <Tooltip title="Priority: Low">
          <button>
            <PriorityFlag style={{ color }} height="16" width="16" />
          </button>
        </Tooltip>
      </Popover>

      <Popover
        content={
          <UserSelect
            setCurrentSelectedUser={(user) =>
              handleSubtaskOnChange(
                user.userId,
                "assigneeId",
                subtask.id,
                isNew
              )
            }
            currentSelectedUser={currentSelectedUser}
          />
        }
        arrow={false}
        placement="bottomLeft"
        trigger={["click"]}
      >
        <button className="ml-2 min-h-[20px] min-w-5">
          <Tooltip
            title={
              currentSelectedUser
                ? getUsersName(currentSelectedUser)
                : "Unassigned"
            }
          >
            <div>
              {currentSelectedUser ? (
                <CustomAvatar
                  src={currentSelectedUser?.profilePicture}
                  title={getUsersName(currentSelectedUser)}
                  size={20}
                  whiteText
                  fontSize={"10px"}
                  color={currentSelectedUser?.profilePictureColorCode}
                />
              ) : (
                <img src="/images/v2/schedule/Avatar.svg" />
              )}
            </div>
          </Tooltip>
        </button>
      </Popover>

      {isNew && (
        <>
          <button
            onClick={() => handleSaveClick(subtask)}
            className="out-500-14 text-primary-700 mx-3"
          >
            Save
          </button>
          <button
            className="out-500-14 text-gray-500"
            onClick={() => handleOnCancel(subtask)}
          >
            Cancel
          </button>
        </>
      )}
    </div>
  );
};

const AddSubtask = ({
  subtasks,
  task,
  setTask,
  setShowAddTask,
  setEditedTaskDetails,
}) => {
  const { taskId, projectId } = useParams();
  const [showSubtask, setShowSubtask] = useState(true);
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const [allSubtasks, setAllSubtasks] = useState(subtasks);
  const [addMoreSubtask, setAddMoreSubtasks] = useState([]);
  const [edited, setEdited] = useState(false);

  useEffect(() => {
    if (subtasks.length > 0) {
      setAllSubtasks(subtasks);
    } else {
      setAddMoreSubtasks([
        {
          id: 1,
          name: "",
          priorityId: 1,
          assigneeId: null,
          status: 3,
        },
      ]);
    }
  }, [subtasks]);

  useEffect(() => {
    if (edited) {
      setEditedTaskDetails({ subtasks: allSubtasks });
      setEdited(false);
    }
  }, [allSubtasks]);

  const handleOnCancel = (subtask) => {
    const othereTask = addMoreSubtask.filter((t) => t.id !== subtask.id);
    setAddMoreSubtasks(othereTask);
    if (othereTask.length === 0) {
      setShowAddTask(false);
    }
  };
  const handleSubtaskOnChange = async (value, key, id, isNew) => {
    if (isNew) {
      setAddMoreSubtasks((prevState) =>
        prevState.map((subtask) => {
          if (subtask.id === id) {
            return { ...subtask, [key]: value };
          }
          return subtask;
        })
      );
    } else {
      setAllSubtasks((prevState) =>
        prevState.map((subtask) => {
          if (subtask.id === id) {
            return { ...subtask, [key]: value };
          }
          return subtask;
        })
      );
      setEdited(true);
      // const newsubtasks =

      // await dispatch(
      //   updateGoal({
      //     [key]: value,
      //     goalId: id,
      //   })
      // );
      await dispatch(updateSchedule({ key: "isGoalEdited", value: true }));
    }
  };

  const handleSave = async (subtask) => {
    handleOnCancel(subtask);
    if (taskId !== "new") {
      setTask({ ...task, subtasks: [subtask, ...task.subtasks] });
      const payload = {
        sprintId: null,
        projectId: projectId,

        name: subtask.name,
        isCompleted: 0,
        isNewGoal: 0,
        statusId: task.statusId,
        priorityId: subtask.priorityId,
        goalType: 4,
        assigneeId: subtask.assigneeId,
        reporterId: task.reporterId,
        parentGoalId: taskId,
      };

      if (searchParams.has("sprintId")) {
        payload.sprintId = searchParams.get("sprintId");
      }

      await dispatch(createGoal(payload));
      dispatch(getGoalDetails({ goalId: taskId }));
    } else {
      setTask({ ...task, subtasks: [subtask, ...task.subtasks] });
    }
  };

  return (
    <div className="mt-4">
      <div
        className=" flex items-center gap-x-3 cursor-pointer"
        onClick={() => setShowSubtask(!showSubtask)}
      >
        <ChevronIcon
          className={`text-gray-500 mt-0.5 ${showSubtask ? "" : "-rotate-90"}`}
        />
        <p className="out-500-14 leading-5 text-black">Sub-tasks</p>
        <span className="out-300-12 text-gray-500">{subtasks.length}</span>
      </div>
      {showSubtask && (
        <div className="space-y-3 mt-3">
          {allSubtasks.map((subtask) => (
            <SubtaskItem
              key={subtask.id}
              subtask={subtask}
              handleOnCancel={() => console.log("Nothing")}
              handleSaveClick={handleSave}
              handleSubtaskOnChange={handleSubtaskOnChange}
            />
          ))}
          {addMoreSubtask.map((subtask) => (
            <SubtaskItem
              key={subtask.id}
              isNew={true}
              subtask={subtask}
              handleOnCancel={handleOnCancel}
              handleSaveClick={handleSave}
              handleSubtaskOnChange={handleSubtaskOnChange}
            />
          ))}
          <button
            onClick={() =>
              setAddMoreSubtasks([
                ...addMoreSubtask,
                {
                  id: addMoreSubtask.length + 1,
                  name: "",
                  priorityId: 1,
                  assigneeId: null,
                  status: 3,
                },
              ])
            }
            className="flex items-center h-[30px]  cursor-pointer "
          >
            <PlusIcon className="text-gray-500" />
            <p className="pl-2 out-500-14 leading-5 text-gray-500 capitalize">
              Add More
            </p>
          </button>
        </div>
      )}
    </div>
  );
};

export default AddSubtask;
