import React from "react";
import Icon from "@ant-design/icons";

const DashBoardSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path stroke="currentColor" d="M3 3h5v5H3V3z"></path>
      <path stroke="currentColor" d="M3 12H8V17H3z"></path>
      <path stroke="currentColor" d="M12 3H17V8H12z"></path>
      <path stroke="currentColor" d="M17 17.5V12h-5v5h2.5"></path>
    </svg>
  );
};

const MessagesSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    fill="none"
    viewBox="0 0 20 20"
  >
    <path
      stroke="currentColor"
      d="M17 10v7m-7 0h4.5M17 10a7 7 0 10-7 7M8 10h5"
    ></path>
  </svg>
);

const ScheduleSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    fill="none"
    viewBox="0 0 20 20"
  >
    <path
      stroke="currentColor"
      d="M14.54 16.588a8 8 0 111.803-1.712M9.5 6v5l3.5 2.5"
    ></path>
  </svg>
);

const LibrarySvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    fill="none"
    viewBox="0 0 20 20"
  >
    <path
      stroke="currentColor"
      d="M4.5 5.363V17l5.5-4 5.5 4V5.363M4 3h12"
    ></path>
  </svg>
);

const DashBoardIcon = (props) => {
  return <Icon component={DashBoardSvg} {...props} />;
};

const MessagesIcon = (props) => {
  return <Icon component={MessagesSvg} {...props} />;
};

const ScheduleIcon = (props) => {
  return <Icon component={ScheduleSvg} {...props} />;
};

const LibraryIcon = (props) => {
  return <Icon component={LibrarySvg} {...props} />;
};

export { DashBoardIcon, MessagesIcon, ScheduleIcon, LibraryIcon };
