import React, { useEffect, useState } from "react";
import ScheduleRouteSelector from "../../Components/ScheduleRouteSelector";
import { ChevronIcon } from "../../../../Core/svgV2/Chevron";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { CrossIcon } from "../../../../Core/svgV2/CrossIcon";
import CustomButton from "../../../../Core/CommonV2/CustomButton";
import ReleaseModal from "../../Modals/ReleaseModal";
import { Popover, Segmented } from "antd";
import { FilterIcon } from "../../../../Core/svgV2/FilterIcon";

import Filters, { AppliedFilters } from "../../Sprints/Filters";
import { useAppDispatch, useAppSelector } from "../../../../Core/redux/hooks";
import { MoveToIcon } from "../../../../Core/svgV2/MoveToIcon";
import dayjs from "dayjs";
import ExportIcon from "../../../../Core/svgV2/ExportIcon";
import getUsersName from "../../../../Core/utils/getUserName";
import { getPhaseName } from "../../../../Core/utils/getPhaseName";
import { convertArrayToCsv } from "../../../../Core/utils/exportToCsv";
import { updateDashboard } from "../../../../Core/redux/slices/dashboard.slice";
import {
  getGoalsBySprint,
  getReleaseVersions,
} from "../../../../Core/redux/api/scheduleAPI";
import ReleaseErrorModal from "../../../Dashboard/Modals/ReleaseErrorModal";
import NewReleaseVersionModal from "../../Modals/NewReleaseVersionModal";
import { updateSchedule } from "../../../../Core/redux/slices/schedule.slice";

const ReleaseNotesMenu = () => {
  const { projectId, parentNoteId } = useParams();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [releaseModal, setReleaseModal] = useState(false);
  const { releaseVersions, phases, goals, releaseNotesView } = useAppSelector(
    (state) => state.schedule
  );
  const { projectDetails } = useAppSelector((state) => state.overview);
  const { tenantMembers } = useAppSelector((state) => state.tenant);
  const dispatch = useAppDispatch();
  const parentVersion = releaseVersions.find((v) => v.id == parentNoteId || "");
  const noteId =
    parentNoteId && !searchParams.has("noteId")
      ? parentNoteId
      : searchParams.get("noteId");
  useEffect(() => {
    dispatch(getReleaseVersions({ projectId }));
  }, [projectId]);
  const currentVersion = releaseVersions.find((v) => v.id == noteId);
  const { releaseErrorModal } = useAppSelector((state) => state.dashboard);
  const [newReleaseVersionModal, setNewReleaseVersionModal] = useState(false);
  const handleExport = () => {
    function formatKeys(obj) {
      const formattedObj = {};
      for (const key in obj) {
        switch (key) {
          case "id":
            formattedObj["ID"] = obj[key];
            break;
          case "name":
            formattedObj["NAME"] = obj[key];
            break;
          case "statusId":
            formattedObj["STATUS"] =
              obj[key] == 1 ? "TO DO" : obj[key] == 2 ? "IN PROGRESS" : "DONE";
            break;
          case "status":
            formattedObj["STATUS"] =
              obj[key] == 1 ? "TO DO" : obj[key] == 2 ? "IN PROGRESS" : "DONE";
            break;
          case "priorityId":
            formattedObj["PRIORITY"] =
              obj[key] == 1 ? "LOW" : obj[key] == 2 ? "MEDIUM" : "HIGH";
            break;
          // case "isNewGoal":
          //   formattedObj["NEW SCOPE"] =
          //     obj[key] == 1 ? "YES" : "NO";
          //   break;
          case "projectId":
            formattedObj["PROJECT ID"] = obj[key];
            formattedObj["PROJECT NAME"] = projectDetails.title;
            break;
          case "goalType":
            formattedObj["GOAL TYPE"] =
              obj[key] == 1
                ? "TASK"
                : obj[key] == 2
                ? "EVENT"
                : obj[key] == 3
                ? "BUG"
                : "SUBTASK";
            break;
          case "parentGoalId":
            formattedObj["PARENT GOAL ID"] = obj[key];
            break;
          // case "sprintId":
          //   formattedObj["SPRINT ID"] = obj[key];
          //   break;
          // case "assigneeId":
          //   formattedObj["ASSIGNEE ID"] = obj[key];
          //   formattedObj["ASSIGNEE NAME"] = getUsersName(
          //     tenantMembers.find((user) => user.userId == obj[key])
          //   );
          //   break;
          // case "reporterId":
          //   formattedObj["REPORTER ID"] = obj[key];
          //   formattedObj["REPORTER NAME"] = getUsersName(
          //     tenantMembers.find((user) => user.userId == obj[key])
          //   );
          //   break;
          case "phaseId": {
            const phase = phases.find((p) => p.projectPhaseId == obj[key]);
            if (phase) {
              formattedObj["PHASE"] = `${phase.phases} ${
                phase.phaseIteration ? phase.phaseIteration : ""
              }`;
            } else {
              formattedObj["PHASE"] = `--`;
            }

            break;
          }
          case "estimatedTime":
            formattedObj["ESTIMATED TIME"] = obj[key];
            break;
          case "timeLogged":
            formattedObj["TIME LOGGED"] = obj[key];
            break;
          // case "componentName":
          //   formattedObj["COMPONENT NAME"] = obj[key];
          //   break;
          case "linkedGoalId":
            formattedObj["LINKED GOAL ID"] = obj[key];
            break;
          case "createdAt":
            formattedObj["CREATED AT"] = obj[key]
              ? dayjs(obj[key]).format("DD MMM YYYY")
              : "-";
            break;
          case "startDate":
            formattedObj["START DATE"] = obj[key]
              ? dayjs(obj[key]).format("DD MMM YYYY")
              : "-";
            break;
          case "endDate":
            formattedObj["END DATE"] = obj[key]
              ? dayjs(obj[key]).format("DD MMM YYYY")
              : "-";
            break;
          // case "isNewSprint":
          //   formattedObj["NEW SCOPE"] =
          //     obj[key] == 1 ? "YES" : "NO";
          //   break;
          case "goals":
            formattedObj["TOALT GOALS COUNT"] = obj[key].length;
            break;
          case "createdById":
            formattedObj["CREATED BY"] = getUsersName(
              tenantMembers.find((user) => user.userId == obj[key])
            );
            break;
          case "phases":
            if (obj[key].length > 0) {
              if (obj[key][0]?.phaseId) {
                const newObj = obj[key].map((a) => {
                  return getPhaseName(a.phaseId);
                });
                formattedObj["PHASE"] = newObj.join(" & ");
              }
            }
            break;
          default:
            break;
        }
      }
      return formattedObj;
    }

    const data = [...goals];
    goals.forEach((g) => {
      if (g.subTasks && g.subTasks.length) {
        data.push(...g.subTasks);
      }
    });
    // console.log(sprints.map(formatKeys), "convertArrayToCsv");
    // exportToCsv(
    //   "sprint",
    //   searchParams.has("selectedSprint")
    //     ? data.map(formatKeys)
    //     : sprints.map(formatKeys)
    // );
    convertArrayToCsv(data.map(formatKeys));
  };

  const handleCustomButtonClick = async () => {
    const isProd = currentVersion?.hasAssociatedVersions;

    if (isProd) {
      const childVersions = currentVersion?.associatedVersions;
      if (childVersions.length > 0) {
        const hasError = childVersions.some(
          (childVersion) => !childVersion.versionStatus
        );
        if (hasError) {
          dispatch(
            updateDashboard({
              key: "releaseErrorModal",
              value: {
                visible: true,
                type: releaseErrorModal.type,
              },
            })
          );
        } else {
          await dispatch(
            getGoalsBySprint({
              projectId,
              filters: {
                releaseVersionId: currentVersion?.id,
              },
            })
          );
          setReleaseModal(true);
        }
      }
    } else {
      await dispatch(
        getGoalsBySprint({
          projectId,
          filters: { releaseVersionId: currentVersion?.id },
        })
      );
      setReleaseModal(true);
    }
  };
  console.log(!searchParams.has("noteId") || !parentNoteId, "booleans");

  return (
    <div className="py-3 px-6 border-b border-gray-200">
      <div className="flex justify-between">
        <div>
          <div className="flex gap-x-2 items-center">
            {" "}
            <div
              role="button"
              onClick={() => navigate(-1)}
              className="out-500-14 flex items-center cursor-pointer gap-x-2 text-black"
            >
              <ChevronIcon className="transform rotate-90" />{" "}
              <span>Release Notes</span>
            </div>
          </div>
          <p className="out-300-14 text-gray-500">
            View & export all the tasks or bugs associated to a release version
            from here.
          </p>
        </div>
        <div>
          <div>
            <ScheduleRouteSelector current="reports" />
          </div>
          {!searchParams.has("noteId") && !parentNoteId && (
            <div className="mt-4 flex items-center justify-end">
              <CustomButton
                text="Create"
                height="30px"
                onClick={() => {
                  setNewReleaseVersionModal(true);
                }}
              />

              <NewReleaseVersionModal
                visible={newReleaseVersionModal}
                onClose={() => setNewReleaseVersionModal(false)}
              />
            </div>
          )}
        </div>
      </div>

      {(searchParams.has("noteId") || parentNoteId) && (
        <div className="mt-4 flex items-center justify-between">
          <div className="flex items-center gap-x-2">
            <button onClick={() => navigate(-1)} className="hover:bg-gray-50">
              <CrossIcon className="text-gray-700" />
            </button>
            {parentVersion && parentNoteId && searchParams.has("noteId") ? (
              <>
                <span className="out-300-14 text-gray-500">
                  {parentVersion?.versionName}
                </span>
                <span className="out-300-14 text-gray-500 mx-1.5">/</span>
              </>
            ) : (
              <></>
            )}
            <span className="out-500-14 text-black">
              {currentVersion?.versionName}
            </span>
          </div>
          <div className="flex items-center gap-x-2">
            <Segmented
              options={[
                {
                  label: (
                    <div className="flex  items-center rounded-md h-[25px]  gap-x-2 justify-center   ">
                      <div
                        className={`  text-center out-500-14 ${
                          releaseNotesView === 1
                            ? "text-black"
                            : "text-gray-500"
                        }  `}
                      >
                        Tasks
                      </div>
                    </div>
                  ),
                  value: "Tasks",
                },
                {
                  label: (
                    <div className="flex h-[25px]  items-center gap-x-2 justify-center rounded-md ">
                      <div
                        className={`  text-center out-500-14 ${
                          releaseNotesView !== 1
                            ? "text-black"
                            : "text-gray-500"
                        }  `}
                      >
                        Subtasks
                      </div>
                    </div>
                  ),
                  value: "Subtasks",
                },
              ]}
              block={true}
              defaultValue="Tasks"
              value={releaseNotesView === 1 ? "Tasks" : "Subtasks"}
              className={
                "bg-primary-600 text-white out-500-14 rounded-[4px] h-[32px] p-1 flex items-center justify-center "
              }
              onChange={(value) => {
                dispatch(
                  updateSchedule({
                    key: "releaseNotesView",
                    value: releaseNotesView === 1 ? 0 : 1,
                  })
                );
              }}
            />
            <AppliedFilters />
            <Popover
              trigger={["click"]}
              content={<Filters />}
              arrow={false}
              placement="bottomLeft"
            >
              <button className="hover:bg-gray-50 h-[30px] w-[30px] flex justify-center items-center">
                {" "}
                <FilterIcon className="" />
              </button>
            </Popover>
            <button className="mr-2" onClick={handleExport}>
              <ExportIcon className="text-gray-500" />
            </button>
            {currentVersion?.versionStatus === 1 ? (
              <CustomButton
                text={`Released on ${
                  currentVersion.releaseDate
                    ? dayjs(currentVersion.releaseDate).format("MMMM DD YYYY")
                    : "-"
                }`}
                className="bg-white border out-500-14 text-gray-700 border-gray-200"
                height="30px"
                icon={<MoveToIcon className="text-gray-700 ml-2.5" />}
              />
            ) : (
              <CustomButton
                text={"Release"}
                height="30px"
                onClick={handleCustomButtonClick}
              />
            )}
          </div>
        </div>
      )}
      {releaseModal && (
        <ReleaseModal
          visible={releaseModal}
          onClose={() => setReleaseModal(false)}
          version={currentVersion}
        />
      )}
    </div>
  );
};

export default ReleaseNotesMenu;
