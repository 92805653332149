import React, { useEffect, useState } from "react";
import { Divider, Dropdown, MenuProps, Popover } from "antd";
import { PlusIcon } from "../svgV2/PlusIcon";
import { EditIcon } from "../svgV2/EditIcon";
import { BellIcon } from "../svgV2/BellIcon";
import { SettingIcon } from "../svgV2/SettingsIcon";
import { FolderIcon } from "../svgV2/FolderIcon";
import { UploadIcon } from "../svgV2/UploadIcon";
import { ThreadIcon } from "../svgV2/ThreadIcon";
import { UserIcon } from "../svgV2/UserIcon";
import { QuestionMarkIcon } from "../svgV2/QuestionMarkIcon";
import { LogoutIcon } from "../svgV2/LogoutIcon";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import Cookies from "js-cookie";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import NotificationPopup from "../../ModulesV2/Notifications/NotificationPopup";
import { getNotifications } from "../redux/api/notificationAPI";
import { updateConversations } from "../redux/slices/conversations.slice";
import WhiteBoard from "../../ModulesV2/Dashboard/Modals/WhiteBoard";
import usePermission from "../hooks/usePermission";
import CustomAvatar from "../CommonV2/CustomAvatar";
import DcfHeaderIcon from "../svgV3/DCFHeaderIcon";
import localforage from "localforage";
import { fetchUserLocation } from "../utils/fetchUserLocation";
import { getTodaysDcfActivity } from "../redux/api/myFootprintAPI";

const NotificationCountBadge = () => {
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.userDetails);
  const { notificationList } = useAppSelector((state) => state.notification);
  const [unreadCount, setUnreadCount] = useState(0);
  //const intervalId = useRef(null);

  useEffect(() => {
    dispatch(getNotifications({ userId: user.id }));
    const intervalId = setInterval(() => {
      dispatch(getNotifications({ userId: user.id }));
    }, 300000); // 5 minutes in milliseconds

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    const c = notificationList.filter((n) => n.isUnread);

    setUnreadCount(c.length);
  }, [notificationList]);

  if (unreadCount === 0) return <></>;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="8"
      height="8"
      viewBox="0 0 8 8"
      fill="none"
    >
      <circle cx="4" cy="4" r="4" fill="#F04438" />
    </svg>
  );
};

const PlusButton = React.memo(() => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { hasPermission: CREATE_PROJECT } = usePermission("1");
  const { user } = useAppSelector((state) => state.userDetails);

  const items = [
    CREATE_PROJECT &&
      +user.roleId !== 4 && {
        key: "1",
        label: (
          <div className="text-gray-700 out-300-14">Create New Project</div>
        ),
        icon: <FolderIcon />,
      },
    +user.roleId !== 4 && {
      key: "2",
      label: <div className="text-gray-700 out-300-14">Upload To Library</div>,
      icon: <UploadIcon />,
    },
    {
      key: "3",
      label: <div className="text-gray-700 out-300-14">Create New Thread</div>,
      icon: <ThreadIcon />,
    },
  ];
  const onClick: MenuProps["onClick"] = ({ key }) => {
    switch (key) {
      case "1":
        navigate("/project-onboardingV2");
        break;
      case "2":
        navigate("/library");

        break;
      case "3":
        navigate("/messages");

        dispatch(
          updateConversations({
            key: "newGroupModal",
            value: true,
          })
        );
        break;

      default:
        console.log("item others");
        break;
    }
  };
  return (
    <Dropdown
      menu={{ items, onClick }}
      dropdownRender={(origin) => {
        return <div className="w-[250px] h-[120px]">{origin}</div>;
      }}
      trigger={["click"]}
    >
      <button className="flex justify-center items-center rounded-[4px] shadow-xs cursor-pointer bg-primary-600 h-[30px] w-[30px]">
        <PlusIcon className="text-white" />
      </button>
    </Dropdown>
  );
});

PlusButton.displayName = "PlusButton";

export const UserProfile = ({ size = 32 }) => {
  const { user } = useAppSelector((state) => state.userDetails);
  const navigate = useNavigate();
  const content = (
    <div className="w-[250px] pb-[18px]">
      <div className="out-500-16 pt-4 ml-[24px]">Profile</div>
      <div className="flex items-center ml-[24px]">
        <CustomAvatar
          src={user.profilePicture}
          title={(user.firstName || "w") + " " + (user.lastName || "d")}
          size={32}
          whiteText
          color={user.profilePictureColorCode}
          badge
          userId={user.id}
        />
        <div className="ml-3">
          <p className="text-gray-900 out-500-14">
            {user.firstName || "w"} {user.lastName || "d"}
          </p>
          <p className="text-gray-500 out-300-12">{user.email}</p>
        </div>
      </div>
      <div
        role="button"
        onClick={() => navigate("/manage-profile")}
        className="flex mt-[26px] ml-[24px]"
      >
        <UserIcon />
        <span className="ml-2 text-gray-700 cursor-pointer out-300-14">
          Manage Profile
        </span>
      </div>
      <div className="flex mt-[22px] ml-[24px]">
        <QuestionMarkIcon />
        <span className="ml-2 text-gray-700 cursor-pointer out-300-14">
          Help Centre
        </span>
      </div>
      <Divider className="my-[18px]" />
      <div
        className="flex ml-[24px]"
        role="button"
        onClick={() => {
          localStorage.removeItem("persist:root");
          localStorage.removeItem("user");
          localforage.clear();
          Cookies.remove("jwt");
          location.reload();
        }}
      >
        <LogoutIcon />
        <span className="ml-2 text-gray-700 cursor-pointer out-300-14">
          Log out
        </span>
      </div>
    </div>
  );
  return (
    <Popover
      content={content}
      title={null}
      trigger="click"
      arrow={false}
      placement="bottomRight"
    >
      <button className="cursor-pointer" id="dashboard-tour-profile">
        {" "}
        <CustomAvatar
          src={user.profilePicture}
          title={(user.firstName || "w") + " " + (user.lastName || "d")}
          size={size}
          badge
          userId={user.id}
          whiteText
          color={user.profilePictureColorCode}
        />
      </button>
    </Popover>
  );
};

const Header = ({ title }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { projectId } = useParams();
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.userDetails);
  const { todayGCO2value } = useAppSelector((state) => state.footPrintDetails);
  const [whiteBoardModal, setWhiteBoardModal] = useState(false);

  useEffect(() => {
    dispatch(getNotifications({ userId: user.id }));
    const intervalId = setInterval(() => {
      dispatch(getNotifications({ userId: user.id }));
    }, 300000); // 5 minutes in milliseconds

    return () => clearInterval(intervalId);
  }, []);
  useEffect(() => {
    dispatch(getTodaysDcfActivity({}));

    const intervalId = setInterval(() => {
      dispatch(getTodaysDcfActivity({}));
    }, 300000); // 5 minutes in milliseconds

    return () => clearInterval(intervalId);
  }, []);
  // useEffect(() => {
  //   const userLocation = async () => {
  //     const date = new Date();
  //     // const hourConstants = 1000 * 60 * 60;
  //     const time = date.getTime() / 3600000;
  //     const lastLocationFetched =
  //       localStorage.getItem("lastLocationFetched") || 0;

  //     if (time - +lastLocationFetched > 5) {
  //       const locationData = await fetchUserLocation();
  //       //todo db update
  //       localStorage.setItem("lastLocationFetched", `${time}`);
  //     }
  //   };
  //   userLocation();
  //   //update this in backend lat , long , countryname
  // }, []);

  const handleSettingsClick = () => {
    let url = "";
    if (+user.roleId === 4) {
      url = "/settingsV2?tab=Integrations";
    } else {
      url = "/settingsV2?tab=WorkspaceRoles";
    }

    if (projectId) {
      url = `/settingsV2?tab=General&projectId=${projectId}`;
    }

    localStorage.removeItem("settingsRedirectUrl");

    localStorage.setItem("settingsRedirectUrl", location.pathname);

    navigate(url);
  };

  return (
    <header style={{ position: "sticky", top: 0, zIndex: 1, width: "100%" }}>
      <div className="flex justify-between h-[46px] bg-white border-b border-gray-200 px-6 items-center">
        {title}
        <div className="flex items-center gap-x-[24px]">
          <div
            className="flex h-[30px] justify-center  py-[3px] px-2 gap-x-1 bg-gray-100 rounded border border-gray-200 cursor-pointer"
            onClick={() => navigate("/my-footprint")}
          >
            <DcfHeaderIcon />
            {todayGCO2value < 1000 ? (
              <p className="text-primary-700 out-600-16">
                {todayGCO2value || 0}
                <span className="out-300-12 ml-0.5">
                  gCo<sub>2</sub>e
                </span>
              </p>
            ) : (
              <p className="text-primary-700 out-600-16">
                {(todayGCO2value / 1000).toFixed(2) || 0}
                <span className="out-300-12 ml-0.5">
                  kgCo<sub>2</sub>e
                </span>
              </p>
            )}
          </div>
          <PlusButton />
          <div
            className="cursor-pointer"
            role="button"
            onClick={() => setWhiteBoardModal(true)}
          >
            <EditIcon className="text-black" />
          </div>
          <Popover
            content={<NotificationPopup />}
            trigger={"click"}
            arrow={false}
            placement="bottomRight"
            destroyTooltipOnHide
          >
            <div className="relative cursor-pointer">
              <BellIcon className="text-black" />
              <div className="absolute top-0 left-0">
                <NotificationCountBadge />
              </div>
            </div>
          </Popover>
          <div
            className="cursor-pointer"
            role="button"
            onClick={() => handleSettingsClick()}
          >
            <SettingIcon className="text-black" />
          </div>
          <UserProfile />
        </div>
      </div>

      {whiteBoardModal && (
        <WhiteBoard
          onCancel={() => {
            setWhiteBoardModal(false);
          }}
          visible={whiteBoardModal}
        />
      )}
    </header>
  );
};

export default React.memo(Header);
