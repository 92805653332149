import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";
import CustomTable from "../../../Core/CommonV2/CustomTable";
import { Tooltip } from "antd";
import { getPhaseName } from "../../../Core/utils/getPhaseName";
import { PhaseIcon } from "../../../Core/svgV2/PhaseIcon";
import CustomAvatar from "../../../Core/CommonV2/CustomAvatar";
import TaskTypeIcon from "../../../Core/svgV2/TaskTypeIcon";
import getUsersName from "../../../Core/utils/getUserName";
import CustomBadge from "../../../Core/CommonV2/CustomBadge";
import {
  getPriorityDetails,
  getStatusDetails,
} from "../../../Core/utils/scheduleUtils";
import PriorityFlag from "../../../Core/svgV2/PriorityFlag";
import dayjs from "dayjs";
import { useNavigate, useParams } from "react-router-dom";
import { useQueryClient } from "react-query";
import SprintAccordian from "../Planner/SprintAccordian";
import TaskItem from "../Planner/TaskItem";
import { DEFAULT_LIMIT } from "../../../Core/constants";
import CustomPagination from "../../../Core/CommonV3/CustomPagination";
import MyBoardLabel from "../../../Core/CommonV3/MyBoardLabel";
import {
  CreateTaskHeader,
  PrimaryTaskHeader,
  SelectedTaskHeader,
} from "../Planner/BacklogTaskHeader";
import { updateSchedule } from "../../../Core/redux/slices/schedule.slice";
import { getGoalsBySprint } from "../../../Core/redux/api/scheduleAPI";
import { off } from "process";

const SprintListView = () => {
  const {
    sprints,
    phases,
    filterGoalParameter,
    filters,
    goals,
    selectedTasks,
    selectedTasksSprintId,
    createTaskHeader,
    goalsCount,
    labels,
    sprintKanbanView,
  } = useAppSelector((state) => state.schedule);
  const { projectId, sprintId } = useParams();
  const [offset, setOffset] = useState(0);

  const { userList } = useAppSelector((state) => state.overview);
  const { user } = useAppSelector((state) => state.userDetails);
  const navigate = useNavigate();
  const StatusItem = ({ status }) => {
    const { text, color, className } = getStatusDetails(status);
    return <CustomBadge text={text} color={color} className={className} dot />;
  };
  const dispatch = useAppDispatch();
  const queryClient: any = useQueryClient();

  useEffect(() => {
    if (sprintId) {
      dispatch(
        getGoalsBySprint({
          projectId,
          sprintId,
          limit: DEFAULT_LIMIT,
          offset,
        })
      );
    }
  }, [sprintId, offset, sprintKanbanView]);

  const handleFilter = (goals) => {
    let filteredGoals = [...goals];
    const filterParams = filterGoalParameter;

    // filter by assignee
    if (filterParams?.assignee.length > 0) {
      const currentProjectFilter = filterGoalParameter?.assignee?.find(
        (filter) => +filter.projectId === +projectId
      );
      if (
        currentProjectFilter !== undefined &&
        currentProjectFilter?.assignee.length > 0
      ) {
        filteredGoals = filteredGoals.filter((goal) =>
          currentProjectFilter?.assignee?.includes(goal.assigneeId)
        );
      }
    }

    if (filters?.assignee.length > 0) {
      filteredGoals = filteredGoals.filter((goal) =>
        filters.assignee.includes(goal.assigneeId)
      );
    }

    if (filters?.reporter.length > 0) {
      filteredGoals = filteredGoals.filter((goal) =>
        filters.reporter.includes(goal.reporterId)
      );
    }

    if (filters?.status.length > 0) {
      filteredGoals = filteredGoals.filter((goal) =>
        filters.status.includes(goal.statusId)
      );
    }

    if (filters?.label.length > 0) {
      filteredGoals = filteredGoals.filter((goal) => {
        return filters.label.includes(goal.labelId);
      });
    }

    if (filters?.priority.length > 0) {
      filteredGoals = filteredGoals.filter((goal) =>
        filters.priority.includes(goal.priorityId)
      );
    }

    if (filters?.type.length > 0) {
      filteredGoals = filteredGoals.filter((goal) => {
        const type = +goal.goalType === 4 ? 1 : +goal.goalType;
        return filters.type.includes(type);
      });
    }

    if (filters?.phase.length > 0) {
      filteredGoals = filteredGoals.filter((goal) =>
        filters.phase.includes(goal.phaseId)
      );
    }

    // filter by search value
    const searchValue =
      filterGoalParameter?.searchValue?.projectId === projectId &&
      filterGoalParameter?.searchValue?.module === "sprints"
        ? filterParams?.searchValue?.searchValue?.toLowerCase()
        : "";

    filteredGoals = filteredGoals.filter(
      (goal) =>
        goal.name.toLowerCase().includes(searchValue) ||
        goal.id.includes(searchValue) ||
        goal.identifier?.includes(searchValue)
    );

    return filteredGoals;
  };

  const getHeader = ({ offset = 0 }) => {
    if (selectedTasks?.length > 0 && +selectedTasksSprintId === +sprintId)
      return <SelectedTaskHeader goalCount={handleFilter(goals).length} />;
    if (createTaskHeader)
      return (
        <CreateTaskHeader
          labelOptions={labelOptions}
          offset={offset}
          callBack={() =>
            queryClient.invalidateQueries([
              "goalsBySprint",
              projectId,
              sprintId,
            ])
          }
          sprintId={sprintId}
          key={sprintId}
        />
      );
    return <PrimaryTaskHeader />;
  };
  const handlePagination = async (page: number) => {
    setOffset((page - 1) * DEFAULT_LIMIT);
  };
  const labelOptions = labels
    ?.filter((item) => !item.isMyBoard && +item.projectId === +projectId)
    ?.map((label) => {
      return {
        id: label.id,
        value: label.id,
        label: label.name,
        render: () => (
          <div className="flex items-center justify-between">
            <MyBoardLabel labelColorId={label.labelColorId} text={label.name} />

            <div className="h-[14px] w-[14px] rounded-full" />
          </div>
        ),
      };
    });

  const handleMultipleSelect = (e, taskId) => {
    e.preventDefault();
    const isCtrlPressed = e.ctrlKey || e.metaKey;
    const currentTask = goals?.find((item) => +item.id == taskId);
    const isCreatedByTheUser = currentTask?.createdById == user.id;

    if (isCtrlPressed) {
      dispatch(
        updateSchedule({
          key: "selectedTasks",
          value: selectedTasks?.includes(taskId)
            ? selectedTasks.filter((item) => item !== taskId)
            : [...selectedTasks, taskId],
        })
      );

      dispatch(
        updateSchedule({
          key: "selectedTasksSprintId",
          value: sprintId,
        })
      );
    }
    if (selectedTasks?.length > 0 && !isCtrlPressed) {
      if (selectedTasks?.length > 0) {
        dispatch(
          updateSchedule({
            key: "selectedTasks",
            value: [],
          })
        );
      }
    }
  };
  const columns = [
    sprintId
      ? null
      : {
          title: () => (
            <div className="out-500-12 leading-[18px] text-gray-500">
              Sprint
            </div>
          ),
          width: "100px",
          dataIndex: "sprintId",
          defaultSortOrder: "ascend",
          sorter: (a, b) => a.id - b.id,
          render: (text) => (
            <div className="leading-5 text-gray-500 out-300-12 whitespace-nowrap">
              {sprints.find((s) => s.id == text)?.name || "--"}
            </div>
          ),
        },
    {
      title: () => (
        <div className="out-500-12 leading-[18px] text-gray-500 ">Type</div>
      ),
      width: "100px",
      dataIndex: "goalType",
      defaultSortOrder: "ascend",
      sorter: (a, b) => a.id - b.id,
      render: (text, record) => (
        <Tooltip
          className="text-white cursor-pointer"
          title={getPhaseName(record.phaseId)}
        >
          <TaskTypeIcon
            type={record.isNewGoal ? "newscope" : +record.goalType}
            width="20"
            height="20"
          />
        </Tooltip>
      ),
    },
    {
      title: () => (
        <div className="out-500-12 leading-[18px]  text-gray-500 ">ID No.</div>
      ),
      width: "100px",
      dataIndex: "id",
      defaultSortOrder: "ascend",
      sorter: (a, b) => a.id - b.id,
      render: (text, record) => (
        <div className="leading-5 text-gray-500 out-300-12 ">
          {" "}
          PI - {`${record.linkedGoalId || record.id}`}
        </div>
      ),
    },
    {
      title: () => (
        <div className="out-500-12 leading-[18px]  text-gray-500 ">Title</div>
      ),
      width: "320px",
      dataIndex: "name",
      defaultSortOrder: "ascend",
      sorter: (a, b) => a.id - b.id,
      render: (text) => (
        <p className="leading-5 text-black out-300-12 ">{text}</p>
      ),
    },
    {
      title: () => (
        <div className="out-500-12 leading-[18px]  text-gray-500 ">
          Assignee
        </div>
      ),
      width: "200px",
      defaultSortOrder: "ascend",
      sorter: (a, b) => a.id - b.id,

      dataIndex: "assigneeId",
      render: (text, record) => {
        const assignee = userList.find(
          (user) => +user.userId === +record.assigneeId
        );
        //const ownerDetails = getOwnerDetails(text);
        return (
          <div className="flex items-center gap-x-3 ">
            <CustomAvatar
              title={`${getUsersName(assignee)}`}
              src={assignee?.profilePicture}
              size={20}
              whiteText
              color={assignee?.profilePictureColorCode}
            />
            <p className="leading-5 text-gray-500 out-300-12">
              {getUsersName(assignee)}
            </p>
          </div>
        );
      },
    },
    {
      title: () => (
        <div className="out-500-12 leading-[18px]  text-gray-500 ">
          Reporter
        </div>
      ),
      width: "200px",
      defaultSortOrder: "ascend",
      sorter: (a, b) => a.id - b.id,

      dataIndex: "reporterId",
      render: (text, record) => {
        const reporter = userList.find(
          (user) => +user.userId === +record.reporterId
        );
        //const ownerDetails = getOwnerDetails(text);
        return (
          <div className="flex items-center gap-x-3 ">
            <CustomAvatar
              title={`${getUsersName(reporter)}`}
              src={reporter?.profilePicture}
              size={20}
              whiteText
              color={reporter?.profilePictureColorCode}
            />

            <p className="leading-5 text-gray-500 out-300-12">
              {getUsersName(reporter)}
            </p>
          </div>
        );
      },
    },
    {
      title: () => (
        <div className="out-500-12 leading-[18px]  text-gray-500 ">Status</div>
      ),
      width: "200px",
      defaultSortOrder: "ascend",
      sorter: (a, b) => a.id - b.id,

      dataIndex: "statusId",
      render: (text) => {
        //const ownerDetails = getOwnerDetails(text);
        return <StatusItem status={text} />;
      },
    },
    {
      title: () => (
        <div className="out-500-12 leading-[18px]  text-gray-500 ">Phase</div>
      ),

      defaultSortOrder: "ascend",
      sorter: (a, b) => a.id - b.id,
      width: "156px",
      dataIndex: "phaseId",
      render: (text) => {
        const phase = phases.find((p) => p.projectPhaseId == text);
        if (!phase) {
          return "-";
        }
        return (
          <div className="flex items-center gap-x-3 ">
            <PhaseIcon phaseId={+phase.phaseId} />
            <p className="text-gray-500 out-200-12">
              {phase.phases} {phase.phaseIteration}
            </p>
          </div>
        );
      },
    },
    {
      title: () => (
        <div className="out-500-12 leading-[18px]  text-gray-500 ">
          Priority
        </div>
      ),
      width: "68px",
      defaultSortOrder: "ascend",
      sorter: (a, b) => a.id - b.id,

      dataIndex: "priorityId",
      render: (text) => {
        const priorityData = getPriorityDetails(text);
        return (
          <PriorityFlag
            style={{ color: priorityData.color }}
            height="20"
            width="20"
          />
        );
      },
    },
    {
      title: () => (
        <div className="out-500-12 leading-[18px]  text-gray-500 ">
          Created On
        </div>
      ),
      width: "200px",
      dataIndex: "createdAt",
      defaultSortOrder: "ascend",
      sorter: (a, b) => a.id - b.id,
      render: (text) => (
        <p className="leading-5 text-gray-500 out-300-12 ">
          {text ? dayjs(text).format("DD MMM YYYY") : "-"}
        </p>
      ),
    },
    {
      title: () => (
        <div className="out-500-12 leading-[18px]  text-gray-500 ">
          Updated On
        </div>
      ),
      width: "200px",
      dataIndex: "updatedAt",
      defaultSortOrder: "ascend",
      sorter: (a, b) => a.id - b.id,
      render: (text, record) => (
        <p className="leading-5 text-gray-500 out-300-12 ">
          {text ? dayjs(text).format("DD MMM YYYY") : "-"}
        </p>
      ),
    },
  ];
  const [checkedTask, setCheckedTask] = useState([]);

  const handelTaskCheck = (id) => {
    if (checkedTask.includes(id)) {
      const newIds = checkedTask.filter((taskId) => taskId !== id);
      setCheckedTask(newIds);
    } else {
      setCheckedTask([...checkedTask, id]);
    }
  };
  const [limit, setLimit] = useState(1000);
  const [currentPageNo, setCurrentPageNo] = useState(1);

  return (
    <div className="min-h-[calc(100vh-174px)] max-h-[calc(100vh-174px)] overflow-y-scroll bg-gray-50">
      <div className="bg-white max-h-[calc(100vh-174px)]">
        <div className="px-6 py-3 bg-gray-50">
          {/* SprintAccordian component */}

          <div className="mt-3 first:rounded-t-xl">
            {getHeader({ offset })}

            {handleFilter(goals).map((task) => (
              <TaskItem
                handleMultipleSelect={handleMultipleSelect}
                selected={selectedTasks?.includes(task.id)}
                handleSelect={handelTaskCheck}
                taskId={task.id}
                key={task.id}
                task={task}
                selectedItems={checkedTask}
                labelOptions={labelOptions}
                offset={offset}
              />
            ))}
          </div>
          <div className="flex items-center justify-between w-full py-3 bg-white border border-x rounded-b-xl">
            <div className="flex-1" />
            {goalsCount > 0 &&
              Object.values(filters).every(
                (value) => Array.isArray(value) && value.length === 0
              ) && (
                <CustomPagination
                  handleOnChange={handlePagination}
                  total={goalsCount}
                  pageSize={DEFAULT_LIMIT}
                />
              )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SprintListView;
