import React, { useEffect, useRef, useState } from "react";
import CustomModal from "../../../Core/CommonV2/CustomModal";
import { CrossIcon, CrossIconSecondary } from "../../../Core/svgV2/CrossIcon";
import { Checkbox, DatePicker, Divider, Dropdown, TimePicker } from "antd";
import CustomInputBox from "../../../Core/CommonV2/CustomInputBox";
import { ClockCircleOutlined } from "@ant-design/icons";
import { CalendarRightIcon } from "../../../Core/svgV3/CalendarRightIcon";
import { ChevronIcon } from "../../../Core/svgV2/Chevron";
import { MarkerPinIcon } from "../../../Core/svgV3/MarkerPinIcon";
import CustomTextEditor from "../../../Core/CommonV3/CustomTextEditor";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";
import CustomSearchBox from "../../../Core/CommonV2/CustomSearchBox";
import useSearch from "../../../Core/hooks/useSearch";
import { CalendarAvatarIcon } from "../../../Core/svgV3/CalendarAvatarIcon";
import CustomAvatar from "../../../Core/CommonV2/CustomAvatar";
import MarkOptionalIcon from "../../../Core/svgV3/MarkOptionalIcon";
import RemoveFromEventIcon from "../../../Core/svgV3/RemoveFromEventIcon";
import { CircleIconPrimary } from "../../../Core/svgV3/CalendarCircleIcon";
import { CalendarNotificationIcon } from "../../../Core/svgV3/NotificationCalendarIcon";
import CustomRecurrenceModal from "./CustomRecurrenceModal";
import { updateDashboard } from "../../../Core/redux/slices/dashboard.slice";
import dayjs from "dayjs";
import CustomButton from "../../../Core/CommonV2/CustomButton";
import {
  getEventById,
  newCreateEvent,
  updateEventV3,
} from "../../../Core/redux/api/scheduleAPI";
import { client } from "../../../Core/utils/axiosClient";
import Quill from "quill";
import "quill/dist/quill.snow.css";
const MoreInformationAccordion = ({
  onLocationChange,
  onRecurrenceChange,
  recurrence,
  location,
  isChecked,
  handleCheckboxChange,
}) => {
  const [accordionOpen, setAccordionOpen] = useState(false);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const getRecurrenceLabel = (recurrence) => {
    if (!recurrence) {
      return "Doesn’t repeat";
    }
    const endOfYearPattern = "1231T235959Z";
    if (
      recurrence.startsWith("RRULE:FREQ=DAILY;UNTIL=") &&
      recurrence.includes(endOfYearPattern)
    ) {
      return "Daily";
    }
    if (
      recurrence.startsWith("RRULE:FREQ=WEEKLY;UNTIL=") &&
      recurrence.includes(";BYDAY=MO") &&
      recurrence.includes(endOfYearPattern)
    ) {
      if (recurrence.includes(";BYDAY=MO") && !recurrence.includes(","))
        return "Weekly on Monday";
    }
    if (
      recurrence.startsWith("RRULE:FREQ=MONTHLY;UNTIL=") &&
      recurrence.includes(";BYDAY=2MO") &&
      recurrence.includes(endOfYearPattern)
    ) {
      return "Monthly on the second Monday";
    }
    console.log(recurrence, "recurrence");
    return "Custom";
  };
  const [selectedRepitition, setSelectedRepitition] = useState("");
  const [selectedLocation, setSelectedLocation] = useState("");
  const { myCalendarRecurrence } = useAppSelector((state) => state.dashboard);
  const dispatch = useAppDispatch();
  const currentYear = new Date().getFullYear();
  const endDate = `${currentYear}1231T235959Z`;
  useEffect(() => {
    if (recurrence) {
      setSelectedRepitition(getRecurrenceLabel(recurrence));
      console.log(recurrence, "Last Day");
    } else {
      setSelectedRepitition("Doesn’t repeat");
    }
    if (location) {
      setSelectedLocation(location);
    }
  }, [recurrence, location, accordionOpen]);
  const options = [
    {
      key: "1",
      label: "Doesn’t repeat",
      onClick: () => {
        setSelectedRepitition("Doesn’t repeat");
        onRecurrenceChange("");
      },
    },
    {
      key: "2",
      label: "Daily",
      onClick: () => {
        setSelectedRepitition("Daily");
        onRecurrenceChange(`RRULE:FREQ=DAILY;UNTIL=${endDate}`);
      },
    },
    {
      key: "3",
      label: "Weekly on Monday",
      onClick: () => {
        setSelectedRepitition("Weekly on Monday");
        onRecurrenceChange(`RRULE:FREQ=WEEKLY;UNTIL=${endDate};BYDAY=MO`);
      },
    },
    {
      key: "4",
      label: "Monthly on the second Monday",
      onClick: () => {
        setSelectedRepitition("Monthly on the second Monday");
        onRecurrenceChange(`RRULE:FREQ=MONTHLY;UNTIL=${endDate};BYDAY=2MO`);
      },
    },
    {
      key: "5",
      label: "Custom",
      onClick: () => {
        setSelectedRepitition("Custom");
        dispatch(
          updateDashboard({
            key: "myCalendarRecurrence",
            value: true,
          })
        );
      },
    },
  ];
  const handleDropDownVisibleChange = (visible) => {
    setDropdownVisible(visible);
  };
  const toggleAccordion = () => {
    setAccordionOpen(!accordionOpen);
  };
  const handleLocationChange = (e) => {
    const location = e.target.value;
    onLocationChange(location);
    setSelectedLocation(location);
  };
  const handleCancelRecurrence = () => {
    dispatch(
      updateDashboard({
        key: "myCalendarRecurrence",
        value: false,
      })
    );
  };
  const handleChange = (isChecked) => () => {
    handleCheckboxChange(!isChecked);
  };
  return (
    <div className="px-2.5 pt-2 pb-2.5 bg-slate-100 rounded flex-col gap-2 inline-flex">
      <div className="flex gap-2" role="button" onClick={toggleAccordion}>
        <div className="flex flex-grow">
          <div className="flex flex-col gap-1">
            <p className="text-slate-700 out-500-14">More Information</p>
            <p className="text-gray-500 out-300-12">
              Add location and set the frequency of repeating this event
            </p>
          </div>
        </div>
        <div className="w-5 h-5">
          <ChevronIcon
            className={`transform ${
              accordionOpen ? "rotate-180" : ""
            } text-gray-500 cursor-pointer`}
          />
        </div>
      </div>
      {accordionOpen && (
        <div className="flex-col gap-2 flex">
          <div className="items-center gap-2 flex">
            <Checkbox
              style={{
                borderColor: "initial",
                background: "initial",
              }}
              className="createEventV3"
              checked={isChecked}
              onChange={handleChange(isChecked)}
            />
            <p className="text-slate-700 out-300-14">All Day</p>
          </div>
          <Dropdown
            menu={{
              items: options,
            }}
            trigger={["click"]}
            open={dropdownVisible}
            onOpenChange={handleDropDownVisibleChange}
          >
            <div className="flex justify-between bg-white border border-slate-200 items-center py-[5px] px-[10px] rounded">
              <p className="text-grey-900 out-300-14">{selectedRepitition}</p>
              <ChevronIcon
                style={{
                  transform: dropdownVisible ? "rotate(180deg)" : "none",
                }}
              />
            </div>
          </Dropdown>
          <CustomInputBox
            placeholder="Enter location"
            prefix={<MarkerPinIcon />}
            value={selectedLocation}
            onChange={handleLocationChange}
          />
        </div>
      )}
      {selectedRepitition === "Custom" ? (
        <CustomRecurrenceModal
          visible={myCalendarRecurrence}
          onCancel={handleCancelRecurrence}
          onSave={(customRecurrence) => {
            onRecurrenceChange(customRecurrence);
            handleCancelRecurrence;
            dispatch(
              updateDashboard({
                key: "myCalendarRecurrence",
                value: false,
              })
            );
          }}
          recurrence={recurrence}
        />
      ) : (
        <CustomRecurrenceModal
          visible={myCalendarRecurrence}
          onCancel={handleCancelRecurrence}
          onSave={(customRecurrence) => {
            onRecurrenceChange(customRecurrence);
            handleCancelRecurrence;
            dispatch(
              updateDashboard({
                key: "myCalendarRecurrence",
                value: false,
              })
            );
          }}
        />
      )}
    </div>
  );
};
const NotesAccordion = ({ initialNotesContent, onSave }) => {
  const [accordionOpen, setAccordionOpen] = useState(false);
  const [quill, setQuill] = useState(null);
  const editorContainerRef = useRef(null);
  const toolbarRef = useRef(null);
  const initializeQuillEditor = () => {
    if (!quill && editorContainerRef.current) {
      const newQuill = new Quill(editorContainerRef.current, {
        theme: "snow",
        modules: modules,
      });
      newQuill.root.innerHTML = initialNotesContent || "";
      setQuill(newQuill);
    }
  };
  const destroyQuillEditor = () => {
    if (quill) {
      quill.off("text-change");
      quill.disable();
      quill.container.remove();
      setQuill(null);
    }
  };
  const toggleAccordion = () => {
    setAccordionOpen(!accordionOpen);
  };
  useEffect(() => {
    if (accordionOpen) {
      initializeQuillEditor();
    } else {
      destroyQuillEditor();
    }
  }, [accordionOpen, initialNotesContent]);
  useEffect(() => {
    if (quill) {
      const handleChange = () => {
        const notesContent = quill.root.innerHTML;
        onSave(notesContent);
      };
      quill.on("text-change", handleChange);
      return () => {
        quill.off("text-change", handleChange);
      };
    }
  }, [quill, onSave]);
  return (
    <div className="px-2.5 pt-2 pb-2.5 bg-slate-100 rounded flex-col inline-flex">
      <div className="flex gap-2" role="button" onClick={toggleAccordion}>
        <div className="flex flex-grow">
          <div className="flex flex-col gap-1">
            <p className="text-slate-700 out-500-14">Notes</p>
            <p className="text-gray-500 out-300-12">
              Add your notes or agenda prior to the meeting
            </p>
          </div>
        </div>
        <div className="w-5 h-5">
          <ChevronIcon
            className={`transform ${
              accordionOpen ? "rotate-180" : ""
            } text-gray-500 cursor-pointer`}
          />
        </div>
      </div>
      {accordionOpen && (
        <div className="mt-0.5 bg-white">
          <div ref={toolbarRef}></div>
          <div ref={editorContainerRef}></div>
        </div>
      )}
    </div>
  );
};
const AttendeeCard = ({ attendee, onRemoveAttendee, onMarkAsOptional }) => {
  const { tenantMembers } = useAppSelector((state) => state.tenant);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const handleMarkAsOptional = () => {
    if (attendee.optional) {
      onMarkAsOptional(attendee, false);
    } else {
      onMarkAsOptional(attendee, true);
    }
  };
  const content = [
    {
      key: "1",
      label: attendee.optional ? "Unmark as optional" : "Mark as optional",
      icon: <MarkOptionalIcon />,
      onClick: handleMarkAsOptional,
    },
    {
      key: "2",
      label: "Remove",
      icon: <RemoveFromEventIcon />,
      danger: true,
      onClick: () => onRemoveAttendee(attendee),
    },
  ];
  const handleDropDownVisibleChange = (visible) => {
    setDropdownVisible(visible);
  };
  const user = tenantMembers.find((user) => user.email === attendee.email);
  const optionalLabel = attendee.optional ? "Optional" : "";
  const organizerLabel = attendee.organizer ? "Organizer" : "";
  const label =
    optionalLabel && organizerLabel
      ? `${organizerLabel}, ${optionalLabel}`
      : optionalLabel || organizerLabel;
  return (
    <div className="px-3 py-2 justify-start items-center gap-3 inline-flex">
      <div className="">
        {user ? (
          <CustomAvatar
            size={24}
            title={`${user.firstname} ${user.lastname}`}
            src={user.profilePicture}
          />
        ) : (
          <CalendarAvatarIcon />
        )}
      </div>
      <div className="text-zinc-800 text-sm font-light font-['Outfit'] leading-tight">
        {user ? `${user.firstname} ${user.lastname}` : attendee.email}{" "}
        {label && <span className="text-gray-400 ml-1">{`(${label})`}</span>}
      </div>
      <div className="">
        <Dropdown
          menu={{
            items: content,
          }}
          open={dropdownVisible}
          onOpenChange={handleDropDownVisibleChange}
          placement="top"
          trigger={["click"]}
        >
          <ChevronIcon
            style={{
              transform: dropdownVisible ? "rotate(180deg)" : "none",
            }}
          />
        </Dropdown>
      </div>
    </div>
  );
};
const modules = {
  toolbar: {
    container: [
      ["bold", "italic"],
      [{ header: 1 }, { header: 2 }],
      ["blockquote"],
      [{ list: "ordered" }, { list: "bullet" }],
    ],
  },
};
const EditEventModal = ({ visible, onClose }) => {
  const { tenantMembers } = useAppSelector((state) => state.tenant);
  const { selectedEvent } = useAppSelector((state) => state.dashboard);
  const { user } = useAppSelector((state) => state.userDetails);
  const { createEventModal, myCalendarTimelineSelected, currentDate } =
    useAppSelector((state) => state.dashboard);
  const filterKeys = ["firstname", "lastname", "email"];
  const { search, setSearch, filteredData } = useSearch(
    tenantMembers,
    filterKeys
  );
  const [selectedColor, setSelectedColor] = useState(0);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [selectedNotification, setSelectedNotification] = useState(10);
  const [location, setLocation] = useState("");
  const [recurrence, setRecurrence] = useState("");
  const [eventTitle, setEventTitle] = useState("");
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedStartTime, setSelectedStartTime] = useState(null);
  const [selectedEndTime, setSelectedEndTime] = useState(null);
  const [isGoogleMeet, setIsGoogleMeet] = useState(false);
  const [notes, setNotes] = useState("");
  const [eventId, setEventId] = useState("");
  const [attendees, setAttendees] = useState([]);
  const [isAllDay, setIsAllDay] = useState(false);
  const [originalRecurrence, setOriginalRecurrence] = useState("");
  const dispatch = useAppDispatch();
  const quillRef = useRef(null);
  useEffect(() => {
    if (visible) {
      setAttendees((prevAttendees) => [
        ...prevAttendees,
        {
          email: user.email,
          organizer: true,
        },
      ]);
      const editor = quillRef.current;
      if (editor) {
        editor.setText("");
      }
      setSelectedDate(createEventModal.date);
      setSelectedStartTime(createEventModal.startTime);
      setSelectedEndTime(createEventModal.endTime);
    }
  }, [visible]);
  useEffect(() => {
    if (
      visible &&
      selectedEvent &&
      Object.keys(selectedEvent).some((key) => selectedEvent[key] !== "")
    ) {
      setEventTitle(selectedEvent.summary || "");
      setSelectedDate(dayjs(selectedEvent.start.dateTime));
      setSelectedStartTime(dayjs(selectedEvent.start.dateTime));
      setSelectedEndTime(dayjs(selectedEvent.end.dateTime));
      setLocation(selectedEvent.location || "");
      setNotes(selectedEvent.description || "");
      setEventId(selectedEvent.id || "");
      setSelectedColor(+selectedEvent.selectedColor || 0);
      setSelectedNotification(getSelectedNotification(selectedEvent.reminders));
      setIsGoogleMeet(!!selectedEvent.hangoutLink);
      setAttendees(selectedEvent.attendees || []);
      if (selectedEvent.start.date && selectedEvent.end.date) {
        setIsAllDay(true);
      }
      fetchRecurrence();
    }
  }, [visible, selectedEvent]);
  const fetchRecurrence = async () => {
    if (selectedEvent.recurringEventId) {
      try {
        const response = await dispatch(
          getEventById(selectedEvent.recurringEventId)
        );
        const recurrenceArray = response.payload.recurrence;
        if (Array.isArray(recurrenceArray) && recurrenceArray.length > 0) {
          const originalRecurrence = recurrenceArray[0];
          setRecurrence(originalRecurrence);
          setOriginalRecurrence(originalRecurrence);
          console.log(originalRecurrence, "originalRecurrence");
        }
      } catch (error) {
        console.error("Error fetching recurrence:", error);
      }
    }
  };
  const getSelectedNotification = (reminders) => {
    if (!reminders || reminders.useDefault) {
      return 10;
    } else {
      const overridePopup = reminders.overrides.find(
        (override) => override.method === "popup"
      );
      return overridePopup ? overridePopup.minutes : 10;
    }
  };
  const handleLocationChange = (location) => {
    setLocation(location);
  };
  const handleRecurrenceChange = (recurrence) => {
    setRecurrence(recurrence);
  };
  const handleColorSelect = (colorNumber) => {
    setSelectedColor(colorNumber);
  };
  const handleDropDownVisibleChange = (visible) => {
    setDropdownVisible(visible);
  };
  const handleRemoveAttendee = (attendeeToRemove) => {
    setAttendees((prevAttendees) =>
      prevAttendees.filter(
        (attendee) => attendee.email !== attendeeToRemove.email
      )
    );
  };
  const notifyContent = [
    {
      key: "5",
      label: "5 minutes before the meeting",
      onClick: () => setSelectedNotification(5),
    },
    {
      key: "10",
      label: "10 minutes before the meeting",
      onClick: () => setSelectedNotification(10),
    },
    {
      key: "15",
      label: "15 minutes before the meeting",
      onClick: () => setSelectedNotification(15),
    },
    {
      key: "30",
      label: "30 minutes before the meeting",
      onClick: () => setSelectedNotification(30),
    },
    {
      key: "60",
      label: "1 hour before the meeting",
      onClick: () => setSelectedNotification(60),
    },
    {
      key: "1440",
      label: "1 day before the meeting",
      onClick: () => setSelectedNotification(1440),
    },
  ];
  const handleMarkAsOptional = (attendeeToMark) => {
    const attendeeIndex = attendees.findIndex(
      (attendee) => attendee.email === attendeeToMark.email
    );
    if (attendeeIndex !== -1) {
      const updatedAttendees = [...attendees];
      const attendeeToUpdate = updatedAttendees[attendeeIndex];
      if (attendeeToUpdate.optional === undefined) {
        updatedAttendees[attendeeIndex] = {
          ...attendeeToUpdate,
          optional: true,
        };
      } else {
        updatedAttendees[attendeeIndex] = {
          ...attendeeToUpdate,
          optional: !attendeeToUpdate.optional,
        };
      }
      setAttendees(updatedAttendees);
    }
  };
  const selectedAttendees = attendees.map((attendee) => (
    <AttendeeCard
      key={attendee.email}
      attendee={attendee}
      onRemoveAttendee={handleRemoveAttendee}
      onMarkAsOptional={handleMarkAsOptional}
    />
  ));
  // Create the users dropdown options
  const usersDropdown = filteredData.length
    ? filteredData.map((user, index) => ({
        key: index.toString(),
        label: `${user.firstname} ${user.lastname}`,
        profilePicture: user.profilePicture,
        firstname: user.firstname,
        lastname: user.lastname,
        external: false,
        onClick: () => {
          // Check if the user is already present in the attendees list
          const userExists = attendees.some(
            (attendee) => attendee.email === user.email
          );
          // If the user is not already present, add them to attendees with default responseStatus
          if (!userExists) {
            setAttendees((prevAttendees) => [
              ...prevAttendees,
              {
                email: user.email,
                organizer: false,
              },
            ]);
          }
        },
      }))
    : [
        {
          key: "search",
          label: `${search}`,
          firstname: search,
          lastname: "",
          profilePicture: "",
          external: true,
          onClick: () => {
            // Check if the search value is already present in the attendees list
            const searchExists = attendees.some(
              (attendee) => attendee.email === search
            );
            // If the search value is not already present, add it to attendees with default responseStatus
            if (!searchExists) {
              setAttendees((prevAttendees) => [
                ...prevAttendees,
                {
                  email: search,
                  organizer: false,
                },
              ]);
            }
          },
        },
      ];
  const formatLabel = (minutes) => {
    if (minutes === "60") {
      return "1 hour";
    } else if (minutes === "1440") {
      return "1 day";
    } else {
      return `${minutes} minutes`;
    }
  };
  const handleSave = async () => {
    // Early return if any required fields are missing
    if (
      !eventTitle ||
      !selectedDate ||
      !selectedStartTime ||
      !selectedEndTime ||
      attendees.length === 0
    ) {
      alert("Input fields empty");
      return;
    }
    // Set the date part to the selected date
    const startTimeWithDate = selectedStartTime.set(
      "date",
      selectedDate.date()
    );
    const endTimeWithDate = selectedEndTime.set("date", selectedDate.date());
    // Convert to UTC
    const startTimeUTC = dayjs.utc(startTimeWithDate).toISOString();
    const endTimeUTC = dayjs.utc(endTimeWithDate).toISOString();
    const attendeesWithUserId = attendees.map((attendee) => {
      const user = tenantMembers.find((user) => user.email === attendee.email);
      return {
        userId: user ? +user.userId : "",
        dbField: attendee,
        external: !user,
      };
    });
    console.log(originalRecurrence, "original = changed", recurrence);
    const isRecurrenceChange = originalRecurrence !== recurrence ? true : false;
    const payload = {
      date: selectedDate?.format("YYYY-MM-DD"),
      eventId,
      startTime: startTimeUTC,
      endTime: endTimeUTC,
      description: notes,
      eventTitle,
      attendees: attendeesWithUserId,
      location,
      recurrence,
      selectedColor,
      selectedNotification,
      isGoogleMeet,
      isAllDay,
      isRecurrenceChange,
    };
    console.log(payload, "payload here");
    await dispatch(updateEventV3(payload));
    const getEvents = async () => {
      try {
        let minTime, maxTime;
        const timeZoneOffsetMinutes = dayjs().utcOffset();
        // For day view
        if (myCalendarTimelineSelected === 0) {
          minTime = dayjs(currentDate)
            .startOf("day")
            .utcOffset(timeZoneOffsetMinutes)
            .toISOString();
          maxTime = dayjs(currentDate)
            .endOf("day")
            .utcOffset(timeZoneOffsetMinutes)
            .toISOString();
        }
        // For week view
        else if (myCalendarTimelineSelected === 1) {
          minTime = dayjs(currentDate)
            .startOf("week")
            .startOf("day")
            .utcOffset(timeZoneOffsetMinutes)
            .toISOString();
          maxTime = dayjs(currentDate)
            .endOf("week")
            .endOf("day")
            .utcOffset(timeZoneOffsetMinutes)
            .toISOString();
        } else if (myCalendarTimelineSelected === 2) {
          const firstDayOfMonth = dayjs(currentDate)
            .clone()
            .startOf("month")
            .startOf("week");
          const lastDayOfMonth = dayjs(currentDate)
            .clone()
            .endOf("month")
            .endOf("week");
          minTime = firstDayOfMonth
            .utcOffset(timeZoneOffsetMinutes)
            .toISOString();
          maxTime = lastDayOfMonth
            .utcOffset(timeZoneOffsetMinutes)
            .toISOString();
        }
        if (myCalendarTimelineSelected !== 3) {
          const { data: events } = await client.get(
            "/integration/calender/get-events",
            {
              params: {
                userId: user.id,
                date: dayjs(currentDate).format("YYYY-MM-DD"),
                timeMin: minTime,
                timeMax: maxTime,
              },
            }
          );
          console.log(events, "these are the events ");
          dispatch(
            updateDashboard({
              key: "events",
              value: events?.events || [],
            })
          );
        }
        dispatch(
          updateDashboard({
            key: "alertPopupModal",
            value: {
              visible: true,
              data: {
                title: `Event Updated`,
                subtitle: `${eventTitle} on ${selectedDate
                  ?.format("DD MMMM")
                  .replace(/(^|\s)\S/g, (firstLetter) =>
                    firstLetter.toUpperCase()
                  )}, ${startTimeWithDate.format("h:mmA")}`,
                description: "",
              },
            },
          })
        );
      } catch (e) {
        console.log(e);
      }
    };
    if (selectedDate) {
      getEvents();
    }
    dispatch(
      updateDashboard({
        key: "eventInfoModal",
        value: false,
      })
    );
    handleCancel();
  };
  const handleCancel = () => {
    // Reset all values back to default
    setEventTitle("");
    setSelectedDate(null);
    setSelectedStartTime(null);
    setSelectedEndTime(null);
    setAttendees([]);
    setLocation("");
    setRecurrence("");
    setSelectedColor(0);
    setSelectedNotification(10);
    setSelectedColor(0);
    setIsAllDay(false);
    onClose();
  };
  return (
    <CustomModal
      visible={visible}
      width="480px"
      onCancel={handleCancel}
      body={
        <div className=" h-[546px]">
          <div className="p-4 flex justify-between">
            <p className="text-zinc-800 out-500-16">New Event</p>
            <button className="w-5 h-5" onClick={handleCancel}>
              <CrossIcon className={"text-gray-500 cursor-pointer"} />
            </button>
          </div>
          <Divider className="m-0" />
          <div className="flex flex-col gap-4 p-4 max-h-[428px] overflow-y-scroll">
            <CustomInputBox
              placeholder="New event title"
              value={eventTitle}
              onChange={(e) => setEventTitle(e.target.value)}
            />
            <DatePicker
              placeholder="Select Date"
              format="dddd, D MMMM" // Format for display
              value={selectedDate}
              onChange={(date) => setSelectedDate(date)}
              suffixIcon={null}
            />
            {!isAllDay && (
              <div className="flex gap-3">
                <div className="flex flex-grow">
                  <TimePicker
                    value={selectedStartTime}
                    onChange={(time) => setSelectedStartTime(time)}
                    format="h:mm a" // Format for display
                    className="border border-slate-200 rounded text-grey-900 out-300-14 w-full"
                    suffixIcon={
                      <ClockCircleOutlined className="text-gray-500 mr-1" />
                    }
                  />
                </div>
                <CalendarRightIcon />
                <div className="flex flex-grow">
                  <TimePicker
                    value={selectedEndTime}
                    onChange={(time) => setSelectedEndTime(time)}
                    format="h:mm a" // Format for display
                    className="border border-slate-200 rounded text-grey-900 out-300-14 w-full"
                    suffixIcon={
                      <ClockCircleOutlined className="text-gray-500 mr-1" />
                    }
                  />
                </div>
              </div>
            )}
            <CustomButton
              text=" Add Google Meet video conferencing"
              width="278px"
              height="30px"
              icon={
                <img
                  src={
                    isGoogleMeet
                      ? "/images/icons/check-white.svg"
                      : "/images/icons/plus.svg"
                  }
                  className="ml-2"
                />
              }
              onClick={() => setIsGoogleMeet(!isGoogleMeet)}
            />
            <MoreInformationAccordion
              recurrence={recurrence}
              location={location}
              onLocationChange={handleLocationChange}
              onRecurrenceChange={handleRecurrenceChange}
              isChecked={isAllDay}
              handleCheckboxChange={setIsAllDay}
            />
            <NotesAccordion initialNotesContent={notes} onSave={setNotes} />
            <div className="flex flex-col gap-2">
              <p className="text-slate-700 out-500-14">Invite People</p>
              {/* Dropdown only when search is not empty */}
              <Dropdown
                menu={{
                  items: usersDropdown,
                }}
                trigger={[]}
                placement="bottom"
                open={search !== ""}
              >
                <CustomSearchBox
                  placeholder="Invite people to the event"
                  search={search}
                  setSearch={setSearch}
                />
              </Dropdown>
            </div>
            <div className="flex flex-col">{selectedAttendees}</div>
            {selectedEvent.organizer.self && selectedColor !== -1 && (
              <div className="flex gap-2">
                {[...Array(6)].map((_, index) => (
                  <div
                    key={index}
                    onClick={() => handleColorSelect(index)}
                    className={`flex items-center justify-center w-5 h-5 rounded-full border-2 ${
                      selectedColor === index
                        ? "border-blue-500"
                        : "border-transparent"
                    } cursor-pointer`}
                  >
                    <CircleIconPrimary colorNumber={index} />
                  </div>
                ))}
              </div>
            )}
            <Dropdown
              menu={{
                items: notifyContent,
              }}
              open={dropdownVisible}
              onOpenChange={handleDropDownVisibleChange}
              placement="top"
              trigger={["click"]}
            >
              <div className="flex gap-3 px-2.5 py-2">
                <CalendarNotificationIcon />
                <p>{formatLabel(selectedNotification)} before the meeting</p>
                <ChevronIcon
                  style={{
                    transform: dropdownVisible ? "rotate(180deg)" : "none",
                  }}
                />
              </div>
            </Dropdown>
          </div>
          <Divider className="m-0" />
          <div className="flex gap-3 justify-end items-center p-4">
            <button
              className="text-grey-500 out-500-14 py-[5px] px-[10px] flex justify-between items-center rounded-[4px]"
              onClick={handleCancel}
            >
              Cancel
            </button>
            <button
              className="bg-primary-600 text-white out-500-14 py-[5px] px-[10px] flex justify-between items-center rounded-[4px]"
              onClick={handleSave}
            >
              Update
            </button>
          </div>
        </div>
      }
    />
  );
};
export default EditEventModal;
