import React, { useEffect, useState } from "react";

import { Checkbox, DatePicker, Popover, Tooltip, message } from "antd";
import CustomBadge from "../../../Core/CommonV2/CustomBadge";
import PriorityFlag from "../../../Core/svgV2/PriorityFlag";
import CustomAvatar from "../../../Core/CommonV2/CustomAvatar";
import DragSource from "./DNDKit/DragSource";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";
import getUsersName from "../../../Core/utils/getUserName";
import { useNavigate, useParams } from "react-router-dom";
import CustomSelect from "../../../Core/CommonV2/CustomSelect";
import { updateSchedule } from "../../../Core/redux/slices/schedule.slice";
import { PlusIcon } from "../../../Core/svgV2/PlusIcon";
import {
  getGoalDetails,
  getGoalsBySprint,
  getTaskActivityLog,
  getTodoList,
  updateGoal,
  updateProgress,
} from "../../../Core/redux/api/scheduleAPI";
import TimeInput from "../Task/TimeInput";
import {
  DEFAULT_LIMIT,
  TASK_ACTIVITY_LOG_ACTIONS,
} from "../../../Core/constants";
import { getPriorityDetails } from "../../../Core/utils/scheduleUtils";
import { CheckIcon } from "../../../Core/svgV2/Check";
import { SelectStatus, TaskInput } from "../Task/TaskReusable";
import usePermission from "../../../Core/hooks/usePermission";
import { updateGlobalKey } from "../../../Core/redux/slices/global.slice";
import { DotsVerticalIcon } from "../../../Core/svgV2/DotsVertical";
import { DeleteScheduleIcon } from "../../../Core/svgV3/DeleteIcon";
import copyTextToClipboard from "../../../Core/utils/clipboard";
import { client } from "../../../Core/utils/axiosClient";
import { LabelSelect, SubtaskButton } from "../MyBoard/TaskReusable";
import dayjs, { Dayjs } from "dayjs";
import { ChevronIcon } from "../../../Core/svgV2/Chevron";
import SubTaskItem, { NewSubTaskItem } from "./SubtaskItem";
import CustomOnboardingDatePicker, {
  CustomOnboardingDateRangePicker,
} from "../../../Core/Common/CustomOnboardingDatePicker";
import CustomInputBox from "../../../Core/CommonV2/CustomInputBox";
import CustomButton from "../../../Core/CommonV2/CustomButton";
import getCurrentUser from "../MyBoard/getCurrentUser";
import { PencilEditIcon } from "../../../Core/svgV3/PencilEditIcon";
import { useQueryClient } from "react-query";
import { updateDashboard } from "../../../Core/redux/slices/dashboard.slice";
import { CustomDatePicker } from "../Modals/ManageLabelsModal";
export const ThreeDots = ({ task }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { taskId, subtaskId, projectId } = useParams();
  const { deleteTaskModal } = useAppSelector((state) => state.myBoard);

  const [open, setOpen] = useState(false);
  const handleDuplicate = async () => {
    try {
      const { data } = await client.post("/schedule/duplicate-goal", {
        id: task.id,
      });

      navigate(`/schedule/${projectId}/task/${data.result.id}`);
    } catch (e) {
      console.log(e);
    }
  };
  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };

  const options = [
    {
      id: 1,
      value: "Copy link",
      onClick: () => {
        copyTextToClipboard(
          process.env.REACT_APP_API_URL +
            `/schedule/${projectId}/task/${task.id}`
        );
        setOpen(false);
      },
    },
    {
      id: 2,
      value: "Duplicate",
      onClick: () => {
        handleDuplicate();
        setOpen(false);
      },
    },
    {
      id: 3,
      value: "Delete",
      icon: <DeleteScheduleIcon />,
      onClick: () => {
        dispatch(
          updateSchedule({
            key: "selectedTasks",
            value: subtaskId ? [subtaskId] : [task.id],
          })
        );

        dispatch(
          updateSchedule({
            key: "selectedTasksSprintId",
            value: task.sprintId ? task.sprintId : null,
          })
        );

        dispatch(
          updateSchedule({
            key: "deleteTaskModal",
            value: {
              ...deleteTaskModal,
              visible: true,
              multiple: false,
              goalId: task.id,
              sprintId: task.sprintId,
            },
          })
        );
        setOpen(false);
      },
    },
  ];

  return (
    <Popover
      getPopupContainer={(parent) => parent?.parentElement}
      content={
        <div className="min-w-[200px] overflow-hidden">
          {options.map((option) => (
            <div
              key={option.id}
              role="button"
              className={`flex items-center gap-x-3 px-3 py-1.5 hover:bg-gray-50 ${
                option.id === 3 && "border-t border-gray-200"
              }`}
              onClick={option.onClick}
            >
              {option.icon}
              <p
                className={`out-300-14 ${
                  option.id === 3 ? "text-error-600" : "text-gray-600 "
                }`}
              >
                {option.value}
              </p>
            </div>
          ))}
        </div>
      }
      arrow={false}
      open={open}
      onOpenChange={handleOpenChange}
      placement="bottomLeft"
      trigger={"click"}
      destroyTooltipOnHide
    >
      <button
        className={taskId || subtaskId ? "" : ` invisible group-hover:visible`}
      >
        <DotsVerticalIcon className="text-gray-500" />
      </button>
    </Popover>
  );
};

const TimeSelect = ({
  estimatedTime,
  visible = false,
  task,
  setTask,
  type = "timeLog",
}) => {
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };
  return (
    <Popover
      getPopupContainer={(parent) => parent?.parentElement}
      content={
        <div className="min-w-[200px]">
          {type === "timeLog" ? (
            <TimeInput
              className=""
              autoFocus
              value={task.timeLogged || ""}
              addNewTimeToPreviousTime
              onChange={(e) => setTask({ ...task, timeLogged: e })}
              onOk={async (newTime: string, totalTime: string) => {
                setOpen(false);
                await dispatch(
                  updateGoal({
                    timeLogged: newTime,
                    goalId: task.id,
                    action: {
                      oldValue: task.timeLogged,
                      newValue: totalTime,
                      actionName: TASK_ACTIVITY_LOG_ACTIONS.TIMELOG_CHANGE,
                    },
                  })
                );
                await dispatch(
                  updateSchedule({ key: "isGoalEdited", value: true })
                );
              }}
            />
          ) : (
            <TimeInput
              className=""
              autoFocus
              value={task.estimatedTime}
              onChange={(e) => setTask({ ...task, estimatedTime: e })}
              onOk={() => setOpen(false)}
            />
          )}
        </div>
      }
      arrow={false}
      open={open}
      onOpenChange={handleOpenChange}
      placement="bottomLeft"
      trigger={"click"}
      destroyTooltipOnHide
    >
      {estimatedTime ? (
        <Tooltip title={type === "timeLog" ? "Log Time" : "Estimated Time"}>
          <img
            className="cursor-pointer "
            src="/images/v2/schedule/hourglass-02.svg"
          />
        </Tooltip>
      ) : (
        <Tooltip title={type === "timeLog" ? "Log Time" : "Estimated Time"}>
          <img
            src="/images/v2/schedule/hourglass-02.svg"
            className={`cursor-pointer`}
          />
        </Tooltip>
      )}
    </Popover>
  );
};

const PrioritySelect = ({ visible = false, task, setTask }) => {
  const priority = task.priorityId ? +task.priorityId : 1;

  const [open, setOpen] = useState(false);

  // const priorityData = getPriorityDetails(priority);

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };

  const getLable = (priority, bold = false) => {
    const { color, priorityName } = getPriorityDetails(priority);
    return (
      <div className="flex items-center ">
        <PriorityFlag
          style={{
            color,
          }}
        />
        <p
          className="out-300-14 ml-2"
          style={{ color, fontWeight: bold ? 500 : 300 }}
        >
          {priorityName}
        </p>
      </div>
    );
  };

  const option = [
    {
      id: 1,
      value: 1,
      label: "Low",
      render: () => getLable(1),
    },
    {
      id: 2,
      value: 2,
      label: "Medium",
      render: () => getLable(2),
    },
    {
      id: 3,
      value: 3,
      label: "High",
      render: () => getLable(3),
    },
    {
      id: 4,
      value: 4,
      label: "Urgent",
      render: () => getLable(4),
    },
  ];

  return (
    <Popover
      getPopupContainer={(parent) => parent?.parentElement}
      content={
        <div className="min-w-[200px]">
          <CustomSelect
            placeholder="Select Priority"
            options={option}
            defaultOpen
            showSearch
            onChange={(e) => {
              setOpen(false);
              setTask(e);
            }}
          />
        </div>
      }
      arrow={false}
      open={open}
      onOpenChange={handleOpenChange}
      placement="bottomLeft"
      trigger={"click"}
      destroyTooltipOnHide
    >
      <button>
        {(priority || visible) && (
          <PriorityFlag priorityId={task.priorityId ? +task.priorityId : 1} />
          // <PriorityFlag
          //   style={{ color: priorityData.color }}
          //   height="20"
          //   width="20"
          // />
        )}
      </button>
    </Popover>
  );
};

export const TaskTypeItem = ({ taskDetails, checked = false, onClick }) => {
  return (
    <div
      onClick={onClick}
      className="flex cursor-pointer py-2.5 px-3.5 hover:bg-gray-50 rounded-t-lg justify-between"
    >
      <div className="flex  items-center">
        <img src={taskDetails.src} />
        <span className="out-300-14 text-gray-700 ml-2">
          {" "}
          {taskDetails.taskName}
        </span>
      </div>
      {checked && <CheckIcon className="text-primary-600" />}
    </div>
  );
};
export const TaskType = ({
  onTaskTypeChange,
  taskType = 1,
  isNewGoal = false,
}) => {
  const [selectedType, setSelectedType] = useState(isNewGoal ? 4 : taskType);
  const [open, setOpen] = useState(false);
  const getTaskDetails = (taskType) => {
    switch (+taskType) {
      case 1:
        return { src: "/images/v2/schedule/Task.svg", taskName: "Task" };
      case 2:
        return { src: "/images/v2/schedule/meet.svg", taskName: "Event" };
      case 3:
        return { src: "/images/v2/schedule/Bug.svg", taskName: "Bug" };
      case 4:
        return { src: "/images/v2/schedule/Subtask.svg", taskName: "Subtask" };
      default:
        return {
          src: "/images/v2/schedule/Task.svg",
          taskName: "Task",
        };
    }
  };
  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };
  const { taskName, src } = getTaskDetails(selectedType);

  return (
    <Popover
      arrow={false}
      open={open}
      placement="bottomLeft"
      content={
        <div className="w-[200px] bg-white shadow-lg rounded-lg ">
          <TaskTypeItem
            taskDetails={getTaskDetails(1)}
            checked={selectedType === 1}
            onClick={async () => {
              await onTaskTypeChange(1);
              setSelectedType(1);
              setOpen(false);
            }}
          />
          <TaskTypeItem
            taskDetails={getTaskDetails(3)}
            checked={selectedType === 3}
            onClick={async () => {
              await onTaskTypeChange(3);
              setSelectedType(3);
              setOpen(false);
            }}
          />
          {/* cannot change main task to subtask */}
          {/* <TaskTypeItem
            taskDetails={getTaskDetails(4)}
            checked={selectedType === 4}
            onClick={async () => {
              await onTaskTypeChange(4);
              setSelectedType(4);
              setOpen(false);
            }}
          /> */}
        </div>
      }
      onOpenChange={handleOpenChange}
      trigger={["click"]}
    >
      <button
        className="flex items-center cursor-pointer"
        onClick={() => setOpen(true)}
      >
        <Tooltip title="Select Type">
          <img src={src} className="h-[20px] w-[20px]" />
        </Tooltip>
      </button>
    </Popover>
  );
};
export const CollaboratorSelect = ({
  task,
  setTask = (e) => console.log(""),
}) => {
  const dispatch = useAppDispatch();

  const { userList } = useAppSelector((state) => state.overview);
  const { collaboratorsForTask } = useAppSelector((state) => state.schedule);

  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState("");

  const handleMultiSelect = (current) => {
    dispatch(
      updateSchedule({
        key: "collaboratorsForTask",
        value: collaboratorsForTask.includes(current.userId)
          ? collaboratorsForTask.filter((item) => item != current.userId)
          : [...collaboratorsForTask, current.userId],
      })
    );
  };

  const onClose = () => {
    setOpen(false);
    dispatch(
      updateSchedule({
        key: "collaboratorsForTask",
        value: [],
      })
    );
  };
  const onRequest = async () => {
    setOpen(false);
    if (task.id) {
      await dispatch(
        updateGoal({
          goalId: task.id,
          collaborators: collaboratorsForTask,
        })
      );
      dispatch(getGoalDetails({ goalId: task.id }));
      dispatch(
        updateDashboard({
          key: "alertPopupModal",
          value: {
            visible: true,
            data: {
              title: `Added New Collaborators`,
              subtitle: `New collaborators were added for task`,
              description: `${task.name}`,
            },
          },
        })
      );
    } else {
      setTask(collaboratorsForTask);
    }
    dispatch(
      updateSchedule({
        key: "collaboratorsForTask",
        value: [],
      })
    );
  };

  return (
    <Popover
      getPopupContainer={(parent) =>
        parent?.parentElement?.parentElement?.parentElement?.parentElement
      }
      content={
        <div className="min-w-[200px] bg-white rounded-lg">
          <CustomInputBox
            className="px-3 pt-2"
            placeholder="Search for a name"
            value={search}
            onChange={(event) => setSearch(event.target.value)}
            autoFocus
          />

          <div className="overflow-y-scroll h-[200px]">
            <p className="px-3 pt-1.5 text-gray-500 out-300-14">
              Select 1 or multiple members
            </p>
            {userList
              ?.filter(
                (current) =>
                  collaboratorsForTask?.includes(current.userId) ||
                  task?.collaborators?.some((c) => c === current.userId)
              )
              .filter(
                (u) =>
                  u.firstname.toLowerCase().includes(search.toLowerCase()) ||
                  u.lastname.toLowerCase().includes(search.toLowerCase())
              )
              .map((current, index) => (
                <div
                  role="button"
                  key={`${current.userId}${index}`}
                  className="flex items-center gap-x-3 mt-1.5 px-3"
                  onClick={() => handleMultiSelect(current)}
                >
                  <Checkbox
                    checked={
                      collaboratorsForTask?.includes(current.userId) ||
                      task?.collaborators?.some((c) => c === current.userId)
                    }
                  />
                  <CustomAvatar
                    src={current.profilePicture}
                    size={24}
                    title={getUsersName(current)}
                    whiteText
                    color={current.profilePictureColorCode}
                  />

                  <p className="text-gray-900 capitalize out-300-12">
                    {getUsersName(current)}
                  </p>
                </div>
              ))}
            {userList
              ?.filter(
                (current) =>
                  !collaboratorsForTask?.includes(current.userId) &&
                  !task?.collaborators?.some((c) => c === current.userId)
              )
              .filter(
                (u) =>
                  u.firstname.toLowerCase().includes(search.toLowerCase()) ||
                  u.lastname.toLowerCase().includes(search.toLowerCase())
              )
              .map((current, index) => (
                <div
                  role="button"
                  key={`${current.userId}${index}`}
                  className="flex items-center gap-x-3 mt-1.5 px-3"
                  onClick={() => handleMultiSelect(current)}
                >
                  <Checkbox
                    checked={
                      collaboratorsForTask?.includes(current.userId) ||
                      task?.collaborators?.some((c) => c === current.userId)
                    }
                  />
                  <CustomAvatar
                    src={current.profilePicture}
                    size={24}
                    title={getUsersName(current)}
                    whiteText
                    color={current.profilePictureColorCode}
                  />

                  <p className="text-gray-900 capitalize out-300-12">
                    {getUsersName(current)}
                  </p>
                </div>
              ))}
          </div>

          <div className="z-10 flex items-center justify-end w-full p-3 bg-white rounded-b-lg">
            <CustomButton
              text="Cancel"
              className="text-gray-500 bg-transparent"
              height="30px"
              width="63px"
              onClick={onClose}
            />
            <CustomButton
              text="Request"
              height="30px"
              width="71px"
              className="bg-transparent text-primary-600"
              onClick={onRequest}
            />
          </div>
        </div>
      }
      arrow={false}
      open={open}
      onOpenChange={(open) => setOpen(open)}
      placement="bottomLeft"
      trigger={"click"}
    >
      {task && getCurrentUser({ task })}
    </Popover>
  );
};

const TaskItem = ({
  selected,
  handleSelect,
  taskId,
  selectedItems,
  task,
  labelOptions,
  handleMultipleSelect,
  isBacklog,
  showSubtaskKanbanBoard,
  setShowSubtaskKanbanBoard,
  offset,
}: {
  selected?: boolean;
  isBacklog?: boolean;
  handleSelect?: (id) => void;
  taskId: string | number;
  selectedItems?: any;
  task: any;
  labelOptions: any;
  handleMultipleSelect?: any;
  showSubtaskKanbanBoard?: boolean;
  setShowSubtaskKanbanBoard?: any;
  offset?: number;
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { projectId, sprintId } = useParams();
  const [disabled, setDisabled] = useState(false);
  const {
    sprints,
    sprintKanbanView,
    showSubtaskView,
    selectedTasksSprintId,
    goals,
    todoList,
  } = useAppSelector((state) => state.schedule);
  const [showRangePicker, setShowRangePicker] = useState(false);
  const [newSubtask, setNewSubtask] = useState(false);

  const [taskDetails, setTaskDetails] = useState(task);
  const [dateRange, setDateRange] = useState(
    taskDetails?.startDate && taskDetails?.endDate
      ? [dayjs(taskDetails.startDate), dayjs(taskDetails.endDate)]
      : null
  );

  const { hasPermission: TASK_EVENT_SCOPE_EDIT } = usePermission("9");
  const hasEditAccess = TASK_EVENT_SCOPE_EDIT || taskId === "new";

  const [editTaskName, setEditTaskName] = useState(task.name);
  const [showSubtasks, setShowSubTasks] = useState(false);

  const [editedTaskName, setEditedTaskName] = useState(task.name);
  const [titleEdit, setTitleEdit] = useState(false);
  const queryClient: any = useQueryClient();

  useEffect(() => setEditTaskName(task.name), [task]);
  useEffect(() => {
    setTaskDetails(task);
  }, [goals, todoList]);
  const handleDateChange = async (dates) => {
    if (dates[0] && dates[1]) {
      const formattedStartDate = dayjs(dates[0]).format(
        "YYYY-MM-DD HH:mm:ss.SSS"
      );
      const formattedEndDate = dayjs(dates[1]).format(
        "YYYY-MM-DD HH:mm:ss.SSS"
      );

      await setTaskDetails({
        ...taskDetails,
        startDate: formattedStartDate,
        endDate: formattedEndDate,
      });
      await dispatch(
        updateGoal({
          startDate: formattedStartDate,
          endDate: formattedEndDate,
          goalId: task.id,
          action: {
            oldValue: task.timeLogged,
            newValue: dates,
            actionName: TASK_ACTIVITY_LOG_ACTIONS.TIMELOG_CHANGE,
          },
        })
      );
      if (!taskDetails.sprintId) {
        dispatch(
          getTodoList({ limit: DEFAULT_LIMIT, offset: offset, projectId })
        );
      }
    }
  };

  const combineCollaborators = (taskDetails) => {
    if (!taskDetails) return [];

    const { assigneeId, collaborators } = taskDetails;
    const combined = [];

    if (assigneeId) {
      combined.push(assigneeId);
    }

    if (collaborators && Array.isArray(collaborators)) {
      combined.push(...collaborators);
    }

    return combined;
  };

  const formattedPlaceholder =
    taskDetails?.startDate && taskDetails?.endDate
      ? `${dayjs(taskDetails.startDate).format("DD MMM")} - ${dayjs(
          taskDetails.endDate
        ).format("DD MMM")}`
      : "Not Set";

  return (
    <DragSource
      key={taskId}
      id={taskId}
      task={task}
      disabled={disabled}
      selectedItems={selectedItems}
      className={`${
        showSubtaskView && !isBacklog && sprintKanbanView ? "" : "  "
      } overflow-hidden first:rounded-t-lg flex flex-col`}
    >
      <div
        className={`flex group items-center gap-x-3  px-3 py-2  ${
          (selected &&
            !isBacklog &&
            +selectedTasksSprintId === +task?.sprintId) ||
          (selected && isBacklog) ||
          showSubtasks ||
          (!isBacklog && sprintId && sprintKanbanView && showSubtaskView)
            ? "bg-primary-100"
            : "bg-white"
        }  ${
          sprintKanbanView &&
          showSubtaskView &&
          !isBacklog &&
          sprintId &&
          +selectedTasksSprintId === +task?.sprintId
            ? "rounded-t-lg"
            : "border border-gray-200 "
        } ${
          (selected &&
            !isBacklog &&
            +selectedTasksSprintId === +task?.sprintId) ||
          (selected && isBacklog)
            ? "border border-sky-200"
            : ""
        }`}
        onClick={(e) =>
          showSubtaskView && sprintKanbanView && !isBacklog && sprintId
            ? console.log("")
            : handleMultipleSelect(e, taskDetails?.id)
        }
      >
        {taskDetails?.subtasks?.length > 0 ? (
          <button
            className={` ${
              sprintKanbanView && showSubtaskView && sprintId
                ? showSubtaskKanbanBoard
                  ? ""
                  : "-rotate-90"
                : showSubtasks
                ? "-rotate-180"
                : "-rotate-90"
            } `}
            onClick={() =>
              sprintKanbanView && showSubtaskView && sprintId
                ? setShowSubtaskKanbanBoard(!showSubtaskKanbanBoard)
                : setShowSubTasks(!showSubtasks)
            }
          >
            <ChevronIcon className="text-gray-500" />
          </button>
        ) : (
          <div className="w-5 h-5" />
        )}

        <button data-no-dnd="true" className="flex gap-x-1.5 items-center">
          <TaskType
            isNewGoal={task.isNewGoal}
            taskType={+task.goalType}
            onTaskTypeChange={async (type) => {
              const updateObj: {
                goalType?: number | null;
                goalId?: string | null;
                isNewGoal?: number | null;
              } = {
                goalType: type === 4 ? 1 : type,
                goalId: task.id,
              };
              if (type === 4) {
                updateObj.isNewGoal = Number(type === 4);
              } else {
                updateObj.isNewGoal = 0;
              }
              // setTask({ ...task, ...updateObj });
              await dispatch(updateGoal(updateObj));
              await dispatch(
                updateSchedule({ key: "isGoalEdited", value: true })
              );
              if (task.sprintId) {
                dispatch(
                  getGoalsBySprint({
                    projectId,
                    sprintId: task.sprintId,
                    limit: DEFAULT_LIMIT,
                    offset: offset,
                  })
                );
              } else {
                // dispatch(getTodoList({ projectId }));
              }
            }}
          />
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="8"
            height="6"
            viewBox="0 0 8 6"
            fill="none"
          >
            <path d="M1 1.5L4 4.5L7 1.5" fill="#667085" />
            <path
              d="M1 1.5L4 4.5L7 1.5H1Z"
              stroke="#667085"
              strokeWidth="1.67"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>

        <p
          className=" text-[10px] font-normal font-['Outfit'] text-gray-500 hover:underline cursor-pointer"
          role="button"
          onClick={async () => {
            localStorage.removeItem("taskRedirectUrl");
            localStorage.setItem("taskRedirectUrl", location.pathname);
            dispatch(updateGlobalKey({ key: "loading", value: true }));
            await dispatch(getGoalDetails({ goalId: task.id })).then(() =>
              dispatch(updateGlobalKey({ key: "loading", value: false }))
            );

            navigate(`/schedule/${projectId}/task/${task.id}`);
          }}
        >
          {task.identifier ?? task.id}{" "}
        </p>
        {titleEdit ? (
          <div className="flex-1   ">
            {" "}
            <TaskInput
              autoSize
              value={editTaskName}
              placeholder="Enter a title"
              plannerScreen
              autoFocus={true}
              type="planner"
              onSave={async (e, save) => {
                if (save) {
                  e.preventDefault();
                  setTaskDetails({ ...taskDetails, name: e.target.value });
                  setEditedTaskName(e.target.value);
                  await dispatch(
                    updateGoal({
                      name: e.target.value,
                      goalId: task.id,
                    })
                  );
                  await dispatch(
                    updateSchedule({ key: "isGoalEdited", value: true })
                  );
                  setTitleEdit(false);

                  if (task.sprintId) {
                    dispatch(
                      getGoalsBySprint({
                        projectId,
                        sprintId: task.sprintId,
                        limit: DEFAULT_LIMIT,
                        offset: offset,
                      })
                    );
                    queryClient.invalidateQueries([
                      "goalsBySprint",
                      projectId,
                      +task.sprintId,
                    ]);
                  } else {
                    // dispatch(getTodoList({ projectId }));
                    queryClient.invalidateQueries(["todoList", projectId]);
                  }
                } else {
                  setTitleEdit(false);
                }
              }}
              onChange={async (e, plannerScreen = false) => {
                if (!TASK_EVENT_SCOPE_EDIT) return;
                setEditTaskName(e.target.value);

                plannerScreen
                  ? console.log("")
                  : await dispatch(
                      updateGoal({
                        name: e.target.value,
                        goalId: task.id,
                      })
                    );
                plannerScreen
                  ? console.log("")
                  : await dispatch(
                      updateSchedule({ key: "isGoalEdited", value: true })
                    );
              }}
            />
          </div>
        ) : (
          <p
            role="button"
            onDoubleClick={async (e) => {
              e.stopPropagation();
              dispatch(updateGlobalKey({ key: "loading", value: true }));
              localStorage.removeItem("taskRedirectUrl");
              localStorage.setItem("taskRedirectUrl", location.pathname);

              await dispatch(getGoalDetails({ goalId: task.id })).then(() =>
                dispatch(updateGlobalKey({ key: "loading", value: false }))
              );

              navigate(`/schedule/${projectId}/task/${task.id}`);
              if (handleSelect) {
                handleSelect(task.id);
              }
            }}
            className="out-300-14 text-slate-700 flex-1 truncate"
            id="currentInputBoxMaxWidth"
          >
            <span
              onClick={() => {
                setTitleEdit(true);
              }}
              className="inline-block rounded-[4px] p-1"
            >
              <div className="flex gap-0.5 items-center group">
                <Tooltip title={taskDetails.name} placement="topLeft">
                  <p className="text-slate-700 overflow-hidden whitespace-nowrap overflow-ellipsis">
                    {taskDetails.name}
                  </p>
                </Tooltip>
                <span className="opacity-0 group-hover:opacity-100">
                  <PencilEditIcon />
                </span>
              </div>
            </span>
          </p>
        )}
        <TimeSelect
          estimatedTime={task.timeLogged}
          task={taskDetails}
          setTask={setTaskDetails}
          type="timeLog"
          visible={false}
        />
        <LabelSelect
          visible={true}
          task={taskDetails}
          labelOptions={labelOptions}
          setTask={async (e) => {
            setTaskDetails({ ...taskDetails, labelId: e });

            await dispatch(
              updateGoal({
                labelId: e,
                goalId: task.id,
                action: {
                  oldValue: task.labelId,
                  newValue: e,
                  actionName: TASK_ACTIVITY_LOG_ACTIONS.LABEL_CHANGE,
                },
              })
            );
            await dispatch(
              updateSchedule({ key: "isGoalEdited", value: true })
            );
            if (task.sprintId) {
              dispatch(
                getGoalsBySprint({
                  projectId,
                  sprintId: task.sprintId,
                  limit: DEFAULT_LIMIT,
                  offset: offset,
                })
              );
            } else {
              // dispatch(getTodoList({ projectId }));
              await dispatch(
                getTodoList({ limit: DEFAULT_LIMIT, offset: offset, projectId })
              );
            }
          }}
        />
        <SelectStatus
          setStatusId={async (e) => {
            if (!hasEditAccess) return;

            if (taskDetails.sprintId) {
              const currentSprint = sprints.find(
                (sprint) => +sprint.id == +taskDetails.sprintId
              );

              if (currentSprint && currentSprint.status == 3) {
                message.error({
                  content: (
                    <span className="out-400-14 text-gray-900 cursor-pointer">
                      Please Start the Sprint first!
                    </span>
                  ),
                  className: "custom-message",
                  style: {
                    borderRadius: "10px",
                  },
                });

                return;
              }
              if (currentSprint && currentSprint.status < 2) {
                message.error({
                  content: (
                    <span className="out-400-14 text-gray-900 cursor-pointer">
                      Please Start the Sprint first!
                    </span>
                  ),
                  className: "custom-message",
                  style: {
                    borderRadius: "10px",
                  },
                });

                return;
              }
            }

            setTaskDetails((oldState) => {
              const newState = {
                ...oldState,
                statusId: e.statusId,
                swimlaneId: e.swimlaneId,
              };
              return newState;
            });
            if (taskId !== "new") {
              await dispatch(
                updateGoal({
                  ...e,
                  goalId: taskId,
                  action: {
                    oldValue: task?.swimlaneId,
                    newValue: e.swimlaneId,
                    actionName: TASK_ACTIVITY_LOG_ACTIONS.STATUS_CHANGED,
                  },
                })
              );
              await dispatch(
                updateSchedule({ key: "isGoalEdited", value: true })
              );
              dispatch(getTaskActivityLog({ goalId: taskId.toString() }));
              if (e.statusId == 3) {
                dispatch(
                  updateProgress({
                    projectId,
                  })
                );
              }
            }
          }}
          statusId={taskDetails.statusId}
          task={taskDetails}
        />
        {+task.goalType !== 3 && (
          <Tooltip
            className="cursor-default"
            title="Duration"
            placement="bottom"
          >
            <div>
              <div
                className="text-black out-300-12"
                onClick={() => {
                  setShowRangePicker(!showRangePicker);
                }}
              >
                <CustomDatePicker
                  value={dateRange}
                  onChange={handleDateChange}
                  className=""
                  placeholder={formattedPlaceholder}
                />
              </div>
            </div>
          </Tooltip>
        )}

        <PrioritySelect
          task={taskDetails}
          setTask={async (e) => {
            setTaskDetails({ ...taskDetails, priorityId: e });

            await dispatch(
              updateGoal({
                priorityId: e,
                goalId: task.id,
                action: {
                  oldValue: task.priorityId,
                  newValue: e,
                  actionName: TASK_ACTIVITY_LOG_ACTIONS.PRIORITY_CHANGE,
                },
              })
            );
            await dispatch(
              updateSchedule({ key: "isGoalEdited", value: true })
            );
            if (task.sprintId) {
              console.log(offset, "offset here");
              dispatch(
                getGoalsBySprint({
                  projectId,
                  sprintId: task.sprintId,
                  limit: DEFAULT_LIMIT,
                  offset: offset,
                })
              );
            }
          }}
        />
        <SubtaskButton
          subTaskLength={taskDetails?.subtasks?.length}
          handleClick={() => {
            setNewSubtask(!newSubtask);
          }}
        />

        <CollaboratorSelect task={taskDetails} />

        <ThreeDots task={taskDetails} />
      </div>
      {(showSubtasks && (!sprintKanbanView || isBacklog || !sprintId)) ||
      newSubtask ? (
        <div>
          <NewSubTaskItem
            parentGoalId={task.id}
            setNewSubtask={(val) => setNewSubtask(val)}
          />
        </div>
      ) : null}
      {showSubtasks && (!sprintKanbanView || isBacklog || !sprintId) ? (
        <div>
          {task?.subtasks?.map((subtask) => (
            <SubTaskItem key={subtask.id} subtask={subtask} />
          ))}
        </div>
      ) : null}
    </DragSource>
  );
};

export default TaskItem;
