import React from "react";
import { labelColors } from "../../../Core/constants";
import { Popover } from "antd";

const LabelColorPicker = ({ selectedLabel, setSelectedLabel }) => {
  const handleSelect = (current) => {
    setSelectedLabel(current);
  };

  return (
    <Popover
      content={
        <div className="px-2 pt-1.5 pb-1 bg-white border border-gray-100 rounded-lg">
          <p className="text-gray-700 capitalize out-500-12">
            Choose label colour
          </p>
          <div className="grid grid-cols-7 gap-2 py-2">
            {labelColors.map((label) => (
              <div
                className={`rounded-full p-0.5 flex items-center justify-center ${
                  selectedLabel && selectedLabel.id === label.id
                    ? "border border-primary-300"
                    : "border border-transparent"
                }`}
                key={label.id}
                onClick={() => handleSelect(label)}
              >
                <button
                  className="w-5 h-5 rounded-full"
                  style={{
                    backgroundColor: label.baseColor,
                  }}
                >
                  <p className="text-center" style={{ color: label.textColor }}>
                    T
                  </p>
                </button>
              </div>
            ))}
          </div>
        </div>
      }
    >
      <button className="w-[30px] h-[30px] rounded border-[0.5px] border-gray-200 flex items-center justify-center">
        {selectedLabel ? (
          <div
            className="w-3 h-3 rounded-full"
            style={{ backgroundColor: selectedLabel.baseColor }}
          />
        ) : null}
      </button>
    </Popover>
  );
};

export default LabelColorPicker;
