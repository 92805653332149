import { createSlice, current } from "@reduxjs/toolkit";

import {
  createSprint,
  deleteScheduleTasks,
  getAllSprintsByPhase,
  getGoalCount,
  getGoalDetails,
  getGoalsByLabelId,
  getGoalsByPhase,
  getGoalsBySprint,
  getLabels,
  getProjectPhases,
  getReleaseVersions,
  getReportsByDateRange,
  getReportsBySprint,
  getSprintsByPhase,
  getSubtaskDetails,
  getSwimlanes,
  getTaskActivityLog,
  getTodoList,
  updateGoal,
  updatePhase,
  updateSprint,
} from "../api/scheduleAPI";
//We do not recommend the object notation for extraReducers any more, especially with TypeScript you should be using the builder notation --by redux toolkit team.

const initialState = {
  loading: false,
  sprintsLoading: false,
  phaseLoading: false,
  goalsLoading: false,
  newSprintModal: {
    visible: false,
    isNewScope: false,
    scopeDescription: "",
  },
  newSprintScope: false,
  newGoalModal: false,
  newGoalScope: false,
  newScopeModal: false,
  moveSwimlaneModal: false,
  error: {},
  status: "idle",
  phases: [
    {
      id: 0,
      phaseId: "0",
      phaseProgress: 0,
      phases: "",
      startDate: "",
      endDate: "",
      color: "",
      isOngoing: 0,
      isCompleted: 0,
      components: "",
      projectPhaseId: "",
      phaseIteration: "",
    },
  ],
  currentPhaseGoals: [],
  currentSelectedPhase: {
    id: 0,
    phaseId: "0",
    phaseProgress: 0,
    phases: "",
    startDate: "",
    endDate: "",
    color: "",
    isOngoing: 0,
    isCompleted: 0,
  },
  selectedSprint: {
    id: "0",
    name: "",
    endDate: "",
    startDate: "",
    goals: [],
    isActive: 1,
    isCompleted: 0,
    isOngoing: 0,
    status: 1,
    phaseId: 1,
  },
  sprints: [
    {
      id: 0,
      name: "",
      goals: [
        {
          id: "",
          swimlaneId: 0,
          subTasks: [],
          name: "",
          phaseId: "",
          statusId: 0,
        },
      ],
      status: 0,
      isActive: 1,
      isCompleted: 0,
      isOngoing: 0,
      phaseId: 1,
      startDate: null,
      endDate: null,
    },
  ],
  allSprints: [],
  goals: [],

  selectedTask: null,
  selectedBug: null,
  selectedSubTask: null,
  selectedEvent: null,

  // schedule revised
  sprintDetailsModal: false,
  deleteSubTaskModal: false,
  newTaskModal: {
    visible: false,
    isNewScope: false,
    scopeDescription: "",
  },
  newBugModal: false,
  newSubTaskModal: {
    visible: false,
    editTask: false,
    subtask: {
      sprintId: "",
      projectId: "",
      phaseId: "",
      names: [],
      isCompleted: false,
      statusId: "",
      priorityId: "",
      goalType: "",
      assigneeId: "",
      reporterId: "",
      description: "",
      parentGoalId: "",
      goalId: "",
      parentTaskName: "Task",
    },
  },
  newEventModal: false,
  deleteTaskModal: {
    visible: false,
    statusId: 0,
    goalId: 1,
    sprintId: 0,
  },
  deleteBugModal: {
    visible: false,
    statusId: 0,
    goalId: 1,
  },
  deleteStatusModal: false,
  swimlanes: [
    {
      id: 0,
      projectId: 0,
      statusId: 1,
      title: "To Do",
      positionIndex: "0",
    },
  ],

  swimlaneDetails: {
    id: 0,
    projectId: 0,
    statusId: 1,
    title: "To Do",
  },
  endPhaseModal: false,
  deleteSprintModal: {
    visible: false,
    sprintId: "",
  },
  completeSprintModal: {
    visible: false,
    sprintId: "",
    sprint: { id: "", name: "", goals: [], goalCount: "", endDate: "" },
  },
  filterGoalParameter: {
    searchValue: { searchValue: "", projectId: "", module: "" },
    assignee: [], // [{projectId,assigneeId:[]},module:""] ,
    typeDropdown: [],
    sprintIds: [],
    filter: "",
    projectId: "",
  },
  createdSprintId: null,
  startSprintModal: false,
  startPhaseModal: false,
  newSprint: {},
  filters: {
    type: [],
    assignee: [],
    reporter: [],
    phase: [],
    priority: [],
    label: [],
    status: [],
  },
  reportGoals: [],
  duplicateTaskModal: {
    visible: false,
    duplicateTaskDetails: null,
  },

  editSprintModal: {
    visible: false,
    sprint: { id: "", name: "", startDate: "", endDate: "", phaseId: "" },
    mode: "new",
    phaseId: "",
  },

  confirmDatesModal: {
    visible: false,
    sprint: { id: "", name: "", startDate: "", endDate: "", phaseId: "" },
    newValue: "",
    phaseId: "",
  },
  // new

  newLabelModal: false,
  manageLabelModal: false,
  deleteLabelModal: { visible: false, labelId: "" },
  newReleaseVersionModal: false,

  sprintKanbanView: true,
  plannerIsDragActive: { isActive: false, current: null },
  phaseModal: { visible: false, phaseId: "" },
  roadMapZoom: 160,
  roadMapView: "ganttView",
  roadMapPhaseId: 0,

  // Timeline

  timelineView: "GanttView",
  timelineSelected: 2,

  // Schedule Tour
  run: false,
  showTakeTourModal: true,
  currentTourStep: 0,

  labels: [],
  releaseVersions: [],
  taskActivity: [],
  reportsGroupBy: {
    key: "Status",
    value: [],
  },
  releaseNotesView: 1,
  todoList: [],
  todoListCount: 0,
  selectedTasksForDragAndDrop: [],
  newTask: null,
  lastVisitedSchedulePage: {
    projectId: "",
    previousProjectId: "",
    redirectUrl: "",
  },
  scrollData: {
    sprintId: "",
    scrollTop: "",
    scrollTask: "",
  },
  isGoalEdited: false,
  isTaskExpanded: false,
  emptyScreen: false,
  moveSprintsModal: {
    visible: false,
    sprint: "",
    sprintId: "",
    data: {},
  },
  selectedTasks: [],
  selectedTasksSprintId: "",
  selectedTasksSprintIds: [],
  createTaskHeader: false,
  showSubtaskView: false,
  goalsByLabelId: {},
  goalsCount: 0,
  inProgressCount: 0,
  toDoCount: 0,
  projectListModal: false,
  exportModal: false,
  collaboratorsForTask: [],
  showNewTaskCard: { visible: false, channel: { id: "" }, taskId: "" },
  labelGoals: [], //[{id:"",goals:[]}]
  selectedPhases: [],
  onAssigneeChange: {
    visible: false,
    originalAssignee: "",
    updatedAssignee: "",
  },
  isBug: false,
};

export const scheduleSlice = createSlice({
  name: "schedule",
  initialState,
  reducers: {
    addDummyDataForScheduleScreen: (state, action) => {
      const dummyState = action.payload;
      Object.keys(dummyState).forEach((key) => (state[key] = dummyState[key]));
    },
    resetToInitialSchedule: (state, action) => {
      Object.keys(initialState).forEach(
        (key) => (state[key] = initialState[key])
      );
    },
    updateSchedule: (state, action) => {
      state[action.payload.key] = action.payload.value;
    },

    setCurrentSelectedPhase: (state, action) => {
      state.currentSelectedPhase = action.payload;
    },

    setSelectedSprint: (state, action) => {
      state.selectedSprint = action.payload;
    },

    resetSchedule: (state, action) => {
      action.payload.map((ele) => {
        state[ele] = initialState[ele];
      });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getProjectPhases.pending, (state, { payload }) => {
      state.phaseLoading = true;
      state.status = "pending";
    });
    builder.addCase(getProjectPhases.fulfilled, (state, { payload }) => {
      state.phaseLoading = false;
      state.status = "fulfilled";
      state.phases = payload;
      if (payload.length > 0) {
        console.log(payload, "added");
        if (state.currentSelectedPhase) {
          const newPhase = payload.find((phase) => {
            console.log(phase, phase.id, state.currentSelectedPhase);
            return +phase.id === +state.currentSelectedPhase.id;
          });
          console.log(newPhase, "xxxxyyyyyy");
          if (newPhase && newPhase.phaseId)
            state.currentSelectedPhase = newPhase;
        } else {
          state.currentSelectedPhase = payload[0];
        }
      }

      state.error = null;
    });
    builder.addCase(getProjectPhases.rejected, (state, { payload }) => {
      state.phaseLoading = false;
      state.status = "rejected";
      state.error = payload;
    });
    builder.addCase(updatePhase.pending, (state, { payload }) => {
      state.phaseLoading = true;
      state.status = "pending";
    });
    builder.addCase(updatePhase.fulfilled, (state, { payload }) => {
      state.phaseLoading = false;
      state.status = "fulfilled";

      state.error = null;
    });
    builder.addCase(updatePhase.rejected, (state, { payload }) => {
      state.phaseLoading = false;
      state.status = "rejected";
      state.error = payload;
    });

    builder.addCase(getSprintsByPhase.pending, (state, { payload }) => {
      state.sprintsLoading = true;
      state.status = "pending";
    });
    builder.addCase(getSprintsByPhase.fulfilled, (state, { payload }) => {
      state.sprintsLoading = false;
      state.status = "fulfilled";
      // Map new sprints data while preserving old goals
      const mappedSprints = payload.result.map((newSprint) => {
        const oldSprint = state.sprints.find(
          (oldSprint) => oldSprint.id === newSprint.id
        );

        if (oldSprint) {
          // Preserve the goals from the old sprint
          newSprint.goals = oldSprint.goals;
        }

        return newSprint;
      });
      state.sprints = mappedSprints;

      state.error = null;
    });
    builder.addCase(getSprintsByPhase.rejected, (state, { payload }) => {
      state.sprintsLoading = false;
      state.status = "rejected";
      state.error = payload;
    });

    // get todolidt

    builder.addCase(getTodoList.pending, (state, { payload }) => {
      state.sprintsLoading = true;
      state.status = "pending";
    });
    builder.addCase(getTodoList.fulfilled, (state, { payload }) => {
      state.sprintsLoading = false;
      state.status = "fulfilled";
      state.todoList = payload.result;
      state.todoListCount = payload.totalRecords;
      state.error = null;
    });
    builder.addCase(getTodoList.rejected, (state, { payload }) => {
      state.sprintsLoading = false;
      state.status = "rejected";
      state.error = payload;
    });

    // get goals by phase id

    builder.addCase(getGoalsByPhase.pending, (state, { payload }) => {
      state.sprintsLoading = true;
      state.status = "pending";
    });
    builder.addCase(getGoalsByPhase.fulfilled, (state, { payload }) => {
      state.sprintsLoading = false;
      state.status = "fulfilled";
      state.currentPhaseGoals = payload.result;
      state.error = null;
    });
    builder.addCase(getGoalsByPhase.rejected, (state, { payload }) => {
      state.sprintsLoading = false;
      state.status = "rejected";
      state.error = payload;
    });

    // get task activity

    builder.addCase(getTaskActivityLog.pending, (state, { payload }) => {
      state.sprintsLoading = true;
      state.status = "pending";
    });
    builder.addCase(getTaskActivityLog.fulfilled, (state, { payload }) => {
      state.sprintsLoading = false;
      state.status = "fulfilled";
      state.taskActivity = payload.result;
      state.error = null;
    });
    builder.addCase(getTaskActivityLog.rejected, (state, { payload }) => {
      state.sprintsLoading = false;
      state.status = "rejected";
      state.error = payload;
    });

    // get All Labels
    builder.addCase(getLabels.pending, (state, { payload }) => {
      state.status = "pending";
    });
    builder.addCase(getLabels.fulfilled, (state, { payload }) => {
      state.status = "fulfilled";
      state.labels = payload.result;
      state.error = null;
    });
    builder.addCase(getLabels.rejected, (state, { payload }) => {
      state.status = "rejected";
      state.error = payload;
    });

    // get All Release Versions
    builder.addCase(getReleaseVersions.pending, (state, { payload }) => {
      state.status = "pending";
    });
    builder.addCase(getReleaseVersions.fulfilled, (state, { payload }) => {
      state.status = "fulfilled";
      state.releaseVersions = payload.result;
      state.error = null;
    });
    builder.addCase(getReleaseVersions.rejected, (state, { payload }) => {
      state.status = "rejected";
      state.error = payload;
    });

    // get goal details
    builder.addCase(getGoalDetails.pending, (state, { payload }) => {
      state.status = "pending";
    });
    builder.addCase(getGoalDetails.fulfilled, (state, { payload }) => {
      state.status = "fulfilled";
      state.selectedTask = payload.result;
      state.error = null;
    });
    builder.addCase(getGoalDetails.rejected, (state, { payload }) => {
      state.status = "rejected";
      state.error = payload;
    });
    // get subtask details
    builder.addCase(getSubtaskDetails.pending, (state, { payload }) => {
      state.status = "pending";
    });
    builder.addCase(getSubtaskDetails.fulfilled, (state, { payload }) => {
      state.status = "fulfilled";
      state.selectedTask = payload.result;
      state.error = null;
    });
    builder.addCase(getSubtaskDetails.rejected, (state, { payload }) => {
      state.status = "rejected";
      state.error = payload;
    });

    // get All sprints in single section

    builder.addCase(getAllSprintsByPhase.pending, (state, { payload }) => {
      state.sprintsLoading = true;
      state.status = "pending";
    });
    builder.addCase(getAllSprintsByPhase.fulfilled, (state, { payload }) => {
      state.sprintsLoading = false;
      state.status = "fulfilled";
      state.allSprints = [...state.allSprints, ...payload.result];
      state.error = null;
    });
    builder.addCase(getAllSprintsByPhase.rejected, (state, { payload }) => {
      state.sprintsLoading = false;
      state.status = "rejected";
      state.error = payload;
    });

    builder.addCase(getGoalsBySprint.pending, (state, { payload }) => {
      state.sprintsLoading = true;
      state.status = "pending";
    });
    builder.addCase(getGoalsBySprint.fulfilled, (state, { payload }) => {
      console.log(payload, "get goals");

      state.sprintsLoading = false;
      state.status = "fulfilled";
      state.sprints = state.sprints.map((sprint) => {
        if (+sprint?.id === +payload.sprintId) {
          // if (
          //   payload.result.length > 0 &&
          //   +sprint?.id === +payload.result[0]?.sprintId
          // ) {
          //   return { ...sprint, goals: payload.result };
          // }
          return {
            ...sprint,
            goals: payload.result.length === 0 ? [] : payload.result,
          };
        }
        return sprint;
      });
      if (payload.sprint && payload.sprint.length > 0) {
        state.sprints = state.sprints.map((sprint) => {
          // if (
          //   payload.result.length > 0 &&
          //   +sprint?.id === +payload.result[0]?.sprintId
          // ) {
          //   return { ...sprint, goals: payload.result };
          // }
          const filteredGoals = payload.result.filter(
            (goal) => +goal.sprintId === +sprint.id
          );
          return {
            ...sprint,
            goals: payload.result.length === 0 ? [] : filteredGoals,
            goalCount: payload.result.length === 0 ? [] : filteredGoals.length,
          };
        });
      }
      state.goals = payload.result;
      state.goalsCount = payload.totalRecords;
      state.error = null;
      state.newGoalModal = false;
      // return { ...state,goals:payload.result, };
    });
    builder.addCase(getGoalsBySprint.rejected, (state, { payload }) => {
      state.sprintsLoading = false;
      state.status = "rejected";
      state.error = payload;
    });
    builder.addCase(getReportsBySprint.pending, (state, { payload }) => {
      state.sprintsLoading = true;
      state.status = "pending";
    });
    builder.addCase(getReportsBySprint.fulfilled, (state, { payload }) => {
      state.sprintsLoading = false;
      state.status = "fulfilled";
      state.reportGoals = payload.sprintTask;
      //state.phases = payload;
      //  state.
    });
    builder.addCase(getReportsBySprint.rejected, (state, { payload }) => {
      state.sprintsLoading = false;
      state.status = "rejected";
      state.error = payload;
    });
    builder.addCase(getReportsByDateRange.pending, (state, { payload }) => {
      state.sprintsLoading = true;
      state.status = "pending";
    });
    builder.addCase(getReportsByDateRange.fulfilled, (state, { payload }) => {
      state.sprintsLoading = false;
      state.status = "fulfilled";
      state.reportGoals = payload.dateTasks;
      //state.phases = payload;
      //  state.
    });
    builder.addCase(getReportsByDateRange.rejected, (state, { payload }) => {
      state.sprintsLoading = false;
      state.status = "rejected";
      state.error = payload;
    });
    builder.addCase(createSprint.pending, (state, { payload }) => {
      state.sprintsLoading = true;
      state.status = "pending";
    });
    builder.addCase(createSprint.fulfilled, (state, { payload }) => {
      state.sprintsLoading = false;
      state.status = "fulfilled";
      //state.phases = payload;
      state.newSprintScope = false;
      state.newSprintModal = initialState.newSprintModal;
      state.createdSprintId = payload.createdSprint[0].id;
      state.selectedSprint = payload.createdSprint[0];
    });
    builder.addCase(createSprint.rejected, (state, { payload }) => {
      state.sprintsLoading = false;
      state.status = "rejected";
      state.error = payload;
    });

    builder.addCase(getSwimlanes.pending, (state, { payload }) => {
      state.loading = true;
      state.status = "pending";
    });
    builder.addCase(getSwimlanes.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.status = "fulfilled";
      //state.phases = payload;
      state.swimlanes = payload.result;
      state.error = null;
    });
    builder.addCase(getSwimlanes.rejected, (state, { payload }) => {
      state.loading = false;
      state.status = "rejected";
      state.error = payload;
    });

    // update sprint
    builder.addCase(updateSprint.pending, (state, { payload }) => {
      state.loading = true;
      state.status = "pending";
    });
    builder.addCase(updateSprint.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.status = "fulfilled";
      state.error = null;

      const updatedSprint = payload.result[0];

      // Check if the selected sprint needs to be updated
      if (state.selectedSprint?.id == updatedSprint.id) {
        state.selectedSprint = { ...state.selectedSprint, ...updatedSprint };
      }

      // Find the index of the sprint that needs to be updated
      const sprintIndex = state.sprints.findIndex(
        (s) => s.id == updatedSprint.id
      );
      if (sprintIndex !== -1) {
        // Create a new array with the updated sprint
        state.sprints = [
          ...state.sprints.slice(0, sprintIndex),
          { ...state.sprints[sprintIndex], ...updatedSprint },
          ...state.sprints.slice(sprintIndex + 1),
        ];
      }
    });
    builder.addCase(updateSprint.rejected, (state, { payload }) => {
      state.loading = false;
      state.status = "rejected";
      state.error = payload;
    });

    // update goal
    builder.addCase(updateGoal.pending, (state, { payload }) => {
      state.loading = true;
      state.status = "pending";
    });
    builder.addCase(updateGoal.fulfilled, (state, { payload }) => {
      // Update the loading and error states
      state.loading = false;
      state.status = "fulfilled";
      state.error = null;

      // Get the updated goal and its id and parentGoalId
      const updatedGoal = payload.result[0];
      const { id, parentGoalId } = updatedGoal;

      // If the updated goal has a parent goal, find the parent goal in the list
      // and update its subtasks with the updated goal
      if (parentGoalId) {
        state.goals = state.goals.map((goal) => {
          // If the goal is not the parent goal, return it unchanged
          if (goal.id !== parentGoalId) return goal;
          // Find the updated goal in the subtasks and return the updated goal
          const newSubtask = goal?.subTasks
            ? goal?.subTasks?.map((subtask) =>
                subtask.id === id ? updatedGoal : subtask
              )
            : goal?.subtasks?.map((subtask) =>
                subtask.id === id ? updatedGoal : subtask
              );
          // Return the updated parent goal with the updated subtasks
          return { ...goal, subtasks: newSubtask };
        });
      } else {
        // If the updated goal does not have a parent goal, find it in the list
        // and update it with the updated goal
        state.goals = state.goals.map((goal) =>
          goal.id !== id ? goal : { ...goal, ...updatedGoal }
        );
      }
    });

    builder.addCase(updateGoal.rejected, (state, { payload }) => {
      state.loading = false;
      state.status = "rejected";
      state.error = payload;
    });

    builder.addCase(getGoalsByLabelId.pending, (state) => {
      state.goalsLoading = true;
    });
    builder.addCase(getGoalsByLabelId.fulfilled, (state, action) => {
      const { labelId, result } = action.payload;
      if (!state.goalsByLabelId) {
        state.goalsByLabelId = [];
      }
      state.goalsByLabelId[labelId] = [...result] || [];
      state.goalsLoading = false;
    });
    builder.addCase(getGoalsByLabelId.rejected, (state, action) => {
      state.goalsLoading = false;
      if (action.meta.arg.labelId && state.goalsByLabelId) {
        state.goalsByLabelId[action.meta.arg.labelId] = [];
      }
      state.error = action.payload || "Failed to fetch goals by label ID";
    });
    builder.addCase(getGoalCount.pending, (state, { payload }) => {
      state.sprintsLoading = true;
      state.status = "pending";
    });
    builder.addCase(getGoalCount.fulfilled, (state, { payload }) => {
      state.sprintsLoading = false;
      state.status = "fulfilled";
      state.toDoCount = payload.result.toDoCount;
      state.inProgressCount = payload.result.inProgressCount;
      state.error = null;
    });
    builder.addCase(getGoalCount.rejected, (state, { payload }) => {
      state.sprintsLoading = false;
      state.status = "rejected";
      state.error = payload;
    });
    // delete tasks
    builder.addCase(deleteScheduleTasks.pending, (state) => {
      state.sprintsLoading = true;
      state.status = "pending";
    });
    builder.addCase(deleteScheduleTasks.fulfilled, (state) => {
      state.sprintsLoading = false;
      state.status = "fulfilled";
      state.error = null;
    });
    builder.addCase(deleteScheduleTasks.rejected, (state, { payload }) => {
      state.sprintsLoading = false;
      state.status = "rejected";
      state.error = payload;
    });
  },
});

export const {
  updateSchedule,
  setCurrentSelectedPhase,
  resetSchedule,
  setSelectedSprint,
  resetToInitialSchedule,
  addDummyDataForScheduleScreen,
} = scheduleSlice.actions;

export default scheduleSlice.reducer;
