import React, { useState } from "react";
import { CrossIcon } from "../../../Core/svgV2/CrossIcon";
import CustomModal from "../../../Core/CommonV2/CustomModal";
import InfoByFrosty from "../../../Core/CommonV2/InfoByFrosty";
import CustomInputBox from "../../../Core/CommonV2/CustomInputBox";
import CustomColorPicker from "../../../Core/CommonV2/CustomColorPicker";
import CustomButton from "../../../Core/CommonV2/CustomButton";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";
import { updateSchedule } from "../../../Core/redux/slices/schedule.slice";
import { useForm, Controller } from "react-hook-form";
import { client } from "../../../Core/utils/axiosClient";
import { getLabels } from "../../../Core/redux/api/scheduleAPI";
import { useParams, useSearchParams } from "react-router-dom";
import CustomDatePicker from "../../../Core/CommonV2/CustomDatePicker";
import dayjs from "dayjs";
import LabelColorPicker from "../MyBoard/LabelColorPicker";

const NewLabelModal = ({ visible, onClose, isMyBoard = false }) => {
  const [searchParams] = useSearchParams();
  const { projectId: projectIdFromParams } = useParams();

  const dispatch = useAppDispatch();
  const { labels } = useAppSelector((state) => state.schedule);

  const { handleSubmit, control, formState, reset, setError } = useForm({
    values: {
      labelName: "",
      startDate: "",
      endDate: "",
    },
  });
  const [color, setColor] = useState("#165ABF");
  const [selectedLabel, setSelectedLabel] = useState({
    id: 1,
    baseColor: "#D1E9FF",
    textColor: "#7DC7FF",
  });

  const projectId = projectIdFromParams || searchParams.get("projectId");

  const createLabel = async (data) => {
    try {
      const alreadyExists = labels
        .filter((label) => label.projectId === projectId)
        .find((l) => l.name === data.labelName);

      if (alreadyExists) {
        setError("labelName", {
          type: "alreadyExists",
          message: "Label already exists",
        });
      } else {
        await client.post("/schedule/label", {
          labelName: data.labelName,
          color: color ? color : "#7DC7FF",
          projectId: isMyBoard ? null : projectId,
          isMyBoard: projectId ? false : true,
          startDate: data.startDate,
          endDate: data.endDate,
          labelColorId: selectedLabel.id,
        });

        reset({ labelName: "", startDate: "", endDate: "" });
        dispatch(getLabels({ projectId, isMyBoard }));
        onClose();
      }
    } catch (e) {
      console.log("Something went wrong while creating a new label");
    }
  };

  const handleClose = () => {
    onClose();
    reset({ labelName: "", startDate: "", endDate: "" });
  };

  return (
    <>
      <CustomModal
        visible={visible}
        width="352px"
        onCancel={() => onClose()}
        body={
          <div className="p-4">
            <div className="flex justify-between">
              <p className="text-black out-500-14">New Label</p>
              <div className="flex items-center gap-x-2.5 ">
                <InfoByFrosty
                  title="Label"
                  content="Add labels to your tasks, bugs or events to help organize your boards. All the labels will be visible in every project of this workspace."
                />
                <button onClick={handleClose}>
                  <CrossIcon className="text-gray-700" />
                </button>
              </div>
            </div>{" "}
            <div className="flex items-start w-full mt-4 gap-x-3">
              <Controller
                control={control}
                name="labelName"
                rules={{ required: "Please Add the label Name" }}
                render={({ field }) => (
                  <CustomInputBox
                    {...field}
                    className="w-full"
                    placeholder="Enter a name"
                    formState={formState}
                  />
                )}
              />
              {/* {isMyBoard ? ( */}
              <LabelColorPicker
                selectedLabel={selectedLabel}
                setSelectedLabel={setSelectedLabel}
              />
              {/* 
              <CustomColorPicker onColorChange={(color) => setColor(color)} /> */}
            </div>
            <div className="flex items-center justify-between mt-2 gap-x-2">
              <Controller
                control={control}
                name="startDate"
                rules={{ required: "Please Add Start Date" }}
                render={({ field }) => (
                  <CustomDatePicker
                    {...field}
                    className="w-full text-zinc-800 out-300-14 bg-transparent rounded-[4px] border-gray-200"
                    format="DD MMM YYYY"
                    placeholder="Start date"
                    suffixIcon={<></>}
                    formState={formState}
                    disabledDate={(current) =>
                      current.isBefore(dayjs().subtract(1, "day"))
                    }
                  />
                )}
              />
              <Controller
                control={control}
                name="endDate"
                rules={{ required: "Please Add End Date" }}
                render={({ field }) => (
                  <CustomDatePicker
                    {...field}
                    className="w-full text-zinc-800 out-300-14 bg-transparent rounded-[4px] border-gray-200"
                    format="DD MMM YYYY"
                    placeholder="End date"
                    suffixIcon={<></>}
                    formState={formState}
                    disabledDate={(current) =>
                      current.isBefore(dayjs().subtract(1, "day"))
                    }
                  />
                )}
              />
            </div>
            <div className="flex items-center justify-between mt-4">
              <CustomButton
                text="Manage Labels"
                className="bg-transparent text-primary-700 out-500-14"
                height="30px"
                onClick={() =>
                  dispatch(
                    updateSchedule({ key: "manageLabelModal", value: true })
                  )
                }
              />
              <div className="flex items-center">
                <CustomButton
                  text="Cancel"
                  className="text-gray-500 bg-transparent out-500-14"
                  height="30px"
                  onClick={handleClose}
                />
                <CustomButton
                  onClick={handleSubmit(createLabel)}
                  text="Create"
                  className="text-white bg-primary-600 out-500-14"
                  height="30px"
                />
              </div>
            </div>
          </div>
        }
      />
    </>
  );
};

export default NewLabelModal;
