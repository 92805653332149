import React from "react";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";
import CustomAvatar from "../../../Core/CommonV2/CustomAvatar";
import getUsersName from "../../../Core/utils/getUserName";
import { Tooltip } from "antd";
import dayjs from "dayjs";
import { TASK_ACTIVITY_LOG_ACTIONS } from "../../../Core/constants";

import {
  getPriorityDetails,
  getStatusDetails,
} from "../../../Core/utils/scheduleUtils";
import CustomBadge from "../../../Core/CommonV2/CustomBadge";
import { client } from "../../../Core/utils/axiosClient";
import {
  getGoalDetails,
  getTaskActivityLog,
  updateGoal,
} from "../../../Core/redux/api/scheduleAPI";
import { useParams } from "react-router-dom";
import {
  convertMinutesToTimeLog,
  getTimeLoginMinutes,
} from "../../../Core/utils/timeLogUtils";
import usePermission from "../../../Core/hooks/usePermission";
import { PhaseIcon } from "../../../Core/svgV2/PhaseIcon";
import { updateSchedule } from "../../../Core/redux/slices/schedule.slice";

const ActivityWrapper = ({ userId, timestamp, children }) => {
  const { userList } = useAppSelector((state) => state.overview);
  const currentUser = userList?.find((user) => user.userId == userId);

  console.log(dayjs(timestamp).isUTC(), "isUTC");
  return (
    <div className="flex items-center mt-5 gap-x-2">
      <div className="flex items-center">
        <CustomAvatar
          title={getUsersName(currentUser)}
          size={28}
          src={currentUser?.profilePicture}
          className=""
          whiteText
          color={currentUser?.profilePictureColorCode}
        />
        <p className="out-500-14 ml-2 text-gray-700">
          {getUsersName(currentUser)}
        </p>
      </div>
      {children}
      <div className="">
        <Tooltip
          title={
            timestamp
              ? dayjs(timestamp).local().format("MMMM DD YYYY, hh:mm a")
              : "-"
          }
        >
          <div className="out-300-14 text-gray-500">
            {dayjs(timestamp).local().fromNow()}
          </div>
        </Tooltip>
      </div>
    </div>
  );
};

const AssigneeReporterChange = ({ activity, type }) => {
  const { userList } = useAppSelector((state) => state.overview);
  const currentUser = userList?.find(
    (user) => user.userId == activity.newValue
  );
  return (
    <ActivityWrapper userId={activity.userId} timestamp={activity.timestamp}>
      <div className="out-300-14 text-gray-500">
        changed the <span className="out-500-14 text-black">{type}</span> to{" "}
        <span className="out-500-14 text-black">
          {getUsersName(currentUser)}
        </span>
      </div>
    </ActivityWrapper>
  );
};

const PhaseChange = ({ activity }) => {
  console.log(activity, "acticurty");
  const { phases } = useAppSelector((state) => state.schedule);

  const currentPhase = phases.find(
    (p) => p.projectPhaseId == activity.newValue
  );

  return (
    <ActivityWrapper userId={activity.userId} timestamp={activity.timestamp}>
      <div className="out-300-14 flex text-gray-500">
        changed the phase to
        <div className="out-500-14 flex ml-2 gap-x-1 text-black">
          <PhaseIcon phaseId={+currentPhase?.phaseId || 1} />
          {currentPhase?.phases}
        </div>
      </div>
    </ActivityWrapper>
  );
};

const PriorityChange = ({ activity }) => {
  const { color, priorityName } = getPriorityDetails(activity.newValue);
  return (
    <ActivityWrapper userId={activity.userId} timestamp={activity.timestamp}>
      <div className="out-300-14 flex text-gray-500">
        changed the priority to
        <div
          className="out-500-14 flex ml-2 gap-x-1 text-black"
          style={{ color }}
        >
          {priorityName}
        </div>
      </div>
    </ActivityWrapper>
  );
};
const LabelChange = ({ activity }) => {
  const { labels } = useAppSelector((state) => state.schedule);

  const label = labels.find((l) => l.id == activity.newValue);
  return (
    <ActivityWrapper userId={activity.userId} timestamp={activity.timestamp}>
      <div className="out-300-14 flex gap-x-1 items-center text-gray-500">
        changed the label to
        <CustomBadge
          text={label?.name}
          color={label?.color}
          className="border border-gray-200"
        />
      </div>
    </ActivityWrapper>
  );
};

const SprintChange = ({ activity }) => {
  const { sprints } = useAppSelector((state) => state.schedule);

  const sprint = sprints.find((l) => l.id == activity.newValue);
  return (
    <ActivityWrapper userId={activity.userId} timestamp={activity.timestamp}>
      <div className="out-300-14 flex gap-x-1 items-center text-gray-500">
        {sprint ? (
          <div>
            changed the sprint to{" "}
            <span className="out-500-14 text-black">{sprint.name} </span>
          </div>
        ) : (
          <div>
            <span className="out-500-14 text-black">removed</span> the sprint
          </div>
        )}
      </div>
    </ActivityWrapper>
  );
};

const EstimatedTimeChange = ({ activity }) => {
  return (
    <ActivityWrapper userId={activity.userId} timestamp={activity.timestamp}>
      <div className="out-300-14 flex gap-x-1 items-center text-gray-500">
        added estimate time
        <span className="out-500-14 text-black">{activity.newValue}</span>
      </div>
    </ActivityWrapper>
  );
};

const TimeLoggedChange = ({ activity, selectedTask }) => {
  const dispatch = useAppDispatch();
  const { taskId } = useParams();

  console.log(selectedTask, "selec");

  const handleDelete = async () => {
    try {
      await client.delete("/schedule/goal-activity-log", {
        params: { logId: activity.id },
      });
      const currentLoggedTimeInMinutes = getTimeLoginMinutes(
        selectedTask.timeLogged
      );
      const timeToRemoveInMinutes = getTimeLoginMinutes(activity.newValue);
      const newTimeLog = convertMinutesToTimeLog(
        currentLoggedTimeInMinutes - timeToRemoveInMinutes
      );

      await dispatch(
        updateGoal({
          timeLogged: newTimeLog,
          goalId: activity.goalId,
        })
      );
      await dispatch(updateSchedule({ key: "isGoalEdited", value: true }));
    } catch (e) {
      console.log("something went wrong when deleteing the time log", e);
    } finally {
      dispatch(getGoalDetails({ goalId: taskId }));
      dispatch(getTaskActivityLog({ goalId: taskId }));
    }
  };

  const { hasPermission: LOG_TIME_DELETE } = usePermission("19");
  return (
    <div className="flex items-center">
      <ActivityWrapper userId={activity.userId} timestamp={activity.timestamp}>
        <div className="out-300-14 flex gap-x-1 items-center text-gray-500">
          logged{" "}
          <span className="out-500-14 text-black">{activity.newValue}</span>
        </div>
      </ActivityWrapper>
      {LOG_TIME_DELETE && (
        <button
          className="text-primary-700 out-500-14 ml-2 mt-5"
          onClick={handleDelete}
        >
          Delete
        </button>
      )}
    </div>
  );
};

const ReleaseVersionChange = ({ activity }) => {
  const { releaseVersions } = useAppSelector((state) => state.schedule);

  const releaseVerion = releaseVersions.find((l) => l.id == activity.newValue);
  return (
    <ActivityWrapper userId={activity.userId} timestamp={activity.timestamp}>
      <div className="out-300-14 flex gap-x-1 items-center text-gray-500">
        changed the release version to
        <CustomBadge
          text={releaseVerion?.versionName}
          color="#344054"
          className="border border-gray-200"
        />
      </div>
    </ActivityWrapper>
  );
};

const StatusChange = ({ activity }) => {
  const { swimlanes } = useAppSelector((state) => state.schedule);

  const currentSwimlane = swimlanes.find((s) => s.id == activity.newValue);
  const { color, className } = getStatusDetails(currentSwimlane?.statusId);

  return (
    <ActivityWrapper userId={activity.userId} timestamp={activity.timestamp}>
      <div className="out-300-14 flex gap-x-1 items-center text-gray-500">
        changed the release version to
        <CustomBadge
          text={currentSwimlane?.title || "To Do"}
          color={color}
          className={className}
          dot
        />
      </div>
    </ActivityWrapper>
  );
};

const TaskActivityLog = ({ task }) => {
  const { taskActivity } = useAppSelector((state) => state.schedule);

  const getActivity = (activity) => {
    switch (activity.action) {
      case TASK_ACTIVITY_LOG_ACTIONS.ASSIGNEE_CHANGED:
        return <AssigneeReporterChange activity={activity} type={"Assignee"} />;
      case TASK_ACTIVITY_LOG_ACTIONS.REPORTER_CHANGED:
        return <AssigneeReporterChange activity={activity} type={"Reporter"} />;
      case TASK_ACTIVITY_LOG_ACTIONS.PHASE_CHANGED:
        return <PhaseChange activity={activity} />;
      case TASK_ACTIVITY_LOG_ACTIONS.PRIORITY_CHANGE:
        return <PriorityChange activity={activity} />;
      case TASK_ACTIVITY_LOG_ACTIONS.LABEL_CHANGE:
        return <LabelChange activity={activity} />;
      case TASK_ACTIVITY_LOG_ACTIONS.SPRINT_CHANGE:
        return <SprintChange activity={activity} />;
      case TASK_ACTIVITY_LOG_ACTIONS.ESTIMATE_CHANGE:
        return <EstimatedTimeChange activity={activity} />;
      case TASK_ACTIVITY_LOG_ACTIONS.TIMELOG_CHANGE:
        return <TimeLoggedChange selectedTask={task} activity={activity} />;
      case TASK_ACTIVITY_LOG_ACTIONS.RELEASE_VERSION_CHANGE:
        return <ReleaseVersionChange activity={activity} />;
      case TASK_ACTIVITY_LOG_ACTIONS.STATUS_CHANGED:
        return <StatusChange activity={activity} />;
      default:
        break;
    }
  };

  return <div>{taskActivity?.map((activity) => getActivity(activity))}</div>;
};

export default TaskActivityLog;
