import React, { useEffect, useState } from "react";
import ScheduleLayout from "../Components/ScheduleLayout";
import { Checkbox, Dropdown, Popover, message } from "antd";

import TimeInput from "../Task/TimeInput";

import InfoByFrosty from "../../../Core/CommonV2/InfoByFrosty";
import NewLabelModal from "../Modals/NewLabelModal";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";
import {
  resetSchedule,
  updateSchedule,
} from "../../../Core/redux/slices/schedule.slice";
import ManageLabelsModal from "../Modals/ManageLabelsModal";
import DeleteLabelModal from "../Modals/DeleteLabelModal";
import NewReleaseVersionModal from "../Modals/NewReleaseVersionModal";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import {
  getGoalDetails,
  updateProgress,
} from "../../../Core/redux/api/scheduleAPI";
import AttachmentsAndSubtask from "./AttachmentsandSubtask";
import { client } from "../../../Core/utils/axiosClient";
import { getComments } from "../../../Core/redux/api/conversationAPI";
import {
  DEFAULT_LIMIT,
  DEFAULT_OFFSET,
  TASK_ACTIVITY_LOG_ACTIONS,
} from "../../../Core/constants";

import { CopyIcon, CopyLinkIcon } from "../../../Core/svgV2/CopyIcon";
import { DeleteIcon } from "../../../Core/svgV2/DeleteIcon";
import copyTextToClipboard from "../../../Core/utils/clipboard";
import DeleteTaskModal from "../Modals/DeleteTaskModal";

import FilePreviweModal from "../../Conversations/Modals/FIlePreviweModal";
import { resetConversations } from "../../../Core/redux/slices/conversations.slice";
import { updateDashboard } from "../../../Core/redux/slices/dashboard.slice";
import { useQueryClient } from "react-query";
import CustomAvatar from "../../../Core/CommonV2/CustomAvatar";
import getUsersName from "../../../Core/utils/getUserName";
import { PlusIcon } from "../../../Core/svgV2/PlusIcon";
import dayjs from "dayjs";
import {
  CollaboratorSelect,
  TaskDueDate,
  ParentTaskSelect,
  StatusSelect,
  AddApprovers,
  // CollaboratorSelect,
  DueDate,
  LabelSelect,
  PrioritySelect,
  RowLayout,
  SelectStatus,
  // TaskDueDate,
  TaskInput,
  VersionSelect,
  TimeSelect,
} from "./TaskReusable";
import {
  createMyBoardTask,
  getAllMyBoardTasks,
  getMyBoardTaskById,
  getMyBoardTasks,
  getTaskActivityLog,
  updateMyBoardTask,
} from "../../../Core/redux/api/myBoardAPI";
import { updateMyBoard } from "../../../Core/redux/slices/myBoard.slice";
import { ThreeDots } from "./TaskItem";
import CustomInputBox from "../../../Core/CommonV2/CustomInputBox";
import CustomButton from "../../../Core/CommonV2/CustomButton";
import getCurrentUser from "./getCurrentUser";
import MyBoardLabel from "../../../Core/CommonV3/MyBoardLabel";
import TaskHeader from "./TaskScreenHeader";
import { DotsVerticalIcon } from "../../../Core/svgV2/DotsVertical";
import MyBoardLayout from "./MyBoardLayout";
import DiscardEditedGoalModal from "../Task/DiscardEditedGoalModal";
import CommentsAndActivity from "./CommentsAndActivity";

const UserSelect = ({ task }) => {
  const dispatch = useAppDispatch();

  const { userList } = useAppSelector((state) => state.overview);
  const { user } = useAppSelector((state) => state.userDetails);

  const { collaboratorsForTask } = useAppSelector((state) => state.myBoard);
  const { tenantMembers } = useAppSelector((state) => state.tenant);

  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState("");

  const handleMultiSelect = (current) => {
    dispatch(
      updateMyBoard({
        key: "collaboratorsForTask",
        value: collaboratorsForTask.includes(current.userId)
          ? collaboratorsForTask.filter((item) => item !== current.userId)
          : [...collaboratorsForTask, current.userId],
      })
    );
  };

  const onClose = () => {
    setOpen(false);
    dispatch(
      updateMyBoard({
        key: "collaboratorsForTask",
        value: [],
      })
    );
  };

  const onRequest = async () => {
    setOpen(false);
    if (task.id) {
      await dispatch(
        updateMyBoardTask({
          goalId: task.id,
          collaborators: collaboratorsForTask,
        })
      );
      dispatch(
        updateMyBoard({
          key: "collaboratorsForTask",
          value: [],
        })
      );
    }
    dispatch(
      updateDashboard({
        key: "alertPopupModal",
        value: {
          visible: true,
          data: {
            title: `Sent Collaboration Request`,
            subtitle: `Collaboration request was sent for task`,
            description: `${task.name}`,
          },
        },
      })
    );
  };

  const filteredAndSortedMembers = tenantMembers
    .filter(
      (u) =>
        u.userId !== user?.id &&
        (u.firstname.toLowerCase().includes(search.toLowerCase()) ||
          u.lastname.toLowerCase().includes(search.toLowerCase()))
    )
    .sort((a, b) => {
      const aIsSelected = task?.collaborators?.includes(a.userId) ? 1 : 0;
      const bIsSelected = task?.collaborators?.includes(b.userId) ? 1 : 0;
      return bIsSelected - aIsSelected;
    });

  return (
    <Popover
      getPopupContainer={(parent) =>
        parent?.parentElement?.parentElement?.parentElement?.parentElement
      }
      content={
        <div className="min-w-[200px] bg-white rounded-lg">
          <CustomInputBox
            className="px-3 pt-2"
            placeholder="Search for a name"
            value={search}
            onChange={(event) => setSearch(event.target.value)}
            autoFocus
          />

          <div className="overflow-y-scroll h-[200px]">
            <p className="px-3 pt-1.5 text-gray-500 out-300-14">
              Select 1 or multiple members
            </p>
            {tenantMembers
              .filter((u) => task?.collaborators?.includes(u.userId))
              .filter(
                (u) =>
                  u.userId !== user?.id &&
                  (u.firstname.toLowerCase().includes(search.toLowerCase()) ||
                    u.lastname.toLowerCase().includes(search.toLowerCase()))
              )
              .map((current, index) => (
                <div
                  role="button"
                  key={`${current.userId}${index}`}
                  className="flex items-center gap-x-3 mt-1.5 px-3"
                  onClick={() => handleMultiSelect(current)}
                >
                  <Checkbox
                    checked={task?.collaborators?.includes(current.userId)}
                    disabled={task?.collaborators?.includes(current.userId)}
                  />
                  <CustomAvatar
                    src={current.profilePicture}
                    size={24}
                    title={getUsersName(current)}
                    whiteText
                    color={current.profilePictureColorCode}
                  />

                  <p className="text-gray-900 capitalize out-300-12">
                    {getUsersName(current)}
                  </p>
                </div>
              ))}
            {tenantMembers
              .filter((u) => !task?.collaborators?.includes(u.userId))
              .filter(
                (u) =>
                  u.userId !== user?.id &&
                  (u.firstname.toLowerCase().includes(search.toLowerCase()) ||
                    u.lastname.toLowerCase().includes(search.toLowerCase()))
              )
              .map((current, index) => (
                <div
                  role="button"
                  key={`${current.userId}${index}`}
                  className="flex items-center gap-x-3 mt-1.5 px-3"
                  onClick={() => handleMultiSelect(current)}
                >
                  <Checkbox
                    checked={collaboratorsForTask?.includes(current.userId)}
                  />
                  <CustomAvatar
                    src={current.profilePicture}
                    size={24}
                    title={getUsersName(current)}
                    whiteText
                    color={current.profilePictureColorCode}
                  />

                  <p className="text-gray-900 capitalize out-300-12">
                    {getUsersName(current)}
                  </p>
                </div>
              ))}
          </div>

          <div className="z-10 flex items-center justify-end w-full p-3 bg-white rounded-b-lg">
            <CustomButton
              text="Cancel"
              className="text-gray-500 bg-transparent"
              height="30px"
              width="63px"
              onClick={onClose}
            />
            <CustomButton
              text="Request"
              height="30px"
              width="71px"
              className="bg-transparent text-primary-600"
              onClick={onRequest}
            />
          </div>
        </div>
      }
      arrow={false}
      open={open}
      onOpenChange={(open) => setOpen(open)}
      placement="bottomLeft"
      trigger={"click"}
    >
      {task && getCurrentUser({ task })}
    </Popover>
  );
};

const MyBoardTaskScreen = () => {
  const { user } = useAppSelector((state) => state.userDetails);
  const {
    newLabelModal,
    manageLabelModal,
    deleteLabelModal,
    newReleaseVersionModal,
    sprints,
    labels,
  } = useAppSelector((state) => state.schedule);
  const { currentSelectedTask, collaboratorsForTask, newTask } = useAppSelector(
    (state) => state.myBoard
  );

  const { tenantMembers } = useAppSelector((state) => state.tenant);

  const [searchParams, setSearchParams] = useSearchParams();
  const { filePreview } = useAppSelector((state) => state.conversations);

  const { automations } = useAppSelector((state) => state.settings);
  const [taskDeleteModal, setTaskDeleteModal] = useState(false);

  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const { taskId, subtaskId } = useParams();
  const [selectedGoalId, setSelectedGoalId] = useState(
    subtaskId ? subtaskId : taskId
  );
  const [taskType, setTaskType] = useState(1);
  const initialState = {
    id: "",
    name: "",
    isCompleted: 0,
    statusId: 1,
    componentId: null,
    priorityId: 1,
    startDate: null,
    endDate: null,
    isNewGoal: 0,
    isActive: 1,
    projectId: "",
    tenantId: "",
    goalType: "",
    parentGoalId: null,
    assigneeId: null,
    description: "",
    attachments: [],
    collaborators: [],

    estimatedTime: "",
    createdAt: "",
    timeLogged: "",
    linkedGoalId: null,
    labelId: "",
    releaseVersionId: null,
    identifier: "",
    subtask: [],
    swimlaneId: "1",
  };
  const [taskDetails, setTaskDetails] = useState(initialState);
  const [titleEdit, setTitleEdit] = useState(false);
  const [editedTaskDetails, setEditedTaskDetails] = useState(null);
  const [discardModal, setDiscardModal] = useState(false);

  const { formState, control, setValue, handleSubmit, getValues } = useForm({
    values: {
      taskName: "",
      timeLogged: "",
    },
  });

  const getName = () => {
    switch (currentSelectedTask?.goalType) {
      case "1":
        return "task";
      case "3":
        return "bug";
      default:
        return "task";
    }
  };
  const labelOptions = labels
    ?.filter((item) => item.isMyBoard)
    ?.map((label) => {
      return {
        id: label.id,
        value: label.id,
        label: label.name,
        render: () => (
          <div className="flex items-center justify-between">
            <MyBoardLabel labelColorId={label.labelColorId} text={label.name} />
            <div className="h-[14px] w-[14px] rounded-full" />
          </div>
        ),
      };
    });

  const handleDuplicate = async () => {
    try {
      const { data } = await client.post("/my-board/duplicate-goal", {
        id: selectedGoalId,
      });
      dispatch(
        getMyBoardTasks({ limit: DEFAULT_LIMIT, offset: DEFAULT_OFFSET })
      );
      dispatch(
        updateMyBoard({
          key: "currentSelectedTask",
          value: { ...data.result },
        })
      );
      navigate(`/my-board/task/${+data.result.id}`);
    } catch (e) {
      console.log(e);
    }
  };

  const onClick = ({ key }) => {
    switch (key) {
      case "1":
        copyTextToClipboard(window.location.href);
        break;
      case "2":
        handleDuplicate();
        break;
      case "3":
        dispatch(
          updateMyBoard({
            key: "selectedTasks",
            value: [taskId],
          })
        );
        setTaskDeleteModal(true);
        break;
      default:
        break;
    }
  };

  const redirectToLink = `/my-board`;
  const handleClose = () => {
    redirectToLink ? navigate(redirectToLink) : navigate(-1);
  };
  const createTask = async () => {
    const payload = {
      id: taskDetails.id,
      name: taskDetails.name,
      isCompleted: taskDetails.isCompleted,
      statusId: taskDetails.statusId,
      componentId: taskDetails.componentId,
      priorityId: taskDetails.priorityId,
      startDate: taskDetails.startDate,
      endDate: taskDetails.endDate,
      isNewGoal: taskDetails.isNewGoal,
      isActive: taskDetails.isActive,
      projectId: taskDetails.projectId,
      tenantId: taskDetails.tenantId,
      goalType: taskDetails.goalType,
      parentGoalId: taskDetails.parentGoalId,
      assigneeId: taskDetails.assigneeId,
      description: taskDetails.description,
      attachments: JSON.stringify(taskDetails.attachments),
      estimatedTime: taskDetails.estimatedTime,
      createdAt: taskDetails.createdAt,
      timeLogged: taskDetails.timeLogged,
      linkedGoalId: taskDetails.linkedGoalId,
      labelId: taskDetails.labelId,
      releaseVersionId: taskDetails.releaseVersionId,
      identifier: taskDetails.identifier,
      subtask: taskDetails.subtask,
      swimlaneId: taskDetails.swimlaneId,

      collaborators: [user.id, ...collaboratorsForTask],
    };
    try {
      await dispatch(createMyBoardTask(payload));
      await dispatch(
        getMyBoardTasks({ limit: DEFAULT_LIMIT, offset: DEFAULT_OFFSET })
      );
      dispatch(updateMyBoard({ key: "createTaskHeader", value: false }));
      dispatch(updateMyBoard({ key: "newTask", value: null }));
      dispatch(
        updateMyBoard({
          key: "collaboratorsForTask",
          value: [],
        })
      );
      navigate("/my-board");
    } catch (error) {
      console.log("Error occured while creating the task", error);
    } finally {
      setTaskDetails(initialState);
    }
  };

  const editTask = async () => {
    const action = [];
    if (editedTaskDetails?.assigneeId) {
      action.push({
        oldValue: currentSelectedTask.assigneeId,
        newValue: editedTaskDetails?.assigneeId,
        actionName: TASK_ACTIVITY_LOG_ACTIONS.ASSIGNEE_CHANGED,
      });
    }

    if (editedTaskDetails?.statusId || editedTaskDetails?.swimlaneId) {
      action.push({
        oldValue: currentSelectedTask.swimlaneId,
        newValue: editedTaskDetails?.swimlaneId,
        actionName: TASK_ACTIVITY_LOG_ACTIONS.STATUS_CHANGED,
      });
    }

    if (editedTaskDetails?.priorityId) {
      action.push({
        oldValue: currentSelectedTask.priorityId,
        newValue: editedTaskDetails?.priorityId,
        actionName: TASK_ACTIVITY_LOG_ACTIONS.PRIORITY_CHANGE,
      });
    }
    if (editedTaskDetails?.labelId) {
      action.push({
        oldValue: currentSelectedTask.labelId,
        newValue: editedTaskDetails?.labelId,
        actionName: TASK_ACTIVITY_LOG_ACTIONS.LABEL_CHANGE,
      });
    }

    if (editedTaskDetails?.estimatedTime) {
      action.push({
        oldValue: currentSelectedTask.estimatedTime,
        newValue: editedTaskDetails?.estimatedTime,
        actionName: TASK_ACTIVITY_LOG_ACTIONS.ESTIMATE_CHANGE,
      });
    }
    if (editedTaskDetails?.timeLogged) {
      action.push({
        oldValue: currentSelectedTask.timeLogged,
        newValue: editedTaskDetails?.timeLogged,
        actionName: TASK_ACTIVITY_LOG_ACTIONS.TIMELOG_CHANGE,
      });
    }
    if (editedTaskDetails?.releaseVersionId) {
      action.push({
        oldValue: currentSelectedTask.releaseVersionId,
        newValue: editedTaskDetails?.releaseVersionId,
        actionName: TASK_ACTIVITY_LOG_ACTIONS.RELEASE_VERSION_CHANGE,
      });
    }
    const payload = {
      ...editedTaskDetails,
      action: [...action],
      goalId: taskId,
    };

    await dispatch(updateMyBoardTask(payload));
    dispatch(getMyBoardTaskById({ taskId: taskId }));
    dispatch(getTaskActivityLog({ goalId: taskId }));
    // if (editedTaskDetails.assigneeId) {
    //   await client.post("/notifications/create", {
    //     notificationType: "32",
    //     goalId: taskId,
    //     allUsers: [
    //       { userId: taskDetails.assigneeId },
    //     ],
    //     meta: {
    //       oldAssigneeId: selectedTask.assigneeId,
    //       newAssigneeId: editedTaskDetails.assigneeId,
    //     },
    //   });
    // }

    setEditedTaskDetails(null);
    handleClose();
  };

  const onSubmit = async () => {
    if (taskId === "new") {
      createTask();
    } else {
      editTask();
    }
  };
  useEffect(() => {
    if (selectedGoalId !== "new") {
      dispatch(getMyBoardTaskById({ taskId: taskId }));
      dispatch(getTaskActivityLog({ goalId: taskId }));

      // dispatch(
      //   getComments({
      //     docId: taskId,
      //     docType: getName(),
      //     limit: 1000,
      //     offset: 0,
      //   })
      // );
    }
  }, [taskId, currentSelectedTask?.goalType, subtaskId]);
  useEffect(() => {
    dispatch(getAllMyBoardTasks({}));
  }, []);
  useEffect(() => {
    setSelectedGoalId(subtaskId ? subtaskId : taskId);
  }, [subtaskId, taskId]);

  useEffect(() => {
    if (selectedGoalId !== "new" && currentSelectedTask) {
      setValue("taskName", currentSelectedTask?.name);
      setTaskDetails({
        ...currentSelectedTask,
        attachments: currentSelectedTask?.attachments
          ? JSON.parse(currentSelectedTask?.attachments)
          : [],
        subtask: currentSelectedTask?.subtask
          ? currentSelectedTask.subtask
          : [],
      });

      setTaskType(+currentSelectedTask?.goalType);
      console.log(currentSelectedTask?.collaborators, "task.co 2");
      dispatch(
        updateSchedule({
          key: "collaboratorsForTask",
          value: currentSelectedTask?.collaborators,
        })
      );
    }
  }, [currentSelectedTask, selectedGoalId]);

  useEffect(() => {
    if (newTask && Object.keys(newTask).length !== 0) {
      setValue("taskName", newTask?.name);
      setValue("timeLogged", newTask?.timeLogged);
      setTaskType(newTask?.goalType);
      setTaskDetails((prevValue) => ({
        ...prevValue,
        name:
          newTask?.taskDetails?.name !== null
            ? newTask?.taskDetails?.name
            : prevValue.name,
        statusId:
          newTask?.taskDetails?.statusId !== null
            ? newTask?.taskDetails?.statusId
            : prevValue.statusId,

        priorityId:
          newTask?.taskDetails?.priorityId !== null
            ? newTask?.taskDetails?.priorityId
            : prevValue.priorityId,

        labelId:
          newTask?.taskDetails?.labelId !== null
            ? newTask?.taskDetails?.labelId
            : prevValue.labelId,
        startDate:
          newTask?.taskDetails?.startDate !== null
            ? newTask?.taskDetails?.startDate
            : prevValue.startDate,
        endDate:
          newTask?.taskDetails?.endDate !== null
            ? newTask?.taskDetails?.endDate
            : prevValue.endDate,
        collaborators:
          newTask?.taskDetails?.collaborators !== null
            ? newTask?.taskDetails?.collaborators
            : prevValue.collaborators,
      }));
    }
  }, [newTask]);

  const groupItems = [
    {
      key: "1",
      label: <div className="out-300-14 text-gray-700">Copy link</div>,
      icon: <CopyLinkIcon />,
    },

    {
      key: "2",
      label: (
        <div className="out-300-14 leading-5 text-gray-700">Duplicate</div>
      ),
      icon: <CopyIcon className="text-gray-700" />,
    },

    //todo add vlaidation so user cannot delete if he is not creator
    // TASK_EVENT_SCOPE_DELETE ? { key: "divider", type: "divider" } : null,
    {
      key: "3",
      label: <div className="out-300-14 text-gray-700">Delete</div>,

      icon: <DeleteIcon className="text-gray-700 " />,
    },
  ];

  // automation code
  useEffect(() => {
    if (selectedGoalId === "new") {
      if (getValues("timeLogged")) {
        setTaskDetails((prev) => {
          const obj = { ...prev, timeLogged: getValues("timeLogged") };
          return obj;
        });
      }

      setTaskDetails((prev) => {
        const obj = { ...prev, assigneeId: user.id };
        return obj;
      });
    }
  }, [taskType, taskId]);

  return (
    <MyBoardLayout>
      <TaskHeader
        taskType={subtaskId ? 4 : 1}
        handleSubmit={onSubmit}
        task={taskDetails}
        editedTaskDetails={editedTaskDetails}
        onCancel={() => {
          if (
            editedTaskDetails &&
            Object.keys(editedTaskDetails).length !== 0
          ) {
            setDiscardModal(true);
          } else {
            handleClose();
          }
        }}
      />

      <div className="flex bg-white min-h-[calc(100vh-100px)]">
        <div className="flex-1 px-6 py-4 min-h-[calc(100vh-100px)] max-h-[calc(100vh-100px)] overflow-y-auto">
          {selectedGoalId === "new" ? (
            <Controller
              name="taskName"
              control={control}
              rules={{ required: "Please enter a title before submitting" }}
              render={({ field, formState }) => (
                <TaskInput
                  {...field}
                  autoSize
                  value={taskDetails.name}
                  placeholder="Enter a title"
                  showError={formState.errors.taskName?.message ? true : false}
                  onChange={async (e) => {
                    setTaskDetails({ ...taskDetails, name: e.target.value });
                  }}
                />
              )}
            />
          ) : (
            <TaskInput
              autoSize
              value={taskDetails.name}
              placeholder="Enter a title"
              autoFocus={true}
              onSave={async (e, save) => {
                if (save) {
                  e.preventDefault();
                  setTaskDetails({ ...taskDetails, name: e.target.value });
                  setEditedTaskDetails({
                    ...editedTaskDetails,
                    name: e.target.value,
                  });
                  // await dispatch(
                  //   updateMyBoardTask({
                  //     name: e.target.value,
                  //     goalId: taskDetails.id,
                  //   })
                  // );
                  setTitleEdit(false);
                }
              }}
              onChange={async (e) => {
                setTaskDetails({ ...taskDetails, name: e.target.value });

                setEditedTaskDetails({
                  ...editedTaskDetails,
                  name: e.target.value,
                });
              }}
            />
          )}

          {formState.errors.taskName?.message && (
            <div className="mt-1.5 out-300-12 text-left text-error-500">
              {formState.errors.taskName?.message}
            </div>
          )}

          <div className="mt-4  ">
            <TaskInput
              onChange={async (e) => {
                setTaskDetails({ ...taskDetails, description: e.target.value });
                if (selectedGoalId !== "new") {
                  setEditedTaskDetails({
                    ...editedTaskDetails,
                    description: e.target.value,
                  });
                  // await dispatch(
                  //   updateMyBoardTask({
                  //     description: e.target.value,
                  //     goalId: taskDetails.id,
                  //   })
                  // );
                }
              }}
              autoSize={{ minRows: 3 }}
              inputClassName="task-description"
              placeholder="Enter a description..."
              value={taskDetails.description}
            />
          </div>
          <AttachmentsAndSubtask
            task={taskDetails}
            setSubtasks={async (e) => {
              setTaskDetails(e);
              // setEditedTaskDetails({
              //   ...editedTaskDetails,
              //   subtasks: e.subtasks,
              // });
            }}
            setEditedSubtaskDetails={(e) => {
              console.log(e);
              setEditedTaskDetails({
                ...editedTaskDetails,
                subtasks: e.subtasks,
              });
            }}
            setAttachments={async (e) => {
              setTaskDetails(e);
              setEditedTaskDetails({
                ...editedTaskDetails,
                attachments: e.attachments,
              });
            }}
            setEditedAttachmentDetails={(e) => {
              setEditedTaskDetails({
                ...editedTaskDetails,
                attachments: e.attachments,
              });
            }}
          />

          {selectedGoalId !== "new" && (
            <CommentsAndActivity task={currentSelectedTask} />
          )}
        </div>
        <div className="px-6 w-[386px] border-l border-gray-200 min-h-[calc(100vh-100px)] max-h-[calc(100vh-100px)] overflow-y-auto pt-5 ">
          <div className="flex justify-between">
            <SelectStatus
              setStatusId={async (e) => {
                setTaskDetails((oldState) => {
                  const newState = {
                    ...oldState,
                    statusId: e.statusId,
                    swimlaneId: e.statusId,
                  };
                  return newState;
                });
                if (taskId !== "new") {
                  setEditedTaskDetails({
                    ...editedTaskDetails,
                    statusId: e.statusId,
                    swimlaneId: e.swimlaneId,
                  });
                  // await dispatch(
                  //   updateMyBoardTask({
                  //     ...e,
                  //     goalId: taskId,
                  //   })
                  // );
                }
              }}
              statusId={taskDetails.statusId}
              task={taskDetails}
            />
            {taskId !== "new" ? (
              <Dropdown
                dropdownRender={(menu) => (
                  <div className="w-[200px]">{menu}</div>
                )}
                trigger={["click"]}
                menu={{ items: groupItems, onClick }}
              >
                <button>
                  <DotsVerticalIcon className="text-gray-900" />
                </button>
              </Dropdown>
            ) : null}
          </div>

          <RowLayout customLeft="Assignee" className="mt-5 mb-5">
            <div className="flex border border-transparent hover:border-gray-200 w-full rounded  h-[30px]  items-center ">
              <CustomAvatar
                src={user.profilePicture}
                size={24}
                title={getUsersName(user)}
                whiteText
                color={user?.profilePictureColorCode}
              />

              <p className="out-500-14 pl-2 text-gray-900 capitalize ">
                {getUsersName(user)}
              </p>
            </div>
          </RowLayout>
          <>
            <RowLayout customLeft="Collaborators" className=" mb-5">
              <UserSelect task={taskDetails} />

              {/* <CollaboratorSelect
                  disabled={!REPORTER_EDIT}
                  placeholder={
                    <div className="flex gap-x-2.5 items-center justify-center">
                      <PlusIcon className="text-gray-500" />
                      <p className="text-gray-500 out-300-14">
                        Add collaborators
                      </p>
                    </div>
                  }
                  type="reporter"
                  task={taskDetails}
                  setTask={setTaskDetails}
                  onEdit={async (id) => {
                    if (selectedGoalId!=="new") {
                      await dispatch(
                        updateGoal({
                          reporterId: id,
                          goalId: taskId,
                          action: {
                            oldValue: currentSelectedTask.reporterId,
                            newValue: id,
                            actionName:
                              TASK_ACTIVITY_LOG_ACTIONS.REPORTER_CHANGED,
                          },
                        })
                      );
                      await dispatch(
                        updateSchedule({ key: "isGoalEdited", value: true })
                      );
                      dispatch(getGoalDetails({ goalId: taskId }));

                      dispatch(getTaskActivityLog({ goalId: taskId }));
                    }
                    const sprintId = parseInt(taskDetails.sprintId, 10);
                    queryClient.resetQueries(
                      ["goalsBySprint", projectId, sprintId],
                      { inactive: true }
                    );

                    updateSprintGoals(taskDetails);
                  }}
                /> */}
            </RowLayout>

            <RowLayout customLeft="Priority" className=" mb-5">
              <PrioritySelect
                task={taskDetails}
                setTask={async (e) => {
                  setTaskDetails({ ...taskDetails, priorityId: e });
                  if (selectedGoalId !== "new") {
                    setEditedTaskDetails({
                      ...editedTaskDetails,
                      priorityId: e,
                    });
                    // await dispatch(
                    //   updateMyBoardTask({
                    //     priorityId: e,
                    //     goalId: taskDetails.id,
                    //   })
                    // );
                  }
                }}
              />
            </RowLayout>
            <RowLayout customLeft="Label" className=" mb-5">
              <LabelSelect
                visible={true}
                task={taskDetails}
                labelOptions={labelOptions}
                setTask={async (e) => {
                  setTaskDetails({ ...taskDetails, labelId: e });
                  if (selectedGoalId !== "new") {
                    setEditedTaskDetails({
                      ...editedTaskDetails,
                      labelId: e,
                    });
                    // await dispatch(
                    //   updateMyBoardTask({ labelId: e, goalId: taskDetails.id })
                    // );
                  }
                }}
              />
            </RowLayout>

            <RowLayout customLeft="Start Date" className=" mb-5">
              <TaskDueDate
                task={taskDetails}
                type={"Start"}
                setTask={async (e) => {
                  setTaskDetails({ ...taskDetails, startDate: e });
                  if (selectedGoalId !== "new") {
                    setEditedTaskDetails({
                      ...editedTaskDetails,

                      startDate: e,
                    });
                    // await dispatch(
                    //   updateMyBoardTask({
                    //     startDate: e,
                    //     goalId: selectedGoalId,
                    //   })
                    // );
                  }
                }}
              />
            </RowLayout>
            <RowLayout customLeft="End Date" className=" mb-8">
              <TaskDueDate
                task={taskDetails}
                type={"End"}
                setTask={async (e) => {
                  setTaskDetails({ ...taskDetails, endDate: e });
                  if (selectedGoalId !== "new") {
                    setEditedTaskDetails({
                      ...editedTaskDetails,

                      endDate: e,
                    });
                    // await dispatch(
                    //   updateMyBoardTask({
                    //     endDate: e,
                    //     goalId: selectedGoalId,
                    //   })
                    // );
                  }
                }}
              />
            </RowLayout>
          </>

          <div className="out-500-14 text-black">Time log</div>

          <RowLayout
            customLeft={
              <div className="flex items-center gap-x-1">
                <span>Original estimate</span>{" "}
                <InfoByFrosty
                  title="Original estimate"
                  content={
                    <div className="ml-12">
                      <p className="out-500-14 text-gray-500">0w 0d 00h 00m</p>
                      <p className="out-300-14  pt-2 text-gray-500">
                        w = weeks
                      </p>
                      <p className="out-300-14  pt-2 text-gray-500">d = days</p>
                      <p className="out-300-14  pt-2 text-gray-500">
                        h = hours
                      </p>
                      <p className="out-300-14  pt-2 text-gray-500">
                        m = minutes
                      </p>
                    </div>
                  }
                />
              </div>
            }
            className="mt-4 mb-5"
          >
            <TimeSelect
              task={taskDetails}
              setTask={setTaskDetails}
              visible={true}
              type="estimatedTime"
              setEditedTaskDetails={(e) => {
                setEditedTaskDetails({
                  ...editedTaskDetails,
                  estimatedTime: e,
                });
              }}
            />
          </RowLayout>

          <RowLayout
            customLeft={
              <div className="flex items-center gap-x-1">
                <span>Log more time</span>{" "}
                <InfoByFrosty
                  title="Log more time"
                  content={
                    <div className="ml-12">
                      <p className="out-500-14 text-gray-500">0w 0d 00h 00m</p>
                      <p className="out-300-14  pt-2 text-gray-500">
                        w = weeks
                      </p>
                      <p className="out-300-14  pt-2 text-gray-500">d = days</p>
                      <p className="out-300-14  pt-2 text-gray-500">
                        h = hours
                      </p>
                      <p className="out-300-14  pt-2 text-gray-500">
                        m = minutes
                      </p>
                    </div>
                  }
                />
              </div>
            }
            className="mt-4 mb-8"
          >
            {selectedGoalId === "new" ? (
              <Controller
                name="timeLogged"
                control={control}
                // rules={{ required: "Please enter a title before submitting" }}
                render={({ field, formState }) => (
                  <TimeSelect
                    task={taskDetails}
                    setTask={setTaskDetails}
                    visible={true}
                    type="timeLogged"
                  />
                )}
              />
            ) : (
              <TimeSelect
                task={taskDetails}
                setTask={setTaskDetails}
                visible={true}
                type="timeLogged"
                setEditedTaskDetails={(e) => {
                  setEditedTaskDetails({
                    ...editedTaskDetails,
                    totalTimeLogged: e,
                  });
                }}
              />
            )}
          </RowLayout>

          <div className="out-500-14 text-black">More details</div>
          <RowLayout
            customLeft={
              <div className="flex items-center gap-x-1">
                <span>Release version</span>{" "}
                <InfoByFrosty
                  title="Release version"
                  content={
                    "Release versions are important for identifying and tracking different versions of the software. They help with testing, debugging, troubleshooting, and enable users to understand which version of the software they are using, along with its features and improvements."
                  }
                />
              </div>
            }
            className="mt-4 mb-5"
          >
            <VersionSelect
              task={taskDetails}
              disabled={false}
              setTask={async (e) => {
                setTaskDetails({ ...taskDetails, releaseVersionId: e });
                if (selectedGoalId !== "new") {
                  setEditedTaskDetails({
                    ...editedTaskDetails,

                    releaseVersionId: e,
                  });
                  // await dispatch(
                  //   updateMyBoardTask({
                  //     releaseVersionId: e,
                  //     goalId: selectedGoalId,
                  //     action: {
                  //       oldValue: currentSelectedTask.releaseVersionId,
                  //       newValue: e,
                  //       actionName:
                  //         TASK_ACTIVITY_LOG_ACTIONS.RELEASE_VERSION_CHANGE,
                  //     },
                  //   })
                  // );
                }
              }}
            />
          </RowLayout>
          <RowLayout customLeft="Created on" className=" mb-5">
            <div className="flex px-[14px]">
              <p className="out-300-14 text-gray-400  ">
                {currentSelectedTask?.createdAt
                  ? dayjs(currentSelectedTask?.createdAt).format("DD/MM/YYYY")
                  : dayjs().format("DD/MM/YYYY")}
              </p>
            </div>
          </RowLayout>
        </div>
      </div>

      <NewLabelModal
        visible={newLabelModal}
        onClose={() => dispatch(resetSchedule(["newLabelModal"]))}
        isMyBoard
      />

      <ManageLabelsModal
        visible={manageLabelModal}
        onClose={() => dispatch(resetSchedule(["manageLabelModal"]))}
      />
      <DeleteLabelModal
        visible={deleteLabelModal.visible}
        onClose={() => dispatch(resetSchedule(["deleteLabelModal"]))}
      />
      <NewReleaseVersionModal
        visible={newReleaseVersionModal}
        onClose={() => dispatch(resetSchedule(["newReleaseVersionModal"]))}
      />
      <DeleteTaskModal
        visible={taskDeleteModal}
        onClose={() => {
          setTaskDeleteModal(false);
          dispatch(
            updateMyBoard({
              key: "selectedTasks",
              value: [],
            })
          );
          if (taskId || subtaskId) {
            navigate(-1);
          }
        }}
      />
      <DiscardEditedGoalModal
        onClose={() => setDiscardModal(false)}
        onDiscard={() => {
          setEditedTaskDetails(false);
          setDiscardModal(false);
          handleClose();
        }}
        visible={discardModal}
      />
      <FilePreviweModal
        visible={filePreview.visible}
        page="task"
        onClose={() => dispatch(resetConversations(["filePreview"]))}
      />
    </MyBoardLayout>
  );
};

export default MyBoardTaskScreen;
