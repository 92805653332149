import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Divider } from "antd";
import { ChevronIcon } from "../../Core/svgV2/Chevron";
import { updateDashboard } from "../../Core/redux/slices/dashboard.slice";
import { useAppSelector } from "../../Core/redux/hooks";
import dayjs from "dayjs";

const MyCalendarNavigator = ({ onTodayClick }) => {
  const [isMinimized, setIsMinimized] = useState(false);
  const { myCalendarTimelineSelected } = useAppSelector(
    (state) => state.dashboard
  );
  const [selected, setSelected] = useState(myCalendarTimelineSelected);
  const dispatch = useDispatch();

  const timeframes = ["Day", "Week", "Month", "Year"];

  const handleTimeframeClick = (timeframe) => {
    setSelected(timeframe);

    dispatch(
      updateDashboard({ key: "myCalendarTimelineSelected", value: timeframe })
    );
  };

  const handleTodayClick = () => {
    const today = dayjs();
    onTodayClick(today);
  };

  return (
    <div
      className={`inline-flex items-center rounded-md bg-white shadow border border-slate-200 px-2 py-1 space-x-2 `}
    >
      <div className="flex items-center">
        <button onClick={() => setIsMinimized(!isMinimized)} className="z-10">
          <div className="w-7 h-7 flex justify-center items-center bg-blue-700 rounded-full">
            <ChevronIcon
              className={`transform ${
                isMinimized ? "rotate-90" : "-rotate-90"
              }`}
              style={{ color: "#F9FBFE" }}
              strokeWidth="2"
            />
          </div>
        </button>
        <button
          onClick={handleTodayClick}
          className={`rounded px-4 py-[5px] bg-white `}
        >
          <div
            className={`text-center  font-medium ${
              myCalendarTimelineSelected === 3
                ? "text-slate-400"
                : "text-slate-600"
            }`}
          >
            Today
          </div>
        </button>
      </div>

      {!isMinimized && <Divider type="vertical" className="h-6 bg-grey-200" />}

      {!isMinimized && (
        <div className="flex items-center">
          {timeframes.map((timeframe, index) => (
            <button
              key={index}
              onClick={() => {
                handleTimeframeClick(index);
              }}
              className={`rounded px-4 py-[5px] justify-center ${
                selected === index ? "bg-gray-50" : ""
              }`}
            >
              <div
                className={`text-center ${
                  selected === index
                    ? " text-gray-900 font-medium"
                    : "text-slate-600 font-light"
                }`}
              >
                {timeframes[index]}
              </div>
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default MyCalendarNavigator;
