import React, { useEffect, useState } from "react";

import { AttachmentIcon } from "../../../Core/svgV2/AttachmentIcon";
import { PlusIcon } from "../../../Core/svgV2/PlusIcon";
import { useParams, useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";
import { updateGoal } from "../../../Core/redux/api/scheduleAPI";
import usePermission from "../../../Core/hooks/usePermission";
import { updateSchedule } from "../../../Core/redux/slices/schedule.slice";
import Attachments from "../Task/Attachments";
import {
  createMyBoardTask,
  getMyBoardTaskById,
  getMyBoardTasks,
  updateMyBoardTask,
} from "../../../Core/redux/api/myBoardAPI";
import { ChevronIcon } from "../../../Core/svgV2/Chevron";
import { getPriorityDetails } from "../../../Core/utils/scheduleUtils";
import { Popover, Tooltip } from "antd";
import PriorityFlag from "../../../Core/svgV2/PriorityFlag";
import { CheckIcon } from "../../../Core/svgV2/Check";
import { TaskInput, UserSelect } from "../Task/TaskReusable";
import getUsersName from "../../../Core/utils/getUserName";
import CustomAvatar from "../../../Core/CommonV2/CustomAvatar";
import CustomSelect from "../../../Core/CommonV2/CustomSelect";
import { updateMyBoard } from "../../../Core/redux/slices/myBoard.slice";
import { StatusSelect } from "./TaskReusable";

export const SubtaskUserSelect = ({
  placeholder,
  task,
  setTask,
  onEdit,
  collaboratorsId,
  disabled,
}) => {
  const [showSelect, setShowSelect] = useState(false);
  const { tenantMembers } = useAppSelector((state) => state.tenant);
  const { user } = useAppSelector((state) => state.userDetails);
  const { currentSelectedTask } = useAppSelector((state) => state.myBoard);
  const [currentSelectedUser, setCurrentSelectedUser] = useState(null);
  const { taskId } = useParams();

  const collaboratorsList =
    currentSelectedTask?.collaborators?.length > 0
      ? tenantMembers.filter((member) => {
          return currentSelectedTask.collaborators.some(
            (id) => +id === +member.id
          );
        })
      : [user];
  useEffect(() => {
    let userId = "";

    userId = task.assigneeId;

    const user = collaboratorsList.find((user) => +user == +userId);
    if (user) {
      setCurrentSelectedUser(user);
    } else {
      setCurrentSelectedUser(null);
    }
  }, [task]);

  const handleMenuItemClick = (user) => {
    setCurrentSelectedUser(user);
    setShowSelect(false);
    setTask({
      ...task,
      assigneeId: user.userId,
    });

    onEdit(user.userId);
  };

  if (!showSelect) {
    return (
      <button
        className="flex border border-transparent hover:border-gray-200 w-full rounded pl-2 h-[30px] cursor-pointer items-center "
        onClick={() => setShowSelect(true)}
        disabled={disabled}
      >
        {currentSelectedUser ? (
          <div className=" flex my-2 mx-2 items-center">
            <CustomAvatar
              src={currentSelectedUser?.profilePicture}
              size={24}
              title={getUsersName(currentSelectedUser)}
              whiteText
              color={currentSelectedUser?.profilePictureColorCode}
            />

            <p className="pl-2 text-gray-900 capitalize out-500-14 ">
              {getUsersName(currentSelectedUser)}
            </p>
          </div>
        ) : (
          <p className="pl-2 leading-5 text-gray-500 capitalize out-300-14">
            Select Assignee
          </p>
        )}
      </button>
    );
  }

  return (
    <CustomSelect
      placeholder={placeholder}
      innerClassName="flex w-full min-w-[200px]"
      onDropdownVisibleChange={(open) => !open && setShowSelect(false)}
      defaultOpen
      showSearch
      disabled={disabled}
      options={collaboratorsList.map((user) => {
        return {
          id: user.userId,
          value: user.userId,
          label: getUsersName(user),

          render: () => (
            <div
              className="flex items-center w-full  "
              onClick={() => {
                handleMenuItemClick(user);
              }}
            >
              <CustomAvatar
                src={user.profilePicture}
                size={24}
                title={getUsersName(user)}
                whiteText
                color={user.profilePictureColorCode}
              />

              <p className="pl-2 text-gray-900 capitalize out-300-12 ">
                {getUsersName(user)}
              </p>
            </div>
          ),
        };
      })}
    />
  );
};
const AddSubtask = ({
  subtasks,
  task,
  setTask,
  setShowAddTask,
  setEditedTaskDetails,
}) => {
  const { taskId, projectId } = useParams();
  const [showSubtask, setShowSubtask] = useState(true);
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const [allSubtasks, setAllSubtasks] = useState(task?.subtask || []);
  const [addMoreSubtask, setAddMoreSubtasks] = useState([]);
  const { user } = useAppSelector((state) => state.userDetails);
  const { myBoardTasks } = useAppSelector((state) => state.myBoard);
  const [edited, setEdited] = useState(false);

  useEffect(() => {
    if (task?.subtask?.length > 0) {
      setAllSubtasks(task.subtask);
    } else {
      setAddMoreSubtasks([
        {
          id: 1,
          name: "",
          priorityId: 1,
          assigneeId: null,
          status: 3,
        },
      ]);
    }
  }, [subtasks, task]);
  useEffect(() => {
    if (edited) {
      setEditedTaskDetails({ subtasks: allSubtasks });
      setEdited(false);
    }
  }, [allSubtasks]);

  const handleOnCancel = (subtask) => {
    const othereTask = addMoreSubtask.filter((t) => t.id !== subtask.id);
    setAddMoreSubtasks(othereTask);
    if (othereTask?.length === 0) {
      setShowAddTask(false);
    }
  };
  const handleSubtaskOnChange = async (value, key, id, isNew) => {
    if (isNew) {
      setAddMoreSubtasks((prevState) =>
        prevState.map((subtask) => {
          if (subtask.id === id) {
            return { ...subtask, [key]: value };
          }
          return subtask;
        })
      );
    } else {
      setAllSubtasks((prevState) =>
        prevState.map((subtask) => {
          if (subtask.id === id) {
            return { ...subtask, [key]: value };
          }
          return subtask;
        })
      );
      setEdited(true);

      // await dispatch(
      //   updateMyBoardTask({
      //     [key]: value,
      //     goalId: id,
      //   })
      // );
      // await dispatch(getMyBoardTaskById({ taskId: taskId }));
    }
  };

  const handleSave = async (subtask) => {
    handleOnCancel(subtask);
    if (taskId !== "new") {
      const payload = {
        name: subtask.name,
        isCompleted: 0,
        isNewGoal: 0,
        statusId: task.statusId,
        priorityId: subtask.priorityId,
        goalType: 4,
        assigneeId: subtask.assigneeId,
        collaborators: [user.id],
        parentGoalId: taskId,
        isSubTask: true,
      };

      await dispatch(createMyBoardTask(payload));
      await dispatch(getMyBoardTaskById({ taskId: taskId }));

      const currentTask = myBoardTasks.result.find(
        (task) => +task.id === +taskId
      );
      // dispatch(
      //   updateMyBoard({
      //     key: "currentSelectedTask",
      //     value: {
      //       ...currentTask,
      //     },
      //   })
      // );
      setTask({ ...task, subtask: [subtask, ...task.subtask] });
    } else {
      setTask({ ...task, subtask: [subtask, ...task.subtask] });
    }
  };

  return (
    <div className="mt-4">
      <div
        className=" flex items-center gap-x-3 cursor-pointer"
        onClick={() => setShowSubtask(!showSubtask)}
      >
        <ChevronIcon
          className={`text-gray-500 mt-0.5 ${showSubtask ? "" : "-rotate-90"}`}
        />
        <p className="out-500-14 leading-5 text-black">Sub-tasks</p>
        <span className="out-300-12 text-gray-500">{subtasks?.length}</span>
      </div>
      {showSubtask && (
        <div className="space-y-3 mt-3">
          {allSubtasks?.map((subtask) => (
            <SubtaskItem
              key={subtask.id}
              subtask={subtask}
              isNew={false}
              handleOnCancel={handleOnCancel}
              handleSaveClick={handleSave}
              handleSubtaskOnChange={handleSubtaskOnChange}
              collaboratorsId={task?.collaborators}
            />
          ))}
          {addMoreSubtask.map((subtask) => (
            <SubtaskItem
              key={subtask.id}
              subtask={subtask}
              isNew={true}
              handleOnCancel={handleOnCancel}
              handleSaveClick={handleSave}
              handleSubtaskOnChange={handleSubtaskOnChange}
              collaboratorsId={task?.collaborators}
            />
          ))}
          <button
            onClick={() =>
              setAddMoreSubtasks([
                ...addMoreSubtask,
                {
                  id: addMoreSubtask?.length + 1,
                  name: "",
                  priorityId: 1,
                  assigneeId: null,
                  status: 3,
                },
              ])
            }
            className="flex items-center h-[30px]  cursor-pointer "
          >
            <PlusIcon className="text-gray-500" />
            <p className="pl-2 out-500-14 leading-5 text-gray-500 capitalize">
              Add More
            </p>
          </button>
        </div>
      )}
    </div>
  );
};
const PrioritySelect = ({ currentPriority, setCurrentPriority }) => {
  const getLable = (priority) => {
    const { color, priorityName } = getPriorityDetails(priority);
    return (
      <div className="flex items-center">
        <PriorityFlag
          style={{
            color,
          }}
        />
        <p className="out-300-14 ml-2" style={{ color }}>
          {priorityName}
        </p>
      </div>
    );
  };
  return (
    <div className="w-[216px] border border-gray-200 shadow-lg bg-white  rounded-lg">
      <div
        onClick={() => setCurrentPriority(1)}
        className="flex cursor-pointer hover:bg-gray-50 justify-between first:rounded-t-lg px-[14px] py-2.5 "
      >
        {getLable(1)}
        {currentPriority === 1 ? <CheckIcon /> : <></>}
      </div>
      <div
        onClick={() => setCurrentPriority(2)}
        className="flex cursor-pointer hover:bg-gray-50 justify-between  px-[14px] py-2.5 "
      >
        {getLable(2)}
        {currentPriority === 2 ? <CheckIcon /> : <></>}
      </div>
      <div
        onClick={() => setCurrentPriority(3)}
        className="flex cursor-pointer hover:bg-gray-50 justify-between  px-[14px] py-2.5 "
      >
        {getLable(3)}
        {currentPriority === 3 ? <CheckIcon /> : <></>}
      </div>
      <div
        onClick={() => setCurrentPriority(4)}
        className="flex cursor-pointer hover:bg-gray-50 justify-between last:rounded-b-lg px-[14px] py-2.5 "
      >
        {getLable(4)}
        {currentPriority === 4 ? <CheckIcon /> : <></>}
      </div>
    </div>
  );
};

const SubtaskItem = ({
  isNew = false,
  subtask,
  handleOnCancel,
  handleSaveClick,
  handleSubtaskOnChange,
  collaboratorsId,
}) => {
  const { userList } = useAppSelector((state) => state.overview);
  const [currentSelectedUser, setCurrentSelectedUser] = useState(null);
  const { color } = getPriorityDetails(subtask.priorityId);

  useEffect(() => {
    const currentUser = userList.find((u) => u.userId === subtask.assigneeId);
    setCurrentSelectedUser(currentUser || null);
  }, [subtask]);
  const dispatch = useAppDispatch();

  return (
    <div
      className={`w-full h-[36px] flex justify-between  items-center px-3 py-2 bg-white  rounded-lg border border-gray-200 ${
        isNew ? "shadow-m" : ""
      }`}
    >
      <div className="flex  items-center gap-x-2.5 w-full ">
        <img src="/images/v2/schedule/Subtask.svg" />
        {!isNew && (
          <div className="out-300-12 text-gray-500 min-w-[50px]   ">
            {"SMB-"}
            {subtask.identifier ? subtask.identifier : subtask.id}
          </div>
        )}
        <div className="w-[95%]  out-300-14 text-gray-700 cursor-auto">
          {
            <TaskInput
              placeholder="Enter a title"
              inputClassName="subtask-input"
              autoSize={true}
              value={subtask.name}
              type="input"
              onChange={(e) =>
                handleSubtaskOnChange(e.target.value, "name", subtask.id, isNew)
              }
            />
          }
        </div>
      </div>

      <div className=" flex justify-end ">
        {/* <StatusSelect
          task={subtask.statusId || 1}
          setTask={async (e) => {
            handleSubtaskOnChange(
              e.target.value,
              "statusId",
              subtask.id,
              isNew
            );
            await dispatch(
              updateMyBoardTask({ statusId: e, goalId: subtask.id })
            );
          }}
        /> */}
        <Popover
          content={
            <PrioritySelect
              currentPriority={subtask.priorityId}
              setCurrentPriority={(e) =>
                handleSubtaskOnChange(e, "priorityId", subtask.id, isNew)
              }
            />
          }
          arrow={false}
          placement="bottomLeft"
          trigger={["click"]}
        >
          <Tooltip title="Priority: Low">
            <button>
              <PriorityFlag style={{ color }} height="16" width="16" />
            </button>
          </Tooltip>
        </Popover>

        <Popover
          content={
            <SubtaskUserSelect
              disabled={false}
              onEdit={(e) =>
                handleSubtaskOnChange(e, "assigneeId", subtask.id, isNew)
              }
              placeholder={"Select Assignee"}
              setTask={() => console.log("")}
              task={subtask}
              collaboratorsId={collaboratorsId}
            />
          }
          arrow={false}
          placement="bottomLeft"
          trigger={["click"]}
        >
          <button className="ml-2 min-h-[20px] min-w-5">
            <Tooltip
              title={
                currentSelectedUser
                  ? getUsersName(currentSelectedUser)
                  : "Unassigned"
              }
            >
              <div>
                {currentSelectedUser ? (
                  <CustomAvatar
                    src={currentSelectedUser?.profilePicture}
                    title={getUsersName(currentSelectedUser)}
                    size={20}
                    whiteText
                    fontSize={"10px"}
                    color={currentSelectedUser?.profilePictureColorCode}
                  />
                ) : (
                  <img src="/images/v2/schedule/Avatar.svg" />
                )}
              </div>
            </Tooltip>
          </button>
        </Popover>

        {isNew && (
          <>
            <button
              onClick={() => handleSaveClick(subtask)}
              className="out-500-14 text-primary-700 mx-3"
            >
              Save
            </button>
            <button
              className="out-500-14 text-gray-500"
              onClick={() => handleOnCancel(subtask)}
            >
              Cancel
            </button>
          </>
        )}
      </div>
    </div>
  );
};
const AttachmentsAndSubtask = ({
  task,
  setAttachments,
  setSubtasks,
  setEditedSubtaskDetails,
  setEditedAttachmentDetails,
}) => {
  const { taskId } = useParams();
  const dispatch = useAppDispatch();
  const [showUploadAttachments, setShowUploadAttachments] = useState(false);
  const [showAddTask, setShowAddTask] = useState(false);
  return (
    <>
      <div className="mt-4 flex items-center gap-x-5">
        {task.attachments?.length === 0 && (
          <button
            onClick={() => setShowUploadAttachments(!showUploadAttachments)}
            className="flex items-center h-[30px] cursor-pointer "
          >
            <AttachmentIcon className="text-gray-500" />
            <p className="pl-2 out-500-14 leading-5 text-gray-500 capitalize">
              Attach
            </p>
          </button>
        )}
        {task.subtask?.length === 0 && !showUploadAttachments && !showAddTask && (
          <button
            className="flex items-center h-[30px] cursor-pointer "
            onClick={() => setShowAddTask(true)}
          >
            <PlusIcon className="text-gray-500" />
            <p className="pl-2 out-500-14 leading-5 text-gray-500 capitalize">
              Add Sub-task
            </p>
          </button>
        )}
      </div>

      <Attachments
        showUploadAttachments={showUploadAttachments}
        setShowUploadAttachments={setShowUploadAttachments}
        attachments={task.attachments || []}
        handleUpload={async (e) => {
          setAttachments((task) => {
            return { ...task, attachments: e };
          });
          setEditedAttachmentDetails({ attachments: JSON.stringify(e) });

          if (taskId !== "new") {
            await dispatch(
              updateMyBoardTask({
                attachments: JSON.stringify(e),
                goalId: taskId,
              })
            );
          }
          setTimeout(() => setShowUploadAttachments(false), 1000);
        }}
      />

      {task.subtask?.length === 0 && showUploadAttachments && (
        <button
          onClick={() => setShowAddTask(true)}
          className="flex items-center h-[30px] mt-4 cursor-pointer "
        >
          <PlusIcon className="text-gray-500" />
          <p className="pl-2 out-500-14 leading-5 text-gray-500 capitalize">
            Add Sub-task
          </p>
        </button>
      )}

      {(task.subtask?.length > 0 || showAddTask) && (
        <AddSubtask
          subtasks={task.subtask || []}
          setTask={setSubtasks}
          task={task}
          setShowAddTask={setShowAddTask}
          setEditedTaskDetails={setEditedSubtaskDetails}
        />
      )}
    </>
  );
};

export default AttachmentsAndSubtask;
