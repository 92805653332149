import React, { useEffect, useState } from "react";
import TrendDownIcon, { TrendUpIcon } from "../../../Core/svgV3/Trend";
import { useAppSelector } from "../../../Core/redux/hooks";

const TrendIndicator = ({
  percent = 0,
  trend = "up",
  dateFilter = 1,
  showInWhite = false,
  isNoTrend = false,
  trendDetails = null,
}) => {
  let color = "#4EAF76";
  if (trend === "up") color = "#F26849";
  const [comparisonMetric, setComparisonMetric] = useState("");
  const [showNoTrend, setShowNoTrend] = useState(false);
  const {
    selectedDate,
    todayGCO2value,
    previousDayGCO2value,
    activityGraphData,
  } = useAppSelector((state) => state.footPrintDetails);

  useEffect(() => {
    console.log(trendDetails, "state status of trends");
  }, [trendDetails]);

  function getComparisonMetric() {
    switch (dateFilter) {
      case 1:
        return "yesterday";

      case 2:
        return "today";
      case 3:
        return "this week";
      case 4:
        return "this month";
      case 5:
        return "this year";
    }
  }
  function getNoTrendData() {
    if (selectedDate) {
      if (todayGCO2value === 0) {
        return true;
      }
    } else {
      switch (dateFilter) {
        case 1:
          if (previousDayGCO2value === 0) {
            return true;
          } else {
            return false;
          }

        case 2:
          if (todayGCO2value === 0) {
            return true;
          } else {
            return false;
          }
        default:
          if (activityGraphData?.totalTrendDcf === 0) {
            return true;
          } else {
            return false;
          }
      }
    }
  }
  useEffect(() => {
    const comparisonMetric = getComparisonMetric();
    setComparisonMetric(comparisonMetric);
    const noTrendValue = getNoTrendData();
    setShowNoTrend(noTrendValue);
  }, [dateFilter]);

  return (
    <>
      {showNoTrend ? (
        <div
          className={` flex rounded-2xl gap-x-1 max-w-max h-[22px] px-2 items-center  ${"bg-gray-50"}`}
        >
          <div className="flex items-center">
            <p className="text-[12px] leading-[15px] font-semibold text-gray-500 ">
              No Trend Data Available
            </p>
          </div>
        </div>
      ) : (
        <div
          className={` flex rounded-2xl gap-x-1 max-w-max h-[22px] px-2 items-center  ${
            trend === "up" ? "bg-error-50" : "bg-success-50"
          }`}
        >
          {trend === "up" ? (
            <TrendUpIcon
              className={` ${showInWhite ? "text-[#fff]" : `text-[${color}]`}`}
            />
          ) : (
            <TrendDownIcon
              className={` ${showInWhite ? "text-[#fff]" : `text-[${color}]`}`}
            />
          )}
          <div className="flex items-center">
            <p
              className="text-[12px] leading-[15px] font-semibold"
              style={{ color: showInWhite ? "#fff" : color }}
            >
              {/* {trend === "up" ? "+" : "-"} */}
              {trend === "up" ? "+" : ""}
              {percent}% than {selectedDate ? "today" : comparisonMetric}
            </p>
          </div>
        </div>
      )}
    </>
  );
};

export default TrendIndicator;
