import React, { useEffect, useState } from "react";
import { UserProfile } from "../../Core/LayoutV2/Header";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAppSelector } from "../../Core/redux/hooks";
import { ChevronIcon } from "../../Core/svgV2/Chevron";
import { Popover } from "antd";
import CustomSearchBox from "../../Core/CommonV2/CustomSearchBox";
import CustomAvatar from "../../Core/CommonV2/CustomAvatar";
import useSearch from "../../Core/hooks/useSearch";
import { CheckIcon } from "../../Core/svgV2/Check";

const GroupName = ({
  icon = (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.5 15L7.5 10L12.5 5"
        stroke="#667085"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  title = "Settings",
}) => {
  return (
    <div className="flex flex-row justify-start gap-2 relative w-full h-8 items-center px-6 rounded-lg">
      <div className="min-h-0 min-w-0 relative w-5 shrink-0">{icon}</div>
      <div className="text-sm  font-medium leading-[20px] text-black relative">
        {title}
      </div>
    </div>
  );
};

const NavItem = ({ isSelected, title, url }) => {
  const navigate = useNavigate();
  return (
    <div
      className={`ml-10 rounded-lg mr-2 mt-1 ${isSelected && "bg-primary-50"}`}
      role="button"
      onClick={() => navigate(url)}
    >
      <div
        className={`pl-3 py-[5px] ${
          isSelected
            ? "out-500-14 text-primary-600"
            : "out-300-14 text-gray-500"
        }`}
      >
        {title}
      </div>
    </div>
  );
};

export const SearchProjects = ({ onProjectClick, currentSelectedProject }) => {
  const { projectList } = useAppSelector((state) => state.projects);
  const [searchParams] = useSearchParams();
  const projectId = searchParams.get("projectId");

  const removeCurrentProjectFromList = projectList.filter(
    (project) => +project.id !== +projectId
  );

  const { search, setSearch, filteredData } = useSearch(
    removeCurrentProjectFromList,
    ["title"]
  );
  return (
    <div className="w-[320px] max-h-[320px] overflow-y-auto  bg-white  border border-gray-200 rounded-lg shadow-lg py-1 ">
      <div className="py-2.5 px-[14px]">
        <CustomSearchBox
          placeholder="Search Project"
          search={search}
          setSearch={setSearch}
        />
      </div>
      {filteredData.map((project) => (
        <div
          key={project.id}
          role="button"
          onClick={() => onProjectClick(project)}
          className={`py-2.5 flex items-center gap-x-2 px-[14px] ${
            currentSelectedProject.id === project.id && "bg-gray-100"
          }`}
        >
          <CustomAvatar
            title={project.title}
            whiteText
            src={project.projectImage}
            size={24}
            fontSize={10}
          />
          {/* color={project.projectColor} */}
          <div className="out-300-14 text-gray-900 flex-1">{project.title}</div>
          {currentSelectedProject.id === project.id && (
            <CheckIcon className="text-primary-600" />
          )}
        </div>
      ))}
    </div>
  );
};

export const ProjectSettingHeader = () => {
  const { projectList } = useAppSelector((state) => state.projects);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [currentSelectedProject, setCurrentSelectedProject] = useState({
    id: "",
    title: "",
  });

  useEffect(() => {
    if (searchParams.has("projectId")) {
      const projectId = searchParams.get("projectId");
      if (projectId !== currentSelectedProject.id) {
        const project = projectList.find((p) => p.id === projectId);
        if (project) {
          setCurrentSelectedProject(project);
        }
      }
    }
  }, [searchParams, projectList, currentSelectedProject.id]);

  const isMyBoard =
    searchParams.has("myboard") && searchParams.get("myboard") === "true";

  if (isMyBoard) {
    return (
      <div className="flex out-300-14 text-gray-500 items-center gap-x-1.5">
        <div className="out-500-14">My Board Settings</div>
        <div>/</div>
        <div className="out-500-14 text-black">Labels</div>
      </div>
    );
  }

  if (projectList.length === 0) {
    return <div className="out-500-14">Project Settings</div>;
  }

  return (
    <div className="flex out-300-14 text-gray-500 items-center gap-x-1.5">
      <div>Project Settings</div>
      <div>/</div>
      <Popover
        trigger={"click"}
        onOpenChange={(val) => setPopoverOpen(val)}
        content={
          <SearchProjects
            onProjectClick={(project) => {
              setCurrentSelectedProject(project);
              setPopoverOpen(false);
              setSearchParams((searchParams) => {
                searchParams.set("projectId", project.id);
                return searchParams;
              });
            }}
            currentSelectedProject={currentSelectedProject}
          />
        }
        placement="bottomLeft"
        arrow={false}
        open={popoverOpen}
      >
        <div
          role="button"
          className="flex cursor-pointer items-center gap-x-1.5"
        >
          <div className="out-500-14 text-black">
            {currentSelectedProject.id
              ? currentSelectedProject.title
              : "No Project Selected"}
          </div>
          <ChevronIcon />
        </div>
      </Popover>
    </div>
  );
};

const Header = () => {
  const [searchParams] = useSearchParams();
  const workspaceSettings = [
    "WorkspaceRoles",
    "WorkspaceMembers",
    "Integrations",
    "Start-Guide",
  ];

  if (searchParams.has("tab")) {
    if (workspaceSettings.includes(searchParams.get("tab"))) {
      return <div className="out-500-14">Workspace Settings</div>;
    }

    return <ProjectSettingHeader />;
  }

  return <div className="out-500-14">No Tab Selected</div>;
};

const SettingsLayout = ({ children }) => {
  const [searchParams] = useSearchParams();
  const { user } = useAppSelector((state) => state.userDetails);
  const { projectDetails } = useAppSelector((state) => state.overview);

  const navigate = useNavigate();
  const isSelected = (val, isMyBoard = false) => {
    if (!searchParams.has("tab")) return false;
    if (isMyBoard) {
      return (
        searchParams.get("tab") === val &&
        searchParams.get("myboard") === "true"
      );
    }
    return searchParams.get("tab") === val && !searchParams.has("myboard");
  };

  const getUrl = (tabName: string, isMyBoard = false) => {
    if (isMyBoard) {
      return `/settingsV2?tab=${tabName}&myboard=true`;
    }
    const baseUrl = `/settingsV2?tab=${tabName}`;
    if (searchParams.has("projectId")) {
      return baseUrl + `&projectId=${searchParams.get("projectId")}`;
    }
    return baseUrl;
  };

  const handleBack = () => {
    const url = localStorage.getItem("settingsRedirectUrl");
    if (url) {
      navigate(url);
    } else {
      navigate(-1);
    }
  };
  return (
    <div className="flex bg-white ">
      <div className="w-[12.5625rem] h-[100vh] pt-[0.5rem] flex flex-col relative border-r  border-slate-200">
        <div role="button" onClick={() => handleBack()}>
          <GroupName />
        </div>

        <div className="flex-1">
          <div className="mt-[2.5rem]">
            <GroupName
              title="Workspace"
              icon={
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14.5833 9.16667H10.4167M14.5833 12.5H10.4167M14.5833 5.83333H10.4167M7.5 2.5L7.5 17.5M6.5 2.5H13.5C14.9001 2.5 15.6002 2.5 16.135 2.77248C16.6054 3.01217 16.9878 3.39462 17.2275 3.86502C17.5 4.3998 17.5 5.09987 17.5 6.5V13.5C17.5 14.9001 17.5 15.6002 17.2275 16.135C16.9878 16.6054 16.6054 16.9878 16.135 17.2275C15.6002 17.5 14.9001 17.5 13.5 17.5H6.5C5.09987 17.5 4.3998 17.5 3.86502 17.2275C3.39462 16.9878 3.01217 16.6054 2.77248 16.135C2.5 15.6002 2.5 14.9001 2.5 13.5V6.5C2.5 5.09987 2.5 4.3998 2.77248 3.86502C3.01217 3.39462 3.39462 3.01217 3.86502 2.77248C4.3998 2.5 5.09987 2.5 6.5 2.5Z"
                    stroke="#292927"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              }
            />
            {+user.roleId !== 4 && (
              <>
                <NavItem
                  title={"Roles"}
                  isSelected={isSelected("WorkspaceRoles")}
                  url={getUrl("WorkspaceRoles")}
                />
                <NavItem
                  title={"Members"}
                  isSelected={isSelected("WorkspaceMembers")}
                  url={getUrl("WorkspaceMembers")}
                />
              </>
            )}
            <NavItem
              title={"Integrations"}
              isSelected={isSelected("Integrations")}
              url={getUrl("Integrations")}
            />
          </div>

          <div className="border-solid border-gray-200 relative w-full h-px  border-t my-4 rounded-none" />
          <GroupName
            title="Project"
            icon={
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.8327 5.83333L9.90306 3.9741C9.63552 3.439 9.50174 3.17144 9.30216 2.97597C9.12567 2.80311 8.91295 2.67164 8.67941 2.59109C8.41532 2.5 8.11619 2.5 7.51793 2.5H4.33268C3.39926 2.5 2.93255 2.5 2.57603 2.68166C2.26243 2.84144 2.00746 3.09641 1.84767 3.41002C1.66602 3.76654 1.66602 4.23325 1.66602 5.16667V5.83333M10.8327 5.83333H1.66602M10.8327 5.83333H14.3327M1.66602 5.83333H14.3327M1.66602 5.83333V13.5C1.66602 14.9001 1.66602 15.6002 1.9385 16.135C2.17818 16.6054 2.56063 16.9878 3.03104 17.2275C3.56582 17.5 4.26588 17.5 5.66602 17.5H14.3327C15.7328 17.5 16.4329 17.5 16.9677 17.2275C17.4381 16.9878 17.8205 16.6054 18.0602 16.135C18.3327 15.6002 18.3327 14.9001 18.3327 13.5V9.83333C18.3327 8.4332 18.3327 7.73314 18.0602 7.19836C17.8205 6.72795 17.4381 6.3455 16.9677 6.10582C16.4329 5.83333 15.7328 5.83333 14.3327 5.83333"
                  stroke="#292927"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            }
          />
          <NavItem
            title={"General"}
            isSelected={isSelected("General")}
            url={getUrl("General")}
          />
          {projectDetails.hasPhases && (
            <NavItem
              title={"Phases"}
              isSelected={isSelected("Phases")}
              url={getUrl("Phases")}
            />
          )}
          <NavItem
            title={"Roles & Permissions"}
            isSelected={isSelected("ProjectRoles")}
            url={getUrl("ProjectRoles")}
          />
          <NavItem
            title={"Members"}
            isSelected={isSelected("ProjectMembers")}
            url={getUrl("ProjectMembers")}
          />
          <NavItem
            title={"Preferences"}
            isSelected={isSelected("Preferences")}
            url={getUrl("Preferences")}
          />
          <NavItem
            title={"Notifications"}
            isSelected={isSelected("Notifications")}
            url={getUrl("Notifications")}
          />
          <NavItem
            title={"Labels"}
            isSelected={isSelected("Labels")}
            url={getUrl("Labels")}
          />
          <div className="border-solid border-gray-200 relative w-full h-px  border-t my-4 rounded-none" />
          <GroupName
            title="My Board"
            icon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="none"
                viewBox="0 0 20 20"
              >
                <g>
                  <path
                    stroke="#292927"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M10.83 5.833l-.929-1.859c-.267-.535-.401-.803-.6-.998a1.667 1.667 0 00-.624-.385C8.413 2.5 8.114 2.5 7.516 2.5H4.331c-.934 0-1.4 0-1.757.182-.314.16-.568.414-.728.728-.182.357-.182.823-.182 1.757v.666m9.167 0H1.664m9.167 0h3.5m-12.667 0h12.667m-12.667 0V13.5c0 1.4 0 2.1.273 2.635a2.5 2.5 0 001.092 1.092c.535.273 1.235.273 2.635.273h8.667c1.4 0 2.1 0 2.635-.273a2.5 2.5 0 001.092-1.092c.273-.535.273-1.235.273-2.635V9.833c0-1.4 0-2.1-.273-2.635a2.5 2.5 0 00-1.092-1.092c-.535-.273-1.235-.273-2.635-.273"
                  ></path>
                </g>
              </svg>
            }
          />
          <NavItem
            isSelected={isSelected("Labels", true)}
            title="Labels"
            url={getUrl("Labels", true)}
          />
        </div>

        <div className="pt-1 border-t pb-3 border-gray-200">
          <GroupName
            title="Help Centre"
            icon={
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10 17.5C14.1421 17.5 17.5 14.1421 17.5 10C17.5 5.85786 14.1421 2.5 10 2.5C5.85786 2.5 2.5 5.85786 2.5 10C2.5 14.1421 5.85786 17.5 10 17.5Z"
                  stroke="#292927"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M10 15C10.5178 15 10.9375 14.5803 10.9375 14.0625C10.9375 13.5447 10.5178 13.125 10 13.125C9.48223 13.125 9.0625 13.5447 9.0625 14.0625C9.0625 14.5803 9.48223 15 10 15Z"
                  fill="#292927"
                />
                <path
                  d="M10 11.2502V10.6252C10.4326 10.6252 10.8556 10.497 11.2153 10.2566C11.575 10.0162 11.8554 9.67458 12.021 9.27487C12.1866 8.87515 12.2299 8.43532 12.1455 8.01099C12.0611 7.58665 11.8527 7.19688 11.5468 6.89095C11.2409 6.58502 10.8511 6.37668 10.4268 6.29228C10.0024 6.20787 9.56259 6.25119 9.16288 6.41676C8.76317 6.58233 8.42153 6.8627 8.18116 7.22244C7.9408 7.58217 7.8125 8.0051 7.8125 8.43775"
                  stroke="#292927"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            }
          />
          <NavItem
            title={"Start Guide"}
            isSelected={isSelected("Start-Guide")}
            url={getUrl("Start-Guide")}
          />
        </div>
      </div>
      <div className="flex-1 flex flex-col">
        <div className="flex justify-between py-[11px] px-6 items-center h-[46px] border-b border-gray-200">
          <Header />
          <UserProfile size={24} />
        </div>
        <div className="flex justify-center flex-1 overflow-y-scroll max-h-[calc(100vh-46px)] min-h-[calc(100vh-46px)] pt-6">
          <div className="min-w-[667px]">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default SettingsLayout;
