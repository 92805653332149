import React from "react";

export const DCFRoundFrostySVG = ({ cx, cy }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="79"
      height="78"
      fill="none"
      viewBox="0 0 79 78"
      x={cx - 50}
      y={cy - 50}
    >
      <g filter="url(#filter0_dd_4284_4451)">
        <rect
          width="52.5"
          height="52.5"
          x="13.75"
          y="0.75"
          stroke="#fff"
          strokeWidth="1.5"
          rx="26.25"
        ></rect>
        <circle cx="40" cy="27" r="27" fill="#FEEFEE"></circle>
        <mask
          id="mask0_4284_4451"
          style={{ maskType: "luminance" }}
          width="51"
          height="50"
          x="14"
          y="2"
          maskUnits="userSpaceOnUse"
        >
          <path
            fill="#fff"
            d="M39.921 52C53.694 52 64.86 40.834 64.86 27.06c0-13.772-11.166-24.938-24.939-24.938S14.982 13.288 14.982 27.061 26.148 52 39.921 52z"
          ></path>
        </mask>
        <g mask="url(#mask0_4284_4451)">
          <path
            fill="#D1E9FF"
            d="M83.821 1.224H1.162v53.783h82.659V1.224z"
          ></path>
          <path
            fill="#fff"
            d="M57.676 35.46c1.399-6.993 1.343-11.399-1.031-16.737-3.367-7.57-11.297-11.188-16.873-11.14-8.74.102-17.261 6.703-18.572 18.426-.102.912-.35 5.3.825 9.37.096.257.173.521.23.79.273 1.909-1.398 3.52-3.72 5.632a53.761 53.761 0 00-6.712 7.542C.572 64.849 2.463 83.292 4.78 91.533c4.57 15.642 17.594 23.19 22.786 26.074a23.03 23.03 0 008.296-4.335 22.666 22.666 0 003.762-3.961 13.7 13.7 0 00.934 1.975c.146.255.67.962 1.706 2.364.382.536.864.993 1.42 1.346.575.4 1.171.768 1.786 1.105a15.93 15.93 0 003.762 1.321 32.094 32.094 0 008.684-4.122s10.185-6.762 15.587-19.52c12.593-37.186-17.125-51.874-15.828-58.32z"
          ></path>
          <path
            fill="#F2F0E4"
            d="M39.31 7.58v16.782c-.08.053-.7.745-.7.745-.523-1.287-1.247-3.08-2.999-4.671-.87-.79-2.426-2.2-4.115-1.944-2.73.409-4.13 4.937-4.479 7.6-.07.529-.65 5.62 2.745 9.675 3.24 3.874 7.388 3.545 7.541 5.391.2 2.304-6.615 3.846-11.537 8.353-5.647 5.178-18.447 21.645-12.416 48.724 1.014 4.545 4.787 11.3 6.965 14.768-6.314-4.154-17.436-13.635-17.016-37.214.213-12.007 3.496-19.01 8.803-26.502.678-.958 5.077-5.762 8.986-9.821 1.681-1.748 1.458-2.961 1.216-4.143a48.47 48.47 0 01-.92-7.066c-.059-1.073.536-9.09 4.896-14.1 1.017-1.172 3.975-3.804 6.39-4.895a18.81 18.81 0 014.958-1.55c.69-.097 1.217-.107 1.682-.132z"
          ></path>
          <path
            fill="#F2F0E4"
            d="M73.637 93.846c-2.122 5.364-7.258 12.779-14.093 18.265-.62.496-1.259.923-1.853 1.342 3.377-3.146 9.849-15.642 10.646-22.026 1.049-8.374-.273-31.799-15.034-42.305-5.087-3.612-10.262-5.461-10.164-7.968.105-2.626 5.213-2.49 8.258-7.734 2.483-4.283 1.637-8.775 1.437-9.845-.475-2.514-1.44-3.717-2.028-4.297-.5-.493-1.293-1.283-2.401-1.378-.633-.059-1.906.067-4.332 2.846-3.147 3.609-3.399 6.14-4.521 6.087-.58-.024-.87-.716-1.287-1.748 0 0 .623-.699.7-.745V7.663a14.44 14.44 0 012.776.053 16.73 16.73 0 015.723 1.748c1.888.994 3.64 2.23 5.21 3.675 2.915 2.716 4.3 6.377 4.82 7.723 1.511 3.898.973 9.905.739 11.076-.235 1.171-.35 2.654-.528 3.905-.35 2.185 2.971 6.031 3.597 6.615C83.29 62.995 75.55 87.724 75.55 87.724c-.444 1.7-1.625 5.423-1.912 6.122z"
          ></path>
          <path
            fill="#1C0E2F"
            d="M76.398 83.575a45.076 45.076 0 01-3.23 11.614c-1.986 4.5-4.259 7.416-5.997 9.618a51.013 51.013 0 01-3.566 3.78 41.78 41.78 0 01-3.146 2.933c-.94.766-2.05 1.441-2.759 1.937 2.126-1.986 3.612-3.395 4.689-4.674.59-.7 1.625-2.144 3.496-4.79a41.408 41.408 0 001.703-2.602c.412-.699.853-1.496 1.3-2.401a38.55 38.55 0 001.986-4.895c.042-.13.619-2.126.608-2.189 7.297-29.148-12.866-44.277-17.065-47.2H54.4c-1.825-1.272-6.07-3.013-6.213-4.108-.171-1.304 2.045-1.856 4.101-4.51a13.856 13.856 0 002.549-5.968c.234-1.171 1.244-6.178-1.644-10.464-.807-1.196-2.24-3.322-4.597-3.57-2.531-.262-4.545 1.818-5.175 2.465-3.073 3.168-2.873 7.388-3.734 7.342-.86-.045-.35-4.098-3.181-7.24-.55-.609-2.227-2.48-4.44-2.382-2.214.098-3.63 2.056-4.553 3.283-3.206 4.315-2.506 9.472-2.335 10.56a12.787 12.787 0 002.238 5.806c2.251 3.06 4.985 3.455 4.86 4.864-.106 1.203-1.843 1.507-4.895 3.052a22.652 22.652 0 00-2.472 1.584l-.084.063C1.865 62.646 7.833 88.13 9.043 93.847a45.836 45.836 0 004.038 10.139 38.706 38.706 0 003.58 5.244 35.102 35.102 0 003.654 3.773 36.608 36.608 0 01-8.391-7.388c-6.853-8.286-8.108-17.45-8.741-22.026a48.976 48.976 0 01-.15-12.283A47.23 47.23 0 016.06 59.331a45.102 45.102 0 016.017-10.628 49.094 49.094 0 013.762-4.29c3.916-4.049 6.608-5.654 6.416-8.108-.049-.63-.234-.612-.605-2.447a30.23 30.23 0 01-.507-3.815 24.058 24.058 0 01.644-7.317 20.557 20.557 0 014.685-9.052c1.573-1.724 5.688-5.549 12.034-6.063 9.041-.73 14.873 5.975 15.596 6.832a20.627 20.627 0 013.693 6.87c.234.741 1.423 4.664.895 8.342-.056.416-.26 1.664-.514 3.622-.214 1.65-.315 2.476-.319 2.668-.104 4.486 15.919 13.964 18.667 34.962a45.568 45.568 0 01-.126 12.668z"
          ></path>
          <path
            fill="#F2F0E4"
            d="M32.387 31.987a18.18 18.18 0 006.272 5.21 2.23 2.23 0 002.05 0 13.716 13.716 0 002.247-1.48s.843-.698 3.52-3.67A12.377 12.377 0 0132.388 32v-.014z"
          ></path>
          <path
            fill="#FEF1F5"
            d="M48.82 28.306c1.134 0 2.052-.605 2.052-1.35 0-.745-.918-1.35-2.052-1.35-1.133 0-2.052.605-2.052 1.35 0 .745.919 1.35 2.052 1.35zM31.698 28.306c1.134 0 2.053-.605 2.053-1.35 0-.745-.919-1.35-2.052-1.35-1.134 0-2.053.605-2.053 1.35 0 .745.92 1.35 2.052 1.35z"
          ></path>
          <path
            fill="#010101"
            d="M50.725 23.848a.234.234 0 01-.35.304c-.622-.402-1.251-2.077-2.07-2.053-.818.025-1.681 1.714-2.181 2.01a.234.234 0 01-.354-.172.234.234 0 01.032-.149c.416-.72 1.276-3.028 2.399-3.06 1.049-.03 1.979 2.025 2.524 3.12zM34.849 23.848a.234.234 0 01-.35.304c-.622-.402-1.252-2.077-2.066-2.053-.815.025-1.682 1.714-2.186 2.01a.234.234 0 01-.353-.172.234.234 0 01.032-.149c.416-.72 1.276-3.028 2.398-3.06 1.05-.03 1.976 2.025 2.525 3.12z"
          ></path>
          <path
            fill="#EF4623"
            d="M38.89 24.544l-6.066 6.576a1.255 1.255 0 00-.178.94c.108.402.469.623 1.049.916.682.379 1.396.698 2.133.955 1.04.34 2.106.59 3.188.748.263.035.504.046.504.046h.255a8.105 8.105 0 001.622-.207c.287-.056.525-.108.675-.147 0 0 3.22-.755 4.402-2.17a.944.944 0 00.28-.64.922.922 0 00-.249-.539l-6.1-6.478a1.048 1.048 0 00-1.515 0z"
          ></path>
          <path
            fill="#E02C26"
            d="M39.75 34.736a12.96 12.96 0 001.874-.26c.434-.093.862-.21 1.283-.349.63-.209 1.248-.453 1.85-.73a5.095 5.095 0 001.71-1.196.944.944 0 00.28-.64.867.867 0 00-.25-.538c-2.037-2.266-4.604-4.895-5.593-5.986-.102-.108-.7-.846-1.2-.829v.938c-.034.36-.01 4.25.046 9.59z"
          ></path>
          <path
            fill="#B11F24"
            d="M32.632 31.613c1.145.366 2.313.656 3.496.867 1.167.207 2.35.31 3.535.308.587 0 1.178-.035 1.748-.088.57-.052 1.178-.14 1.766-.245a28.663 28.663 0 003.552-.888 10.412 10.412 0 01-3.332 1.748c-.603.199-1.222.349-1.85.448-.628.099-1.262.15-1.898.154a12.38 12.38 0 01-3.734-.556 10.197 10.197 0 01-3.283-1.748z"
          ></path>
          <path
            fill="url(#paint0_linear_4284_4451)"
            d="M39.048 8.318c-1.605.188-4.573.818-6.587 2.936-2.08 2.19-2.745 5.245-3.304 6.374 0 0 1.192-1.276 2.765-1.342a3.814 3.814 0 011.941.35 6.254 6.254 0 012.447 1.838c.61.675 1.131 1.426 1.55 2.234.26.52.477 1.062.646 1.62.185.552.524 2.097.524 2.097a1.049 1.049 0 011.357.119 13.25 13.25 0 01.584-1.916c.643-1.577.867-2.098 1.549-3.021a9.272 9.272 0 013.129-2.842 4.675 4.675 0 012.276-.7c.276-.024.95-3.083-.892-5.094-1.842-2.01-6.083-2.877-7.985-2.653z"
          ></path>
          <path
            fill="url(#paint1_linear_4284_4451)"
            d="M22.2 37.074c-.724 1.825-1.833 2.025-5.773 4.825-8.45 6-13.618 9.992-19.457 24.075-2.748 6.605-3.786 18.81-1.02 27.778 1.36 4.409 4.286 9.213 5.744 2.06a50.267 50.267 0 012.234-7.65S2.05 76.82 3.375 69.044c1.728-10.14 5.944-18.743 15.178-27.246.762-.7 3.531-2.948 3.664-4.724"
          ></path>
          <path
            fill="url(#paint2_linear_4284_4451)"
            d="M58.127 37.172c.912 1.874 1.37 1.926 5.31 4.727 8.45 6 14.276 11.087 19.447 23.774 2.702 6.643 3.814 19.114 1.048 28.082-1.36 4.41-4.286 9.213-5.744 2.06-.444-2.175-1.66-5.727-2.587-8.042 0 0 2.251-10.08.587-18.841-1.916-10.094-6.541-18.062-14.65-27.009-.698-.769-3.072-3.433-3.411-4.751z"
          ></path>
        </g>
        <path
          stroke="#DDE5ED"
          strokeMiterlimit="10"
          strokeWidth="2"
          d="M39.917 53c14.326 0 25.94-11.614 25.94-25.94 0-14.325-11.614-25.938-25.94-25.938-14.325 0-25.939 11.613-25.939 25.939C13.979 41.386 25.593 53 39.919 53z"
        ></path>
      </g>
      <defs>
        <filter
          id="filter0_dd_4284_4451"
          width="78.022"
          height="78"
          x="0.979"
          y="0"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feMorphology
            in="SourceAlpha"
            radius="2"
            result="effect1_dropShadow_4284_4451"
          ></feMorphology>
          <feOffset dy="4"></feOffset>
          <feGaussianBlur stdDeviation="3"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.03 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_4284_4451"
          ></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feMorphology
            in="SourceAlpha"
            radius="4"
            result="effect2_dropShadow_4284_4451"
          ></feMorphology>
          <feOffset dy="12"></feOffset>
          <feGaussianBlur stdDeviation="8"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.08 0"></feColorMatrix>
          <feBlend
            in2="effect1_dropShadow_4284_4451"
            result="effect2_dropShadow_4284_4451"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect2_dropShadow_4284_4451"
            result="shape"
          ></feBlend>
        </filter>
        <linearGradient
          id="paint0_linear_4284_4451"
          x1="38.772"
          x2="38.408"
          y1="13.66"
          y2="28.561"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1D0F2F"></stop>
          <stop offset="0.37" stopColor="#201238"></stop>
          <stop offset="0.98" stopColor="#2A1A50"></stop>
          <stop offset="1" stopColor="#2A1A51"></stop>
        </linearGradient>
        <linearGradient
          id="paint1_linear_4284_4451"
          x1="13.651"
          x2="-22.263"
          y1="66.834"
          y2="86.424"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1D0F2F"></stop>
          <stop offset="0.37" stopColor="#201238"></stop>
          <stop offset="0.98" stopColor="#2A1A50"></stop>
          <stop offset="1" stopColor="#2A1A51"></stop>
        </linearGradient>
        <linearGradient
          id="paint2_linear_4284_4451"
          x1="62.724"
          x2="102.477"
          y1="64.02"
          y2="91.046"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1D0F2F"></stop>
          <stop offset="0.37" stopColor="#201238"></stop>
          <stop offset="0.98" stopColor="#2A1A50"></stop>
          <stop offset="1" stopColor="#2A1A51"></stop>
        </linearGradient>
      </defs>
    </svg>
  );
};
