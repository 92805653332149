import React from "react";
import GanttPhaseCard, {
  GanttGoalCard,
  GanttLabelCard,
  GanttSprintCard,
} from "./GanttPhaseCard";
import { dayDiff } from "./dateFunctions";
import { useDroppable } from "@dnd-kit/core";
import { useAppDispatch } from "../../../../Core/redux/hooks";
import { setError } from "../../../../Core/redux/slices/global.slice";
import {
  getPhaseColor,
  getPhaseColor as getPhaseColorDark,
  getPhaseName,
} from "../../../../Core/utils/getPhaseName";
import dayjs from "dayjs";

const DayCell = ({
  style,
  task,
  formattedDate,
  handleResize,
  isQuarterView = false,
  isMonthView = false,
  isEOW = false,
  // count = 0,
  cellWidth = 0,
}) => {
  const [width, setWidth] = React.useState(null);
  // console.log(task, formattedDate, "check for dates");

  const { setNodeRef } = useDroppable({
    id: formattedDate,
  });

  const chlidStyle: React.CSSProperties = {
    position: "absolute",
    height:
      task.type && task.type === "sprint"
        ? "calc(var(--cell-height-sprint) - 1px)"
        : "calc(var(--cell-height) - 1px)",
    zIndex: "20",
    cursor: "move",
  };

  const elementRef = React.useRef();

  // const { hasPermission } = usePermission("6");

  const dispatch = useAppDispatch();

  // if (task.goalType) {
  //   alert(task.id);
  // }

  // const handleMouseDown = (e, toSide, callback) => {
  //   if (!hasPermission) {
  //     dispatch(
  //       setError({
  //         message: "You Need Permission to Perform This Action",
  //       })
  //     );

  //     return;
  //   }
  //   e.preventDefault();
  //   const ref = elementRef.current as HTMLElement;
  //   const mminStep = 50;
  //   const originalWidth = parseFloat(
  //     getComputedStyle(ref, null).getPropertyValue("width").replace("px", "")
  //   );

  //   const originalMouseX = e.pageX;

  //   let distance = null;

  //   const onMouseMove = (e) => {
  //     document.body.style.cursor = "col-resize";
  //     if (toSide === "toRight") {
  //       const newWidth = e.pageX - ref.getBoundingClientRect().left;
  //       if (newWidth >= mminStep) {
  //         distance = e.pageX - ref.getBoundingClientRect().left - originalWidth;
  //         setWidth(newWidth);
  //         // setWidth(`100% + ${newWidth}px`);
  //       }
  //     } else if (toSide === "toLeft") {
  //       const newWidth = originalWidth - (e.pageX - originalMouseX);
  //       if (newWidth > mminStep) {
  //         distance = e.pageX - originalMouseX;
  //         ref.style.left = e.pageX - originalMouseX + "px";
  //         setWidth(newWidth);
  //       }
  //     }
  //   };

  //   const onMouseUp = () => {
  //     window.removeEventListener("mousemove", onMouseMove);
  //     document.body.style.cursor = "auto";
  //     if (callback && typeof callback === "function") callback();
  //     handleResize(distance, toSide);
  //     window.removeEventListener("mouseup", onMouseUp);
  //   };

  //   if (elementRef) {
  //     window.addEventListener("mousemove", onMouseMove);
  //     window.addEventListener("mouseup", onMouseUp);
  //   }
  // };

  // if (task.phaseIteration) {
  //   console.log(task, "phaseeeee 2");
  // }
  // todo change cell_width*numdays

  const getPhaseColor = (id) => {
    switch (+id) {
      case 1:
        return "#FFEF90";
      case 2:
        return "#FFA5BD";
      case 3:
        return "#BDB4FE";
      case 4:
        return "#A4DFFF";
      case 5:
        return "#A0FABA";
      default:
        return "#FFDB01";
    }
  };

  const phaseName = getPhaseName(task.id);
  const phaseColor = getPhaseColor(task.id);
  const phaseColorDark = getPhaseColorDark(task.id);
  const numDays = dayjs(task.startDate).diff(dayjs(task.endDate), "day");

  return (
    <div
      ref={setNodeRef}
      style={
        task.isPhase
          ? {
              borderRight: isQuarterView
                ? ""
                : isMonthView
                ? `${isEOW ? "1px solid #DDE5ED" : ""}`
                : "1px solid #DDE5ED",
              zIndex: 1,
            }
          : {
              ...style,

              borderRight: isQuarterView
                ? ""
                : isMonthView
                ? `${isEOW ? "1px solid #DDE5ED" : ""}`
                : "1px solid #DDE5ED",
            }
      }
      data-task={task?.id}
      data-date={formattedDate}
    >
      {dayjs(task?.startDate)?.format("YYYY-MM-DD") == formattedDate && (
        <div
          ref={elementRef}
          key={`${formattedDate}-${task?.id}`}
          style={{
            ...chlidStyle,
            top: !isQuarterView || task.goalType ? "30px" : "62px",

            width: width
              ? width
              : `calc(${dayDiff(task?.startDate, task?.endDate)} * 100% - 1px)`,
          }}
        >
          {task.goalType ? (
            <GanttGoalCard
              labelId={task.goalLabelId} //todo add labelid
              task={task}
              handleMouseDown={() => console.log("")}
            />
          ) : task.isPhase ? (
            <GanttPhaseCard
              width={cellWidth * dayDiff(task?.startDate, task?.endDate)}
              phaseId={task.id}
              handleMouseDown={() => console.log("")}
              task={task}
            />
          ) : (
            <GanttLabelCard
              phaseId={task.id}
              handleMouseDown={() => console.log("")}
              task={task}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default DayCell;
