import { combineReducers, configureStore } from "@reduxjs/toolkit";
import {
  createStateSyncMiddleware,
  initMessageListener,
} from "redux-state-sync";
import overviewSlice from "./slices/overview.slice";
import userSlice from "./slices/user.slice";
import scheduleSlice from "./slices/schedule.slice";
import storage from "redux-persist/lib/storage";

import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  PURGE,
  REGISTER,
  REHYDRATE,
} from "redux-persist";
import vaultSlice from "./slices/vault.slice";
import conversationsSlice from "./slices/conversations.slice";
import dashboardSlice from "./slices/dashboard.slice";
import onboardingSlice from "./slices/onboarding.slice";
import projectSlice from "./slices/projects.slice";
import projectOnboardingSlice from "./slices/projectOnboarding.slice";
import notificationSlice from "./slices/notification.slice";
import settingsSlice from "./slices/settings.slice";
import tenantSlice from "./slices/tenant.slice";
import activityLogSlice from "./slices/activityLog.slice";
import globalSlice from "./slices/global.slice";
import previewSlice from "./slices/preview.slice";
import localforage from "localforage";
import myBoardSlice from "./slices/myBoard.slice";
import footprintSlice from "./slices/footprint.slice";

// redux-state-sync: https://www.howtogeek.com/devops/how-to-sync-a-redux-store-across-browser-tabs/
// redux-persist: https://dev.to/cassiolacerda/with-redux-3g41
//                https://redux-toolkit.js.org/usage/usage-guide#use-with-redux-persist

const reduxStateSyncConfig = {
  // predicate: action => {
  //   console.log('predicate',action, action.type === 'permissions/getPermissions')
  //   return action.type === 'permissions/getPermissions'
  // },
  blacklist: ["persist/PERSIST", "persist/REHYDRATE"],
  // broadcastChannelOption: {type: "idb"},
  // whitelist: ['permissions/getPermissions']
};

const persistConfig = {
  key: "root",
  storage: localforage,
  version: 1,
  migrate: (state) => {
    console.log("Migration Running!");
    return Promise.resolve(state);
  },
};

const reducer = combineReducers({
  global: globalSlice,
  tenant: tenantSlice,
  userDetails: userSlice,
  overview: overviewSlice,
  schedule: scheduleSlice,
  vault: vaultSlice,
  conversations: conversationsSlice,
  dashboard: dashboardSlice,
  onboarding: onboardingSlice,
  projects: projectSlice,
  projectOnboarding: projectOnboardingSlice,
  notification: notificationSlice,
  settings: settingsSlice,
  activityLogs: activityLogSlice,
  preview: previewSlice,
  myBoard: myBoardSlice,
  footPrintDetails: footprintSlice,
  // globalmodal: globalModalSlice
});

const persistedReducer = persistReducer(persistConfig, reducer);

const store = configureStore({
  reducer: persistedReducer,
  //reducer,
  devTools: true,
  // middleware: [createStateSyncMiddleware(reduxStateSyncConfig)],
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(createStateSyncMiddleware(reduxStateSyncConfig)),
});

initMessageListener(store);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
export default store;
