import React, { useEffect, useState, useRef } from "react";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";
import { Input, Popover, Tooltip } from "antd";
import CustomAvatar from "../../../Core/CommonV2/CustomAvatar";
import getUsersName from "../../../Core/utils/getUserName";
import PriorityFlag from "../../../Core/svgV2/PriorityFlag";
import { getPriorityDetails } from "../../../Core/utils/scheduleUtils";
import CustomBadge from "../../../Core/CommonV2/CustomBadge";
import { PlusIcon } from "../../../Core/svgV2/PlusIcon";
import CustomSelect from "../../../Core/CommonV2/CustomSelect";
import {
  resetSchedule,
  updateSchedule,
} from "../../../Core/redux/slices/schedule.slice";
import TimeInput from "../Task/TimeInput";
import { CrossIcon } from "../../../Core/svgV2/CrossIcon";
import { CheckIcon } from "../../../Core/svgV2/Check";
import { useNavigate, useParams } from "react-router-dom";
import {
  createGoal,
  getGoalDetails,
  getGoalsBySprint,
  getSubtaskDetails,
  updateGoal,
} from "../../../Core/redux/api/scheduleAPI";
import { TASK_ACTIVITY_LOG_ACTIONS } from "../../../Core/constants";
import { QueryClient, useQueryClient } from "react-query";
import dayjs from "dayjs";
import { CollaboratorSelect } from "../Planner/TaskItem";
import MyBoardLabel from "../../../Core/CommonV3/MyBoardLabel";

const LabelSelect = ({ task, setTask, visible = false }) => {
  const { labels } = useAppSelector((state) => state.schedule);

  const label = labels.find((l) => l.id == task.labelId);
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };

  const option = labels.map((label) => {
    return {
      id: label.id,
      value: label.id,
      label: label.name,
      render: () => (
        <div className="flex justify-between items-center">
          <MyBoardLabel labelColorId={label.labelColorId} text={label.name} />

          <div className="h-[14px] w-[14px] rounded-full" />
        </div>
      ),
    };
  });

  return (
    <Popover
      getPopupContainer={(parent) => parent.parentElement}
      content={
        <div className="min-w-[200px]">
          <CustomSelect
            placeholder="Enter Label Name"
            options={option}
            // onDropdownVisibleChange={(open) =>
            //   !open && setShowLabelSelect(false)
            // }

            defaultOpen
            showSearch
            customDropDownRender={(menu) => {
              return (
                <div className="min-w-[200px]">
                  <div className="scrollbar-hidden">{menu}</div>
                  <button
                    onClick={() => {
                      dispatch(
                        updateSchedule({ key: "newLabelModal", value: true })
                      );
                      setOpen(false);
                    }}
                    className="flex items-center py-3 px-3.5 hover:bg-gray-50 w-full cursor-pointer "
                  >
                    <PlusIcon className="text-gray-500" />
                    <p className="pl-2 out-300-12 leading-5 text-gray-500 capitalize">
                      Create new label
                    </p>
                  </button>
                </div>
              );
            }}
            onChange={(e) => {
              setTask(e);
              setOpen(false);
            }}
          />
        </div>
      }
      arrow={false}
      open={open}
      onOpenChange={handleOpenChange}
      placement="bottomLeft"
      trigger={"click"}
      destroyTooltipOnHide
    >
      {label ? (
        <button className="truncate">
          <MyBoardLabel labelColorId={label.labelColorId} text={label.name} />
        </button>
      ) : (
        <Tooltip title="Add a label">
          <img
            src="/images/v2/schedule/Tag.svg"
            className={`group-hover:block cursor-pointer ${
              visible ? "block" : "hidden"
            }`}
          />
        </Tooltip>
      )}
    </Popover>
  );
};

const TimeSelect = ({
  estimatedTime,
  visible = false,
  task,
  setTask,
  type = "timeLog",
}) => {
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };
  return (
    <Popover
      getPopupContainer={(parent) => parent.parentElement}
      content={
        <div className="min-w-[200px]">
          {type === "timeLog" ? (
            <TimeInput
              className=""
              autoFocus
              value={task.timeLogged || ""}
              addNewTimeToPreviousTime
              onChange={(e) => setTask({ ...task, timeLogged: e })}
              onOk={async (newTime: string, totalTime: string) => {
                setOpen(false);
                await dispatch(
                  updateGoal({
                    timeLogged: newTime,
                    goalId: task.id,
                    action: {
                      oldValue: task.timeLogged,
                      newValue: totalTime,
                      actionName: TASK_ACTIVITY_LOG_ACTIONS.TIMELOG_CHANGE,
                    },
                  })
                );
                await dispatch(
                  updateSchedule({ key: "isGoalEdited", value: true })
                );
              }}
            />
          ) : type === "NewTimeLog" ? (
            <TimeInput
              className=""
              autoFocus
              value={task.timeLogged}
              onChange={(e) => setTask({ ...task, timeLogged: e })}
              onOk={() => setOpen(false)}
            />
          ) : (
            <TimeInput
              className=""
              autoFocus
              value={task.estimatedTime}
              onChange={(e) => setTask({ ...task, estimatedTime: e })}
              onOk={() => setOpen(false)}
            />
          )}
        </div>
      }
      arrow={false}
      open={open}
      onOpenChange={handleOpenChange}
      placement="bottomLeft"
      trigger={"click"}
      destroyTooltipOnHide
    >
      {estimatedTime ? (
        <Tooltip
          title={
            type === "timeLog" || type === "NewTimeLog"
              ? "Log Time"
              : "Estimated Time"
          }
        >
          <img
            className="cursor-pointer"
            src="/images/v2/schedule/hourglass-02.svg"
          />
        </Tooltip>
      ) : (
        <Tooltip
          title={
            type === "timeLog" || type === "NewTimeLog"
              ? "Log Time"
              : "Estimated Time"
          }
        >
          <img
            src="/images/v2/schedule/hourglass-02.svg"
            className={`group-hover:block cursor-pointer  ${
              visible ? "block" : "hidden"
            }`}
          />
        </Tooltip>
      )}
    </Popover>
  );
};

const PrioritySelect = ({ visible = false, task, setTask }) => {
  const priority = task.priorityId;

  const [open, setOpen] = useState(false);

  const priorityData = getPriorityDetails(priority);

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };

  const getLable = (priority, bold = false) => {
    const { color, priorityName } = getPriorityDetails(priority);
    return (
      <div className="flex items-center ">
        <PriorityFlag
          style={{
            color,
          }}
        />
        <p
          className="out-300-14 ml-2"
          style={{ color, fontWeight: bold ? 500 : 300 }}
        >
          {priorityName}
        </p>
      </div>
    );
  };

  const option = [
    {
      id: 1,
      value: 1,
      label: "Low",
      render: () => getLable(1),
    },
    {
      id: 2,
      value: 2,
      label: "Medium",
      render: () => getLable(2),
    },
    {
      id: 3,
      value: 3,
      label: "High",
      render: () => getLable(3),
    },
    {
      id: 4,
      value: 4,
      label: "Urgent",
      render: () => getLable(4),
    },
  ];

  return (
    <Popover
      getPopupContainer={(parent) => parent.parentElement}
      content={
        <div className="min-w-[200px]">
          <CustomSelect
            placeholder="Select Priority"
            options={option}
            defaultOpen
            showSearch
            onChange={(e) => {
              setOpen(false);
              setTask(e);
            }}
          />
        </div>
      }
      arrow={false}
      open={open}
      onOpenChange={handleOpenChange}
      placement="bottomLeft"
      trigger={"click"}
      destroyTooltipOnHide
    >
      <button>
        {(priority || visible) && (
          <PriorityFlag
            style={{ color: priorityData.color }}
            height="20"
            width="20"
          />
        )}
      </button>
    </Popover>
  );
};

const UserSelect = ({ placeholder, assignee, task, setTask, onEdit }) => {
  const { userList } = useAppSelector((state) => state.overview);

  const [currentSelectedUser, setCurrentSelectedUser] = useState(assignee);
  const [open, setOpen] = useState(false);

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };

  useEffect(() => {
    const userId = task.assigneeId;
    const user = userList.find((user) => user.userId == userId);
    if (user) {
      setCurrentSelectedUser(user);
    }
  }, [task]);

  const handleMenuItemClick = (user, e) => {
    e.stopPropagation();
    setCurrentSelectedUser(user);

    setTask({
      ...task,
      assigneeId: user.userId,
    });

    onEdit(user.userId);
    setOpen(false);
  };

  return (
    <Popover
      getPopupContainer={(parent) => parent.parentElement}
      content={
        <div className="min-w-[200px]">
          <CustomSelect
            placeholder={placeholder}
            defaultOpen
            showSearch
            options={userList.map((user) => {
              return {
                id: user.userId,
                value: user.userId,
                label: getUsersName(user),

                render: () => (
                  <div
                    className="flex items-center "
                    onClick={(e) => {
                      handleMenuItemClick(user, e);
                    }}
                  >
                    <CustomAvatar
                      src={user.profilePicture}
                      size={24}
                      title={getUsersName(user)}
                      color={user?.profilePictureColorCode}
                      whiteText
                    />

                    <p className="out-300-12 pl-2 text-gray-900 capitalize ">
                      {getUsersName(user)}
                    </p>
                  </div>
                ),
              };
            })}
          />
        </div>
      }
      arrow={false}
      open={open}
      onOpenChange={handleOpenChange}
      placement="bottomLeft"
      trigger={"click"}
      destroyTooltipOnHide
    >
      {assignee && (
        <Tooltip
          title={`Assignee: ${getUsersName(currentSelectedUser)}`}
          placement="top"
        >
          <div className="cursor-pointer">
            <CustomAvatar
              title={getUsersName(currentSelectedUser)}
              src={currentSelectedUser?.profilePicture}
              size={20}
              fontSize={"12px"}
              whiteText
              color={currentSelectedUser?.profilePictureColorCode}
            />
          </div>
        </Tooltip>
      )}
    </Popover>
  );
};

const TaskType = ({ onTaskTypeChange, taskType = 1, isNewGoal = false }) => {
  const [open, setOpen] = useState(false);
  const { sprintKanbanView, showSubtaskView } = useAppSelector(
    (state) => state.schedule
  );

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };
  const [selectedType, setSelectedType] = useState(isNewGoal ? 4 : taskType);
  const getTaskDetails = (taskType) => {
    switch (+taskType) {
      case 1:
        return { src: "/images/v2/schedule/Task.svg", taskName: "Task" };
      case 2:
        return { src: "/images/v2/schedule/meet.svg", taskName: "Event" };
      case 3:
        return { src: "/images/v2/schedule/Bug.svg", taskName: "Bug" };
      case 4:
        return { src: "/images/v2/schedule/Subtask.svg", taskName: "Subtask" };
      default:
        return {
          src: "/images/v2/schedule/Task.svg",
          taskName: "Task",
        };
    }
  };

  const TaskItem = ({ taskType }) => {
    const { taskName, src } = getTaskDetails(taskType);
    return (
      <div
        onClick={() => {
          setSelectedType(taskType);
          onTaskTypeChange(taskType);
          setOpen(false);
        }}
        className="flex cursor-pointer py-2.5 px-3.5 hover:bg-gray-50 rounded-t-lg justify-between"
      >
        <div className="flex  items-center">
          <img src={src} />
          <span className="out-300-14 text-gray-700 ml-2">{taskName}</span>
        </div>
        {selectedType == taskType && <CheckIcon className="text-primary-600" />}
      </div>
    );
  };

  const { src } = getTaskDetails(selectedType);
  return sprintKanbanView && !showSubtaskView ? (
    <Popover
      arrow={false}
      placement="bottomLeft"
      open={open}
      onOpenChange={handleOpenChange}
      content={
        <div className="w-[200px] bg-white shadow-lg rounded-lg ">
          <TaskItem taskType={1} />
          <TaskItem taskType={3} />

          {/* <TaskItem taskType={4} /> */}
        </div>
      }
      trigger={["click"]}
    >
      <button
        className="flex items-center cursor-pointer"
        onClick={() => setOpen(true)}
      >
        <Tooltip title="Select Type">
          <div className="flex gap-x-1.5 items-center">
            <img src={src} className="h-[20px] w-[20px]" />
          </div>
        </Tooltip>
      </button>
    </Popover>
  ) : (
    <div className="flex gap-x-1.5 items-center">
      <img src={src} className="h-[20px] w-[20px]" />
    </div>
  );
};

type TaskCardProps = {
  task: any;
  // scrollPosition: any;
  // onScroll: any;
};

const TaskCard: React.FC<TaskCardProps> = React.memo(({ task }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { sprintKanbanView, showSubtaskView } = useAppSelector(
    (state) => state.schedule
  );
  const { projectId } = useParams();
  const { userList } = useAppSelector((state) => state.overview);
  const { selectedTasksForDragAndDrop } = useAppSelector(
    (state) => state.schedule
  );
  const assignee = userList.find((user) => +user.userId === +task.assigneeId);
  const [taskDetails, setTaskDetails] = useState(task);
  const [taskType, setTaskType] = useState(1);

  const [isFocus, setIsFoucs] = useState(false);

  const onSingleClick = async () => {
    //  await onScroll();
    localStorage.removeItem("taskRedirectUrl");
    localStorage.setItem("taskRedirectUrl", location.pathname);
    // dispatch(
    //   updateSchedule({
    //     key: "scrollData",
    //     value: {
    //       sprintId: task?.sprintId,
    //       scrollTop: +scrollPosition,
    //       scrollTask: task?.id,
    //     },
    //   })
    // );
    // localStorage.setItem("currentScrollPosition", scrollPosition);
    // localStorage.setItem("currentSprintId", task?.sprintId);
    //todo handle this in redux
    +task.goalType === 4
      ? await dispatch(getSubtaskDetails({ goalId: task.id }))
      : await dispatch(getGoalDetails({ goalId: task.id }));
    +task.goalType === 4
      ? navigate(
          `/schedule/${projectId}/task/${task.parentGoalId}/subtask/${task.id}`
        )
      : navigate(`/schedule/${projectId}/task/${task.id}`);
    return;
  };

  const handelTaskCheck = (id, e) => {
    // dispatch(updateSchedule({ key: "selectedTasksForDragAndDrop", value: [] }));

    // if (e.target !== e.currentTarget) {
    //   // Click occurred inside child element, skip the action
    //   return;
    // }

    const isCtrlPressed = e.ctrlKey || e.metaKey; // Check if Ctrl key is pressed

    if (!isCtrlPressed) {
      onSingleClick();
    }
    // Ctrl + Click
    else {
      const isSelected = selectedTasksForDragAndDrop.includes(id);
      const newIds = isSelected
        ? selectedTasksForDragAndDrop.filter((taskId) => taskId !== id)
        : [...selectedTasksForDragAndDrop, id];

      dispatch(
        updateSchedule({ key: "selectedTasksForDragAndDrop", value: newIds })
      );
    }
  };

  useEffect(() => {
    setTaskDetails(task);
  }, [task]);

  return (
    <div
      className={`bg-white group  p-[9px] cursor-pointer rounded-lg mt-2 shadow-xs max-h-max  ${
        selectedTasksForDragAndDrop?.includes(task.id)
          ? "border border-primary-300"
          : "border border-transparent"
      }`}
      id={task.id}
      onClick={(e) => {
        handelTaskCheck(task.id, e);
      }}
      // onDoubleClick={onDoubleClick}
    >
      <div className="flex justify-between items-center">
        <div className="flex items-center">
          <div
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <TaskType
              isNewGoal={false}
              taskType={+task.goalType}
              onTaskTypeChange={async (type) => {
                console.log(type, "scop");
                const updateObj: any = {
                  goalType: type === 4 ? 1 : type,
                  goalId: task.id,
                };
                if (type === 4) {
                  updateObj.isNewGoal = Number(type === 4);
                } else {
                  updateObj.isNewGoal = 0;
                }
                // setTask({ ...task, ...updateObj });
                await dispatch(updateGoal(updateObj));
                await dispatch(
                  updateSchedule({ key: "isGoalEdited", value: true })
                );
              }}
            />
          </div>
          <div
            className="out-300-12 text-gray-500 ml-2 hover:underline cursor-pointer"
            role="button"
            onClick={onSingleClick}
          >
            {" "}
            {task.identifier ?? task.id}
            {/* PI - {`${task.linkedGoalId || task.id}`} */}
          </div>
        </div>
        <div className="flex gap-x-2 items-center">
          {+taskDetails.goalType !== 4 &&
            +taskDetails?.goalType !== 3 &&
            taskDetails.startDate &&
            taskDetails.endDate && (
              <div className="text-gray-600 out-400-10">
                {dayjs(taskDetails?.startDate).format("DD MMM")} -{" "}
                {dayjs(taskDetails?.endDate).format("DD MMM")}
              </div>
            )}
          <div onClick={(e) => e.stopPropagation()}>
            <CollaboratorSelect task={taskDetails} />
          </div>
        </div>
      </div>

      <div
        // role="button"
        // onClick={(e) => {
        //   const isCtrlPressed = e.ctrlKey || e.metaKey;
        //   if (!isCtrlPressed) {
        //     navigate(`/schedule/${projectId}/task/${task.id}`);
        //   }
        // }}
        className="out-300-12 text-black my-2 max-w-full break-words"
      >
        {task.name}
      </div>

      <div
        className="flex items-center gap-x-2 "
        onClick={(e) => e.stopPropagation()}
      >
        <PrioritySelect
          task={taskDetails}
          setTask={async (e) => {
            setTaskDetails({ ...taskDetails, priorityId: e });

            await dispatch(
              updateGoal({
                priorityId: e,
                goalId: task.id,
                action: {
                  oldValue: task.priorityId,
                  newValue: e,
                  actionName: TASK_ACTIVITY_LOG_ACTIONS.PRIORITY_CHANGE,
                },
              })
            );
            await dispatch(
              updateSchedule({ key: "isGoalEdited", value: true })
            );
          }}
        />
        {sprintKanbanView && !showSubtaskView && (
          <LabelSelect
            task={taskDetails}
            setTask={async (e) => {
              setTaskDetails({ ...taskDetails, labelId: e });

              await dispatch(
                updateGoal({
                  labelId: e,
                  goalId: task.id,
                  action: {
                    oldValue: task.labelId,
                    newValue: e,
                    actionName: TASK_ACTIVITY_LOG_ACTIONS.LABEL_CHANGE,
                  },
                })
              );
              await dispatch(
                updateSchedule({ key: "isGoalEdited", value: true })
              );
            }}
          />
        )}
        <TimeSelect
          estimatedTime={task.timeLogged}
          task={taskDetails}
          setTask={setTaskDetails}
          type="timeLog"
        />
      </div>
    </div>
  );
});

TaskCard.displayName = "TaskCard";

type NewtaskCardProps = {
  status: any; // Replace 'string' with the actual type of 'status'
  isHovered: boolean;
  defaultOpen?: boolean;
  parentTaskId?: string | number;
};

export const NewtaskCard: React.FC<NewtaskCardProps> = React.memo(
  ({ status, isHovered, defaultOpen = false, parentTaskId }) => {
    const { user } = useAppSelector((state) => state.userDetails);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const queryClient: any = useQueryClient();
    const { showNewTaskCard, showSubtaskView, collaboratorsForTask } =
      useAppSelector((state) => state.schedule);
    const { projectId, sprintId } = useParams();
    const [task, setTask] = useState({
      name: "",
      priorityId: 1,
      estimatedTime: "",
      assigneeId: user.id,
      goalType: 1,
      isNewGoal: 0,
      linkedGoalId: null,
      id: null,
      labelId: null,
      timeLogged: "",
      collaborators: [],
    });

    const { userList } = useAppSelector((state) => state.overview);
    const assignee = userList.find((user) => +user.userId === +task.assigneeId);

    const [showNewTask, setShowNewTask] = useState(false);
    const ref = useRef(null);
    const handleClick = () => {
      console.log(ref, "ref");
      const selector = `[data-kanbanboard="true"]`;
      const newTask = document.querySelector(selector);

      if (newTask) {
        newTask.scrollTo({ top: 0, behavior: "smooth" });
        newTask.scrollIntoView;
      }
    };
    const onSubmit = async () => {
      try {
        const payload = {
          sprintId,
          projectId: projectId,
          name: task.name,
          isCompleted: 0,
          isNewGoal: task.isNewGoal,
          statusId: status.statusId,
          priorityId: task.priorityId,
          goalType: showSubtaskView ? 4 : task.goalType,
          assigneeId: task.assigneeId,
          swimlaneId: status.id,
          estimatedTime: task.estimatedTime,
          timeLogged: task.timeLogged,
          labelId: task.labelId,
          collaborators: task.collaborators,
          isSubTask: showSubtaskView ? 1 : "",
          parentGoalId: showSubtaskView ? parentTaskId : "",
        };

        await dispatch(createGoal(payload));
        dispatch(updateSchedule({ key: "selectedTask", value: payload }));
        dispatch(
          updateSchedule({
            key: "collaboratorsForTask",
            value: [],
          })
        );
      } catch (e) {
        console.log(e);
      } finally {
        setShowNewTask(false);
        dispatch(resetSchedule(["showNewTaskCard"]));
        dispatch(
          getGoalsBySprint({
            projectId,
            sprintId,
          })
        );
        queryClient.invalidateQueries(["goalsBySprint", projectId, +sprintId]);
      }
    };

    const handleExpand = () => {
      dispatch(
        updateSchedule({
          key: "newTask",
          value: {
            goalType: showSubtaskView ? 4 : task.goalType,
            name: task.name,
            timeLogged: task.timeLogged,
          },
        })
      );
      localStorage.removeItem("taskRedirectUrl");
      localStorage.setItem("taskRedirectUrl", location.pathname);
      let url = `/schedule/${projectId}/task/new`;
      if (sprintId) {
        url = `/schedule/${projectId}/task/new?sprintId=${sprintId}`;
      }
      if (showSubtaskView) {
        navigate(`/schedule/${projectId}/task/${parentTaskId}/subtask/new`);
      } else {
        navigate(url);
      }
      dispatch(resetSchedule(["showNewTaskCard"]));
    };

    const newTaskCard = (
      <div className="bg-white group relative p-[8px] rounded-lg mt-10 border border-primary-300 isolate shadow-xs max-h-max cursor-pointer">
        <div className="flex absolute top-[-24px] right-0 gap-x-1 items-center">
          <button
            onClick={() => handleExpand()}
            className="flex justify-center items-center"
          >
            <img src="/images/v2/schedule/expand-01.svg" />
          </button>
          <button
            onClick={() => {
              setShowNewTask(false);
              dispatch(resetSchedule(["showNewTaskCard"]));
            }}
            className="flex justify-center items-center"
          >
            <CrossIcon className="text-gray-500" />
          </button>
        </div>
        <div className="flex justify-between items-start">
          <div className="flex flex-1 items-start">
            <TaskType
              isNewGoal={showSubtaskView ? true : false}
              onTaskTypeChange={async (type) => {
                const updateObj: any = {
                  goalType: type === 4 ? 1 : type,
                };
                if (type === 4) {
                  updateObj.isNewScope = Number(type === 4);
                }
                setTask({ ...task, ...updateObj });
              }}
            />
            <Input.TextArea
              className="w-full flex-1  new-task-textarea pt-0 "
              placeholder={showSubtaskView?"Subtask title":"Task title"}

              onChange={(e) => setTask({ ...task, name: e.target.value })}
              autoSize
              bordered={false}
              onPressEnter={(e) => {
                e.preventDefault();
                onSubmit();
              }}
            />
          </div>
          <div>
            <CollaboratorSelect
              task={task}
              setTask={(collaboratorArray) =>
                setTask({ ...task, collaborators: collaboratorArray })
              }
            />

            {/* <UserSelect
              assignee={assignee}
              task={task}
              setTask={setTask}
              onEdit={(e) => console.log(e)}
              placeholder={"Select the assignee"}
            /> */}
          </div>
        </div>

        {/* <div className="out-300-12 text-black my-2">{task.name}</div> */}

        <div className="flex items-center gap-x-2">
          <PrioritySelect
            task={task}
            setTask={(e) => {
              setTask({ ...task, priorityId: e });
            }}
            visible={true}
          />
          <LabelSelect
            task={task}
            setTask={async (e) => {
              setTask({ ...task, labelId: e });
            }}
            visible={true}
          />

          {/* <TimeSelect
            estimatedTime={task.estimatedTime}
            visible={true}
            task={task}
            setTask={setTask}
            type="estimate"
          /> */}
          <TimeSelect
            estimatedTime={task.timeLogged}
            task={task}
            setTask={setTask}
            type="NewTimeLog"
          />
        </div>
      </div>
    );

    if (defaultOpen) {
      if (showSubtaskView && +showNewTaskCard.taskId === +parentTaskId) {
        return newTaskCard;
      } else if (showSubtaskView) {
        return newTaskCard;
      } else {
        return newTaskCard;
      }
    }

    if (!showNewTask && !isHovered) {
      return <></>;
    }

    if (!showNewTask && isHovered) {
      return (
        <button
          onClick={async () => {
            // setShowNewTask(true);
            await dispatch(
              updateSchedule({
                key: "showNewTaskCard",
                value: { visible: true, channel: status, taskId: parentTaskId },
              })
            );
            handleClick();
          }}
          className="flex px-2.5 mt-2 py-1.5 hover:bg-gray-100 border border-transparent hover:border-gray-100 hover:shadow-xs rounded w-full"
        >
          <PlusIcon className="text-gray-500" />
          <span className="text-gray-500 out-500-12 ml-2">
            {" "}
            {showSubtaskView ? "Create New Subtask" : "Create New Task"}
          </span>
        </button>
      );
    }

    return newTaskCard;
  }
);

NewtaskCard.displayName = "NewTaskCard";

export default TaskCard;
