// useScheduleAPI.ts
import {
  UseMutationResult,
  useMutation,
  useQuery,
  useQueryClient,
} from "react-query";
import {
  hasUnfinishedGoals,
  getProjectPhasesWithSprints,
  getSprintsByPhase,
  getAllSprintsByPhase,
  getGoalsBySprint,
  getTodoList,
  getGoalDetails,
  getGoalsByPhase,
  getSwimlanes,
  getLabels,
  getTaskActivityLog,
  getReleaseVersions,
} from "../api/scheduleAPI";
import { client } from "../../utils/axiosClient";
import { useAppDispatch } from "../hooks";
import { updateSchedule } from "../slices/schedule.slice";

export const useHasUnfinishedGoals = ({
  phaseId,
  projectId,
}: {
  phaseId: string;
  projectId: string;
}) => {
  return useQuery(["unfinishedGoals", phaseId, projectId], () =>
    hasUnfinishedGoals({ phaseId, projectId })
  );
};

export const useGetProjectPhases = (projectId) => {
  return useQuery(
    ["projectPhases", projectId],
    async () => {
      if (!projectId) {
        throw new Error("Project ID is required to fetch project phases.");
      }
      const { data } = await client.get(
        `/project/project-phases?projectId=${projectId}`
      );
      return data;
    },
    {
      enabled: !!projectId,
      refetchInterval: 1800000,
      staleTime: Infinity,
      keepPreviousData: true,
      onError: (error) => {
        console.error("Error fetching project phases:", error);
      },
    }
  );
};

export const useGetProjectPhasesWithSprints = ({
  projectId,
}: {
  projectId: string;
}) => {
  return useQuery(["projectPhasesWithSprints", projectId], () =>
    getProjectPhasesWithSprints({ projectId })
  );
};

export const useGetSprintsByPhase = ({
  projectId,
  phaseId,
}: {
  projectId: string;
  phaseId?: string;
}) => {
  return useQuery(
    ["sprintsByPhase", projectId, phaseId],
    async () => {
      const response = await client.get(`/schedule/sprints`, {
        params: { projectId, phaseId },
      });
      return response.data;
    },
    {
      enabled: true,
      refetchInterval: 1800000,
      staleTime: Infinity,
      keepPreviousData: true,
    }
  );
};

export const useGetAllSprintsByPhase = ({
  projectId,
  phaseId,
}: {
  projectId: string;
  phaseId: number;
}) => {
  return useQuery(["allSprintsByPhase", projectId, phaseId], () =>
    getAllSprintsByPhase({ projectId, phaseId })
  );
};

export const useGetGoalsByLabelId = ({
  labelId,
  projectId,
  filters,
  enabled = true,
}) => {
  return useQuery(
    ["goalsByLabel", labelId, projectId, filters],
    async () => {
      const { data } = await client.get(`schedule/goals-by-label`, {
        params: { labelId, projectId, ...filters },
      });
      return data;
    },
    {
      enabled: !!labelId && enabled,
      refetchInterval: 1800000,
      staleTime: Infinity,
      keepPreviousData: true,
    }
  );
};

export const useGetGoalsBySprint = ({
  projectId,
  sprintId,
  filters,
  enabled = true,
  limit,
  offset,
}: {
  projectId: any;
  sprintId: any;
  filters?: any;
  enabled?: boolean;
  limit?: number;
  offset?: number;
}) => {
  return useQuery(
    ["goalsBySprint", projectId, sprintId, offset],
    async () => {
      const response = await client.get(`schedule/goals`, {
        params: { sprintId, projectId, ...filters, limit, offset },
      });
      return response.data;
    },
    {
      enabled,
      refetchInterval: 1800000,
      staleTime: Infinity,
      keepPreviousData: true,
    }
  );
};

export const useGetActiveSprint = ({ projectId }: { projectId: string }) => {
  return useQuery(
    ["activeSprint", projectId],
    async () => {
      try {
        const { data } = await client.get("/schedule/activeSprint", {
          params: { projectId },
        });
        return data.activeSprint;
      } catch (error) {
        throw new Error("Failed to fetch active sprint");
      }
    },
    {
      enabled: true,
      refetchInterval: 1800000,
      staleTime: Infinity,
      keepPreviousData: true,
    }
  );
};

export const useGetTodoList = ({
  projectId,
  limit,
  offset,
}: {
  projectId: string;
  limit?: number;
  offset?: number;
}) => {
  const dispatch = useAppDispatch();
  return useQuery(
    ["todoList", projectId],
    async () => {
      const response = await client.get(`schedule/goals`, {
        params: {
          projectId,
          limit,
          offset,
          sprintId: "null",
        },
      });

      await dispatch(
        updateSchedule({
          key: "todoListCount",
          value: response.data.totalRecords,
        })
      );

      return response.data;
    },
    {
      enabled: true,
      refetchInterval: 1800000,
      staleTime: Infinity,
      keepPreviousData: true,
    }
  );
};

export const useUpdateGoal = (): UseMutationResult<
  any,
  unknown,
  object,
  unknown
> => {
  const queryClient = useQueryClient();

  const updateGoalMutation = useMutation(
    async (payload: object) => {
      try {
        const response = await client.put("schedule/update-goal", payload);
        return response.data;
      } catch (error) {
        throw new Error("Failed to update goal");
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("todoList");
      },
    }
  );

  return updateGoalMutation;
};

export const useGetGoalDetails = ({ goalId }: { goalId: string }) => {
  return useQuery(["goalDetails", goalId], () => getGoalDetails({ goalId }));
};

export const useGetGoalsByPhase = ({
  projectId,
  phaseId,
}: {
  projectId: string;
  phaseId: string;
}) => {
  return useQuery(["goalsByPhase", projectId, phaseId], () =>
    getGoalsByPhase({ projectId, phaseId })
  );
};

export const useGetSwimlanes = ({ projectId }: { projectId: string }) => {
  return useQuery(
    ["swimlanes", projectId],
    async () => {
      const response = await client.get(
        `schedule/swimlane?projectId=${projectId}`
      );
      return response.data;
    },
    {
      refetchInterval: 1800000,
      staleTime: 86400000,
      keepPreviousData: true,
    }
  );
};

export const useGetLabels = (projectId) => {
  return useQuery(
    ["labels", projectId],
    async () => {
      const response = await client.get(
        `schedule/label?projectId=${projectId}`
      );
      return response.data;
    },
    {
      refetchInterval: 1800000,
      staleTime: 86400000,
      keepPreviousData: true,
      enabled: !!projectId,
    }
  );
};

export const useGetTaskActivityLog = ({ goalId }: { goalId: string }) => {
  return useQuery(["taskActivityLog", goalId], () =>
    getTaskActivityLog({ goalId })
  );
};

export const useGetReleaseVersions = ({ projectId }: { projectId: string }) => {
  return useQuery(["releaseVersions", projectId], () =>
    getReleaseVersions({ projectId })
  );
};
