import React, { useState } from "react";
import { PlusIcon } from "../../../../Core/svgV2/PlusIcon";
import { Input } from "antd";

const AddNewStatus = ({ handelAddNewStatus }) => {
  const [addNewStatus, setAddNewStatus] = useState(false);
  const [name, setName] = useState("");

  if (addNewStatus) {
    return (
      <Input
        autoFocus
        className="rounded-lg bg-white out-500-14 text-gray-700"
        value={name}
        onPressEnter={() => {
          handelAddNewStatus(name, 2);
          setAddNewStatus(false);
        }}
        onChange={(e) => setName(e.target.value)}
        onBlur={() => setAddNewStatus(false)}
      />
    );
  }

  return (
    <button
      onClick={() => setAddNewStatus(true)}
      id="addNewStatus"
      className="flex items-center justify-center  max-h-[40px] hover:bg-white border border-transparent hover:border-gray-100 hover:shadow-s rounded-lg w-full"
    >
      <PlusIcon className="text-gray-500" />
      <span className="text-gray-500 out-500-12 ml-2">ADD STATUS</span>
    </button>
  );
};

export default AddNewStatus;
