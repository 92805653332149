import React, { useState, useEffect } from "react";
import PageTitle from "../PageTitle";
import CustomButton from "../../../Core/CommonV2/CustomButton";
import CustomSearchBox from "../../../Core/CommonV2/CustomSearchBox";
import { Popover, Table } from "antd";

import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";
import CustomAvatar from "../../../Core/CommonV2/CustomAvatar";
import getUsersName from "../../../Core/utils/getUserName";
import { DotsVerticalIcon } from "../../../Core/svgV2/DotsVertical";

import { UserEditIcon, UserIcon } from "../../../Core/svgV2/UserIcon";

import { DeleteIcon } from "../../../Core/svgV2/DeleteIcon";

import { CheckIcon } from "../../../Core/svgV2/Check";
import useSearch from "../../../Core/hooks/useSearch";
import UserDetailsDrawer from "../../Dashboard/Drawers/UserDetailsDrawer";
import { updateDashboard } from "../../../Core/redux/slices/dashboard.slice";
import dayjs from "dayjs";
import { getTenantMembers } from "../../../Core/redux/api/tenantAPI";
import { client } from "../../../Core/utils/axiosClient";
import { updateSettings } from "../../../Core/redux/slices/settings.slice";
import { useNavigate } from "react-router-dom";
import usePermission from "../../../Core/hooks/usePermission";
import { CommentWithLeftTailIcon } from "../../../Core/svgV2/Comments";
import EmptyState from "../../Library/EmptyState";
import GuesttInteractionSettingsModal from "../Modals/GuestInteractionSettingsModal";

const ChangeRoles = ({ currentRole, record, onClose }) => {
  const dispatch = useAppDispatch();

  const { guestInteractionSettingsModal } = useAppSelector(
    (state) => state.settings
  );
  const { tenantDetails } = useAppSelector((state) => state.tenant);
  const getClassName = (currentRole, role) => {
    return `${
      currentRole === role ? "bg-gray-100" : "bg-white"
    } py-2.5 flex items-center justify-between  px-[14px]`;
  };
  const getAllowedUsers = async (guestUserid) => {
    let allowedUsers = [];
    await client
      .get("/settings/get-guest-interaction-allowed-members", {
        params: {
          userId: +guestUserid,
          tenantId: tenantDetails.tenantId,
        },
      })
      .then(({ data }) => {
        allowedUsers = [...data];
      });
    return allowedUsers;
  };
  const chnageRole = async (roleId) => {
    try {
      const data = await client.put("/settings/update-user-tenant-role", {
        userId: record.id,
        roleId,
      });

      console.log(data);
      dispatch(getTenantMembers({}));
    } catch (e) {
      console.log(e);
    } finally {
      const body = document.querySelector("body");
      // Programmatically trigger a click event on the body
      const clickEvent = new MouseEvent("click", {
        bubbles: true,
        cancelable: true,
        view: window,
      });

      body.dispatchEvent(clickEvent);
    }
  };

  return (
    <div
      className={`w-[320px] bg-white  border border-gray-200 rounded-lg shadow-lg py-1 z-0 ${
        guestInteractionSettingsModal?.visible ? "hidden" : ""
      }`}
    >
      <div
        role="button"
        onClick={() => chnageRole(1)}
        className={getClassName(currentRole, 1)}
      >
        <div className="out-300-14 text-gray-900">Owner</div>
        {currentRole === 1 && <CheckIcon className="text-primary-600" />}
      </div>
      <div
        role="button"
        onClick={() => chnageRole(2)}
        className={getClassName(currentRole, 2)}
      >
        <div className="out-300-14 text-gray-900">Admin</div>
        {currentRole === 2 && <CheckIcon className="text-primary-600" />}
      </div>
      <div
        role="button"
        onClick={() => chnageRole(3)}
        className={getClassName(currentRole, 3)}
      >
        <div className="out-300-14 text-gray-900">Basic User</div>
        {currentRole === 3 && <CheckIcon className="text-primary-600" />}
      </div>
      <div
        role="button"
        onClick={async () => {
          chnageRole(4);
          if (currentRole === 4) {
            const allowedUsers = await getAllowedUsers(+record.userId);
            dispatch(
              updateSettings({
                key: "guestInteractionSettingsModal",
                value: {
                  visible: true,
                  email: "",
                  userId: +record.userId,
                  allowedUsers: allowedUsers,
                },
              })
            );
          } else {
            dispatch(
              updateSettings({
                key: "guestInteractionSettingsModal",
                value: {
                  visible: true,
                  email: "",
                  userId: +record.userId,
                },
              })
            );
          }
          onClose();
        }}
        className={getClassName(currentRole, 4)}
      >
        <div className="out-300-14 text-gray-900">Guest</div>
        {currentRole === 4 && <CheckIcon className="text-primary-600" />}
      </div>
    </div>
  );
};
// const EditGuestPermissions = ({ record }) => {
//   const { tenantDetails } = useAppSelector((state) => state.tenant);
//   const dispatch = useAppDispatch();

//   const handleRevokeImmediately = async (record) => {
//     const users = [{ userId: record.userId, tenantId: tenantDetails.tenantId }];
//     const result = await client.put(
//       "/settings/update-deactivation-date-for-guest",
//       {
//         userId: record.userId,
//       }
//     );
//     const result = await client
//       .put("/repository/revoke-guest-access", { users: users })
//       .then(() => {
//         dispatch(
//           updateDashboard({
//             key: "alertPopupModal",
//             value: {
//               visible: true,
//               data: {
//                 title: "Revoked Guest Permssions ",
//                 subtitle: `Permissions were revoked from guest user ${record.firstname} ${record.lastname}`,
//                 description: "",
//               },
//             },
//           })
//         );
//       });

//     return { message: "Edited Guest Permissions Succesfully" };
//   };
//   const handleRevokeAfterTenDays = async (record) => {
//     const result = await client
//       .put("/settings/update-deactivation-date-for-guest", {
//         userId: record.userId,
//       })
//       .then(() => {
//         dispatch(
//           updateDashboard({
//             key: "alertPopupModal",
//             value: {
//               visible: true,
//               data: {
//                 title: "Revoked Guest Permssions",
//                 subtitle: `Permissions will be revoked from guest user ${record.firstname} ${record.lastname} after 10 days`,
//                 description: "",
//               },
//             },
//           })
//         );
//       });

//     return { message: "Edited Guest Permissions Succesfully" };
//   };
//   return (
//     <div
//       className={`w-[320px] bg-white  border border-gray-200 rounded-lg shadow-lg py-1 z-0`}
//     >
//       <div
//         role="button"
//         onClick={() => handleRevokeImmediately(record)}
//         className={`bg-white py-2.5 flex items-center justify-between  px-[14px]`}
//       >
//         <div className="out-300-14 text-gray-900">Revoke Immediately </div>
//       </div>
//       {record?.deactivationDate === null && (
//         <div
//           role="button"
//           onClick={() => handleRevokeAfterTenDays(record)}
//           className={`bg-white py-2.5 flex items-center justify-between  px-[14px]`}
//         >
//           <div className="out-300-14 text-gray-900">Revoke after 10 days</div>
//         </div>
//       )}
//     </div>
//   );
// };
const MoreOptions = ({ text, record }) => {
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [changeRolesOpen, setChangeRoleOpen] = useState(false);
  const dispatch = useAppDispatch();

  const { user } = useAppSelector((state) => state.userDetails);
  const { guestInteractionSettingsModal } = useAppSelector(
    (state) => state.settings
  );
  useEffect(() => console.log(""), [guestInteractionSettingsModal?.visible]);
  const { tenantDetails } = useAppSelector((state) => state.tenant);
  const getAllowedUsers = async (guestUserid) => {
    let allowedUsers = [];
    await client
      .get("/settings/get-guest-interaction-allowed-members", {
        params: {
          userId: +guestUserid,
          tenantId: tenantDetails.tenantId,
        },
      })
      .then(({ data }) => {
        allowedUsers = [...data];
      });
    return allowedUsers;
  };
  const handleRevokeAfterTenDays = async (record) => {
    const users = [{ userId: record.userId, tenantId: tenantDetails.tenantId }];

    const result = await client
      .put("/repository/revoke-guest-access", { users: users })
      .then(() => {
        dispatch(
          updateDashboard({
            key: "alertPopupModal",
            value: {
              visible: true,
              data: {
                title: "Revoked Guest Permssions ",
                subtitle: `Permissions will be revoked from guest user ${record.firstname} ${record.lastname} after 10 days`,
                description: "",
              },
            },
          })
        );
      });

    return { message: "Edited Guest Permissions Succesfully" };
  };
  return (
    <Popover
      onOpenChange={(val) => setPopoverOpen(val)}
      open={popoverOpen}
      content={
        <div className="w-[250px] bg-white rounded-lg border border-gray-100 shadow-lg">
          <div
            role="button"
            className="cursor-pointer px-3.5 py-2.5 flex gap-x-2"
            onClick={() => {
              dispatch(
                updateDashboard({
                  key: "userDetailsDrawer",
                  value: { visible: true, data: record },
                })
              );
              setPopoverOpen(false);
            }}
          >
            <UserIcon className="text-gray-700" />{" "}
            <div className="out-300-14 leading-5 text-gray-700">
              View profile
            </div>
          </div>
          {+user.roleId < 3 && !guestInteractionSettingsModal?.visible && (
            <>
              <Popover
                // onOpenChange={(val) => setChangeRoleOpen(val)}
                content={
                  <ChangeRoles
                    currentRole={+text}
                    record={record}
                    onClose={() => {
                      console.log("what");
                      setPopoverOpen(false);
                      setChangeRoleOpen(false);
                    }}
                  />
                }
                arrow={false}
                placement="left"
                trigger={["click"]}
              >
                <div
                  role="button"
                  onClick={() => setChangeRoleOpen(true)}
                  className="px-3.5 cursor-pointer py-2.5 flex gap-x-2"
                >
                  <UserEditIcon className="text-gray-700" />
                  <div className="out-300-14 leading-5 text-gray-700">
                    Change role
                  </div>
                </div>
              </Popover>
              {+record.roleid === 4 && (
                <div
                  role="button"
                  className="px-3.5 cursor-pointer py-2.5 flex gap-x-2 "
                  onClick={async () => {
                    const allowedUsers = await getAllowedUsers(+record.userId);
                    dispatch(
                      updateSettings({
                        key: "guestInteractionSettingsModal",
                        value: {
                          visible: true,
                          email: "",
                          userId: +record.userId,
                          allowedUsers: allowedUsers,
                        },
                      })
                    );
                    setPopoverOpen(false);
                  }}
                >
                  <CommentWithLeftTailIcon className="text-gray-700" />
                  <div className="out-300-14 leading-5 text-gray-700">
                    Edit interaction settings
                  </div>
                </div>
              )}
              {+user.roleId < 3 &&
                +record.roleid === 4 &&
                record?.deactivationDate === null && (
                  <div
                    role="button"
                    className="px-3.5 cursor-pointer py-2.5 flex gap-x-2 "
                    onClick={() => handleRevokeAfterTenDays(record)}
                  >
                    <DeleteIcon className="text-gray-700" />

                    <div className="out-300-14 leading-5 text-gray-700">
                      Revoke access after 10 days
                    </div>
                  </div>
                )}
              <div
                role="button"
                className="px-3.5 cursor-pointer py-2.5 flex gap-x-2 border-t border-gray-100"
                onClick={() => {
                  dispatch(
                    updateSettings({
                      key: "removeMemberModal",
                      value: {
                        visible: true,
                        user: record,
                        action: "removeTenantMember",
                      },
                    })
                  );
                  setPopoverOpen(false);
                }}
              >
                <DeleteIcon className="text-gray-700" />
                <div className="out-300-14 leading-5 text-gray-700">
                  Remove member
                </div>
              </div>
            </>
          )}
        </div>
      }
      trigger={["click"]}
      placement="bottomRight"
      arrow={false}
    >
      <DotsVerticalIcon className="text-black" />
    </Popover>
  );
};

const WorkspaceMembers = () => {
  const { tenantMembers } = useAppSelector((state) => state.tenant);
  const { userDetailsDrawer } = useAppSelector((state) => state.dashboard);
  const { user } = useAppSelector((state) => state.userDetails);
  const [inactiveUsers, setInactiveUsers] = useState([]);

  // const { hasPermission } = usePermission("2");

  const getInactiveMembers = async () => {
    try {
      const { data } = await client.get("/tenant/invited-members");
      if (data.result) {
        console.log(data, "setActiveSprints");
        setInactiveUsers([...data.result]);
        return;
      }
    } catch (error) {
      console.log(error);
      return;
    }
  };
  useEffect(() => {
    getInactiveMembers();
    console.log(inactiveUsers, "setac");
  }, []);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const onInvite = async ({ user }) => {
    console.log(user, "user resend");
    await client.post("settings/resend-invite", { user: user }).then((res) => {
      console.log(res);
      dispatch(
        updateDashboard({
          key: "alertPopupModal",
          value: {
            visible: true,
            data: {
              title: `Resent Invite `,
              subtitle: `${user.email}`,
              description: ` was invited to waddle`,
            },
          },
        })
      );

      // dispatch(setSuccess({ message: "Invite resent successfully" }));
      // navigate(-1);
    });
  };

  const getRoleName = (role) => {
    switch (+role) {
      case 1:
        return "Owner";
      case 2:
        return "Admin";
      case 3:
        return "Basic User";
      case 4:
        return "Guest";
    }
  };

  const columns = [
    {
      title: () => (
        <div className="mon-500-10 leading-[18px] text-gray-500 ">Name</div>
      ),
      width: "20%",
      dataIndex: "firstName",
      defaultSortOrder: "ascend",
      sorter: (a, b) => a.firstname?.localeCompare(b.firstname),
      render: (text, record) => (
        <div className="flex items-center gap-x-3 ">
          <CustomAvatar
            title={getUsersName(record)}
            size={24}
            fontSize={"10px"}
            src={record?.profilePicture}
            whiteText
            color={record?.profilePictureColorCode}
          />

          <div>
            <p className="out-500-14 text-gray-900">{getUsersName(record)}</p>
            <p className="out-300-12 text-gray-500">{record?.email || "N/A"}</p>
          </div>
        </div>
      ),
    },
    {
      title: () => (
        <div className="mon-500-10 leading-[18px]  text-gray-500 ">
          Workspace Role
        </div>
      ),
      defaultSortOrder: "ascend",
      sorter: (a, b) => a.roleid - b.roleid,
      dataIndex: "roleid",
      render: (text) => (
        <p className="out-300-14 leading-5 text-gray-500 ">
          {getRoleName(text)}
        </p>
      ),
    },
    {
      title: () => (
        <div className="mon-500-10 leading-[18px]  text-gray-500 ">
          Date Added
        </div>
      ),

      dataIndex: "createdAt",
      defaultSortOrder: "ascend",
      sorter: (a, b) => a.id - b.id,
      render: (text) => (
        <p className="out-300-14 leading-5 text-gray-500 ">
          {text ? dayjs(text).format("MMMM DD YYYY") : "--"}
        </p>
      ),
    },
    {
      width: "50px",
      dataIndex: "roleid",
      render: (text, record) => <MoreOptions text={text} record={record} />,
    },
  ];

  const activeUserColumns = [
    {
      title: () => (
        <div className="mon-500-10 leading-[18px] text-gray-500 ">Name</div>
      ),
      width: "20%",
      dataIndex: "firstName",
      defaultSortOrder: "ascend",
      sorter: (a, b) => a.firstname?.localeCompare(b.firstname),
      render: (text, record) => (
        <div className="flex items-center gap-x-3 ">
          <CustomAvatar
            title={getUsersName(record)}
            size={24}
            fontSize={"10px"}
            src={record?.profilePicture}
            whiteText
            color={record?.profilePictureColorCode}
          />
          <div>
            <p className="out-500-14 text-gray-900">{getUsersName(record)}</p>
            <p className="out-300-12 text-gray-500">{record.email || "N/A"}</p>
          </div>
        </div>
      ),
    },
    {
      title: () => (
        <div className="mon-500-10 leading-[18px]  text-gray-500 "></div>
      ),
      // defaultSortOrder: "ascend",
      // sorter: (a, b) => a.roleid - b.roleid,
      dataIndex: "roleid",
      render: (text, record) => (
        <p className="out-300-14 leading-5 text-gray-500 flex gap-x-2 ">
          <CustomButton
            height="30px"
            text="Resend Invite"
            onClick={() => onInvite({ user: record })}
          />
          {/* Email Sent <CheckIcon className="text-success-600 " /> */}
        </p>
      ),
    },
    {
      title: () => (
        <div className="mon-500-10 leading-[18px]  text-gray-500 ">
          Date Added
        </div>
      ),

      dataIndex: "createdAt",
      defaultSortOrder: "ascend",
      sorter: (a, b) => a.id - b.id,
      render: (text) => (
        <p className="out-300-14 leading-5 text-gray-500 ">
          {text ? dayjs(text).format("MMMM DD YYYY") : "--"}
        </p>
      ),
    },
    {
      width: "50px",
      dataIndex: "roleid",
      render: (text, record) => <MoreOptions text={text} record={record} />,
    },
  ];

  const { search, setSearch, filteredData } = useSearch(tenantMembers, [
    "firstname",
    "lastname",
    "email",
  ]);

  if (+user.roleId === 4) {
    return (
      <EmptyState
        message={
          <>You dont have any access. To view, ask the owner for access</>
        }
      />
    );
  } else {
    return (
      <div className="pb-40 max-w-[667px]">
        <PageTitle title={"Members"} subtext={"Manage workspace members."} />
        <div className="my-6 flex justify-between">
          <CustomSearchBox
            placeholder="Search by name or email"
            style={{ width: "50%" }}
            search={search}
            setSearch={setSearch}
          />

          {+user.roleId < 3 && (
            <CustomButton
              height="30px"
              text="Invite People"
              onClick={() => navigate("/invite-members")}
            />
          )}
        </div>

        <div className="mt-6 members-table">
          <div className="out-500-16 p-[6px]">Active members</div>
          <style>{`.members-table .ant-table-tbody .ant-table-cell {
           padding:6px 24px !important;
         }`}</style>

          <Table
            columns={columns as any}
            dataSource={filteredData.filter((user) => user.otpVerified == true)}
            pagination={false}
          />
        </div>

        {+user.roleId < 3 && (
          <div className="mt-6 members-table">
            <div className="out-500-16 p-[6px]">Invited members</div>
            <style>{`.members-table .ant-table-tbody .ant-table-cell {
           padding:6px 24px !important;
         }`}</style>
            <Table
              columns={activeUserColumns as any}
              dataSource={inactiveUsers}
              pagination={false}
            />
          </div>
        )}
        <GuesttInteractionSettingsModal
          onClose={() => {
            dispatch(
              updateSettings({
                key: "guestInteractionSettingsModal",
                value: {
                  visible: false,
                  email: "",
                  userId: "",
                },
              })
            );
          }}
        />
        <UserDetailsDrawer
          open={userDetailsDrawer.visible}
          user={userDetailsDrawer.data}
          mode="WorkspaceUserDetails"
          onClose={() =>
            dispatch(
              updateDashboard({
                key: "userDetailsDrawer",
                value: {
                  visible: false,
                  data: { title: "", subtitle: "", description: "" },
                },
              })
            )
          }
        />
      </div>
    );
  }
};

export default WorkspaceMembers;
