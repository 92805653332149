import React from "react";
import Icon from "@ant-design/icons";

const CopySvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="none"
    viewBox="0 0 16 16"
  >
    <path
      stroke="currentColor"
      strokeLinejoin="round"
      d="M2.8 6H10v7.2H2.8V6z"
    ></path>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M7.6 2.8H6v1.6m5.6-1.6h1.6v1.6m0 4v1.2h-1.6M9.2 2.8h.8M13.2 6v.8"
    ></path>
  </svg>
);

const CopyOutlinedSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    fill="none"
    viewBox="0 0 18 18"
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M6 6V3.9c0-.84 0-1.26.163-1.581a1.5 1.5 0 01.656-.656c.32-.163.74-.163 1.581-.163h5.7c.84 0 1.26 0 1.581.163a1.5 1.5 0 01.656.656c.163.32.163.74.163 1.581v5.7c0 .84 0 1.26-.163 1.581a1.5 1.5 0 01-.656.655c-.32.164-.74.164-1.581.164H12m-8.1 4.5h5.7c.84 0 1.26 0 1.581-.163a1.5 1.5 0 00.655-.656c.164-.32.164-.74.164-1.581V8.4c0-.84 0-1.26-.164-1.581a1.5 1.5 0 00-.655-.656C10.861 6 10.441 6 9.6 6H3.9c-.84 0-1.26 0-1.581.163a1.5 1.5 0 00-.656.656c-.163.32-.163.74-.163 1.581v5.7c0 .84 0 1.26.163 1.581a1.5 1.5 0 00.656.656c.32.163.74.163 1.581.163z"
    ></path>
  </svg>
);
const CopyIcon = (props) => {
  return <Icon component={CopySvg} {...props} />;
};
const CopyOutlinedIcon = (props) => {
  return <Icon component={CopyOutlinedSvg} {...props} />;
};

const CopyLinkIcon = ({ className = "", size = "20", style = {} }) => {
  return (
    <span role="img" className={`anticon ${className}`} style={style}>
      <svg
        width={size}
        height={size}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.99967 11.3333H4.66634C2.82539 11.3333 1.33301 9.84091 1.33301 7.99996C1.33301 6.15901 2.82539 4.66663 4.66634 4.66663H5.99967M9.99967 11.3333H11.333C13.174 11.3333 14.6663 9.84091 14.6663 7.99996C14.6663 6.15901 13.174 4.66663 11.333 4.66663H9.99967"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.66602 8L11.3327 8"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </span>
  );
};

export { CopyIcon, CopyOutlinedIcon, CopyLinkIcon };
