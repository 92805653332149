/* eslint-disable no-useless-escape */
import React, { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../Core/redux/hooks";
import { Dropdown, Popover, Tooltip } from "antd";
import {
  updateConversations,
  updateConversationsMultiple,
} from "../../Core/redux/slices/conversations.slice";
import { downloadFiles } from "../../Core/utils";

// import Album from "../../Core/Common/Album";
import dayjs from "dayjs";
import CustomAvatar from "../../Core/Common/CustomAvatar";
import UserDetailsCard from "./UserDetailsCard";
import ParseMentions from "./ParseMentions";
import { EmojiIcon } from "../../Core/svgV2/EmojiIcon";
import { DotsVerticalIcon } from "../../Core/svgV2/DotsVertical";
import { MessageReplyIcon } from "../../Core/svgV2/MessageReplyIcon";
import { DownloadIcon } from "../../Core/svgV2/DownloadIcon";
import { EyeOffIcon, EyeOpenIcon } from "../../Core/svgV2/EyeIcon";
import { EllipseIcon } from "../../Core/svgV2/Ellipse";
import { SaveIcon } from "../../Core/svgV2/SaveIcon";
import { ForwardIcon } from "../../Core/svgV2/ForwardIcon";
import { EditIcon } from "../../Core/svgV2/EditIcon";
import { DeleteIcon } from "../../Core/svgV2/DeleteIcon";
import { ChevronIcon } from "../../Core/svgV2/Chevron";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import usePermission from "../../Core/hooks/usePermission";
import { REACTIONS } from "../../Core/constants";
import getImageWeServeUrl from "../../Core/utils/getImageWeServeUrl";

export const Attachments = ({
  type,
  url,
  thumbnail,
  showName = false,
  className = "w-[329px] h-[178px]",
  fileName = "",
  onClick,
}) => {
  if (type.includes("video")) {
    return (
      <div className="px-2 py-2 overflow-hidden rounded">
        <video
          muted
          //   onCanPlayThrough={(e) => {
          //     const video = e.target;
          //     setTimeout(() => {
          //       video?.pause();
          //     }, 1000);
          //   }}
          autoPlay={false}
          controls
          preload="metadata"
          //poster={thumbnail}
        >
          <source src={`${url}#t=0.1`}></source>
        </video>
      </div>
    );
  }

  const [showThumnail, setShowThumnail] = useState(true);

  return (
    <>
      <div
        className={`mx-2 my-2 border border-black cursor-pointer rounded overflow-hidden ${className} flex justify-center items-center object-cover relative actions-row bg-white`}
        onClick={onClick}
      >
        {showThumnail && (
          <img
            className={`${
              thumbnail ? "w-full h-full" : " h-24 w-24 "
            } rounded object-cover`}
            src={thumbnail || "/images/No-Image-Placeholder.svg"}
            // alt={name}
          />
        )}

        <img
          className={`${
            url ? "w-full h-full" : " h-24 w-24 "
          } rounded object-cover ${showThumnail ? "hidden" : ""}`}
          src={url || "/images/No-Image-Placeholder.svg"}
          // alt={name}
          onLoad={() => setShowThumnail(false)}
        />
      </div>
      {showName && (
        <div className="flex mx-2 my-2 ">
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              fill="none"
              viewBox="0 0 20 20"
            >
              <rect
                width="15"
                height="15"
                x="2.5"
                y="2.5"
                fill="#E0E7EE"
                rx="4"
              ></rect>
              <path
                stroke="#0D3271"
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M10.833 5.946v1.721c0 .234 0 .35.046.44.04.078.104.142.182.182.089.045.206.045.44.045h1.72m-2.388 3.75h-2.5m3.334-1.667H8.333m5-1.255v3.005c0 .7 0 1.05-.136 1.318a1.25 1.25 0 01-.546.546c-.268.136-.617.136-1.318.136H8.667c-.7 0-1.05 0-1.318-.136a1.25 1.25 0 01-.546-.546c-.136-.268-.136-.618-.136-1.318V7.834c0-.7 0-1.05.136-1.317a1.25 1.25 0 01.546-.547c.268-.136.618-.136 1.318-.136h1.338c.306 0 .459 0 .602.035.128.03.25.08.362.15.126.076.234.185.45.4l1.329 1.33c.216.215.324.323.401.45.069.111.12.233.15.36.034.145.034.298.034.603z"
              ></path>
            </svg>
          </div>
          <p className="mon-500-12 truncate w-[315px] pl-2 ">{fileName}</p>
        </div>
      )}
    </>
  );
};

//todo add typescript validation
export const Thumbnail = ({
  type = "",
  url,
  className = "w-[345px] h-[174px]",
  file,
}) => {
  const [isHover, setIsHover] = useState(false);
  let body = <></>;
  console.log(file, "checkname");

  if (type.includes("video")) {
    body = (
      <div className={`${className} flex flex-col bg-white rounded-[12px]`}>
        {/* <div className="flex flex-col bg-white rounded-sm "> */}
        <div className="w-[329px] h-[130px] rounded-[8px] my-[8px] mx-[8px] overflow-hidden">
          <video
            className="w-[329px] h-[130px]"
            muted
            //   onCanPlayThrough={(e) => {
            //     const video = e.target;
            //     setTimeout(() => {
            //       video?.pause();
            //     }, 1000);
            //   }}
            autoPlay={false}
            controls
            preload="metadata"
            //poster={thumbnail}
          >
            <source src={`${url}#t=0.1`}></source>
          </video>
        </div>
        <div
          className="flex flex-row mb-[8px] mx-[8px] justify-between "
          onMouseEnter={() => {
            setIsHover(true);
          }}
          onMouseLeave={() => setIsHover(false)}
        >
          <Tooltip title={file?.name} placement="bottomLeft">
            <div className="flex  text-gray-700 gap-x-[8px] out-500-12 items-end h-[20px] min-w-[155px] max-w-[180px] ">
              <img src={"/images/v2/conversations/Video.svg"}></img>
              <div className=" truncate max-w-[120px]">{file?.name}</div>
            </div>
          </Tooltip>
          <div className="">
            {isHover ? <DownloadIcon className="out-500-12" /> : ""}
          </div>
        </div>
      </div>
    );
  } else {
    body = (
      <div
        className={`${className} flex flex-col bg-white rounded-[12px] border border-gray-100`}
        onMouseEnter={() => {
          setIsHover(true);
          console.log("hovering");
        }}
        onMouseLeave={() => setIsHover(false)}
      >
        <div className="w-[329px] h-[130px] rounded-[8px] my-[8px] mx-[8px] overflow-hidden">
          <img
            src={"/images/icons/no-files.svg"}
            className="object-contain w-full h-full"
          />
        </div>
        <div className="flex flex-row mb-[8px] mx-[8px] justify-between items-center">
          <div className="flex flex-row   text-gray-700 gap-x-[8px] out-500-12 items-end h-[20px] w-[155px] ">
            <img src={"/images/v2/conversations/pdf.svg"}></img>
            <p className="truncate whitespace-nowrap"> {file.name}</p>
          </div>
          <div className="flex items-center justify-center w-5 h-5">
            {isHover ? (
              <button
                onClick={() => {
                  downloadFiles([url], file.fileSize);
                }}
              >
                <DownloadIcon className="out-500-12" />
              </button>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    );
  }
  return body;
};

// className = "w-[345px] h-[174px]"
// export const UrlThumbnail = ({ url }) => {
//   const [isHover, setIsHover] = useState(false);
//   const [loading, setLoading] = useState(false);
//   const [metaData, setMetaData] = useState({
//     ogTitle: "",
//     ogImage: [
//       {
//         url: "",
//         type: "",
//       },
//     ],
//     favicon: "",
//   });
//   const [errorInFetching, setErrorInFetching] = useState(false);
//   const getData = async () => {
//     setLoading(true);
//     try {
//       const { data } = await client.get("/conversation/scrape", {
//         params: { url },
//       });
//       setMetaData(data);
//     } catch (e) {
//       setErrorInFetching(true);
//     }
//     setLoading(false);
//   };
//   useEffect(() => {
//     getData();
//   }, []);

//   if (errorInFetching) return <></>;
//   if (loading)
//     return (
//       <div
//         className={`w-[345px] h-[174px] flex justify-center items-center cursor-pointer bg-[#F7FAFD] rounded-[12px] `}
//       >
//         <Spin />
//       </div>
//     );
//   return (
//     <div
//       onClick={() => window.open(url, "_blank")}
//       className={`w-[345px] h-[174px] flex flex-col cursor-pointer bg-gray-50 rounded-[12px] `}
//     >
//       <div className="min-w-[329px] min-h-[130px] max-w-[329px] max-h-[130px] flex justify-center items-center  rounded-[8px] my-[8px] mx-[8px] overflow-hidden">
//         <img
//           className="max-w-[329px] max-h-[130px] h-full object-cover"
//           src={
//             metaData?.ogImage ? metaData?.ogImage[0]?.url : metaData?.favicon
//           }
//         />
//       </div>

//       <div
//         className="flex flex-row mb-[8px] mx-[8px] justify-between "
//         onMouseEnter={() => {
//           setIsHover(true);
//           console.log("hovering", isHover);
//         }}
//         onMouseLeave={() => setIsHover(false)}
//       >
//         <div className="flex flex-row   text-primary-600 gap-x-[8px] out-500-12 items-end h-[20px] w-[155px] ">
//           <p className="truncate whitespace-nowrap">{metaData?.ogTitle}</p>
//         </div>
//       </div>
//     </div>
//   );
// };

const ImageComponent = ({
  src,
  isFinal = false,
  message,
  file,
  currentIndex,
}) => {
  const [hover, setHover] = useState(false);
  const dispatch = useDispatch();

  if (isFinal) {
    return (
      <div
        className="h-[100px] w-[147px] object-cover rounded-lg border border-gray-200 flex items-center justify-center"
        style={{
          background: `linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url(${src})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <span className="text-white out-500-16"> +2</span>
      </div>
    );
  }
  const items = [
    {
      key: "1",
      label: (
        <div
          onClick={() => {
            dispatch(
              updateConversations({
                key: "filePreview",
                value: {
                  visible: true,
                  currentIndex,
                  from: "messages",
                  docs: message.attachments,
                  createdAt: message.createdAt,
                },
              })
            );
          }}
          className="text-gray-700 out-300-14"
        >
          Preview{" "}
        </div>
      ),
      icon: <EyeOpenIcon className="text-gray-700" />,
    },
    {
      key: "2",
      label: (
        <div
          onClick={() =>
            dispatch(
              updateConversations({
                key: "sharedFileDetailsDrawer",
                value: {
                  visible: true,
                  data: {
                    ...file,
                    senderName: message.senderName,
                    createdAt: message.createdAt,
                  },
                },
              })
            )
          }
          className="text-gray-700 out-300-14"
        >
          View details
        </div>
      ),
      icon: <EllipseIcon className="text-gray-700" />,
    },
    {
      key: "3",
      label: (
        <div
          onClick={() => {
            downloadFiles([src], file.fileSize);
          }}
          className="text-gray-700 out-300-14"
        >
          Download
        </div>
      ),
      icon: <DownloadIcon className="text-gray-700" />,
    },
    !file.savedInVault ? { key: "4", type: "divider" } : null,
    !file.savedInVault
      ? {
          key: "5",
          label: (
            <div
              onClick={() =>
                dispatch(
                  updateConversations({
                    key: "saveInLibrary",
                    value: { visible: true, file: file },
                  })
                )
              }
              className="text-gray-700 out-300-14 "
            >
              Save in library
            </div>
          ),
          icon: <SaveIcon className="text-gray-700 " />,
        }
      : null,
  ];

  return (
    <div
      className="relative"
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <div className="max-h-[102px] max-w-[147px] min-h-[102px] min-w-[147px] rounded-lg border flex justify-center items-center border-gray-200">
        <img
          className={`max-h-[100px] max-w-[147px]  cursor-pointer ${
            file?.imageOutOfBound ? "" : "object-cover"
          }`}
          src={
            file?.imageOutOfBound
              ? src
              : getImageWeServeUrl(src, { w: "147", h: "102", file: file })
          }
          onClick={() => {
            dispatch(
              updateConversations({
                key: "filePreview",
                value: {
                  visible: true,
                  currentIndex,
                  from: "messages",
                  docs: message.attachments,
                  createdAt: message.createdAt,
                },
              })
            );
          }}
        />
      </div>
      <div
        className={`bg-white ${
          hover ? "flex" : "hidden"
        }  absolute top-1 right-1 h-9 border border-gray-100  justify-center items-center  rounded-[4px]`}
      >
        <Tooltip
          title="Download"
          getPopupContainer={(trigger) => trigger.parentElement}
        >
          <button
            className="w-5 h-5 mx-2"
            onClick={() => {
              downloadFiles([src], file.fileSize);
            }}
          >
            <DownloadIcon height={20} width={20} />
          </button>
        </Tooltip>
        <Tooltip
          title="Forward"
          getPopupContainer={(trigger) => trigger.parentElement}
        >
          <button
            onClick={() => {
              dispatch(
                updateConversationsMultiple([
                  {
                    key: "shareMessageModal",
                    value: true,
                  },
                  {
                    key: "forwardMessage",
                    value: {
                      ...message,
                      attachments: message.attachments.filter(
                        (a) => a.url === src
                      ),
                    },
                  },
                  {
                    key: "shareType",
                    value: "forwardMessage",
                  },
                ])
              );
            }}
            className="w-5 h-5 mx-2 "
          >
            <MessageReplyIcon className="text-gray-700 scale-x-[-1]" />
          </button>
        </Tooltip>
        <Tooltip
          title="More Options"
          getPopupContainer={(trigger) => trigger.parentElement}
        >
          <button className="w-5 h-5 mx-2">
            <Dropdown
              menu={{ items }}
              trigger={["click"]}
              dropdownRender={(menu) => <div className="w-[200px]">{menu}</div>}
            >
              <DotsVerticalIcon className="text-gray-700" />
            </Dropdown>
          </button>
        </Tooltip>
      </div>
    </div>
  );
};

const Album = ({ files, message }) => {
  // TODO: like image array make this for video and other file
  const images = files.filter((file) => file.type?.includes("image")) || [];
  let imageArray = <></>;
  if (images.length > 0) {
    const remainingImages = images.length > 5 ? images.length - 5 : 0;
    imageArray = (
      <div className="flex mt-1 gap-x-3">
        {images.slice(0, 5).map((img, i) => (
          <ImageComponent
            key={i}
            src={img.url}
            isFinal={i === 4 && remainingImages > 0}
            message={message}
            file={img}
            currentIndex={i}
          />
        ))}
      </div>
    );
  }
  const otherFiles =
    files.filter((file) => !file.type?.includes("image")) || [];
  let filesArray = <></>;
  if (otherFiles.length > 0) {
    filesArray = (
      <div className="flex flex-col mt-1 gap-y-3">
        {otherFiles.slice(0, 5).map((img, i) => (
          <div key={i}>
            <Thumbnail type={img.type} url={img.url} file={img} />
          </div>
        ))}
      </div>
    );
  }
  return (
    <div className="pb-1">
      {imageArray}
      <div className="">{filesArray}</div>
    </div>
  );
};

const Message = ({
  message,
  reactToMessage,
  deleteMessage,
  comments,
  allowHover = true,
  showSender = true,
  handleMarkAsUnread,
}: {
  message: any;
  comments?: boolean;
  allowHover?: boolean;
  reactToMessage?: (
    emoji: string,
    messageId: string,
    isAlreadyReacted: boolean
  ) => void;
  handleMarkAsUnread?: (message: object) => void;
  deleteMessage?: (id: string) => void;
  showSender?: boolean;
}) => {
  const { tenantMembers } = useAppSelector((state) => state.tenant);
  const { user } = useAppSelector((state) => state.userDetails);
  const dispatch = useAppDispatch();

  const [repliesOpen, setRepliesOpen] = useState(false);

  const regex =
    /((?:(http|https|Http|Https|rtsp|Rtsp):\/\/(?:(?:[a-zA-Z0-9\$\-\_\.\+\!\*\'\(\)\,\;\?\&\=]|(?:\%[a-fA-F0-9]{2})){1,64}(?:\:(?:[a-zA-Z0-9\$\-\_\.\+\!\*\'\(\)\,\;\?\&\=]|(?:\%[a-fA-F0-9]{2})){1,25})?\@)?)?((?:(?:[a-zA-Z0-9][a-zA-Z0-9\-]{0,64}\.)+(?:(?:aero|arpa|asia|a[cdefgilmnoqrstuwxz])|(?:biz|b[abdefghijmnorstvwyz])|(?:cat|com|coop|c[acdfghiklmnoruvxyz])|d[ejkmoz]|(?:edu|e[cegrstu])|f[ijkmor]|(?:gov|g[abdefghilmnpqrstuwy])|h[kmnrtu]|(?:info|int|i[delmnoqrst])|(?:jobs|j[emop])|k[eghimnrwyz]|l[abcikrstuvy]|(?:mil|mobi|museum|m[acdghklmnopqrstuvwxyz])|(?:name|net|n[acefgilopruz])|(?:org|om)|(?:pro|p[aefghklmnrstwy])|qa|r[eouw]|s[abcdeghijklmnortuvyz]|(?:tel|travel|t[cdfghjklmnoprtvwz])|u[agkmsyz]|v[aceginu]|w[fs]|y[etu]|z[amw]))|(?:(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[1-9])\.(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[1-9]|0)\.(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[1-9]|0)\.(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[0-9])))(?:\:\d{1,5})?)(\/(?:(?:[a-zA-Z0-9\;\/\?\:\@\&\=\#\~\-\.\+\!\*\'\(\)\,\_])|(?:\%[a-fA-F0-9]{2}))*)?(?:\b|$)/gi;
  // let allUrl = [];

  // allUrl = message?.message?.match(regex);

  ///console.log(allUrl, "aaaa");

  // const commentAccess = vault?.[5]?.[0] || {};

  const isAlreadyReacted = (emoji) =>
    message.reactions &&
    message.reactions[emoji] &&
    message.reactions[emoji].includes(user.id);

  const reactionOverLay = (
    <div className="flex bg-white shadow-bottom items-center rounded-full h-[40px]">
      {REACTIONS.map((reaction) => (
        <button
          key={reaction.reaction}
          className="w-5 mx-3"
          onClick={() =>
            reactToMessage(
              reaction.emoji,
              message._id,
              isAlreadyReacted(reaction.emoji)
            )
          }
        >
          <span className="text-xl">{reaction.emoji}</span>
        </button>
      ))}
    </div>
  );

  const location = useLocation();

  const screen =
    location.pathname.includes("schedule") && location.pathname.includes("task")
      ? "schedule"
      : location.pathname.includes("library")
      ? "library"
      : "messages";

  const { hasPermission: COMMENT_EDIT } = usePermission("15");
  const { hasPermission: LIB_COMMENT_EDIT } = usePermission("27");

  const commentsEditPermissions =
    screen === "schedule" ? COMMENT_EDIT : LIB_COMMENT_EDIT;

  const getHoverMenu = () => {
    if (comments) {
      return (
        <div className="bg-white absolute top-[-18px] right-4 h-9 border border-gray-100 flex justify-around items-center  rounded-[4px] w-[64px] ">
          <Dropdown
            trigger={["click"]}
            //open={true}
            overlay={reactionOverLay}
          >
            <Tooltip title="Add reaction">
              <button className="">
                <EmojiIcon />
              </button>
            </Tooltip>
          </Dropdown>
          {commentsEditPermissions && (
            <Tooltip title="Edit Comment">
              <button
                onClick={() => {
                  dispatch(
                    updateConversations({
                      key: "editMessage",
                      value: { edit: true, messageObject: message },
                    })
                  );
                }}
              >
                {" "}
                <EditIcon className="text-black" />
              </button>
            </Tooltip>
          )}
        </div>
      );
    } else {
      const items = [
        {
          key: "1",
          label: (
            <div
              onClick={() => {
                dispatch(
                  updateConversationsMultiple([
                    {
                      key: "shareMessageModal",
                      value: true,
                    },
                    { key: "forwardMessage", value: message },
                    {
                      key: "shareType",
                      value: "forwardMessage",
                    },
                  ])
                );
              }}
              className="text-gray-700 out-300-14"
            >
              Forward{" "}
            </div>
          ),
          icon: <ForwardIcon className="text-gray-700" />,
        },
        {
          key: "2",
          label: (
            <div
              onClick={() => handleMarkAsUnread(message)}
              className="text-gray-700 out-300-14"
            >
              Mark as unread
            </div>
          ),
          icon: <EyeOffIcon className="text-gray-700" />,
        },

        message.senderId == user.id
          ? {
              key: "3",
              label: (
                <div
                  onClick={() => {
                    dispatch(
                      updateConversations({
                        key: "editMessage",
                        value: { edit: true, messageObject: message },
                      })
                    );
                  }}
                  className="text-gray-700 out-300-14"
                >
                  Edit
                </div>
              ),
              icon: <EditIcon className="text-gray-700" />,
            }
          : null,
        message.senderId == user.id ? { key: "4", type: "divider" } : null,
        message.senderId == user.id
          ? {
              key: "5",
              label: (
                <div
                  onClick={() => {
                    deleteMessage(message._id);
                  }}
                  className="text-gray-700 out-300-14 "
                >
                  Delete
                </div>
              ),
              icon: <DeleteIcon className="text-gray-700 " />,
            }
          : null,
      ];
      return (
        <div className="bg-white absolute top-[-18px] right-4 h-9 border border-gray-100 flex justify-center items-center  rounded-[4px] ">
          <Popover
            trigger={["click"]}
            getPopupContainer={(trigger) => trigger.parentElement}
            //open={true}
            arrow={false}
            placement="bottomLeft"
            overlayStyle={{ background: "transparent" }}
            overlayInnerStyle={{ background: "transparent", boxShadow: "none" }}
            content={reactionOverLay}
          >
            {/* <Tooltip
              title="Add reaction"
              getPopupContainer={(trigger) => trigger.parentElement}
            > */}
            <button className="w-5 h-5 mx-2">
              <EmojiIcon className="text-gray-700 text-[20px]" />
            </button>
            {/* </Tooltip> */}
          </Popover>
          <Tooltip
            title="Reply"
            getPopupContainer={(trigger) => trigger.parentElement}
          >
            <button
              className="w-5 h-5 mx-2"
              onClick={() => {
                dispatch(
                  updateConversations({
                    key: "replyMessage",
                    value: {
                      reply: true,
                      message: message.message,
                      messageObject: message,
                    },
                  })
                );
              }}
            >
              <MessageReplyIcon className="text-gray-700" />
            </button>
          </Tooltip>

          <button className="w-5 h-5 mx-2">
            <Dropdown
              menu={{ items }}
              trigger={["click"]}
              dropdownRender={(menu) => <div className="w-[200px]">{menu}</div>}
              getPopupContainer={(trigger) =>
                trigger.parentElement.parentElement
              }
            >
              <DotsVerticalIcon className="text-gray-700" />
            </Dropdown>
          </button>

          {/* {message.attachments?.length > 0 ? (
            <Tooltip
              title="Download"
              getPopupContainer={(trigger) => trigger.parentElement}
            >
              <button
                className="w-5 h-5 mx-2"
                onClick={() => {
                  const urls = message.attachments.map((a) => a.url);
                  downloadFiles(urls);
                }}
              >
                <DownloadIcon height={20} width={20} />
              </button>
            </Tooltip>
          ) : (
            <></>
          )} */}
        </div>
      );
    }
  };

  const getAttachements = (attachments) => {
    if (attachments.length === 0) return;

    const newAttacments = attachments.map((attachment) => {
      return { ...attachment, messageId: message._id };
    });

    return <Album files={newAttacments} message={message} />;
  };

  const messsageBody = (message) => {
    const senderProfilePicture = tenantMembers.find(
      (t) => t.id == message.senderId
    )?.profilePicture;
    return (
      <div className="flex ">
        <div className="hidden group-hover:block">{getHoverMenu()}</div>
        {showSender ? (
          <CustomAvatar
            title={message.senderName}
            size={28}
            src={senderProfilePicture}
            className=""
            whiteText
          />
        ) : (
          <div className="h-full mt-1.5  items-start flex w-[28px] ">
            <Tooltip
              title={
                message.createdAt
                  ? dayjs(message.createdAt)
                      .utc()
                      .local()
                      .format("MMMM DD YYYY, hh:mm a")
                  : "-"
              }
            >
              <div className="hidden text-gray-500 out-300-10 group-hover:block">
                {message.createdAt
                  ? dayjs(message.createdAt).utc().local().format("HH:mm")
                  : "-"}
              </div>
            </Tooltip>
          </div>
        )}
        <div className="w-full ml-2">
          {showSender && (
            <div className="flex items-center gap-x-1.5">
              <UserDetailsCard
                userName={message.senderName}
                isDisable={false}
                userId={message.senderId}
              />
              {message.request?.type && (
                <img
                  src="/images/v2/conversations/AttachmentHeader.svg "
                  className="w-[26px] h-5"
                ></img>
              )}
              <Tooltip
                title={
                  message.createdAt
                    ? dayjs(message.createdAt)
                        .utc()
                        .local()
                        .format("MMMM DD YYYY, hh:mm a")
                    : "-"
                }
              >
                <div className="text-gray-500 out-300-10 ">
                  {message.createdAt
                    ? dayjs(message.createdAt).utc().local().format("hh:mm a")
                    : "-"}
                </div>
              </Tooltip>
            </div>
          )}

          <div className="mt-1">
            {message.replyMessage && message.replyMessage._id && (
              <div className="flex bg-gray-50 group-hover:bg-gray-25 mb-1 rounded-[4px] opacity-80 transition-all duration-300 p-1 items-start w-full">
                <img
                  src="/images/v2/conversations/message-reply-2.svg"
                  className="w-3 h-3 mt-1"
                />
                <div>
                  <div className="flex items-center">
                    {" "}
                    <span className="ml-1 text-gray-400 capitalize out-500-12">
                      {message.replyMessage.senderName}
                    </span>
                  </div>
                  <div className="mt-1 ml-1 ">
                    <ParseMentions
                      value={message.replyMessage.message}
                      className={"  out-300-12 text-gray-500"}
                    />
                  </div>
                </div>
                {/* <div className="mt-1 ml-2">
                      {message.replyMessage.attachments &&
                        message.replyMessage.attachments.length > 0 &&
                        message.replyMessage.attachments.map((att, i) => (
                          <Attachments
                            type={att.type}
                            url={att.url}
                            thumbnail={att.thumbnail}
                            key={i}
                            fileName={att.name}
                          />
                        ))}
                    </div> */}
              </div>
            )}
            <ParseMentions value={message.message} />
          </div>
          {
            // allUrl && allUrl !== null && allUrl.length > 0 ? (
            //   allUrl.map((url, i) => (
            //     <div className="px-2 py-2 overflow-hidden rounded" key={i}>
            //       {/* <ReactTinyLink
            //                   cardSize="large"
            //                   showGraphic={true}
            //                   minLine={1}
            //                   url={url}
            //                 /> */}
            //       {/* <UrlThumbnail url={url} /> */}
            //     </div>
            //   ))
            // ) : (
            //   <></>
            // )
          }
          {message.attachments && getAttachements(message.attachments)}
          <div className={`flex items-center ${"justify-start"} gap-x-2`}>
            {message.reactions &&
              Object.keys(message.reactions).map(
                (emoji, index) =>
                  message.reactions[emoji].length > 0 && (
                    <button
                      className="flex items-center pt-2 gap-x-2 "
                      key={index}
                      onClick={() =>
                        reactToMessage(
                          emoji,
                          message._id,
                          message.reactions[emoji].includes(user.id)
                        )
                      }
                    >
                      <div
                        className="flex items-center py-0.5 px-2  h-6 bg-white rounded-2xl border border-gray-200"
                        onMouseEnter={() => console.log("hi")}
                        style={{
                          padding: "2px, 8px, 2px, 8px",
                        }}
                      >
                        <span className="text-lg">{emoji}</span>
                        {message.reactions[emoji].length > 1 && (
                          <span className="pl-1 text-gray-700 out-500-12">
                            {message.reactions[emoji].length}
                          </span>
                        )}
                      </div>
                    </button>
                  )
              )}
          </div>
        </div>
      </div>
    );
  };

  return (
    // <div
    //   className={`flex   rounded-lg gap-x-2 ${container}`}
    //   onMouseEnter={() => setHover(true)}
    //   onMouseLeave={() => setHover(false)}
    // >
    //   <div
    //     className={`flex flex-col ${alignment} px-4 ${
    //       showSender ? "" : ""
    //     }  rounded-lg`}
    //   >
    //     <div className={`flex  gap-x-2 `}>
    //       {/* <div
    //           className=""
    //           style={{
    //             height: "6px",
    //             width: "6px",
    //             backgroundColor: "#667085",
    //             borderRadius: "50%",
    //           }}
    //         /> */}
    //       {/* {position !== "end" && showSender && (
    //           <CustomAvatar
    //             title={position === "end" ? "You" : message.senderName}
    //             size={40}
    //           />
    //         )} */}
    //       {position !== "end" ? (
    //         showSender ? (
    //           <CustomAvatar
    //             title={position === "end" ? "You" : message.senderName}
    //             size={40}
    //             src={senderProfilePicture}
    //             className="mt-2"
    //           />
    //         ) : (
    //           <div className="w-[40px] h-[40px]"></div>
    //         )
    //       ) : (
    //         <></>
    //       )}
    //       <div>
    //         {showSender && (
    //           <div
    //             className={`flex mt-2 gap-x-2  ${
    //               position === "end" ? "justify-end" : "justify-start"
    //             }`}
    //           >
    //             <UserDetailsCard
    //               userName={position === "end" ? "You" : message.senderName}
    //               isDisable={false}
    //               userId={position === "end" ? user.id : message.senderId}
    //             />

    //             <div className="text-gray-500 mon-400-12 ">
    //               {message.createdAt
    //                 ? dayjs(message.createdAt).utc().local().format("hh:mm a")
    //                 : "-"}
    //             </div>
    //           </div>
    //         )}

    //         <div>
    //           <div
    //             className={`flex ${showSender ? "mt-2" : "mt-1"} gap-x-2 ${
    //               position === "end" ? "flex-row-reverse" : ""
    //             }`}
    //           >
    //             <div
    //               className={`mon-400-12 max-w-[32vw] h-auto  whitespace-pre-wrap break-words   float-right ${
    //                 position === "end"
    //                   ? "bg-primary-600 text-white"
    //                   : "bg-gray-50 text-black"
    //               }`}
    //               style={{
    //                 borderRadius:
    //                   position === "end"
    //                     ? "8px 0px 8px 8px"
    //                     : "0px 8px 8px 8px",
    //               }}
    //             >
    //               {message.replyMessage && message.replyMessage._id && (
    //                 <div
    //                   className={` mx-2 my-2 px-1.5 py-1.5  rounded ${
    //                     position === "end"
    //                       ? "bg-primary-700 text-gray-200 border-l-4 border-[#0D3271]"
    //                       : "bg-gray-200 text-gray-500 border-l-4 border-gray-300"
    //                   }`}
    //                 >
    //                   <div className="flex items-center">
    //                     {" "}
    //                     <span className="ml-2 capitalize mon-400-12">
    //                       {message.replyMessage.senderName}
    //                     </span>
    //                   </div>
    //                   <div className="mt-1 ml-2 ">
    //                     <ParseMentions
    //                       value={message.replyMessage.message}
    //                       className={
    //                         position === "end"
    //                           ? "bg-primary-900 text-gray-400"
    //                           : "bg-gray-300 text-gray-500"
    //                       }
    //                     />
    //                   </div>
    //                   <div className="mt-1 ml-2">
    //                     {message.replyMessage.attachments &&
    //                       message.replyMessage.attachments.length > 0 &&
    //                       message.replyMessage.attachments.map((att, i) => (
    //                         <Attachments
    //                           type={att.type}
    //                           url={att.url}
    //                           thumbnail={att.thumbnail}
    //                           key={i}
    //                           fileName={att.name}
    //                         />
    //                       ))}
    //                   </div>
    //                 </div>
    //               )}

    //               {message.attachments && getAttachements(message.attachments)}
    //               <div className="px-2 py-[9px]">
    //                 <ParseMentions value={message.message} />
    //               </div>
    //               {allUrl && allUrl !== null && allUrl.length > 0 ? (
    //                 allUrl.map((url, i) => (
    //                   <div
    //                     className="px-2 py-2 overflow-hidden rounded"
    //                     key={i}
    //                   >
    //                     {/* <ReactTinyLink
    //                         cardSize="large"
    //                         showGraphic={true}
    //                         minLine={1}
    //                         url={url}
    //                       /> */}
    //                     <a
    //                       className="hover:text-primary-900"
    //                       href={url}
    //                       target="_blank"
    //                       rel="noreferrer"
    //                     >
    //                       {url}
    //                     </a>
    //                   </div>
    //                 ))
    //               ) : (
    //                 <></>
    //               )}
    //             </div>
    //             {hover && getHoverMenu()}
    //           </div>
    //           <div
    //             className={`flex items-center ${
    //               position === "end" ? "justify-end" : "justify-start"
    //             } gap-x-2`}
    //           >
    //             {message.reactions &&
    //               Object.keys(message.reactions).map(
    //                 (emoji, index) =>
    //                   message.reactions[emoji].length > 0 && (
    //                     <button
    //                       className="flex items-center pt-2 gap-x-2"
    //                       key={index}
    //                       onClick={() =>
    //                         reactToMessage(
    //                           emoji,
    //                           message._id,
    //                           message.reactions[emoji].includes(user.id)
    //                         )
    //                       }
    //                     >
    //                       <div
    //                         className="flex items-center h-6 bg-gray-50 rounded-2xl"
    //                         style={{
    //                           padding: "2px, 8px, 2px, 8px",
    //                         }}
    //                       >
    //                         <span className="text-lg">{emoji}</span>
    //                         {message.reactions[emoji].length > 1 && (
    //                           <span className="px-1 text-gray-700 mon-500-14">
    //                             {message.reactions[emoji].length}
    //                           </span>
    //                         )}
    //                       </div>
    //                     </button>
    //                   )
    //               )}
    //           </div>
    //           {image && (
    //             <div className="pt-5 w-[493px] h-[340px]">
    //               <img src="/images/Thumbnail.png" className="w-full h-full" />
    //             </div>
    //           )}
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </div>

    <div
      className={` px-4 py-1 scroll-mt-[20vh]  relative ${
        allowHover ? "group hover:bg-gray-100" : ""
      }`}
      data-message-id={message._id}
    >
      {/* <Attachments
        type={"video"}
        url={"/"}
        onClick={console.log("click click")}
        thumbnail={"/images/"}
        key={1}
        fileName={"att.name"}
      /> */}
      {/* <Thumbnail
        type={"video"}
        url={"/"}
        onClick={console.log("click click")}
        thumbnail={"/images/"}
        key={1}
        fileName={"att.name"}
      />
      <Thumbnail
        type={"image"}
        url={"/"}
        onClick={console.log("click click")}
        thumbnail={"/images/"}
        key={1}
        fileName={"att.name"}
      />
      <Thumbnail
        type={"document"}
        url={"/"}
        onClick={console.log("click click")}
        thumbnail={"/images/"}
        key={1}
        fileName={"att.name"}
      /> */}
      {messsageBody(message)}

      {message.request?.activity?.length > 0 && (
        <div className="pl-2 my-5 ml-6 border-l">
          <div
            className="flex cursor-pointer gap-x-1"
            onClick={() => setRepliesOpen(!repliesOpen)}
          >
            {" "}
            <p className="text-primary-600 out-500-14">
              {" "}
              {message.request?.activity?.length} Reply
            </p>{" "}
            <ChevronIcon className="text-gray-700" />
          </div>
          {repliesOpen && (
            <div className="mt-3">
              {message.request?.activity.map((message) => (
                <div className="mt-2" key={message._id}>
                  {messsageBody(message)}
                </div>
              ))}
              {message.request?.status === "Closed" && (
                <div className="mt-1 text-success-600 out-500-14 ">
                  Closed this request on{" "}
                  {message.request?.requestClosingDate
                    ? dayjs(message.request?.requestClosingDate)
                        .utc()
                        .local()
                        .format("MMMM DD YYYY, hh:mm a")
                    : "-"}
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export const Day = ({ date }) => {
  const formateDate = (date) => {
    if (date === null || typeof date === "undefined") return "-";
    if (dayjs(date).isToday()) return "Today";
    if (dayjs(date).isTomorrow()) return "Tomorrow";
    return dayjs(date).format("MMMM DD YYYY");
  };
  return (
    <div className="px-[10px] py-[5px] mx-auto my-1 border w-max shadow-xs rounded-[4px] border-gray-200">
      <p className="text-gray-700 out-300-14">{formateDate(date)}</p>
    </div>
  );
};

export const Unread = ({ count }) => {
  return (
    <div className="w-full my-1 text-center underline out-500-14 text-error-500 ">
      {count} New messages
    </div>
  );
};

// reactToMessage,
// deleteMessage,
export function TypingIndicator({ typingStatus, currentThread }) {
  const isPrivateThread = currentThread.type === "private";
  const isMatchingUser = +typingStatus.userId === +currentThread.userId;
  const isMatchingThread = +typingStatus.threadId === +currentThread.threadId;

  // useEffect(() => {
  //   console.log(isTyping, typingStatus.isTyping, "typingStatus");
  //   if (typingStatus.isTyping) {
  //     setIsTyping(typingStatus.isTyping);
  //   }
  //   if (!typingStatus.isTyping) {
  //     setTimeout(() => {
  //       if (!typingStatus.isTyping) {
  //         setIsTyping(typingStatus.isTyping);
  //       }
  //     }, 5000);
  //   }
  // }, [typingStatus]);

  if (
    !typingStatus.isTyping ||
    (isPrivateThread ? !isMatchingUser : !isMatchingThread)
  ) {
    return <></>;
  }

  if (currentThread.type !== typingStatus.threadType) {
    return <></>;
  }

  return (
    <div className="pl-16 mt-1.5 mb-0 flex items-center">
      <div className="dot-collision" />

      <span className="italic text-gray-500 ml-7 mon-500-10">
        {currentThread.type === "group"
          ? typingStatus.userName + " " + "is"
          : ""}{" "}
        Typing.....
      </span>
    </div>
  );
}

export default Message;
