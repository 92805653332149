import React, { useEffect, useState } from "react";
import { ChevronIcon } from "../../../Core/svgV2/Chevron";
import { Popover, Tooltip } from "antd";
import { CheckIcon } from "../../../Core/svgV2/Check";
import CustomButton from "../../../Core/CommonV2/CustomButton";
import { CrossIcon } from "../../../Core/svgV2/CrossIcon";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";

import UnwaddleModal from "../Modals/UnwaddleModal";
import usePermission from "../../../Core/hooks/usePermission";
import { updateSchedule } from "../../../Core/redux/slices/schedule.slice";

export const TaskType = ({ onTaskTypeChange, value = 1 }) => {
  const [selectedType, setSelectedType] = useState(value);
  const [popoverOpen, setPopoverOpen] = useState(false);

  useEffect(() => {
    setSelectedType(value);
  }, [value]);
  const getTaskDetails = (taskType) => {
    switch (+taskType) {
      case 1:
        return { src: "/images/v2/schedule/Task.svg", taskName: "Task" };
      case 2:
        return { src: "/images/v2/schedule/meet.svg", taskName: "Event" };
      case 3:
        return { src: "/images/v2/schedule/Bug.svg", taskName: "Bug" };
      case 4:
        return { src: "/images/v2/schedule/Subtask.svg", taskName: "Subtask" };
      default:
        return {
          src: "/images/v2/schedule/Task.svg",
          taskName: "Task",
        };
    }
  };

  const { hasPermission: SUB_TASK_AND_BUG_ADD } = usePermission("11");

  const TaskItem = ({ taskType }) => {
    const { taskName, src } = getTaskDetails(taskType);
    return (
      <div
        onClick={() => {
          onTaskTypeChange(taskType);
          setSelectedType(taskType);
          setPopoverOpen(false);
        }}
        className="flex cursor-pointer py-2.5 px-3.5 hover:bg-gray-50 rounded-t-lg justify-between"
      >
        <div className="flex  items-center">
          <img src={src} />
          <span className="out-300-14 text-gray-700 ml-2">{taskName}</span>
        </div>
        {selectedType == taskType && <CheckIcon className="text-primary-600" />}
      </div>
    );
  };

  const { src } = getTaskDetails(selectedType);
  return (
    <Popover
      arrow={false}
      open={popoverOpen}
      placement="bottomLeft"
      onOpenChange={(val) => setPopoverOpen(val)}
      content={
        <div className="w-[200px] bg-white shadow-lg rounded-lg ">
          <TaskItem taskType={1} />
          {SUB_TASK_AND_BUG_ADD && <TaskItem taskType={3} />}
          {/* <TaskItem taskType={2} /> */}
          <TaskItem taskType={4} />
        </div>
      }
      trigger={["click"]}
    >
      <button
        className="flex items-center cursor-pointer"
        onClick={() => setPopoverOpen(true)}
      >
        <img src={src} />
        <img
          className={`ml-2 ${
            popoverOpen
              ? "transform rotate-180 duration-200"
              : "transform rotate-0 duration-200"
          }`}
          src="/images/v2/schedule/chevron-down-filled.svg"
        />
      </button>
    </Popover>
  );
};

const TaskNavigation = () => {
  const { taskId, projectId } = useParams();
  const { myBoardAllGoals } = useAppSelector((state) => state.myBoard);
  const navigate = useNavigate();

  const newGoals = [...myBoardAllGoals].sort((a, b) => a.statusId - b.statusId);
  const currentTaskIndex = newGoals.findIndex((g) => g.id === taskId);
  const handlePrevClick = () => {
    if (currentTaskIndex > 0) {
      const prevGoal = newGoals[currentTaskIndex - 1];

      navigate(`/my-board/task/${prevGoal.id}`);
    }
  };

  const handleNextClick = () => {
    if (currentTaskIndex < newGoals.length - 1) {
      const nextGoal = newGoals[currentTaskIndex + 1];
      navigate(`/my-board/task/${nextGoal.id}`);
    }
  };

  return (
    <>
      <button
        className="h-[30px] w-[30px] border rounded border-gray-200 flex justify-center items-center"
        onClick={handlePrevClick}
        disabled={!(currentTaskIndex > 0)}
        style={{
          opacity: !(currentTaskIndex > 0) ? 0.5 : 1,
        }}
      >
        <ChevronIcon className="text-gray-500 transform rotate-180" />
      </button>

      <button
        className="h-[30px] w-[30px] border rounded border-gray-200 flex justify-center items-center"
        onClick={handleNextClick}
        disabled={!(currentTaskIndex < newGoals.length - 1)}
        style={{
          opacity: !(currentTaskIndex < newGoals.length - 1) ? 0.5 : 1,
        }}
      >
        <ChevronIcon className="text-gray-500 transform" />
      </button>
    </>
  );
};

const TaskHeader = ({
  taskType,
  handleSubmit,
  task,
  onCancel,
  editedTaskDetails = null,
}) => {
  const { user } = useAppSelector((state) => state.userDetails);
  const { myBoardTasks } = useAppSelector((state) => state.myBoard);
  const { projectId } = useParams();
  const navigate = useNavigate();
  const { taskId, subtaskId } = useParams();
  const [parentGoal, setParentGoal] = useState(null);

  const dispatch = useAppDispatch();

  const getByTitle = (id) => {
    switch (id) {
      case 1:
        return "New Task";
      case 3:
        return "New Bug";
      case 4:
        return "New Subtask";
      default:
        return "New Task";
    }
  };
  const getBreadcrumb = () => {
    if (subtaskId) {
      const parentGoalIdentifier = myBoardTasks?.result?.find(
        (parentTask) => parentTask.id === task.parentGoalId
      ).identifier;

      return (
        <Tooltip title={parentGoal?.name} placement="bottom" trigger={"hover"}>
          <div className=" flex w-full cursor-pointer gap-x-1">
            <span className="uppercase  text-gray-500 out-500-14">
              {`MB-${parentGoalIdentifier ? parentGoalIdentifier : taskId}`}
            </span>
            <span className="max-w-[200px] truncate text-gray-500 out-500-14">
              {parentGoal?.name}
            </span>
            <span className="text-gray-900 out-500-14 ">
              {" "}
              {`/ SMB-${task.identifier ? task.identifier : subtaskId}`}
            </span>
          </div>
        </Tooltip>
      );
    } else {
      return `MB-${task.identifier ? task.identifier : taskId}`;
    }
  };

  useEffect(() => {
    if (subtaskId === "new") {
      const parentTask = myBoardTasks?.result?.find((goal) =>
        task?.parentGoalId ? goal.id === task?.parentGoalId : goal.id === taskId
      );

      setParentGoal(parentTask);
    } else {
      const parentTask = myBoardTasks?.result?.find((goal) =>
        editedTaskDetails?.parentGoalId
          ? goal.id === editedTaskDetails?.parentGoalId
          : goal.id === taskId
      );

      setParentGoal(parentTask);
    }
  }, [editedTaskDetails, task]);
  return (
    <div className="flex justify-between items-center px-6 py-2 bg-white border-b  border-gray-200">
      <div className="flex items-center gap-x-3">
        <TaskNavigation />

        <span className=" flex">
          {subtaskId === "new" ? (
            <Tooltip title={parentGoal?.name} placement="bottom">
              <div className="flex text-gray-500">
                <p className=" max-w-[200px] truncate">
                  {parentGoal?.identifier ? parentGoal?.identifier : taskId} -
                  <span className=" out-500-14 truncate">
                    {" "}
                    {parentGoal?.name}
                  </span>
                </p>
                <p>&nbsp;{" / "} &nbsp;</p>
              </div>
            </Tooltip>
          ) : (
            <></>
          )}
          {taskId === "new" || subtaskId === "new"
            ? getByTitle(taskType)
            : getBreadcrumb()}
        </span>
      </div>
      <div className="flex items-center gap-x-3">
        <>
          <CustomButton text="Save" height="30px" onClick={handleSubmit} />
          <CustomButton
            onClick={onCancel}
            text="Cancel"
            className="bg-transparent text-gray-500"
            height="30px"
            width="64px"
          />
        </>

        <button
          onClick={onCancel}
          className="h-[30px] w-[30px] hover:bg-gray-50 flex items-center justify-center"
        >
          <CrossIcon className="text-gray-700" />
        </button>
      </div>
    </div>
  );
};

export default TaskHeader;
