import React, { useState } from "react";
import { useDraggable } from "@dnd-kit/core";
import {
  getPhaseName,
  getPhaseColor as getPhaseColorDark,
} from "../../../../Core/utils/getPhaseName";
import { PhaseIcon } from "../../../../Core/svgV2/PhaseIcon";

import HandleIcon from "../../../../Core/svgV2/HandleIcon";
import usePermission from "../../../../Core/hooks/usePermission";
import { PlusIcon } from "../../../../Core/svgV2/PlusIcon";
import dayjs from "dayjs";
import { dayDiff } from "../../../../ModulesV2/Schedule/Roadmap/GanttChart/dateFunctions";
import { Tooltip } from "antd";

const Resize = ({
  phaseColorDark = "#DDE5ED",
  height = "44",
  type = "left",
  width,
  handleMouseDown,
  disable = false,
}: {
  phaseColorDark?: string;
  height?: string;
  type?: string;
  width?: string;
  disable?: boolean;
  handleMouseDown?: (e, type, callback) => void;
}) => {
  const [isResizing, setIsResizing] = useState(false);
  if (disable) {
    return <div className="w-3"></div>;
  }
  return (
    <div className="w-3">
      <button
        className={`group-hover:block cursor-col-resize ${
          isResizing ? "block" : "hidden"
        } float-right`}
        onMouseDown={(e) => {
          setIsResizing(true);

          handleMouseDown(e, type === "left" ? "toLeft" : "toRight", () =>
            setIsResizing(false)
          );
        }}
        onMouseUp={() => setIsResizing(false)}
      >
        <HandleIcon
          style={{ color: phaseColorDark }}
          className={type === "right" && "rotate-[180deg]"}
          height={height}
          width={width}
        />
      </button>
    </div>
  );
};

const GanttPhaseCard = ({
  phaseId,
  task,
  handleMouseDown,

  width = 0,
}) => {
  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id: task.projectPhaseId + "-PhaseCard",
    disabled: true,
    data: {
      task,
    },
  });

  const style = transform
    ? {
        transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
      }
    : undefined;

  const getPhaseColor = (id) => {
    switch (+id) {
      case 1:
        return "#FFEF90";
      case 2:
        return "#FFA5BD";
      case 3:
        return "#BDB4FE";
      case 4:
        return "#A4DFFF";
      case 5:
        return "#A0FABA";
      default:
        return "#FFDB01";
    }
  };

  const phaseName = getPhaseName(phaseId);
  const phaseColor = getPhaseColor(phaseId);
  const phaseColorDark = getPhaseColorDark(phaseId);
  const numDays = dayjs(task.startDate).diff(dayjs(task.endDate), "day");
  const height = 250;

  return (
    <div
      className={`flex    group items-center   rounded min-h-[44px] max-h-[44px]`}
      style={{ width: `${width}px` }}
    >
      {/* <Resize
        phaseColorDark={phaseColorDark}
        handleMouseDown={handleMouseDown}
        disable={!hasPermission}
      /> */}
      <div
        ref={setNodeRef}
        {...listeners}
        {...attributes}
        className={`flex  relative gap-x-2.5 flex-1 h-[44px] items-center cursor-pointer group `}
        style={{ width: `${width}px` }}
      >
        <span
          className={`out-500-16 left-0   text-black absolute  h-screen  top-[-28px] `}
          style={{
            ...style,
            width: `${width}px`,
            borderLeft: `2px solid ${phaseColor} `,
            borderRight: `2px solid ${phaseColor}`,
          }}
        >
          <div
            className={`h-screen w-[${width}]`}
            style={{ background: phaseColor, opacity: 0.5 }}
          ></div>
          <div
            className={` hidden group-hover:flex justify-between  text-gray-700 items-center min-w-[160px]  absolute h-[18px] top-[-38px] px-3 rounded-[9px]`}
            style={{
              background: phaseColor,
              width: `${width}px`,
              left: width < 150 ? `-${width + 15}px` : 0,
            }}
          >
            <p className="out-500-10 text-gray-500">
              {dayjs(task.startDate).format("MMM DD")}
            </p>
            <p className="out-500-12">{phaseName}</p>
            <p className="out-500-10 text-gray-500">
              {dayjs(task.endDate).format("MMM DD")}
            </p>
          </div>
        </span>
      </div>
      {/* <Resize
        phaseColorDark={phaseColorDark}
        type="right"
        handleMouseDown={handleMouseDown}
        disable={!hasPermission}
      /> */}
    </div>
  );
};
export const GanttLabelCard = ({ phaseId, task, handleMouseDown }) => {
  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id: task.projectPhaseId + "-PhaseCard",
    disabled: true,
    data: {
      task,
    },
  });

  const style = transform
    ? {
        transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
      }
    : undefined;

  const getPhaseColor = (id) => {
    switch (+id) {
      case 1:
        return "#FFEF90";
      case 2:
        return "#FFA5BD";
      case 3:
        return "#BDB4FE";
      case 4:
        return "#A4DFFF";
      case 5:
        return "#A0FABA";
      default:
        return "#FFDB01";
    }
  };

  const phaseName = task.name;
  const phaseColor = getPhaseColor(task.color);
  const phaseColorDark = getPhaseColorDark(phaseId);

  return (
    <div
      className=" group items-center shadow-m  rounded-[2px] min-h-1.5 max-h-1.5 "
      style={{ background: task.color, ...style, zIndex: 25 }}
    >
      {/* <Resize
        phaseColorDark={phaseColorDark}
        handleMouseDown={handleMouseDown}
        disable={!hasPermission}
      /> */}
      <div
        ref={setNodeRef}
        {...listeners}
        {...attributes}
        className={` relative  h-[44px]  items-center cursor-pointer  `}
      >
        <div className=" flex justify-start relative ">
          <div className="sticky left-0">
            <div className="flex absolute w-max top-[-20px] gap-x-1 justify-start items-center  max-w-screen">
              <div className="out-500-12 flex   text-black capitalize">
                {phaseName}
              </div>
              <div className="w-[4px] h-[4px] rounded bg-gray-500"></div>
              <div className="flex out-300-12 text-gray-500">
                {dayjs(task.startDate).format("MMM DD")}-
                {dayjs(task.endDate).format("MMM DD")}
              </div>
              <div className="w-1 h-1 rounded bg-gray-500"></div>
              <div className="flex out-300-12 text-gray-500">
                {dayjs(task.endDate).diff(task.startDate, "day")} days
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Resize
        phaseColorDark={phaseColorDark}
        type="right"
        handleMouseDown={handleMouseDown}
        disable={!hasPermission}
      /> */}
    </div>
  );
};

export const GanttSprintCard = ({ sprintId, task, handleMouseDown }) => {
  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id: `${sprintId}-SprintCard`,
    disabled: true || task.sprint?.status === 3,
    data: {
      task,
    },
  });

  const style = transform
    ? {
        transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
      }
    : undefined;

  return (
    <div
      className="flex group items-center bg-white shadow-m rounded min-h-[30px] max-h-[30px]"
      style={{ ...style }}
    >
      <Resize
        height="30"
        handleMouseDown={handleMouseDown}
        disable={true || task.sprint?.status === 3}
      />
      <div
        ref={setNodeRef}
        {...listeners}
        {...attributes}
        className={`flex  gap-x-2.5 flex-1 h-[44px] items-center cursor-pointer `}
      >
        <span className="out-300-14  text-black">
          {task.sprintName}{" "}
          {task.sprint?.status === 3
            ? "(Completed)"
            : task.sprint?.status === 2
            ? "(Active)"
            : ""}
        </span>
      </div>
      <Resize
        height="30"
        type="right"
        width="8.18"
        disable={true || task.sprint?.status === 3}
        handleMouseDown={handleMouseDown}
      />
    </div>
  );
};
export const GanttGoalCard = ({ labelId, task, handleMouseDown }) => {
  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id: `${labelId}-SprintCard`,
    disabled: true,
    data: {
      task,
    },
  });

  const style = transform
    ? {
        transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
      }
    : undefined;

  return (
    <Tooltip
      title={`${dayjs(task.startDate).format("MMM DD")} - ${dayjs(
        task.endDate
      ).format("MMM DD")}`}
      placement="bottomRight"
    >
      <div
        className="flex w-full relative group bg-gray-300 items-center  rounded-[2px] h-4"
        style={{ ...style }}
      >
        <div
          ref={setNodeRef}
          {...listeners}
          {...attributes}
          className={`flex  gap-x-2.5 flex-1 h-[44px] items-center cursor-pointer `}
        ></div>

        <span className="out-300-12  w-full truncate   absolute ml-1 left-full top-[-2px] text-black">
          {task.name}{" "}
        </span>
      </div>
    </Tooltip>
  );
};

export default GanttPhaseCard;
