import React, { useState } from "react";
import { ChevronIcon } from "../../../Core/svgV2/Chevron";
import { PlusIcon } from "../../../Core/svgV2/PlusIcon";
import { Dropdown, Tooltip } from "antd";
import { DotsVerticalIcon } from "../../../Core/svgV2/DotsVertical";
import { DownloadIcon } from "../../../Core/svgV2/DownloadIcon";
import { DeleteIcon } from "../../../Core/svgV2/DeleteIcon";
import CustomDragAndDrop from "../../../Core/CommonV2/CustomDragAndDrop";
import { useAppDispatch } from "../../../Core/redux/hooks";

import { downloadFiles } from "../../../Core/utils";
import { updateConversations } from "../../../Core/redux/slices/conversations.slice";

const Image = ({ src, fileName, file, onDelete }) => {
  const dispatch = useAppDispatch();
  const items = [
    {
      key: "1",
      label: <div className="out-300-14 text-gray-700">Download</div>,
      icon: <DownloadIcon className="text-gray-700" />,
    },

    // {
    //   key: "2",
    //   label: <div className="out-300-14 text-gray-700  ">Save in library</div>,
    //   icon: <SaveIcon className="text-gray-700 " />,
    // },
    {
      key: "3",
      label: <div className="out-300-14 text-gray-700  ">Delete</div>,
      icon: <DeleteIcon className="text-gray-700 " />,
    },
  ];

  const onClick = ({ key }) => {
    switch (key) {
      case "1":
        downloadFiles([src], file.fileSize);
        break;
      case "2":
        break;
      case "3":
        onDelete(file);
        break;
      default:
        break;
    }
  };

  // const IMG_FORMATS = ["gif", "png", "jpg", "jpeg"];
  const VIDEOS_FORMATS = [
    "mp4",
    "mp4",
    "mov",
    "avi",
    "flv",
    "wmv",
    "mkv",
    "webm",
  ];

  const getThumbnail = (file) => {
    if (file?.url?.includes("svg")) return file.url;
    if (
      file?.url &&
      VIDEOS_FORMATS.some((format) => file.url.includes(format))
    ) {
      return "/images/thumbnails/video.svg";
    }

    console.log(file);

    return file.mimeType?.includes("image") || file.type?.includes("image")
      ? `https://images.weserv.nl/?url=${file?.url}&h=78&q=70&output=webp`
      : "/images/icons/no-files.svg";
  };

  return (
    <div>
      <div
        role="button"
        className="max-h-[100px] group relative truncate max-w-[100px] min-h-[100px] min-w-[100px] rounded-lg border flex justify-center items-center border-gray-200"
      >
        <img
          className="max-h-[100px] h-[78px] object-contain "
          src={getThumbnail(file)}
          role="button"
          onClick={() => {
            dispatch(
              updateConversations({
                key: "filePreview",
                value: {
                  visible: true,
                  currentIndex: 0,
                  from: "library",
                  docs: [file],
                  createdAt: file.createdAt,
                },
              })
            );
          }}
        />

        <Dropdown
          menu={{ items, onClick }}
          dropdownRender={(origin) => <div className="w-[200px]">{origin}</div>}
          trigger={["click"]}
        >
          <button
            onClick={(e) => e.stopPropagation()}
            className="h-[30px] bg-white  justify-center items-center w-[30px] group-hover:flex shadow-lg border border-gray-200 rounded hidden absolute top-2 right-2"
          >
            <DotsVerticalIcon />
          </button>
        </Dropdown>
      </div>

      <span className="max-w-[100px] out-500-12 text-gray-900 mt-2 inline-block  whitespace-nowrap truncate">
        {fileName}
      </span>
    </div>
  );
};

const Attachments = ({
  showUploadAttachments,
  attachments = [],
  handleUpload,
  setShowUploadAttachments,
}) => {
  const [showAttachments, setShowAttachments] = useState(true);
  const handelDelete = async (currentFile) => {
    const remainFiles = attachments.filter(
      (attachment) => attachment.url !== currentFile.url
    );
    handleUpload(remainFiles);
  };
  return (
    <>
      {/* {showUploadAttachments && (
        <div className="h-[84px]">
          <CustomDragAndDrop
            onUploaded={(e) => handleUpload(e)}
            defaultFileList={attachments}
          />
        </div>
      )} */}
      <div
        className="h-[84px]"
        style={{
          display: showUploadAttachments ? "block" : "none",
        }}
      >
        <CustomDragAndDrop
          onUploaded={(e) => handleUpload(e)}
          defaultFileList={attachments}
        />
      </div>
      {attachments.length > 0 && (
        <div className="mt-4">
          <div className="flex justify-between">
            <div
              className=" flex items-center gap-x-3 cursor-pointer"
              onClick={() => setShowAttachments(!showAttachments)}
            >
              <ChevronIcon
                className={`text-gray-500 mt-0.5 ${
                  showAttachments ? "" : "-rotate-90"
                }`}
              />
              <p className="out-500-14 leading-5 text-black">Attachments</p>
              <span className="out-300-12 text-gray-500">
                {attachments.length}
              </span>
            </div>
            <button onClick={() => setShowUploadAttachments(true)}>
              <Tooltip title="Attach more">
                {" "}
                <PlusIcon className="text-gray-500" />
              </Tooltip>
            </button>
          </div>
          {showAttachments && attachments && attachments.length > 0 && (
            <div className="flex flex-wrap gap-6 mt-3">
              {attachments?.map((attachment) => (
                <Image
                  key={attachment.id}
                  src={attachment.url}
                  fileName={attachment.name}
                  file={attachment}
                  onDelete={handelDelete}
                />
              ))}
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default Attachments;
